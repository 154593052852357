import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../utils/API";
import { toast } from "react-toastify";

export const getAllJournal = createAsyncThunk(
  "get-journal",
  async ({ pageNo }) => {
    try {
      const response = await api.get(
        `/general-journal/all-journal?limit=6&page=${pageNo}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(`Error fetching journal data`, error);
    }
  }
);

export const deleteJournal = createAsyncThunk(
  "delete-journal",
  async ({ id }) => {
    try {
      const response = await api.post(
        "/general-journal/delete-journal",
        { id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
          },
        }
      );
      if (response.data.success === true) {
        toast.success(response.data.message);
        window.location.reload();
      }
      return response.data;
    } catch (error) {
      console.log(`Error fetching journal data`, error);
    }
  }
);

export const findJournal = createAsyncThunk(
  "find-Journal",
  async ({ startDate, endDate }) => {
    try {
      const response = await api.post(
        "/general-journal/find-by-date-journal",
        {
          startDate,
          endDate,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(` Something went wrong`, error);
    }
  }
);

export const allJournal = createAsyncThunk(
  "get-all-journal",
  async ({ pageNo }) => {
    try {
      const response = await api.get(
        `/general-journal/all-journals?limit=${50}&page=${pageNo}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
          },
        }
      );
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(`Error fetching journal data`, error);
    }
  }
);

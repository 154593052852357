import React, { useEffect } from "react";
import { Container } from "@mui/material";
import { MdPriceChange } from "react-icons/md";
import { AiOutlineStock, AiOutlineMinusCircle } from "react-icons/ai";

import { useDispatch, useSelector } from "react-redux";
import { fetchProductById } from "../../Redux/Feature/Product/productAction";
import { useParams } from "react-router-dom";

import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";
import Notice from "../../Components/Notice/Notice";

import Tabs from "../../Components/Tab/Tabs";

const SingleProduct = () => {
  const token = localStorage.getItem("NHHD:token");

  const { singleProduct } = useSelector((state) => state.products);
  console.log(singleProduct);
  const prams = useParams();
  const id = prams.id;
  console.log(id);

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetch() {
      dispatch(fetchProductById({ id, token }));
    }
    fetch();
  }, [dispatch, id, token]);

  const stock = `${
    singleProduct.productStock && singleProduct.productStock.stockQuantity
  }`;

  const Minstock = `${
    singleProduct.productStock && singleProduct.productStock.minimumStock
  }`;

  const status = stock > Minstock;
  console.log(
    singleProduct.product && singleProduct.product.productDescription
  );

  return (
    <>
      <div>
        <BreadCrumb name={"product"} subName={singleProduct.productName} />

        <br />
        <Container className="my-5">
          {status === false ? <Notice /> : null}

          <section className="grid grid-flow-row lg:grid-cols-3 grid-cols-1 gap-3 w-full">
            {[singleProduct].map((item, i) => {
              return (
                <>
                  <div className=" flex  justify-center" key={i}>
                    <div>
                      <div>
                        <img
                          src={item.productImage && item.productImage.url}
                          alt={item.productName}
                          className="rounded-sm  cursor-pointer  w-full h-full"
                        />
                        <p className="py-2 underline">{item.productSKU}</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div>
                      <div className="flex gap-2 ">
                        <p>Product Name</p> <span className="px-2">:</span>{" "}
                        <span className="font-[500] hover:text-green-600 cursor-pointer">
                          {item.productName}
                        </span>
                      </div>
                      <div className=" flex gap-2 py-3">
                        <p>Product Code</p> <span className="px-2">:</span>{" "}
                        <span className="font-[500] hover:text-green-600 cursor-pointer">
                          {item.productCode}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className=" flex gap-2  justify-center">
                    <div className="mx-4">
                      <MdPriceChange className="border rounded-[6px]  bg-[green] text-[white] px-1  text-[3rem]" />
                      <p className="text-[0.8rem] py-1 opacity-30">Price</p>
                      <p className="py-1">{item.productPrice}</p>
                    </div>
                    <div className="mx-4">
                      <AiOutlineStock
                        className="border rounded-[6px]    text-[white] px-1  text-[3rem]"
                        style={{
                          backgroundColor: status === false ? "red" : "green",
                        }}
                      />
                      <p className="text-[0.8rem] py-1 opacity-30">
                        Product stock
                      </p>
                      <p className="py-1">
                        {item.productStock && item.productStock.stockQuantity}
                      </p>
                    </div>
                    <div className="mx-4">
                      <AiOutlineMinusCircle className="border rounded-[6px]  bg-[green] text-[white] px-1  text-[3rem]" />
                      <p className="text-[0.8rem] py-1 opacity-30">
                        minimum Stock
                      </p>
                      <p className="py-1">
                        {item.productStock && item.productStock.minimumStock}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
          </section>
          <section>
            <>
              {" "}
              <Tabs
                desc={singleProduct && singleProduct.productDescription}
                supDetails={singleProduct && singleProduct.productSupplier}
                cateDetails={singleProduct && singleProduct.productCategory}
              />
              <div className="border  mt-[3rem]">
                <p className="py-3 px-2">Product Remarks : </p>
                <hr />
                <p className="px-2 py-3">{singleProduct.productRemarks}</p>
              </div>
            </>
          </section>
        </Container>
      </div>
    </>
  );
};

export default SingleProduct;

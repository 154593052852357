import React from "react";

const Credit = ({
  creditArray,
  debitArray,
  maxLength,
  mainArray,
  totalCreditAmount,
  totalDebitAmount,
}) => {
  return (
    <section className="container px-4 mx-auto">
      <div className="flex flex-col">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden border border-gray-200 md:rounded-lg">
              <p className=" m-2  uppercase opacity-60 text-gray-500">Credit</p>
              <table className="min-w-full divide-y divide-gray-200 fixed-layout">
                <thead className="bg-gray-50">
                  <tr>
                    {th.map((item, i) => {
                      return (
                        <th
                          key={i}
                          class=" px-3  py-2 text-sm font-normal text-left rtl:text-right text-gray-500"
                        >
                          {item.name}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {mainArray.length === 0 ? (
                    <tr>
                      <td colSpan="3" className="text-center">
                        No Data Found
                      </td>
                    </tr>
                  ) : (
                    Array.from({ length: maxLength + 1 }).map((_, index) => {
                      const obj = creditArray[index] || {}; // Retrieve corresponding cash object or empty object
                      return (
                        <tr key={index} className="h-[3rem]">
                          <td className="whitespace-nowrap px-3 py-2">
                            {obj.createdAt?.slice(0, 10) || ""}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2">
                            {obj.credit && obj.credit ? "By, " : null}
                            {obj.namegit && obj.namegit ? "By, " : null}
                            {obj?.credit ? obj?.credit?.name : obj?.name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2">
                            {obj?.credit
                              ? obj?.credit?.amount?.toFixed(2)
                              : obj?.amount?.toFixed(2)}
                          </td>
                        </tr>
                      );
                    })
                  )}
                  {debitArray.length > 0 &&
                    (totalDebitAmount >= totalCreditAmount ? (
                      <tr>
                        <td className="whitespace-nowrap px-3 py-2"></td>
                        <td className="whitespace-nowrap px-3 py-2">
                          <p> By Blance CD:&nbsp; </p>
                        </td>
                        <td className="whitespace-nowrap px-3 py-2">
                          {" "}
                          {totalDebitAmount - totalCreditAmount}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr className="h-[3rem]">
                    <td
                      colSpan={3}
                      align="right"
                      className="whitespace-nowrap px-3 py-2"
                    >
                      {totalCreditAmount >= totalDebitAmount ? (
                        <>
                          <p>{totalCreditAmount}</p>
                        </>
                      ) : totalCreditAmount <= totalDebitAmount ? (
                        <>
                          <p>{totalDebitAmount}</p>
                        </>
                      ) : (
                        0
                      )}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const th = [
  {
    name: "Date",
  },
  {
    name: "Particular Name",
  },
  {
    name: "Amount",
  },
];
export default Credit;

import React, { useState } from "react";
import { FaRegUserCircle } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";
import { AiOutlineBars } from "react-icons/ai";
import { ImCancelCircle } from "react-icons/im";
import { Scrollbars } from "react-custom-scrollbars-2";
import "../../index.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import Logo from "../../Assets/Nepal  AluMinium big.png";
import Logos from "../../Assets/Ram Bharat Trade link.png";
import { toast } from "react-toastify";
import { logout } from "../../Redux/Feature/auth/authSlice";

const Dashboard = ({ anotherComponent }) => {
  const [open, setOpen] = useState(false);
  const [toggle, setToggle] = useState(false);

  const toggleOpen = () => setToggle(true);
  const toggleClosed = () => setToggle(false);
  const openHandle = () => setOpen(true);
  const closeHandle = () => setOpen(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [active, setActive] = useState(1);

  // Get the current URL location
  const location = useLocation();

  // Get the selected menu item's ID from the URL
  const getSelectedMenuItemIdFromUrl = () => {
    const pathname = location.pathname;
    const menuItem = menu
      .map((item) => [
        ...item.home,
        ...item.inventery,
        ...item.Staff,
        ...item.Biling,
        ...item.Account,
        ...item.Expenses,
        ...item.Assest,
        ...item.Journal,
      ])
      .flat()
      .find((item) => item.link === pathname);

    return menuItem ? menuItem.id : 1; // If the menuItem is not found, set the default ID to 1 (Home)
  };

  // Set the active state based on the selected menu item's ID from the URL
  const [active, setActive] = useState(getSelectedMenuItemIdFromUrl());

  const submit = () => {
    dispatch(logout());
    navigate("/login");
    toast.success("Logout");
  };
  const handleLinkClick = (item) => {
    setActive(item.id);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  const { user } = useSelector((state) => state.auth);
  return (
    <div>
      <div className="bg-[green] w-full overflow-hidden ">
        <div className="grid grid-flow-row grid-cols-2">
          <div className="lg:p-2 md:p-2 p-1 flex ">
            {/* <p onClick={toggle === false ? toggleOpen : toggleClosed}> */}
            {toggle === false ? (
              <AiOutlineBars
                className="lg:text-[2rem]   md:text-[2rem] text-[white] mx-2 cursor-pointer lg:hidden   mt-[0.5rem] text-[2rem]"
                onClick={toggleOpen}
              />
            ) : (
              <ImCancelCircle
                className="lg:text-[2rem]   md:text-[2rem] text-[white] mx-2 cursor-pointer lg:hidden   mt-[0.5rem] text-[2rem]"
                onClick={toggleClosed}
              />
            )}
            {/* </p> */}
            <img
              src={Logos}
              alt=""
              className="lg:w-[100px] lg:h-[100px] w-[50px] h-[50px] md:w-[100px] md:h-[100px] rounded-full border bg-[white]  lg:mt-0 md:mt-0 mt-[0.3rem]  border-[white]"
            />
            <p className="uppercase font-semibold lg:mt-[2rem] md:mt-[2rem] mt-[1rem] mx-[1rem]   lg:text-[1.5rem] md:text-[1.5rem] text-[1rem] text-[white] company-name">
              ram bharat trade link
            </p>
          </div>
          <div className="flex justify-end mx-3  lg:mt-[2rem]  md:mt-[2rem] mt-[1.3rem]">
            <p>
              <FaRegUserCircle
                className="lg:text-[2rem] md:text-[2rem] text-[white] mx-2 cursor-pointer"
                onClick={open === true ? closeHandle : openHandle}
              />
              {open === false ? null : (
                <p className="font-bold py-1 text-white " data-aos="zoom-in">
                  {" "}
                  {user.role}
                </p>
              )}
            </p>

            <p>
              <BiLogOut
                className="lg:text-[2rem]  md:text-[2rem] text-[white] mx-2 cursor-pointer"
                onClick={submit}
              />
            </p>
          </div>
        </div>
      </div>
      {toggle === false ? null : (
        <div className="lg:hidden " data-aos="zoom-in-up">
          {menu.map((item, i) => {
            return (
              <div>
                <p className="p-3 mx-3  my-2  text-[white] bg-black rounded-lg border  font-[700] text-[1.3rem]  capitalize">
                  Home
                </p>
                <div className="mx-2" key={i}>
                  {item.home.map((item, i) => {
                    return (
                      <Link to={`${item.link}`}>
                        <p
                          className="capitalize m-2 px-3 py-2 rounded-l-lg"
                          style={{
                            color: active === item.id ? "white" : "black",

                            background: active === item.id ? "green" : "white",
                          }}
                          onClick={() => handleLinkClick(item)}
                        >
                          {item.name}
                        </p>
                      </Link>
                    );
                  })}
                  <hr className="my-2 " />
                </div>{" "}
                <p className="p-3 mx-3  my-2  text-[white] bg-black rounded-lg border  font-[700] text-[1.3rem]  capitalize">
                  Inventery
                </p>
                <div className="mx-2" key={i}>
                  {item.inventery.map((item, i) => {
                    return (
                      <Link to={`${item.link}`}>
                        <p
                          className="capitalize m-2 px-3 py-2 rounded-l-lg"
                          style={{
                            color: active === item.id ? "white" : "black",

                            background: active === item.id ? "green" : "white",
                          }}
                          onClick={() => handleLinkClick(item)}
                        >
                          {item.name}
                        </p>
                      </Link>
                    );
                  })}
                  <hr className="my-2 " />
                </div>
                <p className="p-3 mx-3  my-2  text-[white] bg-black rounded-lg border  font-[700] text-[1.3rem]  capitalize">
                  Staff
                </p>
                <div className="mx-2" key={i}>
                  {item.Staff.map((item, i) => {
                    return (
                      <Link to={`${item.link}`}>
                        <p className="capitalize m-2 px-3 py-2 rounded-l-lg">
                          {item.name}
                        </p>
                      </Link>
                    );
                  })}
                  <hr className="my-2 " />
                </div>
                <p className="p-3 mx-3  my-2  text-[white] bg-black rounded-lg border  font-[700] text-[1.3rem]  capitalize">
                  Sales
                </p>
                <div className="mx-2" key={i}>
                  {item.Biling.map((item, i) => {
                    return (
                      <Link to={`${item.link}`}>
                        <p
                          className="capitalize m-2 px-3 py-2 rounded-l-lg"
                          style={{
                            color: active === item.id ? "red" : null,
                          }}
                          onClick={() => handleLinkClick(item)}
                        >
                          {item.name}
                        </p>
                      </Link>
                    );
                  })}
                  <hr className="my-2 " />
                </div>
                <p className="p-3 mx-3  my-2  text-[white] bg-black rounded-lg border  font-[700] text-[1.3rem]  capitalize">
                  Expenses
                </p>
                <div className="mx-2" key={i}>
                  {item.Expenses.map((item, i) => {
                    return (
                      <Link to={`${item.link}`}>
                        <p
                          className="capitalize m-2 px-3 py-2 rounded-l-lg"
                          style={{
                            color: active === item.id ? "white" : "black",

                            background: active === item.id ? "green" : "white",
                          }}
                          onClick={() => handleLinkClick(item)}
                        >
                          {item.name}
                        </p>
                      </Link>
                    );
                  })}
                  <hr className="my-2 " />
                </div>
                <p className="p-3 mx-3  my-2  text-[white] bg-black rounded-lg border  font-[700] text-[1.3rem]  capitalize">
                  Assest
                </p>
                <div className="mx-2" key={i}>
                  {item.Assest.map((item, i) => {
                    return (
                      <Link to={`${item.link}`}>
                        <p
                          className="capitalize m-2 px-3 py-2 rounded-l-lg"
                          style={{
                            color: active === item.id ? "white" : "black",

                            background: active === item.id ? "green" : "white",
                          }}
                          onClick={() => handleLinkClick(item)}
                        >
                          {item.name}
                        </p>
                      </Link>
                    );
                  })}
                  <hr className="my-2 " />
                </div>
                <p className="p-3 mx-3  my-2  text-[white] bg-black rounded-lg border  font-[700] text-[1.3rem]  capitalize">
                  Journal
                </p>
                <div className="mx-2" key={i}>
                  {item.Journal.map((item, i) => {
                    return (
                      <Link to={`${item.link}`}>
                        <p
                          className="capitalize m-2 px-3 py-2 rounded-l-lg"
                          style={{
                            color: active === item.id ? "white" : "black",

                            background: active === item.id ? "green" : "white",
                          }}
                          onClick={() => handleLinkClick(item)}
                        >
                          {item.name}
                        </p>
                      </Link>
                    );
                  })}
                  <hr className="my-2 " />
                </div>
              </div>
            );
          })}
        </div>
      )}

      <div className="lg:flex md:flex lg:gap-2 md:gap-2">
        <Scrollbars
          style={{ minWidth: 320, maxWidth: 320, height: 1300 }}
          className=" border mbl-view  tab-view"
        >
          <div className="h-full max-w-[dashboard]  ">
            {menu.map((item, i) => {
              return (
                <div key={i}>
                  <p className="p-3 mx-3  my-2  text-[white] bg-black rounded-lg border  font-[700] text-[1.3rem]  capitalize">
                    Home
                  </p>
                  <div className="mx-2" key={i}>
                    {item.home.map((item, i) => {
                      return (
                        <Link to={`${item.link}`}>
                          <p
                            className="capitalize m-2 px-3 py-2 rounded-l-lg"
                            style={{
                              color: active === item.id ? "white" : "black",

                              background:
                                active === item.id ? "green" : "white",
                            }}
                            onClick={() => {
                              handleLinkClick(item);
                            }}
                          >
                            {item.name}
                          </p>
                        </Link>
                      );
                    })}
                    <hr className="my-2 " />
                  </div>{" "}
                  <p className="p-3 mx-3  my-2  text-[white] bg-black rounded-lg border  font-[700] text-[1.3rem]  capitalize">
                    Inventery
                  </p>
                  <div className="mx-2" key={i}>
                    {item.inventery.map((item, i) => {
                      return (
                        <Link to={`${item.link}`}>
                          <p
                            className="capitalize m-2 px-3 py-2 rounded-l-lg"
                            style={{
                              color: active === item.id ? "white" : "black",

                              background:
                                active === item.id ? "green" : "white",
                            }}
                            onClick={() => {
                              handleLinkClick(item);
                            }}
                          >
                            {item.name}
                          </p>
                        </Link>
                      );
                    })}
                    <hr className="my-2 " />
                  </div>
                  <p className="p-3 mx-3  my-2  text-[white] bg-black rounded-lg border  font-[700] text-[1.3rem]  capitalize">
                    Staff
                  </p>
                  <div className="mx-2" key={i}>
                    {item.Staff.map((item, i) => {
                      return (
                        <Link to={`${item.link}`}>
                          <p
                            className="capitalize m-2 px-3 py-2 rounded-l-lg"
                            style={{
                              color: active === item.id ? "white" : "black",

                              background:
                                active === item.id ? "green" : "white",
                            }}
                            onClick={() => handleLinkClick(item)}
                          >
                            {item.name}
                          </p>
                        </Link>
                      );
                    })}
                    <hr className="my-2 " />
                  </div>
                  {/* ................? */}
                  <p className="p-3 mx-3  my-2  text-[white] bg-black rounded-lg border  font-[700] text-[1.3rem]  capitalize">
                    Sales
                  </p>
                  <div className="mx-2" key={i}>
                    {item.Biling.map((item, i) => {
                      return (
                        <Link to={`${item.link}`}>
                          <p
                            className="capitalize m-2 px-3 py-2 rounded-l-lg"
                            style={{
                              color: active === item.id ? "white" : "black",

                              background:
                                active === item.id ? "green" : "white",
                            }}
                            onClick={() => handleLinkClick(item)}
                          >
                            {item.name}
                          </p>
                        </Link>
                      );
                    })}
                    <hr className="my-2 " />
                  </div>
                  <p className="p-3 mx-3  my-2  text-[white] bg-black rounded-lg border  font-[700] text-[1.3rem]  capitalize">
                    Expenses
                  </p>
                  <div className="mx-2" key={i}>
                    {item.Expenses.map((item, i) => {
                      return (
                        <Link to={`${item.link}`}>
                          <p
                            className="capitalize m-2 px-3 py-2 rounded-l-lg"
                            style={{
                              color: active === item.id ? "white" : "black",

                              background:
                                active === item.id ? "green" : "white",
                            }}
                            onClick={() => handleLinkClick(item)}
                          >
                            {item.name}
                          </p>
                        </Link>
                      );
                    })}
                    <hr className="my-2 " />
                  </div>
                  <p className="p-3 mx-3  my-2  text-[white] bg-black rounded-lg border  font-[700] text-[1.3rem]  capitalize">
                    Account
                  </p>
                  <div className="mx-2" key={i}>
                    {item.Account.map((item, i) => {
                      return (
                        <Link to={`${item.link}`}>
                          <p
                            className="capitalize m-2 px-3 py-2 rounded-l-lg"
                            style={{
                              color: active === item.id ? "white" : "black",

                              background:
                                active === item.id ? "green" : "white",
                            }}
                            onClick={() => handleLinkClick(item)}
                          >
                            {item.name}
                          </p>
                        </Link>
                      );
                    })}
                    <hr className="my-2 " />
                  </div>
                  <p className="p-3 mx-3  my-2  text-[white] bg-black rounded-lg border  font-[700] text-[1.3rem]  capitalize">
                    Assest
                  </p>
                  <div className="mx-2" key={i}>
                    {item.Assest.map((item, i) => {
                      return (
                        <Link to={`${item.link}`}>
                          <p
                            className="capitalize m-2 px-3 py-2 rounded-l-lg"
                            style={{
                              color: active === item.id ? "white" : "black",

                              background:
                                active === item.id ? "green" : "white",
                            }}
                            onClick={() => handleLinkClick(item)}
                          >
                            {item.name}
                          </p>
                        </Link>
                      );
                    })}
                    <hr className="my-2 " />
                  </div>
                  <p className="p-3 mx-3  my-2  text-[white] bg-black rounded-lg border  font-[700] text-[1.3rem]  capitalize">
                    Journal
                  </p>
                  <div className="mx-2" key={i}>
                    {item.Journal.map((item, i) => {
                      return (
                        <Link to={`${item.link}`}>
                          <p
                            className="capitalize m-2 px-3 py-2 rounded-l-lg"
                            style={{
                              color: active === item.id ? "white" : "black",

                              background:
                                active === item.id ? "green" : "white",
                            }}
                            onClick={() => handleLinkClick(item)}
                          >
                            {item.name}
                          </p>
                        </Link>
                      );
                    })}
                    <hr className="my-2 " />
                  </div>
                </div>
              );
            })}
          </div>
        </Scrollbars>
        {toggle === true ? null : <>{anotherComponent}</>}
      </div>
    </div>
  );
};

const menu = [
  {
    home: [
      {
        id: 1,
        name: "home",
        link: "/",
      },
    ],
    inventery: [
      {
        id: 2,
        name: "product",
        link: "/product",
      },
      {
        id: 3,
        name: "category",
        link: "/category",
      },

      {
        id: 4,
        name: "Supplier",
        link: "/suppliers",
      },
      {
        id: 22,
        name: "Customer",
        link: "/customer",
      },

      {
        id: 16,
        name: "Inventery Loss Category",
        link: "/add-inventory-loss-category",
      },
      {
        id: 13,
        name: "Inventery Loss Expenses",
        link: "/Inventery-Expenses",
      },
    ],
    Staff: [
      {
        id: 5,
        name: "All Staff",
        link: "/Staff",
      },
      {
        id: 6,
        name: "Salary",
        link: "/employee-salary",
      },
    ],
    Biling: [
      {
        id: 7,
        name: "Sales",
        link: "/sale",
      },
      // {
      //   id: 8,
      //   name: "Bils",
      //   link: "/bil",
      // },
    ],
    Expenses: [
      {
        id: 9,
        name: "Expense Category",
        link: "/category-expense",
      },
      {
        id: 10,
        name: "Expense",
        link: "/expense",
      },
    ],
    Account: [
      {
        id: 18,
        name: "Purchase Account",
        link: "/purchase-account",
      },
      {
        id: 19,
        name: "Sales Account",
        link: "/sales-account",
      },
      {
        id: 20,
        name: "Purchase Return Account",
        link: "/purchase-return-account",
      },
      {
        id: 21,
        name: "Sales Return Account",
        link: "/sales-return-account",
      },
    ],
    Assest: [
      {
        id: 14,
        name: "Assests",
        link: "/assets",
      },
      {
        id: 15,
        name: "Sell Assests",
        link: "/sold-assets",
      },
    ],
    Journal: [
      {
        id: 11,
        name: "General Journal",
        link: "/general-journal",
      },
      {
        id: 12,
        name: "Ledger",
        link: "/ledger",
      },
      {
        id: 17,
        name: "Maskebari",
        link: "/maskebari",
      },
    ],
  },
];

export default Dashboard;

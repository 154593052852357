import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../utils/API";

export const userLogin = createAsyncThunk(
  "auth/login",
  async ({ email, password, navigate, toast }) => {
    try {
      const response = await api.post("/auth/login", { email, password });
      localStorage.setItem("NHHD:token", response.data.token);
      if (response.data.token) {
        toast.success(response.data.message);
        navigate("/");
      }
      if (response.data.success === false) {
        toast.error(response.data.message);
      }

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

import React, { useEffect, useState } from "react";
import Button from "../../Components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { purchaseProduct } from "../../Redux/Feature/Product/productAction";
import "../../index.css";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { toast } from "react-toastify";
import { Container } from "@mui/material";
import Tittle from "../../Components/Tittle/Tittle";

import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";

import { Link, useNavigate } from "react-router-dom";

const AddPurchaseProduct = () => {
  const { categories } = useSelector((state) => state.category);
  const { isLoading } = useSelector((state) => state.products);
  const { supplies } = useSelector((state) => state.supplier);
  console.log(supplies, "suppliers");

  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // New state for button disable
  const navigate = useNavigate();

  const [purchaseFrom, setPurchaseFrom] = useState([
    {
      productName: "",
      productDescription: "",
      productSKU: "",
      CategoryName: "",
      stockQuantity: "",
      stockRemarks: "",
      productPrice: "",
      productRemarks: "",
      productImage: null,
      productQuantity: 0,
    },
  ]);

  const onChangeFeild = (e, i) => {
    const { name, value } = e.target;
    const list = [...purchaseFrom];
    list[i][name] = value;
    setPurchaseFrom(list);
  };

  const removeClick = (i) => {
    const list = [...purchaseFrom];
    list.splice(i, 1);
    setPurchaseFrom(list);
  };

  const addClick = () => {
    setPurchaseFrom([
      ...purchaseFrom,
      {
        purchaseFrom,
      },
    ]);
  };

  const [defeactProduct, setDefeactProduct] = useState([
    {
      defeactProductName: "",
      defeactReason: "",
      defeactProductQuantity: "",
      defeactProductPrice: "",
    },
  ]);

  const onChangeDefact = (e, i) => {
    const { name, value } = e.target;
    const list = [...defeactProduct];
    list[i][name] = value;
    setDefeactProduct(list);
  };

  const removeClickDefact = (i) => {
    const list = [...defeactProduct];
    list.splice(i, 1);
    setDefeactProduct(list);
  };

  const addClickDefact = () => {
    setDefeactProduct([
      ...defeactProduct,
      {
        defeactProduct,
      },
    ]);
  };

  const handleSupplierChange = (e) => {
    const selectedSupplierId = e.target.value;
    const selectedSupplier = supplies.find(
      (supply) => supply._id === selectedSupplierId
    );
    setSupplierId(selectedSupplierId);
    setSupplierIdToCheck(selectedSupplier?.supplierType || "");
    setBillNo(""); // Clear the bill number when supplier changes
    setImportBillNumber(""); // Clear the pragyapan patra number when supplier changes
  };

  const [supplierIdToCheck, setSupplierIdToCheck] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [showDiscountAmount, setShowDiscountAmount] = useState("");
  const [creditType, setCreditType] = useState("");

  const [transctionType, setTransctionType] = useState("");

  const [vatType, setVatType] = useState("");
  const [vatPercent, setVatPercent] = useState("");
  const [VATamount, setVatAmount] = useState("");

  const [importTaxPercent, setImportTaxPercent] = useState("");
  const [importTaxAmount, setImportTaxAmount] = useState("");
  const [taxType, setTaxType] = useState(false);

  // const [month, setMonth] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [billNo, setBillNo] = useState("");

  const [importBillNumber, setImportBillNumber] = useState("");
  const [importedCountry, setImportedCountry] = useState("");
  const [importedCountryAmount, setImportedCountryAmount] = useState("");
  const [purchaseAmountINR, setPurchaseAmountINR] = useState("");
  const [totalTransportationUptoCostum, setTotalTransportationUptoCostum] =
    useState("");
  const [totalCosting, setTotalCosting] = useState("");
  const [importDuty, setImportDuty] = useState("");
  const [customService, setCustomService] = useState("");
  const [totalAmountExcludingVAT, setTotalAmountExcludingVAT] = useState("");
  const [VATamountImport, setVATamountImport] = useState("");
  const [costingIncludeVAT, setCostingIncludeVAT] = useState("");

  const dispatch = useDispatch(0);
  const token = localStorage.getItem("NHHD:token");
  const [productStatus, setProductStatus] = useState("");

  const [partialCredit, setPartialCredit] = useState("");
  const [partialCash, cashPartialCash] = useState("");

  console.log(partialCredit, "part");
  const [transctionCash, setTransctionCash] = useState("");
  const [transctionBank, setTransctionBank] = useState("");
  const [cash, setCash] = useState("");
  const [bank, setBank] = useState("");

  const [forTransaction, setForTransaction] = useState("");

  const monthInBS = parseInt(purchaseDate.slice(5, 7)) - 1;
  const yearInBS = purchaseDate.slice(0, 4);

  const currentMonthInBS = monthOrder[monthInBS];
  console.log(currentMonthInBS);

  const purchaseMonth = currentMonthInBS + "-" + yearInBS;

  const formData = new FormData();
  formData.append("productName", purchaseFrom.productName);
  formData.append("productDescription", purchaseFrom.productDescription);
  formData.append("productSKU", purchaseFrom.productSKU);
  formData.append("CategoryName", purchaseFrom.CategoryName);
  formData.append("stockQuantity", purchaseFrom.stockQuantity);
  formData.append("stockRemarks", purchaseFrom.stockRemarks);
  formData.append("productPrice", purchaseFrom.productPrice);
  formData.append("productRemarks", purchaseFrom.productRemarks);
  formData.append("productQuantity", purchaseFrom.productQuantity);
  formData.append("defeactProductName", defeactProduct.defeactProductName);
  formData.append("defeactReason", defeactProduct.defeactReason);
  formData.append(
    "defeactProductQuantity",
    defeactProduct.defeactProductQuantity
  );
  formData.append("defeactProductPrice", defeactProduct.defeactProductPrice);
  formData.append("productStatus", productStatus);

  formData.append("partialCredit", partialCredit);
  formData.append("partialCash", partialCash);

  formData.append("taxType", taxType);
  formData.append("transctionCash", transctionCash);
  formData.append("transctionBank", transctionBank);
  formData.append("supplierId", supplierId);
  formData.append("discountType", discountType);
  formData.append("discountAmount", discountAmount);
  formData.append("creditType", creditType);
  formData.append("transctionType", transctionType);
  formData.append("vatAmount", VATamount);

  const addToPurchase = () => {
    if (
      purchaseFrom === "" ||
      creditType === "" ||
      productStatus === "" ||
      supplierId === ""
    ) {
      toast.error("Some Feilds are missing");
    } else {
      setIsButtonDisabled(true);
      dispatch(
        purchaseProduct({
          purchaseFrom,
          supplierId,
          discountType,
          creditType,
          discountAmount,
          partialCredit,
          partialCash,
          transctionCash,
          transctionBank,
          transctionType,
          defeactProduct,
          productStatus,
          purchaseMonth,
          VATamount,
          token,
          taxType,
          importedCountry,
          importedCountryAmount,
          purchaseAmountINR,
          totalTransportationUptoCostum,
          totalCosting,
          importDuty,
          customService,
          totalAmountExcludingVAT,
          VATamountImport,
          costingIncludeVAT,
          billNo,
          purchaseDate,
          importBillNumber,
          cash,
          bank,
          vatPercent,
          importTaxPercent,
        })
      ).then(() => {
        setIsButtonDisabled(false);
        navigate("/product");
      });
    }
  };

  const [totalPrice, setTotalPrice] = useState(0);

  const sumOfProductPrices =
    purchaseFrom.reduce((total, bill) => {
      const price = parseInt(bill.productPrice * bill.productQuantity);
      if (!isNaN(price)) {
        return total + price;
      }
      return total;
    }, 0) -
    (discountType === "Amount Discount(Rs.)"
      ? discountAmount
      : discountType === "Percent Discount(%)"
      ? (discountAmount / 100) *
        purchaseFrom.reduce((total, bill) => {
          const price = parseInt(bill.productPrice * bill.productQuantity);
          console.log(price + total);
          if (!isNaN(price)) {
            return total + price;
          }
          return 0;
        }, 0)
      : null);

  const sumOfPriceOnly = purchaseFrom.reduce((total, bill) => {
    const price = parseInt(bill.productPrice * bill.productQuantity);
    if (!isNaN(price)) {
      return total + price;
    }
    return total;
  }, 0);

  console.log(sumOfPriceOnly);

  // Function to calculate total amount including import tax and VAT
  const calculateTotalAmount = (
    sumOfProductPrices,
    importTaxAmount,
    vatAmount,
    taxType,
    vatType
  ) => {
    const importTax = taxType ? parseFloat(importTaxAmount) : 0;
    const vat = vatType === "Taxable" ? parseFloat(vatAmount) : 0;
    console.log(vat, vatAmount, vatType);
    const totalAmount = sumOfProductPrices + vat + importTax;

    // const totalAmount = sumOfProductPrices + importTax + vat;

    return totalAmount.toFixed(2); // You can adjust the precision as needed
  };

  useEffect(() => {
    setTotalPrice(sumOfProductPrices);
  }, [sumOfProductPrices]);

  useEffect(() => {
    setTotalPrice(
      calculateTotalAmount(
        sumOfProductPrices,
        importTaxAmount,
        VATamount,
        taxType,
        vatType
      )
    );
  }, [
    sumOfProductPrices,
    importTaxAmount,
    VATamount,
    taxType,
    discountAmount,
    vatType,
  ]);

  console.log(bank, "here");
  console.log(cash, "cash");
  return (
    <Container>
      <div className="flex items-center justify-between pt-3">
        <Tittle tittle={"Add Product"} />
        <Link to="/product">
          <Button btnName={"Go Back"} />
        </Link>
      </div>

      {/* <div className="border border-gray-100 w-auto h-auto bg-gray-200 py-4 px-3 rounded-[6px] "> */}

      <div className="flex items-center">
        <p className="uppercase opacity-30 py-3">Select Date</p>
        <div className="w-[85%] bg-green-600 h-[0.1px]  mx-2 "></div>
      </div>
      <div className="lg:grid-cols-3  md:grid-cols-3 grid grid-flow-row gap-3 ">
        <div>
          <p className="uppercase text-green-800 py-1">Purchase Date</p>

          <NepaliDatePicker
            className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0   "
            value={purchaseDate}
            onChange={(value) => setPurchaseDate(value)}
            options={{ calenderLocale: "ne", valueLocale: "en" }}
          />
          <p className="text-red-500 px-3">Please Select the date manually</p>
        </div>
      </div>
      <div className="flex items-center">
        <p className="uppercase opacity-30 py-3">Select Supplier</p>
        <div className="w-[85%] bg-green-600 h-[0.1px]  mx-2 "></div>
      </div>
      <div className="lg:flex md:flex items-center  gap-3 ">
        <div>
          <p className="uppercase text-green-800 py-1">Supplier Name</p>
          <select
            value={supplierId}
            onChange={handleSupplierChange}
            className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0  w-[400px] h-full"
          >
            <option value="">Select Supplier</option>
            {supplies.map((item, i) => (
              <option key={item._id} value={item._id}>
                {item.supplierName}
              </option>
            ))}
          </select>
        </div>
        {supplierIdToCheck === "Local Supplier" ? (
          <div>
            <p className="uppercase text-green-800 py-1">Bill No.</p>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              value={billNo}
              onChange={(e) => setBillNo(e.target.value)}
              className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 "
              placeholder="Enter Bill Number"
            />
          </div>
        ) : supplierIdToCheck === "Importer" ? (
          <div>
            <p className="uppercase text-green-800 py-1">Pragyapan Patra No.</p>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              value={importBillNumber}
              onChange={(e) => setImportBillNumber(e.target.value)}
              className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 "
              placeholder="Enter Pragyapan Patra Number"
            />
          </div>
        ) : null}
      </div>
      <div className="flex items-center">
        <p className="uppercase opacity-30 py-3">Product Details </p>

        <div className="w-[85%] bg-green-600 h-[0.1px]  mx-2 "></div>
      </div>
      <div>
        {purchaseFrom.map((item, i) => {
          return (
            <div className="my-5">
              <p className="py-1">Product No:-{i + 1}</p>
              <div className=" grid grid-flow-row lg:grid-cols-3  md:grid-cols-3  gap-3  ">
                <div>
                  <p className="uppercase text-green-800 py-1">Product Name</p>
                  <input
                    type="text"
                    name="productName"
                    onChange={(e) => onChangeFeild(e, i)}
                    className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 "
                    placeholder="Product-Name "
                  />
                </div>

                <div>
                  <p className="uppercase text-green-800 py-1">
                    Product Desc..
                  </p>
                  <input
                    type="text"
                    name="productDescription"
                    onChange={(e) => onChangeFeild(e, i)}
                    className="border py-3 rounded-md placeholder:px-2 px-2"
                    placeholder="Product-Description "
                  />
                </div>
                <div>
                  <p className="uppercase text-green-800 py-1">Product SKU</p>
                  <input
                    type="text"
                    name="productSKU"
                    onChange={(e) => onChangeFeild(e, i)}
                    className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 "
                    placeholder="Product-SKU "
                  />
                </div>
              </div>
              <div className=" grid grid-flow-row lg:grid-cols-3  md:grid-cols-3  gap-3  my-2">
                <div>
                  <p className="uppercase   py-1 text-green-800">
                    Select Category
                  </p>
                  <select
                    onChange={(e) => onChangeFeild(e, i)}
                    name="CategoryName"
                    className="border py-3 rounded-md   px-2 my-2 lg:my-0 md:my-0 w-[335px] "
                  >
                    <option>Select Category</option>
                    {categories &&
                      categories?.categories.map((item, i) => {
                        return <option>{item.category}</option>;
                      })}
                  </select>
                </div>

                <div>
                  <p className="uppercase text-green-800 py-1">
                    Product Remarks
                  </p>
                  <input
                    type="text"
                    onChange={(e) => onChangeFeild(e, i)}
                    className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                    name="productRemarks"
                    placeholder="Product-Remarks "
                  />
                </div>
                <div>
                  <p className="uppercase text-green-800 py-1">Product Price</p>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => onChangeFeild(e, i)}
                    className="border py-3 rounded-md placeholder:px-2 px-2"
                    name="productPrice"
                    placeholder="Product-Price "
                  />
                </div>
              </div>

              <div className="grid grid-flow-row lg:grid-cols-3  md:grid-cols-3  gap-3 my-2">
                <div>
                  <p className="uppercase text-green-800 py-1">
                    Product Quantity
                  </p>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => onChangeFeild(e, i)}
                    className="border py-3 rounded-md placeholder:px-2 px-2"
                    name="productQuantity"
                    placeholder="Product-Quality "
                  />
                </div>
                <div>
                  <p className="uppercase text-green-800 py-1">Stock Remark</p>
                  <input
                    type="text"
                    onChange={(e) => onChangeFeild(e, i)}
                    className="border py-3 rounded-md placeholder:px-2 px-2"
                    name="stockRemarks"
                    placeholder="stock-remarks "
                  />
                </div>
              </div>
              <div className="flex gap-3 my-2">
                {purchaseFrom.length !== 1 && (
                  <p
                    onClick={removeClick}
                    className="flex border border-black-600 bg-[white] p-2 text-red-800 hover:bg-red-800 hover:text-white text-bold rounded-[6px] "
                  >
                    <AiOutlineMinus className="mt-1 " />
                    <span className="mx-1">Remove</span>
                  </p>
                )}
                {purchaseFrom.length - 1 === i && (
                  <p
                    onClick={addClick}
                    className="flex border border-black-600 bg-[white] p-2 text-green-800 hover:bg-green-800 hover:text-white text-bold rounded-[6px] "
                  >
                    <AiOutlinePlus className="mt-1 " />
                    <span className="mx-1">Add</span>
                  </p>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <div>
          <p className="text-[14px] text-gray-400 py-2">
            Is the product imported?
          </p>

          <input
            type="checkbox"
            className="border p-3 mb-4 rounded-md placeholder:p-2"
            onChange={(e) => {
              setTaxType(e.target.checked);
              if (!e.target.checked) {
                setImportTaxPercent(""); // Clear import tax percent
                setImportTaxAmount(""); // Clear import tax amount
              }
            }}
          />
          {taxType === true ? (
            <>
              <div>
                <div className="grid  grid-flow-row grid-cols-3 gap-2">
                  {/* <div>
                    <p className="uppercase opacity-30 py-1">Select Country</p>{" "} */}
                  <select
                    value={importedCountry}
                    onChange={(e) => setImportedCountry(e.target.value)}
                    className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 lg:mt-8   md:my-0  w-full h-[50px]"
                  >
                    <option>Select Country</option>
                    {importCountryList.map((item, i) => {
                      return <option>{item.name}</option>;
                    })}
                  </select>
                  {/* </div> */}

                  {/* <div>
                    {importedCountry && (
                      <div className="grid grid-cols-2 gap-2"> */}
                  <div>
                    <p className="uppercase text-green-800 py-1">
                      Imported Country Price
                    </p>
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      value={importedCountryAmount}
                      onChange={(e) => setImportedCountryAmount(e.target.value)}
                      placeholder={`Enter Amount in ${importedCountry} Currency`}
                      className="border py-3 rounded-md placeholder:px-2 px-2 w-full"
                    />
                  </div>
                  <div>
                    <p className="uppercase text-green-800 py-1">
                      Amount in Nepali RS.
                    </p>
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      value={purchaseAmountINR}
                      onChange={(e) => setPurchaseAmountINR(e.target.value)}
                      placeholder={`Enter Amount in Nepali Currency`}
                      className="border py-3 rounded-md placeholder:px-2 px-2 w-full"
                    />
                  </div>
                  {/* </div> */}
                  {/* )}
                  </div> */}

                  {/* <input
                type="number" onWheel={(e) => e.target.blur()}
                value={importTaxPercent}
                 onChange={(e) => {
                  setImportTaxPercent(e.target.value);
                  const calculatedTaxAmount = (
                    sumOfProductPrices *
                    e.target.value *
                    0.01
                  ).toFixed(2);
                  setImportTaxAmount(calculatedTaxAmount);
                }}
                placeholder="Enter Import Tax Percent"
                className="border py-3 rounded-md placeholder:px-2 px-2"
              /> */}
                  {/* <input
                type="number" onWheel={(e) => e.target.blur()}
                value={importTaxAmount}
                disabled
                placeholder="Import Tax Amount"
                className="border py-3 rounded-md placeholder:px-2 px-2"
              /> */}
                </div>
                <>
                  <div className="flex items-center">
                    <p className="uppercase text-green-800 py-3">Costing</p>
                    <div className="w-[85%] bg-green-600 h-[0.1px]  mx-2 "></div>
                  </div>
                  <div className="grid grid-flow-row lg:grid-cols-4  md:grid-cols-4 gap-2">
                    <div>
                      <p className="uppercase text-green-800 py-1">
                        Transport upto Custom Point
                      </p>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        value={totalTransportationUptoCostum}
                        onChange={(e) =>
                          setTotalTransportationUptoCostum(e.target.value)
                        }
                        placeholder={`Enter Transportation Amount UPTO Custom Point`}
                        className="border  rounded-md placeholder:px-2 px-2 w-full"
                      />
                    </div>
                    <div>
                      <p className="uppercase text-green-800 py-1">
                        Total Costing
                      </p>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        value={totalCosting}
                        onChange={(e) => setTotalCosting(e.target.value)}
                        placeholder={`Enter Total Cost`}
                        className="border py-3 rounded-md placeholder:px-2 px-2 w-full"
                      />
                    </div>
                    <div>
                      <p className="uppercase text-green-800 py-1">
                        Import Duty
                      </p>{" "}
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        value={importDuty}
                        onChange={(e) => setImportDuty(e.target.value)}
                        placeholder={`Enter Import Duty`}
                        className="border py-3 rounded-md placeholder:px-2 px-2 w-full"
                      />
                    </div>
                    <div>
                      <p className="uppercase text-green-800 py-1">
                        Custom Service
                      </p>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        value={customService}
                        onChange={(e) => setCustomService(e.target.value)}
                        placeholder={`Enter Custom Service`}
                        className="border py-3 rounded-md placeholder:px-2 px-2 w-full"
                      />
                    </div>
                    <div>
                      <p className="uppercase text-green-800 py-1">
                        Total Costing Excluding VAT
                      </p>{" "}
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        value={totalAmountExcludingVAT}
                        onChange={(e) =>
                          setTotalAmountExcludingVAT(e.target.value)
                        }
                        placeholder={`Enter Total Costing Excluding VAT`}
                        className="border py-3 rounded-md placeholder:px-2 px-2 w-full"
                      />
                    </div>

                    <div>
                      <p className="uppercase text-green-800 py-1">
                        VAT Amount
                      </p>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        value={VATamountImport}
                        onChange={(e) => setVATamountImport(e.target.value)}
                        placeholder={`Enter VAT Amount`}
                        className="border py-3 rounded-md placeholder:px-2 px-2 w-full"
                      />
                    </div>
                    <div>
                      <p className="uppercase text-green-800 py-1">
                        Costing Include VAT
                      </p>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        value={costingIncludeVAT}
                        onChange={(e) => setCostingIncludeVAT(e.target.value)}
                        placeholder={`Enter Costing Include VAT`}
                        className="border py-3 rounded-md placeholder:px-2 px-2 w-full"
                      />
                    </div>
                  </div>
                </>
              </div>
            </>
          ) : null}
        </div>
      </div>
      {taxType === false ? (
        <div className="grid md:grid-cols-2 lg:grid-cols-2 grid-flow-row gap-3">
          <div>
            <div className="flex items-center">
              <p className="uppercase opacity-30 my-3">Discount</p>

              <div className="w-[85%] bg-green-600 h-[0.1px]  mx-2 "></div>
            </div>
            <div className="mb-4">
              <select
                className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                value={discountType}
                onChange={(e) => setDiscountType(e.target.value)}
              >
                <option>No Discount(-0-)</option>
                <option>Amount Discount(Rs.)</option>
                <option>Percent Discount(%)</option>
              </select>
            </div>

            {discountType === "Amount Discount(Rs.)" ? (
              <>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  value={discountAmount}
                  onChange={(e) => setDiscountAmount(e.target.value)}
                  placeholder={`Enter Discount-Amount(Rs.)`}
                  className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                />
              </>
            ) : discountType === "Percent Discount(%)" ? (
              <div className="lg:flex md:flex gap-3 my-2 w-full ">
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  value={discountAmount}
                  onChange={(e) => {
                    setDiscountAmount(e.target.value);
                    const calculatedDiscountAmount = (
                      sumOfPriceOnly *
                      e.target.value *
                      0.01
                    ).toFixed(2);
                    console.log(calculatedDiscountAmount, "amout");
                    setShowDiscountAmount(calculatedDiscountAmount);
                  }}
                  placeholder={`Enter Discount-Percent(%)`}
                  className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                />
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  disabled
                  value={showDiscountAmount}
                  className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 w-full "
                />
              </div>
            ) : null}
            {discountType !== "Amount Discount(Rs.)" && discountType !== "" && (
              <p className="text-[14px] text-gray-400 py-2">
                Note:- Discount Percent(%) should be less to 100% or equal than
                100% not greater than 100%
              </p>
            )}
          </div>{" "}
          <div>
            <div className="flex items-center">
              <p className="uppercase opacity-30 my-3">VAT</p>

              <div className="w-[85%] bg-green-600 h-[0.1px]  mx-2 "></div>
            </div>
            <div className="mb-4">
              <select
                className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                value={vatType}
                onChange={(e) => {
                  const selectedVatType = e.target.value;
                  console.log(selectedVatType, "here");
                  setVatType(selectedVatType);
                  if (selectedVatType === "Non Taxable") {
                    setVatPercent(0);
                    setVatAmount(0);
                  }
                }}
              >
                <option>Non Taxable</option>
                <option>Taxable</option>
              </select>
            </div>

            {vatType === "Taxable" ? (
              <div className="lg:flex md:flex gap-3 my-2 w-full ">
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  value={vatPercent}
                  onChange={(e) => {
                    setVatPercent(e.target.value);
                    const calculatedVatAmount = (
                      sumOfProductPrices *
                      e.target.value *
                      0.01
                    ).toFixed(2);
                    setVatAmount(calculatedVatAmount);
                  }}
                  placeholder={`Enter Taxable VAT-Percent(%)`}
                  className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                />
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  disabled
                  value={VATamount}
                  className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 w-full "
                />
              </div>
            ) : null}
            {vatType !== "Non Taxable" && vatType !== "" && (
              <p className="text-[14px] text-gray-400 py-2">
                Note:- VAT Percent(%) should be less than or equal to 100%, not
                greater than 100%
              </p>
            )}
          </div>
        </div>
      ) : null}

      <div className="text-lg mt-4">
        <div className="w-[100%] bg-green-600 h-[0.1px]  mx-2 "></div>

        <p className="font-bold mt-4">Total Amount After Discount and VAT</p>

        <p className="text-green-500">{totalPrice}</p>
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-2 grid-flow-row gap-3">
        <div>
          <div className="flex items-center">
            <p className="uppercase opacity-30 my-2">Credit</p>

            <div className="w-[85%] bg-green-600 h-[0.1px]  mx-2 "></div>
          </div>
          <div className="mb-4">
            <select
              className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
              value={creditType}
              onChange={(e) => setCreditType(e.target.value)}
            >
              <option>Select Credit Type</option>
              <option>No Credit</option>
              <option>Credit</option>
              <option>Partial Credit</option>
            </select>
          </div>
          {creditType === "Partial Credit" ? (
            <div className="lg:flex md:flex gap-3 ">
              <div>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  value={partialCash}
                  onChange={(e) => {
                    cashPartialCash(e.target.value);
                    setForTransaction(e.target.value);

                    if (transctionType === "Cash") {
                      setCash(e.target.value);
                      setBank("");
                    }
                    if (transctionType === "Bank") {
                      setBank(e.target.value);
                      setCash("");
                    }

                    // Recalculate partial credit when partial cash changes
                    const calculatedPartialCredit =
                      calculateTotalAmount(
                        sumOfProductPrices,
                        importTaxAmount,
                        VATamount,
                        taxType,
                        vatType
                      ) - e.target.value;
                    setPartialCredit(calculatedPartialCredit);
                  }}
                  placeholder="Enter Cash Amount"
                  className="border  rounded-md placeholder:px-2 px-2 py-3   lg:my-0 md:my-0 w-full"
                />
              </div>
              <div>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  value={partialCredit}
                  readOnly
                  // onChange={(e) => setPartialCredit(e.target.value)}
                  placeholder="Enter Credit Amount"
                  className="border  rounded-md placeholder:px-2 px-2 py-3   lg:my-0 md:my-0 w-full"
                />
              </div>
            </div>
          ) : null}
        </div>
        <div>
          {" "}
          {creditType === "No Credit" ? (
            <>
              <div className="flex items-center">
                <p className="uppercase opacity-30 my-2">Transaction </p>

                <div className="w-[85%] bg-green-600 h-[0.1px]  mx-2 "></div>
              </div>
              <div className="">
                <select
                  className="border py-3 rounded-md placeholder:px-2 px-2  lg:my-0 md:my-0 w-full h-full"
                  name="transictionType"
                  value={transctionType}
                  onChange={(e) => {
                    setTransctionType(e.target.value);
                    if (e.target.value === "Bank") {
                      setBank(
                        calculateTotalAmount(
                          sumOfProductPrices,
                          importTaxAmount,
                          VATamount,
                          taxType,
                          vatType
                        )
                      );
                    } else if (e.target.value === "Cash") {
                      setCash(
                        calculateTotalAmount(
                          sumOfProductPrices,
                          importTaxAmount,
                          VATamount,
                          taxType,
                          vatType
                        )
                      );
                    }
                  }}
                >
                  <option>Select Transiction Type</option>
                  {caseType.map((item, i) => {
                    return <option>{item.TypeName}</option>;
                  })}
                </select>

                <div>
                  <p>
                    {transctionType === "Both" ? (
                      <div className="flex my-2 gap-3">
                        <div className="">
                          {" "}
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            value={transctionBank}
                            onChange={(e) => {
                              setTransctionBank(e.target.value);
                              setForTransaction(totalPrice);
                              const calculatedCash =
                                forTransaction - e.target.value;
                              console.log(calculatedCash, "calculated cash");
                              setTransctionCash(calculatedCash.toFixed(2));
                            }}
                            placeholder="Enter  BANK Amount"
                            className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 w-full"
                          />
                        </div>
                        <div className="">
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            readOnly
                            value={transctionCash}
                            // onChange={(e) => setTransctionCash(e.target.value)}
                            placeholder="Enter  CASH Amount"
                            className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 w-full "
                          />
                        </div>
                      </div>
                    ) : null}
                  </p>
                </div>
              </div>
            </>
          ) : creditType === "Partial Credit" ? (
            <>
              <div className="flex items-center">
                <p className="uppercase opacity-30 my-2">Transaction </p>

                <div className="w-[85%] bg-green-600 h-[0.1px]  mx-2 "></div>
              </div>
              <div className=" ">
                <select
                  className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                  name="transictionType"
                  value={transctionType}
                  onChange={(e) => setTransctionType(e.target.value)}
                >
                  <option>Select Transiction Type</option>
                  {caseType.map((item, i) => {
                    return <option>{item.TypeName}</option>;
                  })}
                </select>

                <div>
                  {transctionType === "Both" && (
                    <div className="flex my-2 mt-4 gap-3">
                      <div className="">
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          value={transctionBank}
                          onChange={(e) => {
                            setTransctionBank(e.target.value);
                            const calculatedCash =
                              forTransaction - e.target.value;
                            console.log(calculatedCash, "calculated cash");
                            setTransctionCash(calculatedCash);
                          }}
                          placeholder="Enter BANK Amount"
                          className="border rounded-md placeholder:px-2 px-2 py-3 my-2 lg:my-0 md:my-0 w-full"
                        />
                      </div>
                      <div className="">
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          readOnly
                          value={transctionCash}
                          placeholder="Enter CASH Amount"
                          className="border rounded-md placeholder:px-2 px-2 py-3 my-2 lg:my-0 md:my-0 w-full"
                        />
                      </div>
                    </div>
                  )}

                  {transctionType === "Bank" && (
                    <div className="flex my-2 mt-4 gap-3">
                      <input
                        readOnly
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        value={partialCash}
                        className="border rounded-md placeholder:px-2 px-2 py-3 my-2 lg:my-0 md:my-0 w-full"
                        placeholder="Bank Amount"
                      />
                    </div>
                  )}

                  {transctionType === "Cash" && (
                    <div className="flex my-2 mt-4 gap-3">
                      <input
                        readOnly
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        value={partialCash}
                        className="border rounded-md placeholder:px-2 px-2 py-3 my-2 lg:my-0 md:my-0 w-full"
                        placeholder="Cash Amount"
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>

      <>
        <div className="flex items-center">
          <p className="uppercase opacity-30 my-2">Select Product Status</p>

          <div className="w-[80%] bg-green-600 h-[0.1px]  mx-2 "></div>
        </div>
        <div className="my-4">
          <select
            className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0  w-[400px] h-full"
            value={productStatus}
            onChange={(e) => setProductStatus(e.target.value)}
          >
            <option>Select Product Status</option>

            <option>Normal</option>
            <option>Ab-Normal</option>
          </select>
          {productStatus === "Normal" ? null : productStatus === "Ab-Normal" ? (
            <>
              <div>
                {defeactProduct.map((item, i) => {
                  return (
                    <>
                      {" "}
                      <div className=" grid lg:grid-cols-3 md:grid-cols-3 grid-flow-row gap-3 mt-3 ">
                        <div>
                          <p className="uppercase text-green-800 py-1">
                            Product Name
                          </p>
                          <select
                            name="defeactProductName"
                            className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0  w-full "
                            onChange={(e) => onChangeDefact(e, i)}
                          >
                            <option>Select Product</option>

                            {purchaseFrom.map((item, i) => (
                              <option>{item.productName}</option>
                            ))}
                          </select>
                        </div>

                        <div>
                          <p className="uppercase text-green-800 py-1">
                            Product quantity
                          </p>

                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            className="border py-3 rounded-md placeholder:px-2 px-2"
                            name="defeactProductQuantity"
                            onChange={(e) => onChangeDefact(e, i)}
                            placeholder="Product-Quality "
                          />
                        </div>

                        <div>
                          <p className="uppercase text-green-800 py-1">
                            Product Reason
                          </p>

                          <input
                            type="text"
                            onChange={(e) => onChangeDefact(e, i)}
                            name="defeactReason"
                            className="border py-3 rounded-md placeholder:px-2 px-2"
                            placeholder="Product Damage Reason... "
                          />
                        </div>

                        <div>
                          <p className="uppercase text-green-800 py-1">
                            Product Price
                          </p>

                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            className="border py-3 rounded-md placeholder:px-2 px-2"
                            name="defeactProductPrice"
                            onChange={(e) => onChangeDefact(e, i)}
                            placeholder="Product-price "
                          />
                        </div>
                      </div>
                      <div className="flex gap-3 my-2">
                        {defeactProduct.length !== 1 && (
                          <p
                            onClick={removeClickDefact}
                            className=" flex border border-black-600 bg-[white] p-2 text-red-800 hover:bg-red-800 hover:text-white text-bold rounded-[6px] "
                          >
                            <AiOutlineMinus className="mt-1 " />
                            <span className="mx-1">Remove</span>
                          </p>
                        )}
                        {defeactProduct.length - 1 === i && (
                          <p
                            onClick={addClickDefact}
                            className="flex border border-black-600 bg-[white] p-2 text-green-800 hover:bg-green-800 hover:text-white text-bold rounded-[6px] "
                          >
                            <AiOutlinePlus className="mt-1 " />
                            <span className="mx-1">
                              {" "}
                              {isLoading ? "Proccessing" : "Add"}
                            </span>
                          </p>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          ) : null}
        </div>
      </>
      <div className="flex justify-center my-3 gap-3 ">
        <Button
          btnName={"Add"}
          click={addToPurchase}
          isLoading={isButtonDisabled}
        />
      </div>
    </Container>
  );
};

const caseType = [
  {
    TypeName: "Cash ",
  },
  {
    TypeName: "Bank ",
  },
  {
    TypeName: "Both ",
  },
];
const monthOrder = [
  "बैशाख",
  "जेठ",
  "असार",
  "श्रावण",
  "भाद्र",
  "असोज",
  "कार्तिक",
  "मंसिर",
  "पुष",
  "माघ",
  "फागुन",
  "चैत्र",
];

const importCountryList = [
  {
    name: "USA",
  },
  {
    name: "India",
  },
  {
    name: "China",
  },
];

export default AddPurchaseProduct;

import React from "react";
import EmployeeList from "./EmployeeList";

import Tittle from "../../Components/Tittle/Tittle";

const index = () => {
  return (
    <>
      <div>
        <Tittle tittle={"employee-salary"} />
        <EmployeeList />
      </div>
    </>
  );
};

export default index;

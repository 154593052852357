import React, { useEffect } from "react";
import { FcMoneyTransfer } from "react-icons/fc";
import { RiArrowUpSFill } from "react-icons/ri";
import Tittle from "../../Components/Tittle/Tittle";
import { useDispatch, useSelector } from "react-redux";
import { getSingleEmployee } from "../../Redux/Feature/staff/staffAction";
import { Spinner } from "../../Components/Spinner/Spinner";

const SalaryLog = () => {
  const { singleSalarys, isLoading } = useSelector((state) => state.salary);
  const { singleEmployee } = useSelector((state) => state.employee);
  console.log(singleSalarys);
  const token = localStorage.getItem("NHHD:token");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSingleEmployee({ id: singleSalarys.employeeId, token }));
  }, [dispatch, singleSalarys.employeeId, token]);

  // const [startDate, setStartDate] = useState();
  // const [endDate, setEndDate] = useState();

  // const findDate = () => {
  //   dispatch(filtersalaryByPaidDate({ token, startDate, endDate }));
  // };
  return (
    <div>
      <Tittle tittle={"Salary Statement"} />
      {/* <div className="flex gap-3 my-4">
        <p className="mt-7 opacity-60 text-md">Filter By Date : </p>
        <div className="  flex gap-2 ">
          <div>
            <p>Start Date</p>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>

          <div>
            <p>End Date</p>
            <div className="flex">
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
          <p
            className="text-md border-gray-400 bg-green-900  w-full  mt-6 cursor-pointer py-2 px-7 text-center uppercase rounded-md text-white font-medium"
            onClick={findDate}
          >
            Find
          </p>
        </div>
      </div> */}
      {isLoading === true ? (
        <Spinner />
      ) : (
        <div className="grid grid-flow-row grid-cols-2 gap-3 mx-3">
          {singleSalarys.employSalaryLog.map((item, i) => {
            return (
              <div className="border-gray-100 bg-gray-200 rounded-[6px] py-2 px-3  my-2 ">
                <p>
                  <span className="capitalize">
                    {singleEmployee && singleEmployee.employeeName}
                  </span>{" "}
                  is Credit with Rs.
                  <span className="text-[red]  ">{item.amount}</span>
                </p>
                <div className="grid grid-cols-2 grid-flow-row">
                  <div className="flex gap-2">
                    <span className="text-4xl border  mt-3 mx-1 rounded-full   ">
                      <FcMoneyTransfer />
                    </span>
                    <div>
                      <span>{new Date(item.paidDate).toDateString()}</span>{" "}
                      <span>-</span>
                      <sapn className="text-green-800  ">
                        {singleSalarys.cashType}
                      </sapn>
                      <p>
                        Monthly Salary-
                        <span className="text-green-800">
                          Rs.{singleSalarys.salaryToBePaid}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="justify-end flex">
                      <span className="text-[red]">Rs.{item.amount}</span>
                      <span className="text-red-600 text-2xl">
                        <RiArrowUpSFill />
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SalaryLog;

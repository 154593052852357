import React from "react";
import Modal from "../../Components/Modal/Modal";
import Button from "../../Components/Button/Button";
import AddSale from "./AddSale";
import SaleList from "./SaleList";
import Tittle from "../../Components/Tittle/Tittle";
import { Container } from "@mui/material";
import ReturnSale from "./ReturnSale/ReturnSale";
import AddBadDebt from "./Debt/BadDebt/AddBadDebt";
import AddRecoveryBadDebt from "./Debt/RecoveryBadDebt/AddRecoveryBadDebt";

const Index = () => {
  return (
    <>
      <Container>
        <Tittle tittle={"Sale"} />
        <div className="flex gap-3">
          <Modal
            btn={<Button btnName={"Add Sale"} />}
            modalDetails={<AddSale />}
            tittle={"Add Sale"}
          />
          <Modal
            btn={<Button btnName={"Sale  Return"} />}
            modalDetails={<ReturnSale />}
            tittle={"Sale Return"}
          />
          <Modal
            btn={<Button btnName={"Bad  Debt"} />}
            modalDetails={<AddBadDebt />}
            tittle={"Bad Debt"}
          />
          <Modal
            btn={<Button btnName={"Recover Bad Debt"} />}
            modalDetails={<AddRecoveryBadDebt />}
            tittle={"Recover Bad Debt"}
          />
          {/* <Modal
            btn={<Button btnName={"Recieved Credit"} />}
            modalDetails={<AddToCreditReturn />}
            tittle={"Payment Receipt"}
          /> */}
        </div>

        <div className="mt-[2rem]">
          <SaleList />
        </div>
      </Container>
    </>
  );
};

export default Index;

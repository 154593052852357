import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getSingleEmployee } from "../../Redux/Feature/staff/staffAction";
import { Spinner } from "../../Components/Spinner/Spinner";
import { Container } from "@mui/material";
import { BsCircleFill, BsPencil } from "react-icons/bs";
import UpdateStaff from "./UpdateStaff";
import Modal from "../../Components/Modal/Modal";

const SingleEmployee = () => {
  const prams = useParams();
  const id = prams.id;
  const token = localStorage.getItem("NHHD:token");
  const { singleEmployee, isLoading } = useSelector((state) => state.employee);
  console.log(singleEmployee);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetch() {
      dispatch(getSingleEmployee({ id, token }));
    }
    fetch();
  }, [dispatch, id, token]);

  return (
    <>
      {isLoading === true ? (
        <Spinner />
      ) : (
        <Container>
          {singleEmployee &&
            [singleEmployee].map((item, i) => {
              return (
                <div key={i}>
                  <div className="bg-gray-300 flex justify-center">
                    <div>
                      <img
                        src={item.employeeProfile && item.employeeProfile.url}
                        alt=""
                        className="w-[200px] h-[200px] border rounded-full my-4 shadow-md border-green-400"
                      />
                      <div className="flex px-2">
                        <p className="text-center capitalize text-xl py-2 font-medium">
                          {item.employeeName}
                        </p>
                        <p className="p-3">{item.employeeStatus}</p>
                        <span
                          style={{
                            color:
                              item.employeeStatus === "Active"
                                ? "green"
                                : "red",
                          }}
                        >
                          <BsCircleFill className="text-[0.7rem] mt-4" />
                        </span>

                        <spa>
                          <Modal
                            btn={
                              <BsPencil className=" text-[3rem] p-2 mx-3 my-1 border rounded-full bg-white shadow-lg  text-blue-700 " />
                            }
                            modalDetails={<UpdateStaff />}
                          ></Modal>
                        </spa>
                      </div>
                      <p>
                        Joinig On :{" "}
                        {new Date(item.employeeJoiningDate).toDateString()}{" "}
                        <span className="px-2 text-black uppercase">
                          - {item.employeePosition}
                        </span>
                      </p>
                    </div>{" "}
                  </div>{" "}
                  <>
                    <div className="grid grid-cols-2 grid-flow-row gap-6">
                      <div>
                        <p className="mt-4   font-[500]">Details</p>
                        <hr />
                        <div className="mt-5">
                          <>
                            <p
                              className="py-1 underline text-green-800
                        "
                            >
                              Employee Name
                            </p>
                            <p className="font-[510]">{item.employeeName}</p>
                            <hr className="mb-5" />
                          </>
                          <>
                            <p
                              className="py-1 underline text-green-800
                        "
                            >
                              Employee Address
                            </p>
                            <p className="font-[510]">{item.employeeAddress}</p>
                            <hr className="mb-5" />
                          </>
                          <>
                            <p
                              className="py-1 underline text-green-800
                        "
                            >
                              Employee Contact Number
                            </p>
                            <p className="font-[510]">{item.employeePhone}</p>
                            <hr className="mb-5" />
                          </>
                          <>
                            <p
                              className="py-1 underline text-green-800
                        "
                            >
                              Employee Email
                            </p>
                            <p className="font-[510]">{item.employeeEmail}</p>
                            <hr className="mb-5" />
                          </>
                          <>
                            <p
                              className="py-1 underline text-green-800
                        "
                            >
                              Employee Current Salary
                            </p>
                            <p className="font-[510]">
                              Rs.{item.employeeSalary}
                            </p>
                            <hr className="mb-5" />
                          </>
                        </div>
                      </div>
                      <div>
                        <p className="mt-4   font-[500]">Citizenship Picture</p>
                        <hr />
                        <>
                          <p
                            className="py-1 underline text-green-800
                        "
                          >
                            Citizenship Front Picture
                          </p>
                          {/* <Link
                            to={
                              item.employeeCitizenship &&
                              item.employeeCitizenship.frontPicture.url
                            }
                            target=" "
                          > */}
                          <img
                            src={
                              item.employeeCitizenship &&
                              item.employeeCitizenship.frontPicture.url
                            }
                            alt={item.employeeName}
                            className="w-[150px] h-[150px] rounded-md"
                          />
                          {/* </Link> */}
                          <hr className="mb-5" />
                        </>
                        <>
                          <p
                            className="py-1 underline text-green-800
                        "
                          >
                            Citizenship Back Picture
                          </p>
                          {/* <Link
                            to={item.employeeCitizenship.backPicture.url}
                            target=" "
                          > */}{" "}
                          <img
                            src={
                              item.employeeCitizenship &&
                              item.employeeCitizenship.backPicture.url
                            }
                            alt={item.employeeName}
                            className="w-[150px] h-[150px] rounded-md"
                          />
                          {/* </Link> */}
                          <hr className="mb-5" />
                        </>
                      </div>
                    </div>
                  </>
                </div>
              );
            })}
        </Container>
      )}
    </>
  );
};

export default SingleEmployee;

import React, { useEffect, useState } from "react";
import { Spinner } from "../../Components/Spinner/Spinner";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

import { useDispatch, useSelector } from "react-redux";
import {
  allJournal,
  deleteJournal,
  findJournal,
} from "../../Redux/Feature/GeneralJournal/journalAction";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Pagination from "./Pagination";

const Journal = () => {
  const { journal, isLoading, count } = useSelector(
    (state) => state.generalJournal
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(allJournal());
  }, [dispatch]);

  const findDate = () => {
    dispatch(findJournal({ startDate, endDate }));
  };

  const dltJournal = (id) => {
    dispatch(deleteJournal({ id }));
  };

  return (
    <>
      <p className="text-end">
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className=" text-[green] border  border-[gray]  rounded-[4px] p-2 hover:bg-[green] hover:text-white"
          table="table-to-xls"
          filename="General-journal"
          sheet="tablexls"
          buttonText="Export to Excel"
        />
      </p>
      <div className="  flex gap-2 my-3">
        <div>
          <p>Start Date</p>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>

        <div>
          <p>End Date</p>
          <div className="flex">
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>
        <div className="lg:flex lg:justify-center">
          <p
            className="uppercase border border-white mx-2  bg-green-800  text-white text-bold rounded-[6px] cursor-pointer   w-[100px] text-center pt-2 h-[40px] lg:mt-[1.4rem] md:mt-0 mt-2"
            onClick={findDate}
          >
            Find
          </p>
        </div>
      </div>
      <section class="container px-4 mx-auto">
        <div class="flex flex-col">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div class="overflow-hidden border border-gray-200 md:rounded-lg">
                <table
                  class="min-w-full divide-y divide-gray-200"
                  id="table-to-xls"
                >
                  <thead class="bg-gray-50">
                    <tr>
                      {th.map((item, i) => {
                        return (
                          <th
                            key={i}
                            class=" px-3  py-2 text-sm font-normal text-left rtl:text-right text-gray-500"
                          >
                            {item.name}
                          </th>
                        );
                      })}
                      <th
                        scope="col col-span-5"
                        class=" py-2 text-sm font-normal text-left rtl:text-right text-gray-500"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  {isLoading === "true" ? (
                    <Spinner />
                  ) : (
                    <tbody class="bg-white divide-y divide-gray-200">
                      {journal &&
                        journal.slice(0, 50).map((ele, i) => {
                          return (
                            <tr>
                              <td class="whitespace-nowrap px-3">
                                {new Date(ele.createdAt).toDateString()}
                              </td>
                              <td class=" whitespace-nowrap px-3 py-1 ">
                                {/* PurchaseProduct-start */}

                                {ele.productPurchase.map((ele) => {
                                  return (
                                    <>
                                      <p>{`${ele.journalName} A/C dr.`}</p>

                                      {ele.productDetails.map((ele) => {
                                        return (
                                          <p className="py-1">
                                            -{ele.productName}
                                          </p>
                                        );
                                      })}
                                      <p>{ele.vatType && "VAT A/C dr."}</p>
                                      <p className="py-1 px-4">
                                        {ele.discountType}
                                      </p>
                                      <p className="px-4">
                                        {ele.creditType ===
                                        "No Credit" ? null : ele.creditType ===
                                          "Credit" ? (
                                          `To, ${ele.supplierName} `
                                        ) : ele.creditType ===
                                          "Partial Credit" ? (
                                          <>
                                            <p>{null}</p>
                                            <p>To, {ele.supplierName} A/C</p>
                                          </>
                                        ) : null}
                                      </p>
                                      <p className="px-4">
                                        {ele.transctionType === "Bank" ? (
                                          "To, Bank"
                                        ) : ele.transctionType === "Cash" ? (
                                          "To, Cash"
                                        ) : ele.transctionType === "Both" ? (
                                          <>
                                            <p>To, Bank A/C</p>
                                            <p>To, Cash A/c</p>
                                          </>
                                        ) : null}
                                      </p>
                                      {/* <p>
                                        {ele.productStatus === "Ab-Normal" && (
                                          <>
                                            <p className="px-3">
                                              To, Ab-normal Looses A/C
                                            </p>
                                            {ele.defeactProducts.map((ele) => {
                                              return (
                                                <p className="px-3">
                                                  {ele.productName}
                                                </p>
                                              );
                                            })}
                                          </>
                                        )}
                                      </p> */}
                                    </>
                                  );
                                })}
                                {/* PurchaseProduct-end */}
                                {/* Purchasereturn-start */}

                                {ele.returnProduct.map((ele) => {
                                  return (
                                    <>
                                      {ele.transcationType === "Cash" && (
                                        <p>Cash</p>
                                      )}
                                      {ele.transcationType === "Bank" && (
                                        <p>Bank</p>
                                      )}
                                      {ele.transcationType === "Both" && (
                                        <>
                                          <p>Cash</p>
                                          <p>Bank</p>
                                        </>
                                      )}
                                      <p className="px-3">
                                        To, {ele.journalName}
                                      </p>
                                      <p className="px-3">
                                        {ele.productName.map((ele) => {
                                          return <p>{ele.name}</p>;
                                        })}
                                      </p>
                                      <p className="px-3">
                                        {ele.vatAmount && "Vat amount"}
                                      </p>
                                    </>
                                  );
                                })}
                                {/* Purchasereturn-end */}

                                {/* sale-start */}
                                {ele.sale.map((ele) => {
                                  return (
                                    <>
                                      <p className="py-1 ">
                                        {ele.discountType} A/C dr.
                                      </p>
                                      <p className="">
                                        {ele.creditType ===
                                        "No Credit" ? null : ele.creditType ===
                                          "Credit" ? (
                                          ` ${ele.customerName} A/C dr. `
                                        ) : ele.creditType ===
                                          "Partial Credit" ? (
                                          <>
                                            <p>{null}</p>
                                            <p>{ele.customerName} A/C dr.</p>
                                          </>
                                        ) : null}
                                      </p>
                                      <p className="">
                                        {ele.transctionType === "Bank" ? (
                                          "Bank"
                                        ) : ele.transctionType === "Cash" ? (
                                          "Cash"
                                        ) : ele.transctionType === "Both" ? (
                                          <>
                                            <p> Bank A/C dr.</p>
                                            <p>Cash A/C dr.</p>
                                          </>
                                        ) : null}
                                      </p>
                                      <p className="px-4">{` To, ${ele.journalName} A/C`}</p>

                                      {ele.saleProductName.map((ele) => {
                                        return (
                                          <p className="py-1 px-4">
                                            -{ele.name}
                                          </p>
                                        );
                                      })}
                                      <p className="px-4">
                                        {ele.vatAmount && "To, VAT A/C"}
                                      </p>
                                      <p>
                                        {ele.productStatus === "Ab-Normal" && (
                                          <>
                                            <p className="px-4">
                                              Ab Normal Losses A/c
                                            </p>
                                            {ele.defeactSale.map((ele) => {
                                              return (
                                                <p className="px-4">
                                                  {ele.defProductName}
                                                </p>
                                              );
                                            })}
                                          </>
                                        )}
                                      </p>
                                    </>
                                  );
                                })}
                                {/* sale-end */}
                                {/* Bad Debt-start */}
                                {ele.badDebt.map((ele) => {
                                  return (
                                    <>
                                      <p>{`${ele.journalName} A/C dr.`}</p>
                                      <p className="py-1  px-4">
                                        To,{" "}
                                        {ele.customerName.replace(/[\d-]/g, "")}
                                        {/* replace(/[\d-]/g, "")-this remove number symbol backet etc */}
                                      </p>
                                    </>
                                  );
                                })}
                                {/* Bad Debt-start */}
                                {/* Bad debt recovery-start */}
                                {ele.badDebtRecovery.map((ele) => {
                                  return (
                                    <>
                                      {ele.transctionType === "Bank" ? (
                                        "Bank A/C dr."
                                      ) : ele.transctionType === "Cash" ? (
                                        "Cash A/C dr."
                                      ) : ele.transctionType === "Both" ? (
                                        <>
                                          <p>Bank dr.</p>
                                          <p>Cash dr.</p>
                                        </>
                                      ) : null}
                                      <p className="px-4">{`To, ${ele.journalName} A/C`}</p>
                                    </>
                                  );
                                })}
                                {/* Bad debt recovery-end */}
                                {/* return sale-start */}
                                {ele.saleReturn.map((ele) => {
                                  return (
                                    <>
                                      <p>{`${ele.journalName} A/C dr.`}</p>
                                      {ele.returnProducts.map((ele) => {
                                        return <p>{ele.productName}</p>;
                                      })}
                                      <p className="px-4">
                                        {ele.creditType ===
                                        "No Credit" ? null : ele.creditType ===
                                          "Credit" ? (
                                          `To, ${ele.customerName} `
                                        ) : ele.creditType ===
                                          "Partial Credit" ? (
                                          <>
                                            <p>{null}</p>
                                            <p>To, {ele.customerName}</p>
                                          </>
                                        ) : null}
                                      </p>
                                      <p className="px-4">
                                        {ele.transctionType === "Bank" ? (
                                          "To, Bank"
                                        ) : ele.transctionType === "Cash" ? (
                                          "To, Cash"
                                        ) : ele.transctionType === "Both" ? (
                                          <>
                                            <p>To, Bank</p>
                                            <p>To, Cash</p>
                                          </>
                                        ) : null}
                                      </p>
                                    </>
                                  );
                                })}
                                {/* return sale-end */}

                                {/* sale credit recived-start */}
                                {ele.saleCreditReturn.map((ele) => {
                                  return (
                                    <>
                                      <p>{`${ele.customerName} A/C dr.`}</p>
                                      <p className="px-4">
                                        To, {`${ele.journalName} A/C dr.`}
                                      </p>
                                    </>
                                  );
                                })}
                                {/* sale credit recived-end */}
                                {/* expenses-start */}
                                {ele.expense.map((ele) => {
                                  return (
                                    <>
                                      <p>{`${ele.expensesName} expenses A/C dr`}</p>
                                      <p className="px-4">
                                        {ele.transctionType === "Both" ? (
                                          <>
                                            <p>To, Bank</p>
                                            <p>To, Cash</p>
                                          </>
                                        ) : (
                                          `To, ${ele.transctionType} A/C`
                                        )}
                                      </p>
                                    </>
                                  );
                                })}
                                {/* expenses-end */}
                                {/* inventory-start */}
                                {ele.inventory.map((ele) => {
                                  return (
                                    <>
                                      <p>{ele.inventoryName} A/C dr</p>
                                      <p className="px-4">
                                        To, {ele.journalName}
                                      </p>
                                    </>
                                  );
                                })}
                                {/* inventory-end */}
                                {/* assest-start */}
                                {ele.assest.map((ele) => {
                                  return (
                                    <>
                                      <p>{ele.assetName} assets A/C </p>
                                      <p>
                                        {ele.vatType === "Taxable" &&
                                          "VAT   A/C dr."}
                                      </p>
                                      <p className="px-4">
                                        To,{" "}
                                        {ele.discountAmount &&
                                          "discount A/C dr."}
                                      </p>
                                      <p className="px-4">
                                        {ele.creditType ===
                                        "No Credit" ? null : ele.creditType ===
                                          "Credit" ? (
                                          `To, ${ele.shopName} `
                                        ) : ele.creditType ===
                                          "Partial Credit" ? (
                                          <>
                                            <p>{null}</p>
                                            <p>To, {ele.shopName}</p>
                                          </>
                                        ) : null}
                                      </p>

                                      <p className="px-4">
                                        {ele.transctionType === "Bank" ? (
                                          "To, Bank"
                                        ) : ele.transctionType === "Cash" ? (
                                          "To, Cash"
                                        ) : ele.transctionType === "Both" ? (
                                          <>
                                            <p>To, Bank</p>
                                            <p>To, Cash</p>
                                          </>
                                        ) : null}
                                      </p>
                                    </>
                                  );
                                })}
                                {/* assest-end */}
                                {/* bonus-start */}
                                {ele.bonus &&
                                  ele.bonus.map((ele) => {
                                    return (
                                      <>
                                        <p>{ele.JournalName} expenses A/C</p>
                                        <p>
                                          {ele.transcationType === "Cash" &&
                                            "To , cash"}
                                        </p>
                                        <p>
                                          {ele.transcationType === "Bank" &&
                                            "To , bank"}
                                        </p>
                                        <p>
                                          {ele.transcationType === "Both" && (
                                            <>
                                              <p>To, Cash</p>
                                              <p>To, Bank</p>
                                            </>
                                          )}
                                        </p>
                                      </>
                                    );
                                  })}
                                {/* bonus-end */}

                                {/* pay-purchase-credit-start */}
                                {ele.payPurchaseCredit.map((ele) => {
                                  return (
                                    <>
                                      <p>{ele.supplierName} A/C dr.</p>
                                      <p className="px-4">
                                        {ele.transcationType === "Cash" && (
                                          <p>To, Cash</p>
                                        )}
                                        {ele.transcationType === "Bank" && (
                                          <p>To, Bank</p>
                                        )}
                                        {ele.transcationType === "Both" && (
                                          <>
                                            <p>To ,Cash</p>
                                            <p>To ,Bank</p>
                                          </>
                                        )}
                                      </p>
                                    </>
                                  );
                                })}
                                {/* pay-purchase-credit-end */}
                                {/* salary-start */}
                                {ele.salary.map((ele) => {
                                  return (
                                    <>
                                      <p>{ele.journalName} expenses A/C dr.</p>
                                      {ele.transcationType === "Cash" && (
                                        <p className="px-4">To, Cash</p>
                                      )}
                                      {ele.transcationType === "Bank" && (
                                        <p className="px-4">To, Bank</p>
                                      )}
                                      {ele.transcationType === "Both" && (
                                        <>
                                          <p className="px-4">To, Cash</p>
                                          <p className="px-4">To, bank</p>
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                                {/* salary-end */}
                                {/* advance-salary-start */}
                                {ele.advanceSalary.map((ele) => {
                                  return (
                                    <>
                                      <p>{ele.journalName} A/C dr.</p>
                                      {ele.transcationType === "Cash" && (
                                        <p className="px-4">To, Cash</p>
                                      )}
                                      {ele.transcationType === "Bank" && (
                                        <p className="px-4">To, Bank</p>
                                      )}
                                      {ele.transcationType === "Both" && (
                                        <p className="px-4">
                                          <p>To, Cash</p>
                                          <p>To, Bank</p>
                                        </p>
                                      )}
                                    </>
                                  );
                                })}
                                {/* advance-salary-end */}
                                {/* assets-to-sale-start */}
                                {ele.saleOfAssets?.map((ele) => {
                                  return (
                                    <>
                                      {ele.transcationType === "Cash" && (
                                        <p>Cash A/C dr</p>
                                      )}
                                      {ele.transcationType === "Cash" && (
                                        <p>Cash A/C dr</p>
                                      )}
                                      {ele.transcationType === "Both" && (
                                        <>
                                          <p>Cash A/C dr</p>
                                          <p>Cash A/C dr</p>
                                        </>
                                      )}
                                      {ele.creditType === "Credit" &&
                                        ele.customerName + "A/C dr."}

                                      <p className="px-4">
                                        <p>To, {ele.assetsName} A/c</p>
                                      </p>
                                    </>
                                  );
                                })}
                                {/* assets-to-sale-end */}
                                {/* {assets-pay-credit-start} */}
                                {ele.assetsCreditRecived?.map((ele) => {
                                  return (
                                    <>
                                      {ele.transcationType === "Cash" && (
                                        <p>Cash A/C dr</p>
                                      )}
                                      {ele.transcationType === "Bank" && (
                                        <p>Bank A/C dr</p>
                                      )}
                                      {ele.transcationType === "Both" && (
                                        <>
                                          <p>Cash A/C dr</p>
                                          <p>Bank A/C dr</p>
                                        </>
                                      )}
                                      <p className="px-4">
                                        To, {ele.customerName} A/C
                                      </p>
                                    </>
                                  );
                                })}
                                {/* {assets-pay-credit-end} */}
                              </td>
                              <td class="whitespace-nowrap px-3">{i + 1}</td>
                              <td class="whitespace-nowrap px-3">
                                <>
                                  {/* Purchase Product-start */}
                                  <p>
                                    {ele.productPurchase.map((ele) => {
                                      return <p>{ele.purchaseAmount}</p>;
                                    })}
                                    {ele.productPurchase.map((ele) =>
                                      ele.productDetails.map((ele) => {
                                        return (
                                          <p className="py-1">
                                            {ele.productPrice}
                                          </p>
                                        );
                                      })
                                    )}
                                    {ele.productPurchase.map((ele) => {
                                      return <p>{ele.vatAmount}</p>;
                                    })}

                                    {ele.productPurchase.map(
                                      ({ discountType }) =>
                                        discountType && (
                                          <p className="py-1 text-white">-</p>
                                        )
                                    )}

                                    {ele.productPurchase.map((ele) => (
                                      <p>
                                        {ele.creditType === "No Credit" ? (
                                          <p className="text-white">{null}</p>
                                        ) : ele.creditType === "Credit" ? (
                                          <p className="text-white">-</p>
                                        ) : ele.creditType ===
                                          "Partial Credit" ? (
                                          <>
                                            <p className="text-white">{null}</p>
                                            <p className="text-white">-</p>
                                          </>
                                        ) : null}
                                        <p>
                                          {ele.transctionType === "Bank" ? (
                                            <p className="text-white">-</p>
                                          ) : ele.transctionType === "Cash" ? (
                                            <p className="text-white">-</p>
                                          ) : ele.transctionType === "Both" ? (
                                            <>
                                              <p className="text-white">-</p>
                                              <p className="text-white">-</p>
                                            </>
                                          ) : null}
                                        </p>
                                        <p>
                                          {ele.productStatus ===
                                            "Ab-Normal" && (
                                            <>
                                              <p className="text-white">-</p>
                                              {ele.defeactProducts.map(
                                                (ele) => {
                                                  return (
                                                    <p className="text-white">
                                                      -
                                                    </p>
                                                  );
                                                }
                                              )}
                                            </>
                                          )}
                                        </p>
                                      </p>
                                    ))}
                                  </p>
                                  {/* Purchase Product-end */}
                                </>
                                {/* PurchaseReturn-start */}
                                {ele.returnProduct.map((ele) => {
                                  return (
                                    <>
                                      {ele.transcationType === "Cash" && (
                                        <p>{ele.cash}</p>
                                      )}
                                      {ele.transcationType === "Bank" && (
                                        <p>{ele.bank}</p>
                                      )}
                                      {ele.transcationType === "Both" && (
                                        <>
                                          <p>{ele.partialCash}</p>
                                          <p>{ele.partialBank}</p>
                                        </>
                                      )}
                                      <p className="px-3 text-white">-</p>
                                      <p className="px-3 text-white">
                                        {ele.productPrice.map((ele) => {
                                          return <p>-</p>;
                                        })}
                                      </p>
                                      <p className="text-white">
                                        {ele.vatAmount && "-"}
                                      </p>
                                    </>
                                  );
                                })}
                                {/* PurchaseReturn-end */}

                                {/* sale-start */}
                                <p>
                                  {ele.sale.map((ele) => (
                                    <p>
                                      <p className="py-1 ">
                                        {Math.floor(ele.discountAmount)}
                                      </p>
                                      <p className="">
                                        {ele.creditType ===
                                        "No Credit" ? null : ele.creditType ===
                                          "Credit" ? (
                                          <p>{ele.amountWithVat}</p>
                                        ) : ele.creditType ===
                                          "Partial Credit" ? (
                                          <>
                                            <p>{null}</p>
                                            <p> {ele.partialCredit}</p>
                                          </>
                                        ) : null}
                                      </p>
                                      <p className="">
                                        {ele.transctionType === "Bank" ? (
                                          <p>{ele.bank}</p>
                                        ) : ele.transctionType === "Cash" ? (
                                          <p>{ele.cash}</p>
                                        ) : ele.transctionType === "Both" ? (
                                          <>
                                            <p>{ele.transctionBank}</p>
                                            <p>{ele.transctionCash}</p>
                                          </>
                                        ) : null}
                                      </p>
                                      <p className="text-white">-</p>

                                      {ele.saleProductName.map((ele) => {
                                        return (
                                          <p className="py-1 text-white">
                                            {"-"}
                                          </p>
                                        );
                                      })}
                                      <p className="text-white">
                                        {ele.vatAmount && "-"}
                                      </p>
                                      <p>
                                        {ele.productStatus === "Ab-Normal" && (
                                          <>
                                            <p className="px-4 text-white">-</p>

                                            {ele.defeactSale.map((ele) => {
                                              return (
                                                <p className="px-4 text-white">
                                                  -
                                                </p>
                                              );
                                            })}
                                          </>
                                        )}
                                      </p>
                                    </p>
                                  ))}
                                </p>
                                {/* sale-end */}
                                {/* Bad Debt-start */}
                                {ele.badDebt.map((ele) => {
                                  return (
                                    <>
                                      <p>{ele.amount}</p>
                                      <p className="py-1 text-white">-</p>
                                    </>
                                  );
                                })}
                                {/* Bad Debt-start */}
                                {/* Bad debt recovery-start */}
                                {ele.badDebtRecovery.map((ele) => {
                                  return (
                                    <>
                                      {ele.transctionType === "Bank" ? (
                                        ele.amount
                                      ) : ele.transctionType === "Cash" ? (
                                        ele.amount
                                      ) : ele.transctionType === "Both" ? (
                                        <>
                                          <p>{ele.transctionBank}</p>
                                          <p>{ele.transctionCash}</p>
                                        </>
                                      ) : null}
                                      <p className="text-white">-</p>
                                    </>
                                  );
                                })}
                                {/* Bad debt recovery-end */}

                                {/* return sale-start */}
                                {ele.saleReturn.map((ele) => {
                                  return (
                                    <>
                                      {ele.returnProducts.reduce(
                                        (total, bill) => {
                                          const price = parseInt(
                                            bill.productPrice
                                          );
                                          return <p>{total + price}</p>;
                                        },
                                        0
                                      )}
                                      {ele.returnProducts.map((ele) => {
                                        return <p>{ele.productPrice}</p>;
                                      })}
                                      <p className=" text-white">
                                        {ele.creditType ===
                                        "No Credit" ? null : ele.creditType ===
                                          "Credit" ? (
                                          `-`
                                        ) : ele.creditType ===
                                          "Partial Credit" ? (
                                          <>
                                            <p>{null}</p>
                                            <p className="text-white">-</p>
                                          </>
                                        ) : null}
                                      </p>
                                      <p className="px-4 text-white">
                                        {ele.transctionType === "Bank" ? (
                                          "-"
                                        ) : ele.transctionType === "Cash" ? (
                                          "-"
                                        ) : ele.transctionType === "Both" ? (
                                          <>
                                            <p>-</p>
                                            <p>-</p>
                                          </>
                                        ) : null}
                                      </p>
                                    </>
                                  );
                                })}
                                {/* return sale-end */}
                                {/* sale credit recived-start */}
                                {ele.saleCreditReturn.map((ele) => {
                                  return (
                                    <>
                                      <p>{`${ele.amount}`}</p>
                                      <p className="text-white">-</p>
                                    </>
                                  );
                                })}
                                {/* sale credit recived-end */}
                                {/* expenses-start */}
                                {ele.expense.map((ele) => {
                                  return (
                                    <>
                                      <p>{`${ele.amount} `}</p>
                                      {ele.transctionType === "Both" ? (
                                        <>
                                          <p className="text-white">-</p>
                                          <p className="text-white">-</p>
                                        </>
                                      ) : (
                                        <p className="text-white">-</p>
                                      )}
                                    </>
                                  );
                                })}
                                {/* expenses-end */}
                                {/* inventory-start */}
                                {ele.inventory.map((ele) => {
                                  return (
                                    <>
                                      <p>{ele.amount}</p>
                                      <p className="text-white">-</p>
                                    </>
                                  );
                                })}
                                {/* inventory-end */}
                                {/* assest-start */}
                                {ele.assest.map((ele) => {
                                  return (
                                    <>
                                      <p>{ele.amount} </p>
                                      <p>
                                        {ele.vatType === "Non Taxable"
                                          ? null
                                          : ele.vatAmount}
                                      </p>
                                      <p className="text-white">
                                        {ele.discountAmount && "-"}
                                      </p>
                                      <p className=" text-white">
                                        {ele.creditType ===
                                        "No Credit" ? null : ele.creditType ===
                                          "Credit" ? (
                                          `- `
                                        ) : ele.creditType ===
                                          "Partial Credit" ? (
                                          <>
                                            <p>{null}</p>
                                            <p>-</p>
                                          </>
                                        ) : null}
                                      </p>
                                      <p className="text-white">
                                        {ele.transctionType === "Bank" ? (
                                          "-"
                                        ) : ele.transctionType === "Cash" ? (
                                          "-"
                                        ) : ele.transctionType === "Both" ? (
                                          <>
                                            <p>-</p>
                                            <p>-</p>
                                          </>
                                        ) : null}
                                      </p>
                                    </>
                                  );
                                })}
                                {/* assest-end */}
                                {/* bonus-start */}
                                {ele.bonus &&
                                  ele.bonus.map((ele) => {
                                    return (
                                      <>
                                        <p>{ele.amount}</p>
                                        <p className="text-white">
                                          {" "}
                                          <p>
                                            {ele.transcationType === "Cash" &&
                                              "-"}
                                          </p>
                                          <p>
                                            {ele.transcationType === "Bank" &&
                                              "-"}
                                          </p>
                                          <p>
                                            {ele.transcationType === "Both" && (
                                              <>
                                                <p>-</p>
                                                <p>-</p>
                                              </>
                                            )}
                                          </p>
                                        </p>
                                      </>
                                    );
                                  })}
                                {/* bonus-end */}
                                {/* pay-purchase-credit-start */}
                                {ele.payPurchaseCredit.map((ele) => {
                                  return (
                                    <>
                                      <p>{ele.amount}</p>
                                      <p className="text-white">
                                        {ele.transcationType === "Cash" && (
                                          <p>-</p>
                                        )}
                                        {ele.transcationType === "Bank" && (
                                          <p>-</p>
                                        )}
                                        {ele.transcationType === "Both" && (
                                          <>
                                            <p>-</p>
                                            <p>-</p>
                                          </>
                                        )}
                                      </p>
                                    </>
                                  );
                                })}
                                {/* pay-purchase-credit-end */}
                                {/* salary-start */}
                                {ele.salary.map((ele) => {
                                  return (
                                    <>
                                      <p>{ele.amount} </p>
                                      {ele.transcationType === "Cash" && (
                                        <p className="text-white">-</p>
                                      )}
                                      {ele.transcationType === "Bank" && (
                                        <p className="text-white">-</p>
                                      )}
                                      {ele.transcationType === "Both" && (
                                        <p className="text-white">
                                          <p>-</p>
                                          <p>-</p>
                                        </p>
                                      )}
                                    </>
                                  );
                                })}
                                {/* salary-end */}
                                {/* advance-salary-start */}
                                {ele.advanceSalary.map((ele) => {
                                  return (
                                    <>
                                      <p>{ele.amount}</p>
                                      {ele.transcationType === "Cash" && (
                                        <p className="text-white">-</p>
                                      )}
                                      {ele.transcationType === "Bank" && (
                                        <p className="text-white">-</p>
                                      )}
                                      {ele.transcationType === "Both" && (
                                        <p className="text-white">
                                          <p>-</p>
                                          <p>-</p>
                                        </p>
                                      )}
                                    </>
                                  );
                                })}
                                {/* advance-salary-end */}
                                {/* assets-to-sale-start */}
                                {ele.saleOfAssets?.map((ele) => {
                                  return (
                                    <>
                                      {ele.transcationType === "Cash" && (
                                        <p>{ele.cash}</p>
                                      )}
                                      {ele.transcationType === "Bank" && (
                                        <p>{ele.bank}</p>
                                      )}
                                      {ele.transcationType === "Both" && (
                                        <>
                                          <p>{ele.partialCash}</p>
                                          <p>{ele.partialBank}</p>
                                        </>
                                      )}
                                      {ele.creditType === "Credit" &&
                                        ele.amount}

                                      <p className="px-4 text-white">
                                        <p>-</p>
                                        <p>-</p>
                                      </p>
                                    </>
                                  );
                                })}
                                {/* assets-to-sale-end */}
                                {/* {assets-pay-credit-start} */}
                                {ele.assetsCreditRecived?.map((ele) => {
                                  return (
                                    <>
                                      {ele.transcationType === "Cash" && (
                                        <p>{ele.cash}</p>
                                      )}
                                      {ele.transcationType === "Bank" && (
                                        <p>{ele.bank}</p>
                                      )}
                                      {ele.transcationType === "Both" && (
                                        <>
                                          <p>{ele.partialCash}</p>
                                          <p>{ele.partialBank}</p>
                                        </>
                                      )}
                                      <p className="px-4 text-white">-</p>
                                    </>
                                  );
                                })}
                                {/* {assets-pay-credit-end} */}
                              </td>
                              <td class="whitespace-nowrap px-3">
                                {/* PurchaseProduct-start */}
                                {ele.productPurchase.map((ele) => {
                                  return (
                                    <>
                                      <p className="text-white">-</p>

                                      {ele.productDetails.map((ele) => {
                                        return (
                                          <p className="py-1 text-white">
                                            {"-"}
                                          </p>
                                        );
                                      })}
                                      <p className="text-white">
                                        {ele.vatType && "-"}
                                      </p>

                                      <p className="py-1 px-4">
                                        {ele.discountAmount}
                                      </p>
                                      <p className="px-4">
                                        {ele.creditType ===
                                        "No Credit" ? null : ele.creditType ===
                                          "Credit" ? (
                                          <p>{ele.credit}</p>
                                        ) : ele.creditType ===
                                          "Partial Credit" ? (
                                          <>
                                            <p>{null}</p>
                                            <p> {ele.partialCredit}</p>
                                          </>
                                        ) : null}
                                      </p>
                                      <p className="px-4">
                                        {ele.transctionType === "Bank" ? (
                                          <p>{ele.bank}</p>
                                        ) : ele.transctionType === "Cash" ? (
                                          <p>{ele.cash}</p>
                                        ) : ele.transctionType === "Both" ? (
                                          <>
                                            <p>{ele.transctionBank}</p>
                                            <p>{ele.transctionCash}</p>
                                          </>
                                        ) : null}
                                      </p>
                                      {/* <p>
                                        {ele.productStatus === "Ab-Normal" && (
                                          <>
                                            {ele.defeactProducts.reduce(
                                              (total, bill) => {
                                                const price = parseInt(
                                                  bill.productPrice
                                                );
                                                return (
                                                  <p className="px-4">
                                                    {total + price}
                                                  </p>
                                                );
                                              },
                                              0
                                            )}
                                            {ele.defeactProducts.map((ele) => {
                                              return (
                                                <p className="px-4">
                                                  {ele.productPrice}
                                                </p>
                                              );
                                            })}
                                          </>
                                        )}
                                      </p> */}
                                    </>
                                  );
                                })}
                                {/* PurchaseProduct-end */}
                                {/* PurchaseReturn-start */}
                                {ele.returnProduct.map((ele) => {
                                  return (
                                    <>
                                      <p className="text-white">
                                        {ele.transcationType === "Cash" && (
                                          <p>-</p>
                                        )}
                                        {ele.transcationType === "Bank" && (
                                          <p>-</p>
                                        )}
                                        {ele.transcationType === "Both" && (
                                          <>
                                            <p>-</p>
                                            <p>-</p>
                                          </>
                                        )}
                                      </p>

                                      <p className="px-3">
                                        {ele.amountExcludeVat}
                                      </p>
                                      <p className="px-3">
                                        {ele.productPrice.map((ele) => {
                                          return <p>{ele.price}</p>;
                                        })}
                                      </p>
                                      <p className="px-3">
                                        {ele.vatAmount && ele.vatAmount}
                                      </p>
                                    </>
                                  );
                                })}
                                {/* PurchaseReturn-end */}
                                {/* sale-start */}
                                {ele.sale.map((ele) => {
                                  return (
                                    <>
                                      <p className="py-1 text-white">-</p>
                                      {ele.creditType === "No Credit" ? (
                                        <p className="text-white">{null}</p>
                                      ) : ele.creditType === "Credit" ? (
                                        <p className="text-white">-</p>
                                      ) : ele.creditType ===
                                        "Partial Credit" ? (
                                        <>
                                          <p className="text-white">{null}</p>
                                          <p className="text-white">-</p>
                                        </>
                                      ) : null}
                                      <p>
                                        {ele.transctionType === "Bank" ? (
                                          <p className="text-white">-</p>
                                        ) : ele.transctionType === "Cash" ? (
                                          <p className="text-white">-</p>
                                        ) : ele.transctionType === "Both" ? (
                                          <>
                                            <p className="text-white">-</p>
                                            <p className="text-white">-</p>
                                          </>
                                        ) : null}
                                      </p>
                                      <p className="px-4">
                                        {ele.saleProductPrice.reduce(
                                          (total, bill) => {
                                            const price = parseInt(bill.price);
                                            return total + price;
                                          },
                                          0
                                        ) + ele.vatAmount}
                                      </p>
                                      {ele.saleProductPrice.map((ele) => {
                                        return (
                                          <p className="py-1 px-3">
                                            {ele.price}
                                          </p>
                                        );
                                      })}
                                      <p className="px-4  ">
                                        {ele.vatAmount && ele.vatAmount}
                                      </p>

                                      <p>
                                        {ele.productStatus === "Ab-Normal" && (
                                          <>
                                            {ele.defeactSale.reduce(
                                              (total, bill) => {
                                                const price = parseInt(
                                                  bill.defProductPrice
                                                );
                                                return (
                                                  <p className="px-4">
                                                    {total + price}
                                                  </p>
                                                );
                                              },
                                              0
                                            )}
                                            {ele.defeactSale.map((ele) => {
                                              return (
                                                <p className="px-4">
                                                  {ele.defProductPrice}
                                                </p>
                                              );
                                            })}
                                          </>
                                        )}
                                      </p>
                                    </>
                                  );
                                })}
                                {/* sale-end */}
                                {/* Bad Debt-start */}
                                {ele.badDebt.map((ele) => {
                                  return (
                                    <>
                                      <p className="text-white">-</p>
                                      <p className="py-1 px-4">{ele.amount}</p>
                                    </>
                                  );
                                })}
                                {/* Bad Debt-start */}
                                {/* Bad debt recovery-start */}
                                {ele.badDebtRecovery.map((ele) => {
                                  return (
                                    <>
                                      <p className="text-white">
                                        {ele.transctionType === "Bank" ? (
                                          "-"
                                        ) : ele.transctionType === "Cash" ? (
                                          "-"
                                        ) : ele.transctionType === "Both" ? (
                                          <>
                                            <p>-</p>
                                            <p>-</p>
                                          </>
                                        ) : null}
                                      </p>

                                      <p className="">{ele.amount}</p>
                                    </>
                                  );
                                })}
                                {/* Bad debt recovery-end */}
                                {/* return sale-start */}

                                {ele.saleReturn.map((ele) => {
                                  return (
                                    <>
                                      <p className="text-white">-</p>
                                      {ele.returnProducts.map((ele) => {
                                        return <p className="text-white">-</p>;
                                      })}
                                      <p className="">
                                        {ele.creditType ===
                                        "No Credit" ? null : ele.creditType ===
                                          "Credit" ? (
                                          <p>
                                            {ele.returnProducts.reduce(
                                              (total, bill) => {
                                                const price = parseInt(
                                                  bill.productPrice
                                                );
                                                return total + price;
                                              },
                                              0
                                            )}
                                          </p>
                                        ) : ele.creditType ===
                                          "Partial Credit" ? (
                                          <>
                                            <p>{null}</p>

                                            <p>{ele.partialCredit}</p>
                                          </>
                                        ) : null}
                                      </p>
                                      <p className="px-4">
                                        {ele.transctionType === "Bank" ? (
                                          "Bank"
                                        ) : ele.transctionType === "Cash" ? (
                                          "Cash"
                                        ) : ele.transctionType === "Both" ? (
                                          <>
                                            <p>{ele.transctionBank}</p>
                                            <p>{ele.transctionCash}</p>
                                          </>
                                        ) : null}
                                      </p>
                                    </>
                                  );
                                })}
                                {/* return sale-end */}
                                {/* sale credit recived-start */}
                                {ele.saleCreditReturn.map((ele) => {
                                  return (
                                    <>
                                      <p className="text-white">-</p>
                                      <p className="px-4">{ele.amount}</p>
                                    </>
                                  );
                                })}
                                {/* sale credit recived-end */}
                                {/* expenses-start */}
                                {ele.expense.map((ele) => {
                                  return (
                                    <>
                                      <p className="text-white">-</p>
                                      {ele.transctionType === "Both" ? (
                                        <>
                                          <p>{ele.transctionBank}</p>
                                          <p>{ele.transctionCash}</p>
                                        </>
                                      ) : (
                                        ` ${ele.amount} `
                                      )}
                                    </>
                                  );
                                })}
                                {/* expenses-end */}
                                {/* inventory-start */}
                                {ele.inventory.map((ele) => {
                                  return (
                                    <>
                                      <p className="text-white">-</p>
                                      <p className="px-4">{ele.amount}</p>
                                    </>
                                  );
                                })}
                                {/* inventory-end */}
                                {/* assest-start */}
                                {ele.assest &&
                                  ele.assest.map((ele) => {
                                    return (
                                      <>
                                        <p className="text-white">-</p>
                                        <p className="text-white">
                                          {ele.vatType === "Non Taxable"
                                            ? null
                                            : "-"}
                                        </p>
                                        <p>
                                          {ele.discountAmount &&
                                            ele.discountAmount}
                                        </p>
                                        <p className=" ">
                                          {ele.creditType ===
                                          "No Credit" ? null : ele.creditType ===
                                            "Credit" ? (
                                            `  ${ele.amountWithVat} `
                                          ) : ele.creditType ===
                                            "Partial Credit" ? (
                                            <>
                                              <p>{null}</p>
                                              <p> {ele.partialCredit}</p>
                                            </>
                                          ) : null}
                                        </p>
                                        <p className=" ">
                                          {ele.transctionType === "Bank" ? (
                                            ele.creditType ===
                                            "Partial Credit" ? (
                                              ele.partialCash
                                            ) : (
                                              ele.amuntWithVat
                                            )
                                          ) : ele.transctionType === "Cash" ? (
                                            ele.creditType ===
                                            "Partial Credit" ? (
                                              ele.partialCash
                                            ) : (
                                              ele.amuntWithVat
                                            )
                                          ) : ele.transctionType === "Both" ? (
                                            <>
                                              <p>{ele.transctionBank}</p>
                                              <p>{ele.transctionCash}</p>
                                            </>
                                          ) : null}
                                        </p>
                                      </>
                                    );
                                  })}
                                {/* assest-end */}
                                {/* bonus-start */}
                                {ele.bonus?.map((ele) => {
                                  return (
                                    <>
                                      <p className="text-white">-</p>
                                      <p>
                                        {ele.transcationType === "Cash" &&
                                          ele.cash}
                                      </p>
                                      <p>
                                        {ele.transcationType === "Bank" &&
                                          ele.bank}
                                      </p>
                                      <p>
                                        {ele.transcationType === "Both" && (
                                          <>
                                            <p>{ele.partialCash}</p>
                                            <p>{ele.partialBank}</p>
                                          </>
                                        )}
                                      </p>
                                    </>
                                  );
                                })}
                                {/* bonus-end */}
                                {/* pay-purchase-credit-start */}
                                {ele.payPurchaseCredit.map((ele) => {
                                  return (
                                    <>
                                      <p className="text-white">-</p>
                                      <p className=" ">
                                        {ele.transcationType === "Cash" && (
                                          <p>{ele.cash}</p>
                                        )}
                                        {ele.transcationType === "Bank" && (
                                          <p>{ele.bank}</p>
                                        )}
                                        {ele.transcationType === "Both" && (
                                          <>
                                            <p>{ele.partialCash}</p>
                                            <p>{ele.partialBank}</p>
                                          </>
                                        )}
                                      </p>
                                    </>
                                  );
                                })}
                                {/* pay-purchase-credit-end */}
                                {/* salary-start */}
                                {ele.salary.map((ele) => {
                                  return (
                                    <>
                                      <p className="text-white">- </p>
                                      {ele.transcationType === "Cash" && (
                                        <p className="">{ele.cash}</p>
                                      )}
                                      {ele.transcationType === "Bank" && (
                                        <p className="">{ele.bank}</p>
                                      )}
                                      {ele.transcationType === "Both" && (
                                        <p className="">
                                          <p>{ele.partialCash}</p>
                                          <p>{ele.partialBank}</p>
                                        </p>
                                      )}
                                    </>
                                  );
                                })}
                                {/* salary-end */}
                                {/* advance-salary-start */}
                                {ele.advanceSalary.map((ele) => {
                                  return (
                                    <>
                                      <p className="text-white">-</p>
                                      {ele.journalNametranscationType ===
                                        "Cash" && (
                                        <p className="px-4">{ele.cash}</p>
                                      )}
                                      {ele.transcationType === "Bank" && (
                                        <p className="px-4">{ele.bank}</p>
                                      )}
                                      {ele.transcationType === "Both" && (
                                        <p className="px-4">
                                          <p>{ele.partialCash}</p>
                                          <p>{ele.partialBank}</p>
                                        </p>
                                      )}
                                    </>
                                  );
                                })}
                                {/* advance-salary-end */}
                                {/* assets-to-sale-start */}
                                {ele.saleOfAssets?.map((ele) => {
                                  return (
                                    <>
                                      {ele.transcationType === "Cash" && (
                                        <p>-</p>
                                      )}
                                      {ele.transcationType === "Bank" && (
                                        <p>-</p>
                                      )}
                                      {ele.transcationType === "Both" && (
                                        <>
                                          <p>-</p>
                                          <p>-</p>
                                        </>
                                      )}
                                      <p className="text-white">
                                        {ele.creditType === "Credit" && "-"}
                                      </p>
                                      <p className=" selection:">
                                        {ele.amount}
                                      </p>
                                    </>
                                  );
                                })}
                                {/* assets-to-sale-end */}
                                {/* {assets-pay-credit-start} */}
                                {ele.assetsCreditRecived?.map((ele) => {
                                  return (
                                    <>
                                      <p className="text-white">
                                        {ele.transcationType === "Cash" && (
                                          <p>-</p>
                                        )}
                                        {ele.transcationType === "Bank" && (
                                          <p>-</p>
                                        )}
                                        {ele.transcationType === "Both" && (
                                          <>
                                            <p>-</p>
                                            <p>-</p>
                                          </>
                                        )}
                                      </p>

                                      <p className="px-4">{ele.amount}</p>
                                    </>
                                  );
                                })}
                                {/* {assets-pay-credit-end} */}
                              </td>
                              <td class="whitespace-nowrap px-3">
                                <div class="flex items-center gap-x-6">
                                  <button
                                    class="  transition-colors duration-200 text-red-500      focus:outline-none"
                                    onClick={() => dltJournal(ele._id)}
                                  >
                                    <DeleteForeverIcon />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
          <Pagination page={1} count={count} />
        </div>
      </section>
    </>
  );
};

const th = [
  {
    name: "Date",
  },
  {
    name: "Particular Name",
  },
  {
    name: "J.F",
  },
  {
    name: "Amount-Debit",
  },
  {
    name: "Amount-Credit",
  },
];

export default Journal;

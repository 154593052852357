import React, { useEffect, useState } from "react";
import Button from "../../Components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { addSale } from "../../Redux/Feature/sale/saleAction";
import { Scrollbars } from "react-custom-scrollbars-2";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { toast } from "react-toastify";

import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";

const AddSale = () => {
  const { data } = useSelector((state) => state.products);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // New state for button disable

  const [datas, setDatas] = useState({
    // soldQuantity: "",
    customerName: "",
    customerPhone: "",
    customerAddress: "",
    // soldPrice: "",
  });

  const [soldProducts, setSoldProducts] = useState([
    {
      productId: "",
      productSKU: "",
      soldQuantity: 0,
      soldPrice: 0,
      soldProductRemark: "",
    },
  ]);

  console.log(data);
  const productData = data.map((item) =>
    item.purchaseFrom
      .filter((ele) =>
        soldProducts.find(({ productId }) => productId === ele._id.toString())
      )
      .map((ele) => ele)
  );

  console.log(productData);
  const onChangeHandle = (e) => {
    const { value, name } = e.target;

    setDatas((pre) => {
      return {
        ...pre,
        [name]: value,
      };
    });
  };

  const { customerName, customerPhone, customerAddress } = datas;
  const dispatch = useDispatch();

  const onChangeFeild = (e, i) => {
    const { name, value } = e.target;
    const list = [...soldProducts];
    list[i][name] = value;
    setSoldProducts(list);
  };

  const removeClick = (i) => {
    const list = [...soldProducts];
    list.splice(i, 1);
    setSoldProducts(list);
  };

  const addClick = () => {
    setSoldProducts([
      ...soldProducts,
      { productId: "", soldPrice: "", soldQuantity: "" },
    ]);
  };

  const [discountType, setDiscountType] = useState("");
  const [discountPercent, setDiscountPercent] = useState("");
  const [discount, setDiscount] = useState("");

  const [month, setMonth] = useState("");
  const [saleDate, setSaleDate] = useState("");
  const [billNo, setBillNo] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");

  const [creditType, setCreditType] = useState("");
  // const [credit, setCredit] = useState("");
  // const [cash, setCash] = useState("");
  const [partial_cash, setPartial_cash] = useState("");
  const [partial_credit, setPartial_credit] = useState("");
  const [transctionType, setTransctionType] = useState("");
  const [bank_amount, setBank_amount] = useState("");
  const [cash_amount, setCash_amount] = useState("");
  const [both_Cash_amount, setBoth_Cash_amount] = useState("");
  const [both_Bank_amount, setBoth_Bank_amount] = useState("");
  const [productStatus, setProductStatus] = useState(0);

  const [forTransaction, setForTransaction] = useState("");

  const [vatType, setVatType] = useState("");
  const [vatPercent, setVatPercent] = useState("");
  const [vatAmount, setVatAmount] = useState("");

  const [defeactSoldProduct, setDefeactSoldProduct] = useState([
    {
      product_name: "",
      product_price: 0,
      product_quantity: 0,
      product_reason: "",
    },
  ]);

  const onChangeDefact = (e, i) => {
    const { name, value } = e.target;
    const list = [...defeactSoldProduct];
    list[i][name] = value;
    setDefeactSoldProduct(list);
  };

  const removeClickDefact = (i) => {
    const list = [...defeactSoldProduct];
    list.splice(i, 1);
    setDefeactSoldProduct(list);
  };

  const addClickDefact = () => {
    setDefeactSoldProduct([
      ...defeactSoldProduct,
      {
        defeactSoldProduct,
      },
    ]);
  };

  const monthInBS = parseInt(saleDate.slice(5, 7)) - 1;
  const yearInBS = saleDate.slice(0, 4);

  const currentMonthInBS = monthOrder[monthInBS];

  const saleMonth = currentMonthInBS + "-" + yearInBS;

  const [totalPrice, setTotalPrice] = useState(0);

  const productBillPrices = soldProducts.reduce((total, bill) => {
    const price = parseInt(bill.soldPrice * bill.soldQuantity);
    if (!isNaN(price)) {
      return total + price;
    }
    return total;
  }, 0);

  // Function to calculate total amount including  VAT
  const calculateTotalAmount = (discount, vatAmount, vatType) => {
    const vat = vatType === "Taxable" ? parseFloat(vatAmount) : 0;

    const totalAmount = productBillPrices - discount + vat;
    console.log(totalAmount);
    return totalAmount;

    // return totalAmount.toFixed(2); // You can adjust the precision as needed
  };
  let credit;
  let cash;
  if (creditType === "Credit") {
    credit = calculateTotalAmount(discount, vatAmount, vatType);
  }
  if (creditType === "No Credit") {
    cash = calculateTotalAmount(discount, vatAmount, vatType);
  }

  useEffect(() => {
    setTotalPrice(productBillPrices);
  }, [productBillPrices]);

  const Submit = () => {
    if (
      soldProducts === "" ||
      customerName === "" ||
      customerPhone === "" ||
      customerAddress === "" ||
      discountType === "" ||
      creditType === "" ||
      saleDate === "" ||
      saleMonth === "" ||
      productStatus === ""
    ) {
      toast.error("Feild Cannot be null");
    } else {
      setIsButtonDisabled(true); // Disable the button

      dispatch(
        addSale({
          soldProducts,
          customerName,
          customerPhone,
          customerAddress,
          discountType,
          discount,
          creditType,
          credit,
          cash,
          partial_cash,
          partial_credit,
          transctionType,
          bank_amount,
          cash_amount,
          both_Cash_amount,
          both_Bank_amount,
          productStatus,
          defeactSoldProduct,
          vatType,
          vatAmount,
          registrationNumber,
          billNo,
          saleDate,
          saleMonth,
          vatPercent,
        })
      ).then(() => {
        setIsButtonDisabled(false);
      });
    }
  };
  return (
    <>
      <div className=" flex justify-center">
        <Scrollbars style={{ width: 760, height: 400 }}>
          <div className="border border-gray-100 w-auto h-auto bg-gray-200 py-4 rounded-[6px]">
            {/* <p className="p-4 text-[1.5rem] text-[green]">Add Sale</p> */}
            <div className="flex items-center px-3">
              <p className="uppercase opacity-30 py-3">Select Date</p>
              <div className="w-[75%] bg-green-600 h-[0.1px]  mx-2 "></div>
            </div>
            <div className="flex">
              <div className=" items-center">
                <p className="text-red-500 px-3">Please Select the Date</p>

                <NepaliDatePicker
                  className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0   "
                  value={saleDate}
                  onChange={(value) => setSaleDate(value)}
                  options={{ calenderLocale: "ne", valueLocale: "en" }}
                />
              </div>
              <div>
                <p className="uppercase text-green-800 py-1">Bill No.</p>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  value={billNo}
                  onChange={(e) => setBillNo(e.target.value)}
                  className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full"
                  placeholder="Enter Bill Number"
                />
              </div>
            </div>
            <div className="flex items-center px-3">
              <p className="uppercase opacity-30 py-3">Select Product</p>
              <div className="w-[75%] bg-green-600 h-[0.1px]  mx-2 "></div>
            </div>
            <div className=" my-2 mx-2">
              {soldProducts.map((item, i) => {
                return (
                  <>
                    <div className=" lg:flex md:flex gap-3 my-2">
                      <select
                        className="border py-3 rounded-md placeholder:px-2 px-2 w-full my-3 md:my-0 lg:my-0"
                        onChange={(e) => onChangeFeild(e, i)}
                        name="productId"
                      >
                        <option>Select Product</option>
                        {data.map((item, i) => {
                          return item.purchaseFrom.map((item) => {
                            return (
                              <option value={item._id} key={i}>
                                {item.productName}-qnty({item.productQuantity}
                                )-Rs.({item.productPrice}/per)
                              </option>
                            );
                          });
                        })}
                      </select>
                      <div className="w-full">
                        <input
                          type="text"
                          name="productSKU"
                          onChange={(e) => onChangeFeild(e, i)}
                          className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 "
                          placeholder="Product-SKU "
                        />
                      </div>
                    </div>

                    <div className="flex gap-3 my-2">
                      <div className="w-full">
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          name="soldQuantity"
                          onChange={(e) => onChangeFeild(e, i)}
                          className="border py-3 rounded-md placeholder:px-2 px-2"
                          placeholder="Sold Quantity "
                        />
                      </div>
                      <div className="w-full">
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          name="soldPrice"
                          onChange={(e) => onChangeFeild(e, i)}
                          required
                          className="border py-3 rounded-md placeholder:px-2 px-2"
                          placeholder="Sold Price "
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <input
                        type="text"
                        name="soldProductRemark"
                        onChange={(e) => onChangeFeild(e, i)}
                        required
                        className="border py-3 rounded-md placeholder:px-2 px-2"
                        placeholder="Remarks"
                      />
                    </div>

                    <div className="flex gap-3">
                      {soldProducts.length !== 1 && (
                        <p
                          onClick={removeClick}
                          className="border border-black-600 bg-[white] p-2 text-red-800 hover:bg-red-800 hover:text-white text-bold rounded-[6px] "
                        >
                          <AiOutlineMinus />
                        </p>
                      )}
                      {soldProducts.length - 1 === i && (
                        <p
                          onClick={addClick}
                          className="border border-black-600 bg-[white] p-2 text-green-800 hover:bg-green-800 hover:text-white text-bold rounded-[6px] "
                        >
                          <AiOutlinePlus />
                        </p>
                      )}
                    </div>
                  </>
                );
              })}
              <div>
                <p className="uppercase opacity-30 pt-3">Discount</p>
                <div className="flex flex-col gap-2">
                  <div className="mb-4 w-full">
                    <select
                      className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                      value={discountType}
                      onChange={(e) => setDiscountType(e.target.value)}
                    >
                      <option>No Discount(-0-)</option>
                      <option>Amount Discount(Rs.)</option>
                      <option>Percent Discount(%)</option>
                    </select>
                  </div>
                  <div className="w-full ">
                    {discountType === "Amount Discount(Rs.)" ? (
                      <>
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          value={discount}
                          onChange={(e) => setDiscount(e.target.value)}
                          placeholder={`Enter Discount-Amount(Rs.)`}
                          className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                        />
                      </>
                    ) : discountType === "Percent Discount(%)" ? (
                      <div className="lg:flex md:flex gap-3 my-2">
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          value={discountPercent}
                          onChange={(e) => {
                            const newDiscountPercent = e.target.value;
                            setDiscountPercent(newDiscountPercent);
                            const calculatedDiscount =
                              newDiscountPercent * productBillPrices * 0.01;
                            setDiscount(calculatedDiscount.toFixed(2));
                          }}
                          placeholder={`Enter Discount-Percent(%)`}
                          className={`border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 ${
                            discount < 100 || discount === 100
                              ? "text-red-700 border-red-700"
                              : null
                          }`}
                        />
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          value={discount}
                          disabled
                          className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                {discountType === "Percent Discount(%)" ? (
                  <p className="text-[14px] text-red-400 py-2">
                    Note: Discount Percent(%) should be less than or equal to
                    100%
                  </p>
                ) : null}
              </div>

              <div>
                <p className="uppercase opacity-30 pt-3">VAT</p>
                <div className="mb-4">
                  <select
                    className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                    value={vatType}
                    onChange={(e) => {
                      const selectedVatType = e.target.value;
                      setVatType(selectedVatType);
                      if (selectedVatType === "Non Taxable") {
                        setVatPercent(0);
                        setVatAmount(0);
                      }
                    }}
                  >
                    <option>Non Taxable</option>
                    <option>Taxable</option>
                  </select>
                  {vatType !== "Non Taxable" && vatType !== "" && (
                    <p className="text-[14px] text-gray-400 py-2">
                      Note:- VAT Percent(%) should be less than or equal to
                      100%, not greater than 100%
                    </p>
                  )}
                </div>

                {vatType === "Taxable" ? (
                  <div className="lg:flex md:flex gap-3 my-2">
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      value={vatPercent}
                      onChange={(e) => {
                        setVatPercent(e.target.value);
                        const calculatedVatAmount = (
                          (productBillPrices - discount) *
                          e.target.value *
                          0.01
                        ).toFixed(2);
                        setVatAmount(calculatedVatAmount);
                      }}
                      placeholder={`Enter Taxable VAT-Percent(%)`}
                      className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                    />
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      disabled
                      value={vatAmount}
                      className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                    />
                  </div>
                ) : null}
              </div>

              <div>
                <p className="uppercase opacity-30 pt-3">Credit</p>
                <div className="mb-4">
                  <select
                    className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                    value={creditType}
                    onChange={(e) => setCreditType(e.target.value)}
                  >
                    <option>Select Credit Type</option>
                    <option>No Credit</option>
                    <option>Credit</option>
                    <option>Partial Credit</option>
                  </select>
                </div>
                {creditType === "No Credit" ? (
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    disabled
                    value={calculateTotalAmount(discount, vatAmount, vatType)}
                    // onChange={ (e) => setCash(e.target.value)}
                    placeholder="Enter Cash Amount"
                    className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                  />
                ) : creditType === "Credit" ? (
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    disabled
                    value={calculateTotalAmount(discount, vatAmount, vatType)}
                    // onChange={(e) => {
                    //   setCredit(e.target.value);
                    //   console.log(credit);
                    // }}
                    placeholder="Enter Credit Amount"
                    className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                  />
                ) : creditType === "Partial Credit" ? (
                  <div className="lg:flex md:flex gap-3 my-2">
                    {/* Input field for Partial Cash */}
                    <div>
                      <label htmlFor="partialCashInput" className="block mb-1">
                        Payment:
                      </label>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        id="partialCashInput"
                        value={partial_cash}
                        onChange={(e) => {
                          setPartial_cash(e.target.value);
                          setForTransaction(e.target.value);
                          setBank_amount(e.target.value);
                          setCash_amount(e.target.value);
                          // Recalculate partial credit when partial cash changes
                          const calculatedPartialCredit =
                            calculateTotalAmount(discount, vatAmount, vatType) -
                            e.target.value;
                          setPartial_credit(calculatedPartialCredit);
                        }}
                        placeholder="Enter the Amount"
                        className="border rounded-md px-2 py-3 my-2 lg:my-0 md:my-0"
                      />
                    </div>

                    {/* Display the calculated Partial Credit Amount */}
                    <div>
                      <label
                        htmlFor="partialCreditInput"
                        className="block mb-1"
                      >
                        Credit:
                      </label>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        id="partialCreditInput"
                        value={partial_credit}
                        readOnly
                        placeholder="Partial Credit Amount"
                        className="border rounded-md px-2 py-3 my-2 lg:my-0 md:my-0"
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              <p className="uppercase opacity-90 text-bold pt-3">
                Total Amount - Rs.
                {calculateTotalAmount(discount, vatAmount, vatType)}
              </p>
              {creditType === "No Credit" ? (
                <>
                  <p className="uppercase opacity-30 pt-3">Transaction </p>
                  <div className="lg:flex md:flex gap-3  my-4">
                    <select
                      className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                      name="transictionType"
                      value={transctionType}
                      onChange={(e) => setTransctionType(e.target.value)}
                    >
                      <option>Select Transaction Type</option>
                      {caseType.map((item, i) => {
                        return <option>{item.TypeName}</option>;
                      })}
                    </select>

                    <div>
                      <p>
                        {transctionType === "Bank" ? (
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            readOnly
                            value={calculateTotalAmount(
                              discount,
                              vatAmount,
                              vatType
                            )}
                            // value={bank_amount}
                            onChange={(e) => {
                              console.log(e.target.value);
                              setBank_amount(e.target.value);
                            }}
                            placeholder="Enter BANK Amount"
                            className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                          />
                        ) : transctionType === "Cash" ? (
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            readOnly
                            value={calculateTotalAmount(
                              discount,
                              vatAmount,
                              vatType
                            )}
                            // value={cash_amount}
                            onChange={(e) => {
                              setCash_amount(e.target.value);
                            }}
                            placeholder="Enter CASH Amount"
                            className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                          />
                        ) : transctionType === "Both" ? (
                          <>
                            <div className="">
                              <input
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                value={both_Bank_amount}
                                onChange={(e) => {
                                  setBoth_Bank_amount(e.target.value);
                                  console.log(forTransaction, "here");
                                  const calculatedCash =
                                    calculateTotalAmount(
                                      discount,
                                      vatAmount,
                                      vatType
                                    ) - e.target.value;
                                  console.log(
                                    calculatedCash,
                                    "calculated cash"
                                  );
                                  setBoth_Cash_amount(calculatedCash);
                                }}
                                placeholder="Enter  BANK Amount"
                                className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                              />
                            </div>
                            <div className="my-2">
                              {" "}
                              <input
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                value={both_Cash_amount}
                                readOnly
                                placeholder="Enter  CASH Amount"
                                className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                              />
                            </div>
                          </>
                        ) : null}
                      </p>
                    </div>
                  </div>
                </>
              ) : creditType === "Partial Credit" ? (
                <>
                  <div className="lg:flex md:flex gap-3  my-4">
                    <select
                      className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                      name="transictionType"
                      value={transctionType}
                      onChange={(e) => setTransctionType(e.target.value)}
                    >
                      <option>Select Transaction Type</option>
                      {caseType.map((item, i) => {
                        return <option>{item.TypeName}</option>;
                      })}
                    </select>

                    <div>
                      <p>
                        {transctionType === "Bank" ? (
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            readOnly
                            value={bank_amount}
                            // onChange={(e) => setBank_amount(e.target.value)}
                            placeholder="Enter BANK Amount"
                            className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                          />
                        ) : transctionType === "Cash" ? (
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            readOnly
                            value={cash_amount}
                            // onChange={(e) => setCash_amount(e.target.value)}
                            placeholder="Enter CASH Amount"
                            className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                          />
                        ) : transctionType === "Both" ? (
                          <>
                            <div className="flex ">
                              <input
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                value={both_Bank_amount}
                                onChange={(e) => {
                                  setBoth_Bank_amount(e.target.value);
                                  console.log(forTransaction, "here");
                                  const calculatedCash =
                                    forTransaction - e.target.value;
                                  console.log(
                                    calculatedCash,
                                    "calculated cash"
                                  );
                                  setBoth_Cash_amount(calculatedCash);
                                }}
                                placeholder="Enter  BANK Amount"
                                className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                              />
                            </div>
                            <div className="my-2">
                              <input
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                value={both_Cash_amount}
                                placeholder="Enter  CASH Amount"
                                className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                              />
                            </div>
                          </>
                        ) : null}
                      </p>
                    </div>
                  </div>
                </>
              ) : null}

              <>
                <p className="uppercase opacity-30 py-3">Product Status</p>

                <div className="my-2">
                  <select
                    className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0  w-full h-full"
                    value={productStatus}
                    onChange={(e) => setProductStatus(e.target.value)}
                  >
                    <option>Select Product Status</option>

                    <option>Normal</option>
                    <option>Ab-Normal</option>
                  </select>
                  {productStatus === "Normal" ? null : productStatus ===
                    "Ab-Normal" ? (
                    <>
                      {defeactSoldProduct.map((item, i) => {
                        return (
                          <>
                            <div className="flex gap-2 my-2 ">
                              <select
                                name="product_name"
                                className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0  w-full h-full"
                                onChange={(e) => onChangeDefact(e, i)}
                              >
                                <option>Select Product</option>

                                {productData.flat().map((item, i) => {
                                  return <option>{item.productName}</option>;
                                })}
                              </select>
                              <div>
                                <input
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  className="border py-3 rounded-md placeholder:px-2 px-2"
                                  name="product_quantity"
                                  onChange={(e) => onChangeDefact(e, i)}
                                  placeholder="Product-Quantity "
                                />
                              </div>
                            </div>
                            <div className="flex gap-2 my-2">
                              <div>
                                <input
                                  type="text"
                                  onChange={(e) => onChangeDefact(e, i)}
                                  name="product_reason"
                                  className="border py-3 rounded-md placeholder:px-2 px-2"
                                  placeholder="Product Damage Reason... "
                                />
                              </div>
                              <div>
                                <input
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  className="border py-3 rounded-md placeholder:px-2 px-2"
                                  name="product_price"
                                  onChange={(e) => onChangeDefact(e, i)}
                                  placeholder="Product-price "
                                />
                              </div>
                            </div>

                            <div className="flex gap-3 my-2">
                              {defeactSoldProduct.length !== 1 && (
                                <p
                                  onClick={removeClickDefact}
                                  className="border border-black-600 bg-[white] p-2 text-red-800 hover:bg-red-800 hover:text-white text-bold rounded-[6px] "
                                >
                                  <AiOutlineMinus />
                                </p>
                              )}
                              {defeactSoldProduct.length - 1 === i && (
                                <p
                                  onClick={addClickDefact}
                                  className="border border-black-600 bg-[white] p-2 text-green-800 hover:bg-green-800 hover:text-white text-bold rounded-[6px] "
                                >
                                  <AiOutlinePlus />
                                </p>
                              )}
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : null}
                </div>
              </>

              <p className="uppercase opacity-30 py-3">Sold to </p>
              <div className="lg:flex md:flex gap-3 my-2">
                <input
                  type="text"
                  name="customerName"
                  value={datas.customerName}
                  onChange={onChangeHandle}
                  className="border py-3 rounded-md placeholder:px-2 px-2"
                  placeholder="Customer Name "
                />
                <input
                  type="text"
                  name="customerAddress"
                  value={datas.customerAddress}
                  onChange={onChangeHandle}
                  className="border py-3 rounded-md placeholder:px-2 px-2 my-3 md:my-0 lg:my-0"
                  placeholder="Customer Address "
                />
              </div>
              <div className="lg:flex md:flex gap-3 my-2">
                <input
                  type="text"
                  name="customerPhone"
                  value={datas.customerPhone}
                  onChange={onChangeHandle}
                  required
                  className="border py-3 rounded-md placeholder:px-2 px-2"
                  placeholder="Customer Phone "
                />

                <input
                  type="text"
                  name="customerPhone"
                  value={registrationNumber}
                  onChange={(e) => {
                    setRegistrationNumber(e.target.value);
                  }}
                  className="border py-3 rounded-md placeholder:px-2 px-2 my-3 md:my-0 lg:my-0"
                  placeholder="Customer PAN Number "
                />
              </div>
            </div>

            <div className="flex justify-center my-3 gap-3 ">
              <Button
                btnName={"Add"}
                click={Submit}
                isLoading={isButtonDisabled}
              />
              <Button btnName={"Reset"} />
            </div>
          </div>
        </Scrollbars>
      </div>
    </>
  );
};
const caseType = [
  {
    TypeName: "Cash ",
  },
  {
    TypeName: "Bank ",
  },
  {
    TypeName: "Both ",
  },
];

const monthOrder = [
  "बैशाख",
  "जेठ",
  "असार",
  "श्रावण",
  "भाद्र",
  "असोज",
  "कार्तिक",
  "मंसिर",
  "पुष",
  "माघ",
  "फागुन",
  "चैत्र",
];

export default AddSale;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars-2";
import { toast } from "react-toastify";

import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
import Button from "../../../Components/Button/Button";
import { assetPayCredit } from "../../../Redux/Feature/Assest/assestAction";

const ReturnAsset = () => {
  const { data } = useSelector((state) => state.products);
  const { assests } = useSelector((state) => state.assest);
  console.log(assests);

  console.log(data);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // New state for Button disable

  const [assetsId, setAssetsId] = useState("");
  const [amount, setAmount] = useState("");

  const [paidDate, setPaidDate] = useState("");

  const [transcationType, setTranscationType] = useState("");
  // const [bank, setBank] = useState("");
  // const [cash, setCash] = useState("");
  const [partialCash, setPartialCash] = useState("");
  const [partialBank, setPartialBank] = useState("");

  const assetData = assests
    .filter((ele) => ele._id === assetsId)
    .map((ele) => ele);

  console.log(assetData);

  const monthInBS = parseInt(paidDate.slice(5, 7)) - 1;
  const yearInBS = paidDate.slice(0, 4);

  const currentMonthInBS = monthOrder[monthInBS];
  console.log(currentMonthInBS);

  const month = currentMonthInBS + "-" + yearInBS;

  const dispatch = useDispatch();
  const returnAsset = () => {
    if (assetsId === "" || month === "" || paidDate === "" || amount === "")
      return toast.error("Some Feilds are missing");

    setIsButtonDisabled(true);
    dispatch(
      assetPayCredit({
        assetsId,
        amount,
        paidDate,
        month,
        cash,
        bank,
        transcationType,
        partialBank,
        partialCash,
      })
    ).then(() => {
      setIsButtonDisabled(false);
    });
  };

  console.log(assetsId);

  let cash = 0;
  let bank = 0;
  if (transcationType === "Cash") {
    cash = amount;
  }
  if (transcationType === "Bank") {
    bank = amount;
  }

  return (
    <>
      <Scrollbars style={{ height: 600 }}>
        <div class="container mx-auto">
          <div class="lg:flex md:flex justify-center px-6 ">
            <div class="w-full lg:w-9/12 px-4">
              <div>
                <div
                  className=" transition duration-150 ease-in-out z-10  "
                  id="modal"
                >
                  <div
                    role="alert"
                    className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
                  >
                    <div className=" ">
                      <div className=" lg:flex md:flex justify-center">
                        <div className="border border-gray-100 w-auto h-auto bg-gray-200 py-4 px-3 rounded-[6px] ">
                          <div className="flex">
                            <div className=" items-center">
                              <p className="text-red-500 px-3">
                                Please Select the Date
                              </p>

                              <NepaliDatePicker
                                className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0   "
                                value={paidDate}
                                onChange={(value) => setPaidDate(value)}
                                options={{
                                  calenderLocale: "ne",
                                  valueLocale: "en",
                                }}
                              />
                            </div>
                          </div>
                          <div className="mb-3">
                            <select
                              className="border py-3 rounded-md  w-full placeholder:px-2 px-2 lg:my-0 md:y-0 my-2"
                              name="ret_assetsId"
                              value={assetsId}
                              onChange={(e) => setAssetsId(e.target.value)}
                            >
                              <option>Select Asset</option>
                              {assests?.map((item, i) => {
                                return (
                                  <option key={i} value={item._id}>
                                    {item?.assestName}-
                                    {item?.registrationNumber}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          {assetsId ? (
                            <div>
                              <table class="min-w-full divide-y divide-gray-200  ">
                                <thead class="bg-gray-50  ">
                                  <tr>
                                    {th.map((item, i) => {
                                      return (
                                        <th
                                          scope="col col-span-5"
                                          key={i}
                                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                                        >
                                          {item.name}
                                        </th>
                                      );
                                    })}
                                  </tr>
                                </thead>

                                <tbody className="bg-white divide-y divide-gray-200    ">
                                  <tr>
                                    <td className="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                      <div className="items-center gap-x-3">
                                        {assetData.map((ele) => (
                                          <p>{ele.assestName}</p>
                                        ))}
                                      </div>
                                    </td>

                                    <td className="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                      <div className="flex items-center gap-x-2">
                                        <div>
                                          {assetData.map((ele) => (
                                            <p>{ele.assestPrice}</p>
                                          ))}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ) : null}

                          <div className="mt-3">
                            <input
                              type="number"
                              value={amount}
                              onWheel={(e) => e.target.blur()}
                              onChange={(e) => {
                                setAmount(e.target.value);
                              }}
                              className="border py-3 rounded-md w-full h-full placeholder:px-2 px-2 lg:my-0 md:y-0 my-2"
                              placeholder="Enter Asset Price"
                            />
                          </div>

                          <>
                            <div className="my-4">
                              <select
                                className="border py-3  rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                                name="transictionType"
                                value={transcationType}
                                onChange={(e) =>
                                  setTranscationType(e.target.value)
                                }
                              >
                                <option>Select Transiction Type</option>
                                {caseType.map((item, i) => {
                                  return <option>{item.TypeName}</option>;
                                })}
                              </select>

                              <div className="mt-3">
                                <p>
                                  {transcationType === "Bank" ? (
                                    <div>
                                      <input
                                        readOnly
                                        type="number"
                                        value={bank}
                                        className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                                      />
                                    </div>
                                  ) : transcationType === "Cash" ? (
                                    <div>
                                      <input
                                        readOnly
                                        type="number"
                                        value={cash}
                                        className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                                      />
                                    </div>
                                  ) : transcationType === "Both" ? (
                                    <>
                                      <div className="flex ">
                                        <input
                                          type="number"
                                          onWheel={(e) => e.target.blur()}
                                          value={partialBank}
                                          onChange={(e) => {
                                            setPartialBank(e.target.value);
                                            // console.log(
                                            //   forTranscation,
                                            //   "here"
                                            // );
                                            // const calculatedCash =
                                            //   forTransaction -
                                            //   e.target.value;
                                            // console.log(
                                            //   calculatedCash,
                                            //   "calculated cash"
                                            // );
                                            // setPartialCash(
                                            //   calculatedCash
                                            // );
                                          }}
                                          placeholder="Enter  BANK Amount"
                                          className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                                        />
                                      </div>
                                      <div className="my-2">
                                        <input
                                          type="number"
                                          onWheel={(e) => e.target.blur()}
                                          value={partialCash}
                                          onChange={(e) => {
                                            setPartialCash(e.target.value);
                                          }}
                                          placeholder="Enter  CASH Amount"
                                          className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                                        />
                                      </div>
                                    </>
                                  ) : null}
                                </p>
                              </div>
                            </div>
                          </>
                          {/* {assetsId ? (
                            <div>
                              <select
                                value={creditType}
                                className="border py-3 rounded-md w-full h-full placeholder:px-2 px-2 lg:my-0 md:y-0 my-2"
                                onChange={(e) => setCreditType(e.target.value)}
                                name="creditType"
                              >
                                <option>Credit</option>
                                <option>No Credit</option>
                                <option>Partial Credit</option>
                              </select>
                            </div>
                          ) : null} */}

                          <div className="flex justify-center my-3 gap-3 ">
                            <Button
                              btnName={"Return"}
                              click={returnAsset}
                              isLoading={isButtonDisabled}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Scrollbars>
    </>
  );
};

const th = [
  {
    name: "Product Name.",
  },

  {
    name: "Price",
  },
];

const caseType = [
  {
    TypeName: "Cash ",
  },
  {
    TypeName: "Bank ",
  },
  {
    TypeName: "Both ",
  },
];
const monthOrder = [
  "बैशाख",
  "जेठ",
  "असार",
  "श्रावण",
  "भाद्र",
  "असोज",
  "कार्तिक",
  "मंसिर",
  "पुष",
  "माघ",
  "फागुन",
  "चैत्र",
];
export default ReturnAsset;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../utils/API";
import { toast } from "react-toastify";

export const getMaskebari = createAsyncThunk(
  "get/maskebari",
  async ({ token }) => {
    try {
      const response = await api.get(`/maskebari/all-maskebari`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const submitMaskebari = createAsyncThunk(
  "submit/maskebari",
  async ({ payload, token }) => {
    try {
      const response = await api.post(`/maskebari/submit-maskebari`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // console.log(response);
      if (response.data.success === true) {
        toast.success(response.data.message);
      }
      if (response.data.success === false) {
        toast.error(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

import React, { useState } from "react";
import Button from "../../../../Components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { addtoBadDebt } from "../../../../Redux/Feature/BadDebt/BadDebtAction";
import { toast } from "react-toastify";

const AddBadDebt = () => {
  const [customerName, setCustomerName] = useState("");
  const [Amount, setAmount] = useState("");
  const dispatch = useDispatch();
  const { sales } = useSelector((state) => state.sale);
  const submit = () => {
    if (customerName === "" || Amount === "") {
      toast.error("Some Feilds are missing");
    } else {
      dispatch(addtoBadDebt({ customerName, Amount }));
    }
  };
  return (
    <>
      <div className=" flex justify-center">
        <div className="border border-gray-100 w-auto h-auto bg-gray-200 py-4 rounded-[6px] ">
          <div className="  my-2 mx-2">
            <div className="w-full h-full my-2 ">
              <select
                className="border py-3 h-full w-full rounded-md placeholder:px-2 px-2   my-2 md:my-0 lg:my-0"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
              >
                <option>Select Customer</option>
                {sales.map((item, i) => (
                  <option>
                    {item.customerName}-{item.customerPhone}
                  </option>
                ))}
              </select>
            </div>
            <div className=" w-full h-full my-2">
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                value={Amount}
                onChange={(e) => setAmount(e.target.value)}
                className="border py-3 rounded-md placeholder:px-2 px-2"
                placeholder=" Amount "
              />
            </div>
            <div className="flex justify-center my-3 gap-3 ">
              <Button btnName={"Add to Bad Debt"} click={submit} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBadDebt;

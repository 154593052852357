import { createSlice } from "@reduxjs/toolkit";
import {
  addEmployee,
  dltEmployee,
  getEmployeeByPosition,
  getEmployeeByStatus,
  getSingleEmployee,
  getStaff,
  updateEmployee,
} from "./staffAction";

const staffSlice = createSlice({
  name: "staff",
  initialState: {
    isLoading: true,
    staff: [],
    singleEmployee: [],
    positionEmployee: [],
    success: false,
    error: null,
    count: 0,
  },
  extraReducers: (builder) => {
    builder.addCase(getStaff.pending, (state) => {
      state.isLoading = true;
      state.success = false;
      state.staff = [];
      state.error = null;
    });
    builder.addCase(getStaff.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success;
      state.staff = action.payload.employees;
      state.count = action.payload.count;
    });
    builder.addCase(getStaff.rejected, (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload.error;
    });
    builder.addCase(dltEmployee.pending, (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    });
    builder.addCase(dltEmployee.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success;
    });
    builder.addCase(dltEmployee.rejected, (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload.error;
    });
    builder.addCase(addEmployee.pending, (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    });
    builder.addCase(addEmployee.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    });
    builder.addCase(addEmployee.rejected, (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload.error;
    });
    builder.addCase(getSingleEmployee.pending, (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    });
    builder.addCase(getSingleEmployee.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success;
      state.singleEmployee = action.payload.employee;
    });
    builder.addCase(getSingleEmployee.rejected, (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload.error;
    });
    builder.addCase(updateEmployee.pending, (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    });
    builder.addCase(updateEmployee.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success;
      state.singleEmployee = action.payload.employee;
    });
    builder.addCase(updateEmployee.rejected, (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.error;
    });
    builder.addCase(getEmployeeByStatus.pending, (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    });
    builder.addCase(getEmployeeByStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
      state.staff = action.payload.employees;
    });
    builder.addCase(getEmployeeByStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.error;
    });
    builder.addCase(getEmployeeByPosition.pending, (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    });
    builder.addCase(getEmployeeByPosition.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
      state.staff = action.payload.employees;
    });
    builder.addCase(getEmployeeByPosition.rejected, (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.error;
    });
  },
});

export const staffReducer = staffSlice.reducer;

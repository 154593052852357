import React from "react";
import { useSelector } from "react-redux";

const SaleProductTable = () => {
  const { singleSale } = useSelector((state) => state.sale);
  console.log(singleSale);
  return (
    <>
      <section class="container px-1 mx-auto">
        <div class="flex flex-col">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-2 lg:px-2">
              <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200  ">
                  <thead class="bg-gray-50  ">
                    <tr>
                      {th.map((item, i) => {
                        return (
                          <th
                            scope="col col-span-5"
                            key={i}
                            class="px-3 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                          >
                            {item.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  <tbody class="bg-white divide-y divide-gray-200  ">
                    {singleSale.soldProducts.map((item, i) => {
                      return (
                        <tr>
                          <td class="px-1 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                            <div class="inline-flex items-center  ">
                              <span>{i + 1}</span>
                            </div>
                          </td>
                          <td class="px-1 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                            <div class="inline-flex">
                              <span className="pt-3 px-2">
                                {item.productId}
                              </span>
                            </div>
                          </td>
                          <td class="px-3 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                            <div class="inline-flex items-center  ">
                              <span>{item.soldQuantity}</span>
                            </div>
                          </td>
                          <td class="px-3 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                            <div class="inline-flex items-center  ">
                              <span>{item.soldPrice}</span>
                            </div>
                          </td>

                          <td class="px-3 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                            <div class="inline-flex items-center  ">
                              <span>Rs.{item.subTotal}</span>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="my-2 text-end">
        <p className="uppercase opacity-30 pt-3">Transction Details</p>
        <p>Total Due : {singleSale.totalSaleBill}</p>
        <p>
          Discount:
          <span className="px-2">
            {singleSale.discountType === "Percent Discount(%)" ? (
              <>{singleSale.discount}%</>
            ) : singleSale.discountType === "Amount Discount(Rs.)" ? (
              <>Rs.{singleSale.discount}</>
            ) : (
              "No Dscount"
            )}
          </span>
        </p>
        <p>
          <span>creditType : {singleSale.creditType}</span>
          <p>
            {singleSale.creditType === "Partial Credit" ? (
              <>
                <p>Partial-Cash: {singleSale.partial_cash}</p>
                <p>Partial-Credit: {singleSale.partial_credit}</p>
              </>
            ) : singleSale.creditType === "Credit" ? (
              <p>Credit: {singleSale.credit}</p>
            ) : singleSale.creditType === "No Credit" ? (
              <p>Cash: {singleSale.cash}</p>
            ) : null}
          </p>
        </p>
      </div>
      <hr />
      {singleSale.defeactSoldProduct && (
        <>
          <section class="container px-1 mx-auto my-3">
            <div class="flex flex-col">
              <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle md:px-2 lg:px-2">
                  <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200  ">
                      <thead class="bg-gray-50  ">
                        <tr>
                          {defeactTabel.map((item, i) => {
                            return (
                              <th
                                scope="col col-span-5"
                                key={i}
                                class="px-3 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                              >
                                {item.name}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>

                      <tbody class="bg-white divide-y divide-gray-200  ">
                        {singleSale.defeactSoldProduct.map((item, i) => {
                          return (
                            <tr>
                              <td class="px-1 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                                <div class="inline-flex items-center  ">
                                  <span>{i + 1}</span>
                                </div>
                              </td>
                              <td class="px-1 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                                <div class="inline-flex">
                                  <span className="pt-3 px-2">
                                    {item.product_name}
                                  </span>
                                </div>
                              </td>
                              <td class="px-3 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                                <div class="inline-flex items-center  ">
                                  <span>{item.product_quantity}</span>
                                </div>
                              </td>
                              <td class="px-3 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                                {item.product_price}
                              </td>
                              <td class="px-3 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                                <div class="inline-flex items-center  ">
                                  <span>Rs.{item.productSubTotal}</span>
                                </div>
                              </td>

                              <td class="px-3 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                                <div class="inline-flex items-center  ">
                                  <span>{item.product_reason}</span>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <p className="text-end">Total Due : {singleSale.totalDefeactBill}</p>
        </>
      )}
    </>
  );
};
// discount

const th = [
  {
    name: "SN.",
  },

  {
    name: "Product Name",
  },
  {
    name: "Quanitity",
  },
  {
    name: "Per Price",
  },
  {
    name: "Total Amount",
  },
];

const defeactTabel = [
  {
    name: "SN.",
  },

  {
    name: "Product Name",
  },
  {
    name: "Quanitity",
  },
  {
    name: "Per Price",
  },
  {
    name: "Total Amount",
  },
  {
    name: "Reason",
  },
];

export default SaleProductTable;

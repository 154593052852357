import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../Screens/Login/Login";
import Register from "../Screens/Register/Register";
import PageNotFound from "../Components/PageNotFound/PageNotFound";
import Dashboard from "../Components/Dashboard/Dashboard";
import { Links } from "./Links";
import BillGenerated from "../Components/Bill/BillGenerated";
export const Router = () => {
  return (
    <>
      <Routes>
        {Links.map((item, i) => {
          return (
            <Route
              key={i}
              path={item.Path}
              element={
                <AuthWraper>
                  <Dashboard anotherComponent={item.Components} />{" "}
                </AuthWraper>
              }
            />
          );
        })}
        <Route
          path="/sale/single-sale/:id"
          element={
            <AuthWraper>
              <BillGenerated />
            </AuthWraper>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};
const AuthWraper = ({ children }) => {
  const token = localStorage.getItem("NHHD:token");

  if (token === null) {
    return <Navigate to={"/login"} />;
  }
  return token && children;
};

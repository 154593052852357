import { createSlice } from "@reduxjs/toolkit";
import {
  addCategoryExpenses,
  addExpenses,
  dltExpenses,
  dltExpensesCategory,
  filterExpensesByDate,
  filterExpensesCategory,
  filterExpensesName,
  getAllExpenses,
  getAllExpensesCategory,
} from "./expensesAction";

const expenseSlice = createSlice({
  name: "expenses",
  initialState: {
    isLoading: true,
    expenses: [],
    expensesCategory: [],
    count: 0,
    success: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllExpenses.pending, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.count = 0;
    });
    builder.addCase(getAllExpenses.fulfilled, (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      state.success = action.payload.success;
      state.expenses = action.payload.expenses;
      state.count = action.payload.total;
    });
    builder.addCase(getAllExpenses.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.error = action.error;
    });
    builder.addCase(addExpenses.pending, (state) => {
      state.isLoading = true;
      state.success = false;
      state.count = 0;
    });
    builder.addCase(addExpenses.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
      state.error = null;
    });
    builder.addCase(addExpenses.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.error = action.error;
    });
    builder.addCase(dltExpenses.pending, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.count = 0;
    });
    builder.addCase(dltExpenses.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    });
    builder.addCase(dltExpenses.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.error = action.error;
    });
    builder.addCase(filterExpensesByDate.pending, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.count = 0;
    });
    builder.addCase(filterExpensesByDate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
      state.expenses = action.payload.expenses;
    });
    builder.addCase(filterExpensesByDate.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.error = action.error;
    });
    builder.addCase(getAllExpensesCategory.pending, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.count = 0;
    });
    builder.addCase(getAllExpensesCategory.fulfilled, (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      state.success = action.payload.success;
      state.expensesCategory = action.payload.expenses;
      state.count = action.payload.total;
    });
    builder.addCase(getAllExpensesCategory.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.error = action.error;
    });
    builder.addCase(addCategoryExpenses.pending, (state) => {
      state.isLoading = true;
      state.success = false;
      state.count = 0;
    });
    builder.addCase(addCategoryExpenses.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
      state.error = null;
    });
    builder.addCase(addCategoryExpenses.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.error = action.error;
    });
    builder.addCase(dltExpensesCategory.pending, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.count = 0;
    });
    builder.addCase(dltExpensesCategory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    });
    builder.addCase(dltExpensesCategory.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.error = action.error;
    });
    builder.addCase(filterExpensesCategory.pending, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.count = 0;
    });
    builder.addCase(filterExpensesCategory.fulfilled, (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      state.success = action.payload.success;
      state.expenses = action.payload.expenses;
      state.count = action.payload.total;
    });
    builder.addCase(filterExpensesCategory.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.error = action.error;
    });
    builder.addCase(filterExpensesName.pending, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.count = 0;
    });
    builder.addCase(filterExpensesName.fulfilled, (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      state.success = action.payload.success;
      state.expenses = action.payload.expenses;
      state.count = action.payload.total;
    });
    builder.addCase(filterExpensesName.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.error = action.error;
    });
  },
});

export const expensesReducer = expenseSlice.reducer;

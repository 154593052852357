import React from "react";
import Tittle from "../../Components/Tittle/Tittle";
import Journal from "./Journal";

const index = () => {
  return (
    <div>
      <Tittle tittle={"General-Journal"} />
      <Journal />
    </div>
  );
};

export default index;

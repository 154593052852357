import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { productReducer } from "../Feature/Product/productSlice";
import { authReducer } from "../Feature/auth/authSlice";
import { categoryReducer } from "../Feature/category/categorySlice";
import { supplierReducer } from "../Feature/supplier/supplierSlice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { saleReducer } from "../Feature/sale/saleSlice";
import { dashboardReducer } from "../Feature/dashboard/dashboardSlice";
import { staffReducer } from "../Feature/staff/staffSlice";
import { billReducer } from "../Feature/Bill/billSlice";
import { salaryReducer } from "../Feature/Salary/salarySlice";
import { expensesReducer } from "../Feature/Expenses/expenseSlice";
import { journalReducer } from "../Feature/GeneralJournal/journalSlice";
import { returnReducer } from "../Feature/RetunProduct/returnProductSlice";
import { badDebtebtReducer } from "../Feature/BadDebt/BadDebtSlice";
import { inventeryExpensesReducer } from "../Feature/InventeryExpenses/InventeryExpensesSlice";
import { assestReducer } from "../Feature/Assest/assestSlice";
import { empSalaryReducer } from "../Feature/EmployeeSalary/EmpSalarySlice";
import { ledgerReducer } from "../Feature/Ledger/ledgerSlice";
import { maskebariReducer } from "../Feature/Maskebari/maskebariSlice";
import { purchaseAccountReducer } from "../Feature/PurchaseAccount/purchaseAccountSlice";
import { salesAccountReducer } from "../Feature/SalesAccount/salesAccountSlice";
import { salesReturnReducer } from "../Feature/SalesReturnAccount/salesReturnSlice";
import { purchaseReturnReducer } from "../Feature/PurchaseReturn/purchaseReturnSlice";
const persistConfig = {
  key: "root",
  storage,
};

const combineReducer = combineReducers({
  products: productReducer,
  auth: authReducer,
  category: categoryReducer,
  supplier: supplierReducer,
  dashboard: dashboardReducer,
  employee: staffReducer,
  sale: saleReducer,
  bill: billReducer,
  salary: salaryReducer,
  expense: expensesReducer,
  generalJournal: journalReducer,
  returnProduct: returnReducer,
  badbebt: badDebtebtReducer,
  inventeryExpenses: inventeryExpensesReducer,
  assest: assestReducer,
  employeeSalary: empSalaryReducer,
  ledger: ledgerReducer,
  maskebari: maskebariReducer,
  purchaseAccount: purchaseAccountReducer,
  salesAccount: salesAccountReducer,
  salesReturn: salesReturnReducer,
  purchaseReturn: purchaseReturnReducer,
});

const persistedReducer = persistReducer(persistConfig, combineReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
export const persistor = persistStore(store);

import React from "react";
import ListExpense from "./ListExpense";
import Button from "../../Components/Button/Button";
import Tittle from "../../Components/Tittle/Tittle";
import BasicModal from "../../Components/Modal/Modal";
import AddExpense from "./AddExpense";

const index = () => {
  return (
    <>
      <div>
        <Tittle tittle={"Expenses"} />
        <BasicModal
          btn={<Button btnName={"Add Expenses"} />}
          tittle={"Add Expenses"}
          modalDetails={<AddExpense />}
        />
        <div className="mt-[2rem]">
          <ListExpense />
        </div>
      </div>
    </>
  );
};

export default index;

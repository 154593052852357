import React from "react";
import { useSelector } from "react-redux";
import { Spinner } from "../../Components/Spinner/Spinner";
import { FaUserAlt } from "react-icons/fa";
import { MdOutlineLocationOn } from "react-icons/md";
import { Container } from "@mui/material";
import { BsFillTelephoneFill, BsPencil } from "react-icons/bs";
import SaleProductTable from "./Sale-product-table";
import BasicModal from "../../Components/Modal/Modal";
import UpdateCustomer from "./UpdateCustomer";

const SingleSale = () => {
  // const token = localStorage.getItem("NHHD:token");
  const { isLoading, singleSale } = useSelector((state) => state.sale);
  console.log(singleSale);
  // console.log(singleSale._id);

  // const dispatch = useDispatch();

  // const navigate = useNavigate();

  // const generateBil = () => {
  //   dispatch(generateBills({ token, saleId: singleSale._id, navigate }));
  // };

  return (
    <>
      {isLoading === true ? (
        <Spinner />
      ) : (
        <div>
          <div className="md:absolute lg:absolute md:m-3 lg:m-3 m-2 right-0">
            {/* <Button
              btnName={
                bill && bill.success === true
                  ? "Go to Bill Dashboard"
                  : "Generate invoice"
              }
              click={generateBil}
            /> */}
            {/* <Link to={singleSale._id} target=" ">
              <Button btnName={"Generate bill"} />
            </Link> */}
          </div>

          <>
            <Container>
              <div className="lg:mt-[3rem] md:mt-[3rem] ">
                <div className="flex">
                  <h1 className=" text-xl underline lg:py-3 md:py-3 ">
                    Customer Details
                  </h1>
                  <span>
                    <BasicModal
                      btn={
                        <BsPencil className="text-green-800 text-2xl m-2 text-bold" />
                      }
                      modalDetails={<UpdateCustomer />}
                    ></BasicModal>
                  </span>
                </div>

                <div className="grid lg:grid-cols-3  md:grid-cols-3 grid-flow-row gap-3 border-green-800 p-3">
                  <div className="flex gap-2 border ">
                    <span>
                      <FaUserAlt className="text-5xl border rounded-[6px] bg-slate-300 " />
                    </span>
                    <p className="px-2">
                      <span className="text-[0.7rem]">
                        Customer Name
                        <br />
                      </span>
                      <span className="text-[0.9rem]">
                        {singleSale.customerName}
                      </span>
                    </p>
                  </div>
                  <div className="flex gap-2 border ">
                    <span>
                      <BsFillTelephoneFill className="text-5xl border rounded-[6px] bg-slate-300 " />
                    </span>
                    <p className="px-2">
                      <span className="text-[0.7rem]">
                        Customer Phone
                        <br />
                      </span>
                      <span className="text-[0.9rem]">
                        {singleSale.customerPhone}
                      </span>
                    </p>
                  </div>
                  <div className="flex gap-2 border ">
                    <span>
                      <MdOutlineLocationOn className="text-5xl border rounded-[6px] bg-slate-300 " />
                    </span>
                    <p className="px-2">
                      <span className="text-[0.7rem]">
                        Customer Address
                        <br />
                      </span>
                      <span className="text-[0.9rem]">
                        {singleSale.customerAddress}
                      </span>
                    </p>
                  </div>
                </div>
                <div>
                  <p className=" text-xl underline py-3 ">Product Details</p>
                  <SaleProductTable />
                </div>
              </div>
            </Container>
          </>
        </div>
      )}
    </>
  );
};

export default SingleSale;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addPendingSalary } from "../../Redux/Feature/EmployeeSalary/EmpSalaryAction";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import Scrollbars from "react-custom-scrollbars-2";

const PayPendingSalary = ({ salaryId, month }) => {
  const [pendingAmount, setPendingAmount] = useState("");

  const { pendingSalary, singleEmp_salaries } = useSelector(
    (state) => state.employeeSalary
  );
  const dispatch = useDispatch();

  // filter data for pendingSalary with remaining amount not equal to zero
  const pendingSalaryWithoutRemainingAmount = pendingSalary.filter(
    (obj) => obj.remainingAmount !== 0
  );
  console.log(pendingSalaryWithoutRemainingAmount);

  // Function to convert the month format from "March-2023" to "2023-03"
  function convertMonthFormat(month) {
    const [monthStr, yearStr] = month.split("-");
    const monthIndex = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ].indexOf(monthStr);
    const monthNumber = (monthIndex + 1).toString().padStart(2, "0");
    return `${yearStr}-${monthNumber}`;
  }

  // Function to combine data from dataset1 and dataset2
  function combineData(data1, data2) {
    // Create a map to store employee data with the month as the key
    const employeeDataMap = new Map();

    // Add data from dataset1 to the map
    for (const obj1 of data1) {
      const monthYear = convertMonthFormat(obj1.month);
      employeeDataMap.set(monthYear, { ...obj1 });
    }

    // Add salary details from dataset2 to the corresponding month in the map
    for (const obj2 of data2) {
      const monthYear = convertMonthFormat(obj2.salaryDetails[0].month);
      if (employeeDataMap.has(monthYear)) {
        employeeDataMap.get(monthYear).salaryDetails = obj2.salaryDetails[0];
      }
    }

    // Convert the map values to an array
    const combinedData = Array.from(employeeDataMap.values());

    return combinedData;
  }

  const combinedResult = combineData(
    pendingSalaryWithoutRemainingAmount,
    singleEmp_salaries
  );

  const [selectedMonth, setSelectedMonth] = useState("");
  const [isMonthSelected, setIsMonthSelected] = useState(false); // Track whether the month is selected or not

  const [selectedRemainingAmount, setSelectedRemainingAmount] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedSalaryDetailsId, setSelectedSalaryDetailsId] = useState("");

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setSelectedMonth(selectedMonth);

    // Find the data object for the selected month
    const selectedData = combinedResult.find(
      (obj) => obj.month === selectedMonth
    );
    if (selectedData) {
      setIsMonthSelected(true); // Set isMonthSelected to true when the month is selected
      setSelectedRemainingAmount(selectedData.remainingAmount);
      setSelectedId(selectedData._id);

      // Check if salaryDetails exist for the selected month
      if (selectedData.salaryDetails) {
        setSelectedSalaryDetailsId(selectedData.salaryDetails._id);
      } else {
        setSelectedSalaryDetailsId(""); // If salary details not available, set it to empty string
      }
    } else {
      setIsMonthSelected(false); // Set isMonthSelected to false if the selected month data is not found

      setSelectedRemainingAmount("");
      setSelectedId("");
      setSelectedSalaryDetailsId("");
    }
    console.log(selectedData, selectedSalaryDetailsId, "tfgh");
  };

  const submit = () => {
    dispatch(
      addPendingSalary({
        salaryId: selectedSalaryDetailsId,
        amount: pendingAmount,
        month: selectedMonth,
        pendingSalaryId: selectedId,
      })
    );
  };

  const [salaryDetails, setSalaryDetails] = useState([
    {
      amount: "",
      month: "",
      paidDate: "",
    },
  ]);
  // Add a state variable to track if the amount exceeds the remaining amount
  const [amountExceedsRemaining, setAmountExceedsRemaining] = useState(false);

  // Add a state variable to store the remaining amount message
  const [remainingAmountMessage, setRemainingAmountMessage] = useState("");

  // Function to handle changes in the amount input
  const handleAmountChange = (event) => {
    const enteredAmount = parseFloat(event.target.value);
    if (selectedRemainingAmount && enteredAmount > selectedRemainingAmount) {
      // If the entered amount exceeds the remaining amount, set the state variables accordingly
      setAmountExceedsRemaining(true);
      setRemainingAmountMessage(
        `The remaining amount is ${selectedRemainingAmount}. Please enter an amount equal to or less than ${selectedRemainingAmount}.`
      );
    } else {
      setAmountExceedsRemaining(false);
      setRemainingAmountMessage("");
    }
    setPendingAmount(enteredAmount); // Update the pendingAmount state
  };

  const onChangeFeilds = (e, i) => {
    const { name, value } = e.target;
    const list = [...salaryDetails];
    list[i][name] = value;
    setSalaryDetails(list);
  };

  const removeClick = (i) => {
    const list = [...salaryDetails];
    list.splice(1, 1);
    setSalaryDetails(list);
  };

  const addClick = () => {
    setSalaryDetails([
      ...salaryDetails,
      {
        salaryDetails,
      },
    ]);
  };
  return (
    <Scrollbars style={{ height: 500 }}>
      <div class="container mx-auto">
        <div class="lg:flex md:flex justify-center px-6 ">
          <div class="w-full lg:w-9/12 px-4">
            <div>
              <div
                className=" transition duration-150 ease-in-out z-10  "
                id="modal"
              >
                <div
                  role="alert"
                  className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
                >
                  <div>
                    <div className=" lg:flex md:flex justify-center">
                      <div className="border border-gray-100 w-auto h-auto bg-gray-200 py-4 px-3 rounded-[6px] ">
                        {!isMonthSelected ? (
                          <div className="flex flex-col items-center justify-center mb-4">
                            <p className="text-[13px] py-2 text-red-900">
                              Please select the month first.
                            </p>
                            <select
                              className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                              value={selectedMonth}
                              onChange={handleMonthChange}
                            >
                              <option value="">Select a month</option>
                              {pendingSalaryWithoutRemainingAmount.map(
                                (obj) => (
                                  <option key={obj._id} value={obj.month}>
                                    {obj.month}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        ) : (
                          <>
                            {salaryDetails.map((item, i) => {
                              return (
                                <div key={i}>
                                  <div>
                                    <div>
                                      <input
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        className={`py-3 rounded-md w-full h-full placeholder:px-2 px-2 mb-2    ${
                                          amountExceedsRemaining
                                            ? "text-red-900"
                                            : null
                                        }`}
                                        placeholder="Enter Amount"
                                        name="amount"
                                        onChange={handleAmountChange} // Update the onChange event handler
                                      />
                                      {/* Display the remaining amount message if the amount exceeds the remaining amount */}
                                      {amountExceedsRemaining && (
                                        <p className="text-red-900 text-sm mb-2">
                                          {remainingAmountMessage}
                                        </p>
                                      )}
                                    </div>
                                    {/* <select
                                  className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                                  name="month"
                                  onChange={(e) => onChangeFeilds(e, i)}
                                >
                                  <option>Select Month</option>
                                  {pendingSalaryWithoutRemainingAmount.map(
                                    (item, i) => {
                                      return <option>{item.month}</option>;
                                    }
                                  )}
                                </select> */}
                                    <select
                                      className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                                      value={selectedMonth}
                                      onChange={handleMonthChange}
                                    >
                                      <option value="">Select a month</option>
                                      {pendingSalaryWithoutRemainingAmount.map(
                                        (obj) => (
                                          <option
                                            key={obj._id}
                                            value={obj.month}
                                          >
                                            {obj.month}
                                          </option>
                                        )
                                      )}
                                    </select>

                                    {pendingSalaryWithoutRemainingAmount.remainingAmount <
                                      item.amount && (
                                      <p className="text-[13px] py-2 text-red-900">
                                        Employee Salary must be less than or
                                        equals to Monthy Salary:{" "}
                                        {
                                          pendingSalaryWithoutRemainingAmount.remainingAmount
                                        }
                                      </p>
                                    )}

                                    <input
                                      type="Date"
                                      className={`border py-3 rounded-md placeholder:px-2 px-2 w-full my-2 `}
                                      name="paidDate"
                                      onChange={(e) => onChangeFeilds(e, i)}
                                    />
                                  </div>
                                  <div className="flex gap-3 my-2">
                                    {salaryDetails.length !== 1 && (
                                      <p
                                        onClick={removeClick}
                                        className="border border-black-600 bg-[white] p-2 text-red-800 hover:bg-red-800 hover:text-white text-bold rounded-[6px] "
                                      >
                                        <AiOutlineMinus />
                                      </p>
                                    )}
                                    {salaryDetails.length - 1 === i && (
                                      <p
                                        onClick={addClick}
                                        className="border border-black-600 bg-[white] p-2 text-green-800 hover:bg-green-800 hover:text-white text-bold rounded-[6px] "
                                      >
                                        <AiOutlinePlus />
                                      </p>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}

                        <button
                          className={`border bg-green-700 w-[200px] text-center text-white font-[500] text-xl py-2 px-3 rounded-[6px] my-4 uppercase ${
                            amountExceedsRemaining
                              ? "cursor-not-allowed opacity-50"
                              : "hover:bg-green-800"
                          }`}
                          onClick={submit}
                          disabled={amountExceedsRemaining} // Disable the button if the amount exceeds the remaining amount
                        >
                          Add Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Scrollbars>
  );
};

// const caseType = [
//   {
//     TypeName: "Cash ",
//   },
//   {
//     TypeName: "Bank ",
//   },
//   {
//     TypeName: "Both ",
//   },
// ];

// const date = new Date();
// const year = date.getFullYear();

// const month = [
//   {
//     name: `January-${year}`,
//   },
//   {
//     name: `Febuary-${year}`,
//   },
//   {
//     name: `March-${year}`,
//   },
//   {
//     name: `April-${year}`,
//   },
//   {
//     name: `May-${year}`,
//   },
//   {
//     name: `June-${year}`,
//   },
//   {
//     name: `July-${year}`,
//   },
//   {
//     name: `August-${year}`,
//   },
//   {
//     name: `September-${year}`,
//   },
//   {
//     name: `October-${year}`,
//   },
//   {
//     name: `November-${year}`,
//   },
//   {
//     name: `December-${year}`,
//   },
// ];
export default PayPendingSalary;

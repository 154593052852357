import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { Scrollbars } from "react-custom-scrollbars-2";
import { addReturnProduct } from "../../../Redux/Feature/RetunProduct/returnProductAction";
import { toast } from "react-toastify";

import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
import Button from "../../../Components/Button/Button";

const ReturnProduct = () => {
  const { data } = useSelector((state) => state.products);
  // const { supplies } = useSelector((state) => state.supplier);

  console.log(data);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // New state for Button disable

  const [returnProucts, setReturnProducts] = useState([
    {
      ret_productId: "",
      ret_productQuantity: 0,
      ret_productPrice: 0,
    },
  ]);
  const [supplierId, setSupplierId] = useState("");
  // const [purchaseId, setPurchaseId] = useState("");

  // const [month, setMonth] = useState("");
  const [returnDate, setReturnDate] = useState("");
  // const [billNO, setBillNO] = useState("");

  const [transcationType, setTranscationType] = useState("");
  // const [bank_amount, setBank_amount] = useState("");
  // const [cash_amount, setCash_amount] = useState("");
  const [partialCash, setPartialCash] = useState("");
  const [partialBank, setPartialBank] = useState("");

  const onChangeFeilds = (e, i) => {
    const { name, value } = e.target;
    const list = [...returnProucts];
    list[i][name] = value;
    setReturnProducts(list);
  };

  const removeClick = (i) => {
    const list = [...returnProucts];
    list.splice(1, 1);
    setReturnProducts(list);
  };

  const addClick = () => {
    setReturnProducts([
      ...returnProucts,
      {
        returnProucts,
      },
    ]);
  };

  const productData = data
    .filter((ele) => ele._id === supplierId)
    .map((ele) => ele);

  console.log(productData);
  // const [creditType, setCreditType] = useState("");
  const monthInBS = parseInt(returnDate.slice(5, 7)) - 1;
  const yearInBS = returnDate.slice(0, 4);

  const currentMonthInBS = monthOrder[monthInBS];
  console.log(currentMonthInBS);

  const returnMonth = currentMonthInBS + "-" + yearInBS;

  const dispatch = useDispatch();
  const returnProduct = () => {
    if (supplierId === "" || returnProduct === "" || returnDate === "")
      return toast.error("Some Feilds are missing");

    setIsButtonDisabled(true);
    dispatch(
      addReturnProduct({
        supplierId,
        returnProucts,
        returnDate,
        returnMonth,
        registrationNo,
        vatAmount,
        nonTaxableAmount,
        purchaseId,
        transcationType,
        partialBank,
        partialCash,
      })
    ).then(() => {
      setIsButtonDisabled(false);
    });
  };

  const purchase = productData.map((data) => {
    return data._id;
  });
  const registrationNo1 = productData.map((data) => {
    return data.supplierDetails.regisitrationNumber;
  });
  // const vatAmount1 = productData.map((data) => {
  //   return data.VATamount;
  // });
  const vatPercent1 = productData.map((data) => {
    return data.vatPercent;
  });
  const nonTaxableAmount1 = productData.map((data) => {
    return data.nonTaxableAmount;
  });
  const registrationNo = registrationNo1[0];
  const vatPercent = vatPercent1[0];
  const nonTaxableAmount = nonTaxableAmount1[0];
  const purchaseId = purchase[0];

  const vatAmount = returnProucts.reduce((accumulator, product) => {
    const productResult =
      product.ret_productQuantity *
      product.ret_productPrice *
      vatPercent *
      0.01;

    return accumulator + productResult;
  }, 0);

  const result = returnProucts.reduce((accumulator, product) => {
    const productResult =
      product.ret_productQuantity *
      product.ret_productPrice *
      vatPercent *
      0.01;
    const result =
      product.ret_productPrice * product.ret_productQuantity + productResult;
    return accumulator + result;
  }, 0);
  console.log(result, "jello");
  console.log(vatPercent);
  const totalAmountAfterVat = result;

  return (
    <>
      <Scrollbars style={{ height: 500 }}>
        <div class="container mx-auto">
          <div class="lg:flex md:flex justify-center px-6 ">
            <div class="w-full lg:w-9/12 px-4">
              <div>
                <div
                  className=" transition duration-150 ease-in-out z-10  "
                  id="modal"
                >
                  <div
                    role="alert"
                    className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
                  >
                    <div className=" ">
                      <div className=" lg:flex md:flex justify-center">
                        <div className="border border-gray-100 w-auto h-auto bg-gray-200 py-4 px-3 rounded-[6px] ">
                          <div className="flex">
                            <div className=" items-center">
                              <p className="text-red-500 px-3">
                                Please Select the Date
                              </p>

                              <NepaliDatePicker
                                className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0   "
                                value={returnDate}
                                onChange={(value) => setReturnDate(value)}
                                options={{
                                  calenderLocale: "ne",
                                  valueLocale: "en",
                                }}
                              />
                            </div>
                            {/* <div>
                              <p className="uppercase text-green-800 py-1">
                                Bill No.
                              </p>
                              <input
                                type="number" onWheel={(e) => e.target.blur()}
                                value={billNO}
                                onChange={(e) => {
                                  setBillNO(e.target.value);
                                }}
                                className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full"
                                placeholder="Enter Bill Number"
                              />
                            </div> */}
                          </div>
                          <div>
                            <select
                              className="border py-3 rounded-md  w-full placeholder:px-2 px-2 lg:my-0 md:y-0 my-2"
                              name="ret_supplierId"
                              value={supplierId}
                              onChange={(e) => setSupplierId(e.target.value)}
                            >
                              <option>Select supplier</option>
                              {data?.map((item, i) => {
                                console.log(item);
                                return (
                                  <option key={i} value={item._id}>
                                    {item.supplierDetails?.supplierName}-
                                    {item.supplierDetails?.phoneNumber}-
                                    {item.supplierDetails?.regisitrationNumber}-
                                    {item.billNo}-({item.purchaseDate})
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          {supplierId ? (
                            <div>
                              <table class="min-w-full divide-y divide-gray-200  ">
                                <thead class="bg-gray-50  ">
                                  <tr>
                                    {th.map((item, i) => {
                                      return (
                                        <th
                                          scope="col col-span-5"
                                          key={i}
                                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                                        >
                                          {item.name}
                                        </th>
                                      );
                                    })}
                                  </tr>
                                </thead>

                                <tbody className="bg-white divide-y divide-gray-200    ">
                                  <tr>
                                    <td className="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                      <div className="items-center gap-x-3">
                                        {productData.map((ele) =>
                                          ele.purchaseFrom.map((ele) => (
                                            <p>{ele.productName}</p>
                                          ))
                                        )}
                                      </div>
                                    </td>

                                    <td className="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                      <div className="flex items-center gap-x-2">
                                        <div>
                                          {productData.map((ele) =>
                                            ele.purchaseFrom.map((ele) => (
                                              <p>{ele.productQuantity}</p>
                                            ))
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                      <div className="flex items-center gap-x-2">
                                        <div>
                                          {productData.map((ele) =>
                                            ele.purchaseFrom.map((ele) => (
                                              <p>{ele.productPrice}</p>
                                            ))
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ) : null}

                          {returnProucts.map((item, i) => {
                            return (
                              <>
                                <div className="lg:flex md:flex gap-3 my-2 mt-10">
                                  <select
                                    className="border py-3 rounded-md w-full h-full placeholder:px-2 px-2 lg:my-0 md:y-0 my-2"
                                    onChange={(e) => onChangeFeilds(e, i)}
                                    name="ret_productId"
                                  >
                                    <option>Select Product</option>

                                    {/* {supplies
                                      .filter((ele) => ele._id === supplierId)
                                      .map((ele) =>
                                        ele.purchaseDetails.map((ele) =>
                                          ele.productDetails.map((ele) => (
                                            <option>{ele.productName}</option>
                                          ))
                                        )
                                      )} */}
                                    {productData.map((ele) =>
                                      ele.purchaseFrom.map((ele) => (
                                        <option value={ele._id}>
                                          {ele.productName}
                                        </option>
                                      ))
                                    )}
                                  </select>
                                </div>
                                <div className="lg:flex md:flex gap-3 ">
                                  <div>
                                    <input
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      name="ret_productQuantity"
                                      onChange={(e) => onChangeFeilds(e, i)}
                                      className="border py-3 rounded-md w-full h-full placeholder:px-2 px-2 lg:my-0 md:y-0 my-2"
                                      placeholder="Enter Product Quntity"
                                    />
                                  </div>
                                  <div>
                                    {" "}
                                    <input
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      name="ret_productPrice"
                                      onChange={(e) => onChangeFeilds(e, i)}
                                      className="border py-3 rounded-md w-full h-full placeholder:px-2 px-2 lg:my-0 md:y-0 my-2"
                                      placeholder="Enter Product Price"
                                    />
                                  </div>
                                </div>

                                <div className="flex gap-3 my-2">
                                  {returnProucts.length !== 1 && (
                                    <p
                                      onClick={removeClick}
                                      className="border border-black-600 bg-[white] p-2 text-red-800 hover:bg-red-800 hover:text-white text-bold rounded-[6px] "
                                    >
                                      <AiOutlineMinus />
                                    </p>
                                  )}
                                  {returnProucts.length - 1 === i && (
                                    <p
                                      onClick={addClick}
                                      className="border border-black-600 bg-[white] p-2 text-green-800 hover:bg-green-800 hover:text-white text-bold rounded-[6px] "
                                    >
                                      <AiOutlinePlus />
                                    </p>
                                  )}
                                </div>
                              </>
                            );
                          })}

                          <div className="flex flex-col p-1 ">
                            <div>
                              <p> Supplier Registration Number</p>
                              <input
                                className="w-full"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                readOnly
                                value={registrationNo}
                              />
                            </div>
                            <div className="flex items-center  gap-3">
                              <div>
                                <p>VAT Percent</p>
                                <input
                                  className="w-full"
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  readOnly
                                  value={vatPercent}
                                />
                              </div>
                              <div>
                                <p>Total VAT Amount</p>
                                <input
                                  className="w-full"
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  readOnly
                                  value={vatAmount}
                                />
                              </div>
                              <div>
                                <p>Total Amount After VAT</p>
                                <input
                                  className="w-full"
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  readOnly
                                  value={totalAmountAfterVat}
                                />
                              </div>
                            </div>

                            <div>
                              {nonTaxableAmount !== undefined ? (
                                <div>
                                  <p>Total Non Taxable Amount</p>
                                  <input
                                    className="w-full"
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    readOnly
                                    value={nonTaxableAmount}
                                  />
                                </div>
                              ) : null}
                            </div>
                          </div>

                          {/* <div>
                            <p>Total Amount After VAT:</p>
                            <p>{totalAmountAfterVat}</p>
                          </div> */}
                          <>
                            <div className="lg:flex md:flex gap-3  my-4">
                              <select
                                className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                                name="transictionType"
                                value={transcationType}
                                onChange={(e) =>
                                  setTranscationType(e.target.value)
                                }
                              >
                                <option>Select Transiction Type</option>
                                {caseType.map((item, i) => {
                                  return <option>{item.TypeName}</option>;
                                })}
                              </select>

                              <div>
                                <p>
                                  {transcationType ===
                                  "Bank" ? null : transcationType ===
                                    "Cash" ? null : transcationType ===
                                    "Both" ? (
                                    <>
                                      <div className="flex ">
                                        <input
                                          type="number"
                                          onWheel={(e) => e.target.blur()}
                                          value={partialBank}
                                          onChange={(e) => {
                                            setPartialBank(e.target.value);
                                            // console.log(
                                            //   forTranscation,
                                            //   "here"
                                            // );
                                            // const calculatedCash =
                                            //   forTransaction -
                                            //   e.target.value;
                                            // console.log(
                                            //   calculatedCash,
                                            //   "calculated cash"
                                            // );
                                            // setPartialCash(
                                            //   calculatedCash
                                            // );
                                          }}
                                          placeholder="Enter  BANK Amount"
                                          className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                                        />
                                      </div>
                                      <div className="my-2">
                                        <input
                                          type="number"
                                          onWheel={(e) => e.target.blur()}
                                          value={partialCash}
                                          onChange={(e) => {
                                            setPartialCash(e.target.value);
                                          }}
                                          placeholder="Enter  CASH Amount"
                                          className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                                        />
                                      </div>
                                    </>
                                  ) : null}
                                </p>
                              </div>
                            </div>
                          </>
                          {/* {supplierId ? (
                            <div>
                              <select
                                value={creditType}
                                className="border py-3 rounded-md w-full h-full placeholder:px-2 px-2 lg:my-0 md:y-0 my-2"
                                onChange={(e) => setCreditType(e.target.value)}
                                name="creditType"
                              >
                                <option>Credit</option>
                                <option>No Credit</option>
                                <option>Partial Credit</option>
                              </select>
                            </div>
                          ) : null} */}

                          <div className="flex justify-center my-3 gap-3 ">
                            <Button
                              btnName={"Return"}
                              click={returnProduct}
                              isLoading={isButtonDisabled}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Scrollbars>
    </>
  );
};

const th = [
  {
    name: "Product Name.",
  },
  {
    name: "qnty",
  },
  {
    name: "price",
  },
];

const caseType = [
  {
    TypeName: "Cash ",
  },
  {
    TypeName: "Bank ",
  },
  {
    TypeName: "Both ",
  },
];
const monthOrder = [
  "बैशाख",
  "जेठ",
  "असार",
  "श्रावण",
  "भाद्र",
  "असोज",
  "कार्तिक",
  "मंसिर",
  "पुष",
  "माघ",
  "फागुन",
  "चैत्र",
];
export default ReturnProduct;

import React from "react";
import { Spinner } from "../../Components/Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { filtersalaryByEmployeeId } from "../../Redux/Feature/Salary/salaryAction";

import { Link } from "react-router-dom";
import Pagination from "./Pagination";
import { getSingleEmployee } from "../../Redux/Feature/staff/staffAction";
const EmployeeList = () => {
  const { count } = useSelector((state) => state.employee);

  const { staff, isLoading } = useSelector((state) => state.employee);
  console.log(staff);
  const token = localStorage.getItem("NHHD:token");

  const dispatch = useDispatch();

  const singleEmployee = (id) => {
    dispatch(getSingleEmployee({ id: id, token }));
    dispatch(filtersalaryByEmployeeId({ employeeId: id, token }));
  };

  return (
    <>
      <div class="flex flex-col">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div class="overflow-hidden border border-gray-200   md:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200  ">
                <thead class="bg-gray-50 ">
                  <tr>
                    {th.map((item, i) => {
                      return (
                        <th
                          scope="col col-span-5"
                          key={i}
                          class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                        >
                          {item.name}
                        </th>
                      );
                    })}
                    <th
                      scope="col col-span-5"
                      class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                {isLoading === true ? (
                  <Spinner />
                ) : (
                  <tbody class="bg-white divide-y divide-gray-200">
                    {staff.slice(0, 6).map((item, i) => {
                      return (
                        <tr
                          key={i}
                          className={`${
                            item.status === "Pending"
                              ? "bg-green-400"
                              : item.status === "Paid"
                              ? "bg-yellow-100"
                              : "bg-white"
                          }`}
                        >
                          <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                            <div class="inline-flex items-center gap-x-3">
                              <span>{i + 1}</span>
                            </div>
                          </td>
                          <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap ">
                            <div class="flex items-center gap-x-2">
                              <div>
                                {" "}
                                <h2 class="text-sm capitalize font-medium text-gray-800   ">
                                  {item.employeeName}
                                </h2>
                              </div>
                            </div>
                          </td>

                          <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                            <div class="flex items-center gap-x-2">
                              <div>
                                <h2 class="text-sm capitalize font-medium text-gray-800   ">
                                  {item.employeeSalary}
                                </h2>
                              </div>
                            </div>
                          </td>
                          <td class="  py-4 text-sm text-gray-500   whitespace-nowrap">
                            <div class="flex items-center  m-3">
                              <div className="flex items-center gap-3">
                                <h2 class="text-sm capitalize font-medium text-blue-800   ">
                                  <Link to={item._id}>
                                    <span
                                      className={`border rounded-md shadow-md text-black py-2 px-3  captialize bg-blue-300 font-[300] `}
                                      onClick={() => {
                                        singleEmployee(item._id);
                                      }}
                                    >
                                      Give Salary
                                    </span>
                                  </Link>
                                </h2>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
        <Pagination page={1} count={count} />
      </div>
    </>
  );
};

export default EmployeeList;

const th = [
  {
    name: "SN.",
  },
  {
    name: "Employee Name",
  },

  // {
  //   name: "Status",
  // },

  {
    name: "Net Salary",
  },
];

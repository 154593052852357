import React from "react";
import { useSelector } from "react-redux";
import { Spinner } from "../../../Components/Spinner/Spinner";

const AdvanceSalaryList = () => {
  const { isLoading, singleAdvance } = useSelector(
    (state) => state.employeeSalary
  );

  console.log(singleAdvance);
  return (
    <section class="container px-4 mx-auto">
      <div class="flex flex-col">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div class="overflow-hidden border border-gray-200 md:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    {th.map((item, i) => {
                      return (
                        <th
                          key={i}
                          class="  py-3.5  px-2 text-sm font-normal text-left rtl:text-right text-gray-500"
                        >
                          {item.name}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                {isLoading === true ? (
                  <Spinner />
                ) : (
                  <tbody class="bg-white divide-y divide-gray-200    ">
                    {singleAdvance.length === 0 ? (
                      <p className="p-1 py-2">No Data Found!!</p>
                    ) : (
                      singleAdvance.slice(0, 4).map((item, i) => {
                        return (
                          <tr key={i}>
                            <td class=" py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                              <div class="inline-flex items-center gap-x-3 px-3">
                                <span>{i + 1}</span>
                              </div>
                            </td>
                            <td class=" py-4 text-sm text-gray-500   whitespace-nowrap ">
                              <p className="px-3 py-2 capitalize">
                                {item.advanceAmount}
                              </p>
                            </td>
                            <td class=" py-4 text-sm text-gray-500   whitespace-nowrap ">
                              <p className="px-3 py-2 capitalize text-green-400 font-bold">
                                {new Date(item.advaceDate).toDateString()}
                              </p>
                            </td>
                            <td class=" py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                              <p className="px-3 py-2 capitalize">
                                {item.salaryType}
                              </p>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const th = [
  {
    name: "SN.",
  },
  {
    name: "Amount",
  },
  {
    name: "Date",
  },
  {
    name: "Status",
  },
];
export default AdvanceSalaryList;

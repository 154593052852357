const { createSlice } = require("@reduxjs/toolkit");
const {
  addEmployeeSalary,
  getAllEmployeeSalary,
  getAllPendingSalary,
  getEmployeeSalaryById,
  addEmployeeBonus,
  getAllEmployeeBonus,
  getEmployeePendingSalaryById,
  getEmployeeBonusById,
  empAddAdvanceSalalry,
  getAdvanceSalalryById,
  addPendingSalary,
} = require("./EmpSalaryAction");

const EmpSalarySlice = createSlice({
  name: "empSalaries",
  initialState: {
    emp_salaries: [],
    isLoading: true,
    success: false,
    pen_salaries: [],
    singleEmp_salaries: [],
    emp_bonus: [],
    pendingSalary: [],
    singleBonus: [],
    singleAdvance: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addEmployeeSalary.pending, (state, payload) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(addEmployeeSalary.fulfilled, (state, payload) => {
      state.isLoading = true;
      state.success = payload.action;
    });
    builder.addCase(addEmployeeSalary.rejected, (state, payload) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(getAllEmployeeSalary.pending, (state, action) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(getAllEmployeeSalary.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
      state.emp_salaries = action.payload.empSalary;
    });
    builder.addCase(getAllEmployeeSalary.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.error = action.payload.message;
    });
    builder.addCase(getEmployeeSalaryById.pending, (state, action) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(getEmployeeSalaryById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
      state.singleEmp_salaries = action.payload.singleemployeSalary;
    });
    builder.addCase(getEmployeeSalaryById.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.error = action.payload;
    });
    builder.addCase(getAllPendingSalary.pending, (state, action) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(getAllPendingSalary.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success;
      state.pen_salaries = action.payload.pendSalary;
    });
    builder.addCase(getAllPendingSalary.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.error = action.payload;
    });
    builder.addCase(addEmployeeBonus.pending, (state, payload) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(addEmployeeBonus.fulfilled, (state, payload) => {
      state.isLoading = true;
      state.success = payload.action;
    });
    builder.addCase(addEmployeeBonus.rejected, (state, payload) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(getAllEmployeeBonus.pending, (state, action) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(getAllEmployeeBonus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
      state.emp_bonus = action.payload.employeeBonus;
    });
    builder.addCase(getAllEmployeeBonus.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.error = action.payload.message;
    });
    builder.addCase(getEmployeePendingSalaryById.pending, (state, action) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(getEmployeePendingSalaryById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
      state.pendingSalary = action.payload.pendSalary;
    });
    builder.addCase(getEmployeePendingSalaryById.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.error = action.payload;
    });
    builder.addCase(getEmployeeBonusById.pending, (state, action) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(getEmployeeBonusById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success;
      console.log(action.payload, "payload");
      state.singleBonus = action.payload.singleEmployeeBonus;
    });
    builder.addCase(getEmployeeBonusById.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.error = action.payload;
    });
    builder.addCase(empAddAdvanceSalalry.pending, (state, payload) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(empAddAdvanceSalalry.fulfilled, (state, payload) => {
      state.isLoading = true;
      state.success = payload.action;
    });
    builder.addCase(empAddAdvanceSalalry.rejected, (state, payload) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(getAdvanceSalalryById.pending, (state, action) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(getAdvanceSalalryById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success;
      state.singleAdvance = action.payload.singleAdvanceSalary;
    });
    builder.addCase(getAdvanceSalalryById.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.error = action.payload;
    });
    builder.addCase(addPendingSalary.pending, (state, payload) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(addPendingSalary.fulfilled, (state, payload) => {
      state.isLoading = true;
      state.success = payload.action;
    });
    builder.addCase(addPendingSalary.rejected, (state, payload) => {
      state.isLoading = true;
      state.success = false;
    });
  },
});

export const empSalaryReducer = EmpSalarySlice.reducer;

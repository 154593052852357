import React, { useState } from "react";
import Button from "../../../../Components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { addBadDebttoRecovery } from "../../../../Redux/Feature/BadDebt/BadDebtAction";
import { toast } from "react-toastify";

const AddRecoveryBadDebt = () => {
  const { sales } = useSelector((state) => state.sale);

  const [customerName, setCustomerName] = useState("");
  const [recover_Amount, setRecover_Amount] = useState("");
  const [transctionType, setTransctionType] = useState("");
  const [partial_cash, setPartial_cash] = useState("");
  const [partial_bank, setPartial_bank] = useState("");
  const dispatch = useDispatch();
  const submit = () => {
    if (customerName === "" || recover_Amount === "" || transctionType === "") {
      toast.error("Some feilds are missing");
    } else {
      dispatch(
        addBadDebttoRecovery({
          customerName,
          recover_Amount,
          partial_bank,
          partial_cash,
          transctionType,
        })
      );
    }
  };
  return (
    <>
      <div className=" flex justify-center">
        <div className="border border-gray-100 w-auto h-auto bg-gray-200 py-4 rounded-[6px] ">
          <div className="  my-2 mx-2">
            <div className="w-full h-full my-2 ">
              <select
                className="border py-3 h-full w-full rounded-md placeholder:px-2 px-2   my-2 md:my-0 lg:my-0"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
              >
                <option>Select Customer</option>
                {sales.map((item, i) => (
                  <option>
                    {item.customerName}-{item.customerPhone}
                  </option>
                ))}
              </select>
            </div>
            <div className=" w-full h-full my-2">
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                value={recover_Amount}
                onChange={(e) => setRecover_Amount(e.target.value)}
                className="border py-3 rounded-md placeholder:px-2 px-2"
                placeholder="Recovery Amount"
              />
            </div>
            <>
              <p className="uppercase opacity-30 pt-3">Transiction </p>
              <div className="  gap-3  my-4">
                <select
                  className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                  name="transictionType"
                  value={transctionType}
                  onChange={(e) => setTransctionType(e.target.value)}
                >
                  <option>Select Transiction Type</option>
                  {caseType.map((item, i) => {
                    return <option>{item.TypeName}</option>;
                  })}
                </select>

                <div className="my-2">
                  <p>
                    {transctionType === "Both" && (
                      <>
                        <div className="">
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            value={partial_bank}
                            onChange={(e) => setPartial_bank(e.target.value)}
                            placeholder="Enter  BANK Amount"
                            className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                          />
                        </div>
                        <div className="my-2">
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            value={partial_cash}
                            onChange={(e) => setPartial_cash(e.target.value)}
                            placeholder="Enter  CASH Amount"
                            className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                          />
                        </div>
                      </>
                    )}
                  </p>
                </div>
              </div>
            </>
            <div className="flex justify-center my-3 gap-3 ">
              <Button btnName={"Add to Bad Debt Recovery"} click={submit} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const caseType = [
  {
    TypeName: "Cash ",
  },
  {
    TypeName: "Bank ",
  },
  {
    TypeName: "Both ",
  },
];
export default AddRecoveryBadDebt;

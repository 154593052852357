import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployeeSalary } from "../../Redux/Feature/EmployeeSalary/EmpSalaryAction";
import { Spinner } from "../../Components/Spinner/Spinner";

const CurrentEmployeeSalalry = () => {
  const { isLoading, singleEmp_salaries } = useSelector(
    (state) => state.employeeSalary
  );
  console.log(singleEmp_salaries);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllEmployeeSalary());
  }, [dispatch]);

  // Step 1: Create an object to hold the combined data grouped by month and status
  const combinedData = {};

  // Step 2: Group the data by month and status
  singleEmp_salaries.forEach((empSalary) => {
    empSalary.salaryDetails.forEach((item) => {
      const month = item.month;
      const status = item.status;

      // Check if the combination of month and status already exists in the object
      const key = `${month}_${status}`;

      if (combinedData[key]) {
        // Add the amount to the existing month and status combination
        combinedData[key].amount += item.amount;
      } else {
        // Create a new entry for the month and status in the object
        combinedData[key] = { ...item };
      }
    });
  });

  // Step 3: Convert the combined data object values back to an array
  const mergedRows = Object.values(combinedData);
  console.log(mergedRows, "chjkm,lmnhgf");
  return (
    <section class="container px-4 mx-auto">
      <div class="flex flex-col">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div class="overflow-hidden border border-gray-200 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {th.map((item, i) => (
                      <th
                        key={i}
                        className="py-3.5 px-2 text-sm font-normal text-left rtl:text-right text-gray-500"
                      >
                        {item.name}
                      </th>
                    ))}
                    <th></th>
                  </tr>
                </thead>
                {isLoading === true ? (
                  <tbody>
                    <tr>
                      <td colSpan={th.length}>
                        <Spinner />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody className="bg-white divide-y divide-gray-200">
                    {mergedRows.map((item, i) => (
                      <React.Fragment key={i}>
                        <tr>
                          <td className="py-4 text-sm text-gray-500 whitespace-nowrap">
                            <p className="px-3 py-2 capitalize">
                              {new Date(item.paidDate).toDateString()}
                            </p>
                          </td>

                          <td
                            className="py-4 text-sm text-gray-500 whitespace-nowrap"
                            rowSpan={item.pendingLog.length + 1}
                          >
                            <p className="px-3 py-2 capitalize">{item.month}</p>
                          </td>
                          <td
                            className="py-4 text-sm font-medium text-gray-700 whitespace-nowrap"
                            rowSpan={item.pendingLog.length + 1}
                          >
                            <p className="px-3 py-2 capitalize">
                              Rs.{item.amount}
                            </p>
                          </td>
                          <td
                            className="py-4 text-sm font-medium text-gray-700 whitespace-nowrap"
                            rowSpan={item.pendingLog.length + 1}
                          >
                            <p
                              className={`px-3 py-2 uppercase font-bold text-center my-2 ${
                                item.status.toLowerCase() === "paid"
                                  ? "text-white bg-green-600 rounded-sm"
                                  : item.status.toLowerCase() === "pending"
                                  ? "text-white bg-red-600 rounded-sm"
                                  : item.status.toLowerCase() === "partial-paid"
                                  ? "text-white bg-blue-600 rounded-sm"
                                  : null
                              }`}
                            >
                              {item.status}
                            </p>
                          </td>
                        </tr>

                        {item.pendingLog.length !== 0 &&
                          item.pendingLog.map((logItem, j) => (
                            <tr key={`pending_${i}_${j}`}>
                              <td className="py-4 text-sm text-gray-500 text-left whitespace-nowrap">
                                <p className="px-3 py-2 capitalize">
                                  {new Date(logItem.paidDate).toDateString()}
                                </p>
                              </td>
                              <td className="py-4 text-sm text-gray-500 text-left whitespace-nowrap">
                                <p className="px-3 py-2 capitalize"></p>
                              </td>
                              {/* <td className="py-4 text-sm font-medium text-gray-700 text-left whitespace-nowrap">
                                <p className="px-3 py-2 capitalize">
                                  Rs.{logItem.remainingAmount}
                                </p>
                              </td> */}
                              <td></td>
                            </tr>
                          ))}
                      </React.Fragment>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const th = [
  // {
  //   name: "SN.",
  // },
  {
    name: "Date",
  },
  {
    name: "Month",
  },
  {
    name: "Amount",
  },
  {
    name: "Status",
  },
];

export default CurrentEmployeeSalalry;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../utils/API";
import { toast } from "react-toastify";

export const addSupplier = createAsyncThunk(
  "/supplier/add",
  async ({
    token,
    supplierName,
    supplierLocation,
    supplierEmail,
    supplierContact,
    supplierRemarks,
    registrationType,
    registrationNumber,
    supplierType,
    country,
    countryCode,
  }) => {
    console.log(registrationNumber, registrationType);
    try {
      const response = await api.post(
        "/supplier/add-supplier",
        {
          supplierName,
          supplierLocation,
          supplierEmail,
          supplierContact,
          supplierRemarks,
          registrationType,
          registrationNumber,
          supplierType,
          country,
          countryCode,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.success === true) {
        toast.success(response.data.message);
        window.location.reload();
      }
      if (response.data.success === false) {
        toast.info(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getSupplier = createAsyncThunk(
  "get/supplier",
  async ({ token, pageNo }) => {
    try {
      const response = await api.get(
        `/supplier/all-suppliers?limit={20}&page=${pageNo}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const getSingleSupplier = createAsyncThunk(
  "get/singleSupplier",
  async ({ token, id }) => {
    console.log(id);
    console.log(token);
    try {
      const response = await api.get(`/supplier/supplier-by/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

// export const getSupplierById = createAsyncThunk(
//   "get/supplier/id",
//   async ({ id }) => {
//     try {
//       const response = await api.get(`/supplier/supplier-by/${id}`, {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
//         },
//       });

//       console.log(response);
//       return response.data;
//     } catch (error) {
//       console.log(error);
//     }
//   }
// );

export const dltSupplier = createAsyncThunk(
  "delete/supplier",
  async ({ id, token }) => {
    try {
      const response = await api.post(
        "/supplier/delete-supplier",
        { id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (id) {
        toast.success(response.data.message);
      }
      if (response.data.success === false) {
        toast.info(response.data.message);
      }

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const filterSupplier = createAsyncThunk(
  "filter/supplier/supplierEmail",
  async ({ token, supplierEmail }) => {
    try {
      const response = await api.get(
        `/supplier/search-supplier?supplierEmail=${supplierEmail}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

import { createSlice } from "@reduxjs/toolkit";
import { getDashboard } from "./dashboardAction";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    isLoading: true,
    success: true,
    error: null,
    dashboard: [],
    topCategory: [],
    topProduct: [],
    topSale: [],
    topSupplier: [],
    maskiBariNotification: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDashboard.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(getDashboard.fulfilled, (state, action) => {
      console.log(action.payload.maskebariNotifications);
      state.isLoading = false;
      state.success = true;
      state.dashboard = action.payload;
      state.topCategory = action.payload.recentCategories;
      state.topProduct = action.payload.recentProducts;
      state.topSale = action.payload.recentSales;
      state.topSupplier = action.payload.recentSuppliers;
      state.maskiBariNotification = action.payload.maskebariNotifications;
    });
    builder.addCase(getDashboard.rejected, (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    });
  },
});

export const dashboardReducer = dashboardSlice.reducer;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchInventeryLossCategory,
  fetchInventoryExpenses,
} from "../../Redux/Feature/InventeryExpenses/InventeryExpensesAction";
import Pagination from "./Pagination";

const exp = [
  {
    name: "S.N.",
  },
  {
    name: "Inventory Category",
  },
  {
    name: "Product",
  },
  {
    name: "Product Quantity",
  },
  {
    name: "Subtotal",
  },
];

const InventoryList = () => {
  const dispatch = useDispatch();
  const { count, inventoryExpenses } = useSelector(
    (state) => state.inventeryExpenses
  );
  console.log(inventoryExpenses);
  useEffect(() => {
    async function fetch() {
      dispatch(fetchInventeryLossCategory());
      dispatch(fetchInventoryExpenses());
    }
    fetch();
  }, [dispatch]);
  return (
    <>
      <div>
        {/* filter-start */}

        {/* <div class="relative mx-3 my-5">
          <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <svg
              class="w-5 h-5 text-gray-500 dark:text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
          <input
            type="search"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            class="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-green-500 focus:border-green-500 dark:bg-gray-700  "
            placeholder="Search Product..."
            required
          />
           <button
          onClick={findProduct}
          disabled={productName === "" ? true : false}
          class="text-white absolute right-2.5 bottom-2.5 bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2"
        >
          Search
        </button> 
                  </div>  */}

        {/* filter-end */}

        <section class="container px-4 mx-auto">
          <div class="flex flex-col">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div class="overflow-hidden border border-gray-200 md:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        {exp.map((item, i) => {
                          return (
                            <th
                              scope="col col-span-5"
                              key={i}
                              class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                            >
                              {item.name}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      {inventoryExpenses?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap ">
                              <div class="flex items-center gap-x-2">
                                <div>
                                  <h2 class="text-sm capitalize font-medium text-gray-800   ">
                                    {i + 1}
                                  </h2>
                                </div>
                              </div>
                            </td>
                            <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap ">
                              <div class="flex items-center gap-x-2">
                                <div>
                                  <h2 class="text-sm capitalize font-medium text-gray-800   ">
                                    {item.inventeryCategoryName}
                                  </h2>
                                </div>
                              </div>
                            </td>
                            <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap ">
                              <div class="flex items-center gap-x-2">
                                <div>
                                  <h2 class="text-sm capitalize font-medium text-gray-800   ">
                                    {item.productId}
                                  </h2>
                                </div>
                              </div>
                            </td>
                            <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap ">
                              <div class="flex items-center gap-x-2">
                                <div>
                                  <h2 class="text-sm capitalize font-medium text-gray-800   ">
                                    {item.productQuantity}
                                  </h2>
                                </div>
                              </div>
                            </td>
                            <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap ">
                              <div class="flex items-center gap-x-2">
                                <div>
                                  <h2 class="text-sm capitalize font-medium text-gray-800   ">
                                    {item.subTotal}
                                  </h2>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {inventoryExpenses === "" ? (
              <Pagination page={1} count={count} />
            ) : null}
          </div>
        </section>
      </div>
    </>
  );
};

export default InventoryList;

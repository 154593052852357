import React from "react";
import Ledger from "./Ledger";
const index = () => {
  return (
    <div className="">
      <Ledger />
      {/* <CreditTabel />
      <DebitTabel /> */}
    </div>
  );
};

export default index;

import React from "react";

import AddCategoryExpenses from "./AddCategoryExpenses";
import Tittle from "../../../Components/Tittle/Tittle";
import BasicModal from "../../../Components/Modal/Modal";
import Button from "../../../Components/Button/Button";
import ExpensesCategoryList from "./expensesCategoryList";

const index = () => {
  return (
    <>
      <div>
        <Tittle tittle={"Add Category Expenses"} />
        <BasicModal
          btn={<Button btnName={"Add Category Expenses"} />}
          tittle={"Add Category Expenses"}
          modalDetails={<AddCategoryExpenses />}
        />
        <div className="mt-[2rem]">
          <ExpensesCategoryList />
        </div>
      </div>
    </>
  );
};

export default index;

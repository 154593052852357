import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../../Components/Spinner/Spinner";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  dltExpenses,
  filterExpensesByDate,
  filterExpensesCategory,
  filterExpensesName,
  getAllExpenses,
} from "../../Redux/Feature/Expenses/expensesAction";
import Pagination from "./Pagination";
import { useNavigate } from "react-router-dom";

const ListExpense = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("NHHD:token");
  const { isLoading, expenses, count, expensesCategory } = useSelector(
    (state) => state.expense
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    async function fetch() {
      dispatch(getAllExpenses({ token }));
    }
    fetch();
  }, [token, dispatch]);

  const deleteExpense = (id) => {
    dispatch(dltExpenses({ id, token }));
  };

  const filterDate = () => {
    dispatch(filterExpensesByDate({ startDate, endDate, token }));
  };

  const [expensesCategorys, setExpensesCategorys] = useState("");

  useEffect(() => {
    dispatch(filterExpensesCategory({ expensesCategorys, token }));
  }, [expensesCategorys, dispatch, token]);
  const navigate = useNavigate();
  const allClick = () => {
    navigate("/expense");
    return window.location.reload();
  };

  const [expenseName, setExpenseName] = useState("");
  useEffect(() => {
    dispatch(filterExpensesName({ expenseName, token }));
  }, [expenseName, token, dispatch]);
  return (
    <>
      {/* filter-start */}
      <div className="grid grid-cols-2 grid-flow-row gap-2">
        {/* <div class="relative mx-3 my-5">
          <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <svg
              class="w-5 h-5 text-gray-500   mt-[3rem]"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
          <input
            type="search"
            id="default-search"
            class="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-green-500 focus:border-green-500   mt-[3rem] "
            placeholder="Search Category..."
            required
          />
        </div> */}
        <div class="mt-[4.5rem] flex sm:flex-row flex-col h-[38px]">
          <div class="flex flex-row mb-1 sm:mb-0">
            <div class="relative">
              <select class="h-full rounded-l border block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                <option>{count}</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
            <div class="relative">
              <select
                class=" h-full rounded-r border-t sm:rounded-r-none sm:border-r-0 border-r border-b block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                value={expensesCategorys}
                onClick={expensesCategorys === "All" ? allClick : null}
                onChange={(e) => setExpensesCategorys(e.target.value)}
              >
                <option>All</option>
                {expensesCategory.map((item, i) => {
                  return <option>{item.expenseCategory}</option>;
                })}
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
          <div class="block relative">
            <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
              <svg
                viewBox="0 0 24 24"
                class="h-4 w-4 fill-current text-gray-500"
              >
                <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
              </svg>
            </span>
            <input
              placeholder="Search Expense Name...."
              value={expenseName}
              onChange={(e) => setExpenseName(e.target.value)}
              class="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            />
          </div>
        </div>
        <div>
          <h4 className="pt-2 text-green-600 text-xl mx-3">Filter Date</h4>
          <div className="flex gap-3 mx-3 my-3">
            <div>
              <p>Start Date</p>
              <input
                type="date"
                className="bg-gray-100 rounded-[6px]"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div>
              <p>End Date</p>
              <input
                type="date"
                className="bg-gray-100 rounded-[6px]"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <p
              className="bg-[green] rounded-[6px] text-white font-[500] px-3 uppercase h-[40px] pt-2 mt-6 hover:bg-green-600 cursor-pointer"
              onClick={filterDate}
            >
              Filter
            </p>
          </div>
        </div>
      </div>

      {/* filter-end */}

      <section class="container px-4 mx-auto">
        <div class="flex flex-col">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div class="overflow-hidden border border-gray-200 md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      {th.map((item, i) => {
                        return (
                          <th
                            key={i}
                            class="  py-3.5  px-3 text-sm font-normal text-left rtl:text-right text-gray-500"
                          >
                            {item.name}
                          </th>
                        );
                      })}
                      <th
                        scope="col col-span-5"
                        class=" py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  {isLoading === true ? (
                    <Spinner />
                  ) : (
                    <tbody class="bg-white divide-y divide-gray-200    ">
                      {expenses && expenses.length === 0 ? (
                        <p className="p-1 py-2">No Data Found!!</p>
                      ) : (
                        expenses &&
                        expenses.slice(0, 6).map((item, i) => {
                          return (
                            <tr key={i}>
                              <td class=" py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                <div class="inline-flex items-center gap-x-3 px-3">
                                  <span>{i + 1}</span>
                                </div>
                              </td>
                              <td class=" py-4 text-sm text-gray-500   whitespace-nowrap ">
                                <p className="px-3 capitalize">
                                  {item.expenseName}
                                </p>
                              </td>
                              <td class=" py-4 text-sm text-gray-500   whitespace-nowrap ">
                                <p className="px-3 capitalize">
                                  {item.expensesCategorys}
                                </p>
                              </td>
                              <td class=" py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                                <p className="px-3 capitalize">
                                  {item.expenseDescription}
                                </p>
                              </td>
                              <td class=" py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                                <div>
                                  <h2 class="text-sm font-normal px-3">
                                    Rs.{item.expenseAmount}
                                  </h2>
                                </div>
                              </td>
                              <td class=" py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                                <div>
                                  <h2 class="text-sm font-normal px-3">
                                    {item.transctionType}
                                  </h2>
                                </div>
                              </td>
                              <td class=" py-4 text-sm text-gray-500   whitespace-nowrap">
                                <div class="flex items-center gap-x-2">
                                  <div>
                                    <h2 class="text-sm font-medium text-gray-800   px-3 ">
                                      {new Date(
                                        item.expenseDate
                                      ).toDateString()}
                                    </h2>
                                  </div>
                                </div>
                              </td>
                              <td class=" py-4 text-sm text-gray-500   whitespace-nowrap  ">
                                <p className="px-3">{item.supplierRemarks}</p>
                              </td>
                              <td class=" py-4 text-sm whitespace-nowrap">
                                <div class="flex items-center gap-x-6">
                                  <button
                                    class="  transition-colors duration-200 text-red-500      focus:outline-none"
                                    onClick={() => deleteExpense(item._id)}
                                  >
                                    <DeleteForeverIcon />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Pagination page={1} count={count} />
    </>
  );
};

const th = [
  {
    name: "SN.",
  },
  {
    name: "Expenses Name",
  },
  {
    name: "Expenses Category",
  },
  {
    name: "Expenses Description",
  },
  {
    name: "Expenses Amount",
  },
  {
    name: "Transction",
  },
  {
    name: "Expenses Date",
  },
];
export default ListExpense;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../utils/API";
import { toast } from "react-toastify";

export const addEmployeeSalary = createAsyncThunk(
  "post/employee-salary",
  async (payload) => {
    try {
      const response = await api.post("/emp-salary/employee-salary", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
        },
      });
      console.log(response.data._id);

      if (response.data.success === true) {
        toast.success(response.data.message);
        // window.location.reload();
      }

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getEmployeeSalaryById = createAsyncThunk(
  "get/emplyee-salary-id",
  async ({ id }) => {
    try {
      const response = await api.get(`/emp-salary/employee-salary/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
        },
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAllEmployeeSalary = createAsyncThunk(
  "get/current-salary",
  async () => {
    try {
      const response = await api.get(`/emp-salary/all-employee-salary`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
        },
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAllPendingSalary = createAsyncThunk(
  "get/pending-salary",
  async () => {
    try {
      const response = await api.get(
        `/emp-salary/all-pending-salary`,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addEmployeeBonus = createAsyncThunk(
  "post/employee-bonus",
  async (payload) => {
    try {
      const response = await api.post("/bonus/employee-bonus", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
        },
      });
      console.log(response.data._id);

      if (response.data.success === true) {
        toast.success(response.data.message);
        window.location.reload();
      }

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAllEmployeeBonus = createAsyncThunk(
  "get/employee-bonus",
  async () => {
    try {
      const response = await api.get(
        `/bonus/all-employee-bonus`,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getEmployeePendingSalaryById = createAsyncThunk(
  "get/emplyee-pending-salary-id",
  async ({ id }) => {
    try {
      const response = await api.get(`/emp-salary/all-pending-salary/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getEmployeeBonusById = createAsyncThunk(
  "get/emplyee-bonus-id",
  async ({ id }) => {
    try {
      const response = await api.get(`/bonus/single-employee-bonus/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
        },
      });

      console.log(response, "bonus");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const empAddAdvanceSalalry = createAsyncThunk(
  "post/advance-salalry",
  async (payload) => {
    try {
      const response = await api.post(
        "/advance/employee-advance-salary",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
          },
        }
      );

      if (response.data.success === true) {
        toast.success(response.data.message);
        window.location.reload();
      }

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAdvanceSalalry = createAsyncThunk(
  "get/all-emplyee-advance-salary",
  async () => {
    try {
      const response = await api.get(`/advance/all-employee-advance-salary`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
        },
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const getAdvanceSalalryById = createAsyncThunk(
  "get/all-emplyee-advance-salalry-id",
  async ({ id }) => {
    try {
      const response = await api.get(
        `/advance/all-employee-advance-salary/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addPendingSalary = createAsyncThunk(
  "post/pending-salary",
  async (payload) => {
    try {
      const response = await api.post("/emp-salary/pay-pending", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
        },
      });
      console.log(response.data._id);

      if (response.data.success === true) {
        toast.success(response.data.message);
        window.location.reload();
      }

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

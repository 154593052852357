import { createSlice } from "@reduxjs/toolkit";
import {
  getPurchaseAccount,
  getPurchaseAccountByMonth,
} from "./purchaseAccountAction";

const purchaseAccountSlice = createSlice({
  name: "purchaseAccount",
  initialState: {
    purchaseAccount: [],
    error: null,
    isLoading: true,
    count: 0,
    purchaseAccountByMonth: [],
  },
  reducers: {},
  extraReducers: {
    [getPurchaseAccount.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getPurchaseAccount.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.count = action.payload.count;
      state.purchaseAccount = action.payload.purchaseAccount;
    },
    [getPurchaseAccount.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getPurchaseAccountByMonth.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getPurchaseAccountByMonth.fulfilled]: (state, action) => {
      // console.log(action.payload);
      state.isLoading = false;
      console.log(action.payload);
      state.purchaseAccountByMonth = action.payload.purchaseAccount;
    },
    [getPurchaseAccountByMonth.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export const purchaseAccountReducer = purchaseAccountSlice.reducer;

import React from "react";

const Tittle = ({ tittle }) => {
  return (
    <div>
      <p className="p-3 text-xl font-semibold underline capitalize text-green-900">
        {tittle}
      </p>
    </div>
  );
};

export default Tittle;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteJournal,
  findJournal,
  getAllJournal,
} from "../../Redux/Feature/GeneralJournal/journalAction";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Pagination from "./Pagination";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const GeneraalJournalList = () => {
  const dispatch = useDispatch();
  const { journals, count } = useSelector((state) => state.generalJournal);
  const [generalJournal, setGeneralJournal] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  generalJournal.map((ele) => console.log(ele));

  useEffect(() => {
    setGeneralJournal(journals);
  }, [journals]);
  useEffect(() => {
    dispatch(getAllJournal());
  }, [dispatch]);

  const dltJournal = (id) => {
    dispatch(deleteJournal({ id, setGeneralJournal }));
  };

  const findDate = () => {
    dispatch(findJournal({ startDate, endDate }));
  };

  return (
    <div>
      <p className="text-end">
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className=" text-[green] border  border-[gray]  rounded-[4px] p-2 hover:bg-[green] hover:text-white"
          table="table-to-xls"
          filename="General-journal"
          sheet="tablexls"
          buttonText="Export to Excel"
        />
      </p>

      <div className="  flex gap-2 my-3">
        <div>
          <p>Start Date</p>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>

        <div>
          <p>End Date</p>
          <div className="flex">
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>
        <div className="lg:flex lg:justify-center">
          <p
            className="uppercase border border-white mx-2  bg-green-800  text-white text-bold rounded-[6px] cursor-pointer   w-[100px] text-center pt-2 h-[40px] lg:mt-[1.4rem] md:mt-0 mt-2"
            onClick={findDate}
          >
            Find
          </p>
        </div>
      </div>

      <section className="container px-4 mx-auto">
        <div className="flex flex-col">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden border border-gray-200 md:rounded-lg">
                <table
                  className="min-w-full divide-y divide-gray-200"
                  id="table-to-xls"
                >
                  <thead className="bg-gray-50">
                    <tr>
                      {th.map((item, i) => {
                        return (
                          <th
                            key={i}
                            className="  py-3.5  px-3 text-sm font-normal text-left rtl:text-right text-gray-500"
                          >
                            {item.name}
                          </th>
                        );
                      })}
                      <th
                        scope="col col-span-5"
                        className=" py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-gray-200">
                    {generalJournal.slice(0, 6).map((item, i) => {
                      return (
                        <tr key={i}>
                          <td className=" py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                            <div>
                              <p className="text-sm font-normal px-3">
                                {new Date(item.createdAt).toDateString()}
                              </p>
                            </div>
                          </td>
                          <td className=" py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                            <p className="text-sm font-normal px-3  underline">
                              {item.supplierName && "To, Purchase A/C Dr."}
                              {item.retProductName.length >= 1 &&
                                " Purchase Return A/C Dr."}
                              {item.sale.length >= 1 && "     Sale A/C Dr."}
                              {item.expenses.length >= 1 &&
                                "  Expenses A/C Dr."}
                              {item.salary.length >= 1 && "To, Salary A/C Dr."}
                              {item.badDebt.length >= 1 && "Bad Debt A/C Dr."}
                              {item.badDebtRecovery.length >= 1 &&
                                item.badDebtRecovery.map((ele) =>
                                  ele.transctionType === "Cash" ? (
                                    "Cash A/C Dr."
                                  ) : ele.transctionType === "Bank" ? (
                                    " Bank A/C Dr."
                                  ) : ele.transctionType === "Both" ? (
                                    <>
                                      <p>Bank A/C Dr.</p>
                                      <p>Cash A/C Dr.</p>
                                    </>
                                  ) : null
                                )}
                              <p>
                                {item.inventeryExpenses.length >= 1 &&
                                  `Loss by ${item.inventeryExpenses.map(
                                    (ele) => ele.inventeryLossCategory
                                  )} A/C Dr.`}
                              </p>
                            </p>
                            <p>
                              {item.salePaymentRecived.length >= 1 &&
                                item.salePaymentRecived.map((ele) =>
                                  ele.transctionType === "Cash" ? (
                                    "Cash A/C Dr."
                                  ) : ele.transctionType === "Bank" ? (
                                    " Bank A/C Dr."
                                  ) : ele.transctionType === "Both" ? (
                                    <>
                                      <p>Bank A/C Dr.</p>
                                      <p>Cash A/C Dr.</p>
                                    </>
                                  ) : null
                                )}
                            </p>
                            <p>
                              {item.salePaymentRecived.length >= 1 &&
                                item.salePaymentRecived.map(
                                  (ele) => `TO, ${ele.customerName}`
                                )}
                            </p>

                            <div>
                              {item.supplierName &&
                                item.generalProductBils.map((item, i) => {
                                  return (
                                    <p className="text-sm font-normal px-3">
                                      {item.productName}
                                    </p>
                                  );
                                })}
                              {item.retProductName.length >= 1 &&
                                item.retProductName.map((ele) => {
                                  return (
                                    <p className="text-sm font-normal px-3">
                                      {ele.name}
                                    </p>
                                  );
                                })}
                              {item.sale.length >= 1 &&
                                item.sale.map((ele) =>
                                  ele.saleProductName.map((item) => (
                                    <p className="text-sm font-normal px-3">
                                      {item.sale_productName}
                                    </p>
                                  ))
                                )}
                              {item.expenses.length >= 1 &&
                                item.expenses.map((ele) =>
                                  ele.expensesBill.map((item) => (
                                    <p className="text-sm font-normal px-3">
                                      {item.expensesName}
                                    </p>
                                  ))
                                )}
                              {item.salary.length >= 1 &&
                                item.salary.map((ele) =>
                                  ele.employeeDetails.map((ele) => {
                                    return (
                                      <p className="text-sm font-normal px-3">
                                        {ele.employeename}
                                      </p>
                                    );
                                  })
                                )}
                              {item.badDebt.length >= 1 &&
                                item.badDebt.map((ele) => {
                                  return <p>To ,{ele.customerName}</p>;
                                })}
                              {item.badDebtRecovery.length >= 1 && (
                                <p className="text-sm font-normal px-3">
                                  To, Bad debt
                                </p>
                              )}
                            </div>
                            <div>
                              <p>
                                {item.productDiscountType ===
                                "Percent Discount(%)"
                                  ? " To, Discount Amount Cr"
                                  : item.productDiscountType ===
                                    "Amount Discount(Rs.)"
                                  ? " To, Discount Amount Cr"
                                  : null}

                                {item.sale.map((item) =>
                                  item.sale_discountType ===
                                  "Percent Discount(%)"
                                    ? " To, Discount Amount Cr"
                                    : item.sale_discountType ===
                                      "Amount Discount(Rs.)"
                                    ? " To, Discount Amount Cr"
                                    : null
                                )}
                              </p>
                              <p>
                                {item.productCreditType === "Partial Credit" ? (
                                  <>
                                    <p>To, Cash Amount</p>
                                    <p>To, {item.supplierName}</p>
                                  </>
                                ) : item.productCreditType === "Credit" ? (
                                  `To, ${item.supplierName} `
                                ) : item.productCreditType === "No Credit  " ? (
                                  "To, Cash"
                                ) : null}
                                {item.sale.map((ele) =>
                                  ele.sale_productCreditType ===
                                  "Partial Credit" ? (
                                    <>
                                      <p>To, Cash Amount</p>
                                      <p>To , Credit Amount</p>
                                    </>
                                  ) : ele.sale_productCreditType ===
                                    "Credit" ? (
                                    "To ,Credit Amount"
                                  ) : ele.sale_productCreditType ===
                                    "No Credit" ? (
                                    "To, Cash Amount"
                                  ) : null
                                )}
                              </p>
                              <p>
                                {item.productTransctionType === "Cash" ? (
                                  "To, cash Transcation"
                                ) : item.productTransctionType === "Bank" ? (
                                  "To, bank Transcation"
                                ) : item.productTransctionType === "Both" ? (
                                  <>
                                    <>
                                      <p>To, bank transcation</p>
                                      <p>To, cash transcation</p>
                                    </>
                                  </>
                                ) : null}
                                {item.sale.length >= 1 &&
                                  item.sale.map((ele) =>
                                    ele.sale_productTransctionType ===
                                    "Cash" ? (
                                      "To , Cash Transcation "
                                    ) : ele.sale_productTransctionType ===
                                      "Bank" ? (
                                      "To, bank Transcation"
                                    ) : ele.sale_productTransctionType ===
                                      "Both" ? (
                                      <>
                                        <p>To, bank transcation</p>
                                        <p>To, cash transcation</p>
                                      </>
                                    ) : null
                                  )}
                                {item.expenses.length >= 1 &&
                                  item.expenses.map((ele) =>
                                    ele.expenses_transction_Type === "Cash" ? (
                                      "To , Cash Transcation "
                                    ) : ele.expenses_transction_Type ===
                                      "Bank" ? (
                                      "To, bank Transcation"
                                    ) : ele.expenses_transction_Type ===
                                      "Both" ? (
                                      <>
                                        <p>To, bank transcation</p>
                                        <p>To, cash transcation</p>
                                      </>
                                    ) : null
                                  )}
                                {item.salary.length >= 1 &&
                                  item.salary.map((ele) =>
                                    ele.transctionType === "Cash" ? (
                                      "To , Cash Transcation "
                                    ) : ele.transctionType === "Bank" ? (
                                      "To, bank Transcation"
                                    ) : ele.transctionType === "Both" ? (
                                      <>
                                        <p>To, bank transcation</p>
                                        <p>To, cash transcation</p>
                                      </>
                                    ) : null
                                  )}
                              </p>

                              <p>
                                {item.productStatus ===
                                "Normal" ? null : item.productStatus ===
                                  "Ab-Normal" ? (
                                  <>
                                    <p>Product Ab-normal</p>
                                    <p>
                                      {item.defeactGeneralProductBils.map(
                                        (item, i) => {
                                          return (
                                            <>
                                              <p>{item.defProductName}</p>
                                            </>
                                          );
                                        }
                                      )}
                                    </p>
                                  </>
                                ) : null}
                                {item.sale.map((ele) =>
                                  ele.sale_productStatus ===
                                  "Normal" ? null : ele.sale_productStatus ===
                                    "Ab-Normal" ? (
                                    <>
                                      <p>Product Ab-Normal</p>
                                      <p>
                                        {ele.saleDefeactProductBils.map(
                                          (ele) => {
                                            return (
                                              <p>{ele.sale_defProductName}</p>
                                            );
                                          }
                                        )}
                                      </p>
                                    </>
                                  ) : null
                                )}
                                {item.inventeryExpenses.length >= 0 &&
                                  `To , inventery`}
                              </p>
                            </div>
                          </td>
                          <td className=" py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                            <div>
                              <p className="text-sm font-normal px-3">
                                {i + 1}
                              </p>
                            </div>
                          </td>
                          <td className=" text-sm font-medium text-gray-700 whitespace-nowrap">
                            <p className="text-sm font-normal px-3  underline">
                              {item.supplierName &&
                                item.generalProductBils.reduce(
                                  (total, bill) => {
                                    const price = parseInt(bill.productPriceCr);
                                    return total + price;
                                  },
                                  0
                                )}
                              {item.retProductName.length >= 1 &&
                                item.totalReturnBill}
                              {item.retProductName.length >= 1 &&
                                item.sale.map((ele) => ele.totalSaleBill)}
                              {item.sale.length >= 1 &&
                                item.sale.map((ele) => ele.totalSaleBill)}
                              {item.expenses.length >= 1 &&
                                item.expenses.map((ele) =>
                                  ele.expensesBill.map(
                                    (ele) => ele.expensesAmountCr
                                  )
                                )}
                              {item.salary.length >= 1 &&
                                item.salary.map((ele) =>
                                  ele.employeeDetails.map((ele) => {
                                    return (
                                      <p className="text-sm font-normal px-3">
                                        {ele.paymentCr}
                                      </p>
                                    );
                                  })
                                )}
                              {item.badDebt.length >= 1 &&
                                item.badDebt.map((ele) => {
                                  return ele.AmountCr;
                                })}
                              {item.badDebtRecovery.length >= 1 &&
                                item.badDebtRecovery.map((item) =>
                                  item.transctionType === "Cash" ? (
                                    item.cashDr
                                  ) : item.transctionType === "Bank" ? (
                                    item.bankDr
                                  ) : item.transctionType === "Both" ? (
                                    <>
                                      <>
                                        <p>{item.partial_bankDr}</p>
                                        <p>{item.partial_cashDr}</p>
                                      </>
                                    </>
                                  ) : null
                                )}
                            </p>
                            <p>
                              {item.salePaymentRecived.length >= 1 &&
                                item.salePaymentRecived.map((ele) =>
                                  ele.transctionType === "Cash" ? (
                                    ele.amount
                                  ) : ele.transctionType === "Bank" ? (
                                    ele.amount
                                  ) : ele.transctionType === "Both" ? (
                                    <>
                                      <p> {ele.partial_bankCr}</p>
                                      <p>{ele.partial_cashCr}</p>
                                    </>
                                  ) : null
                                )}
                            </p>
                            <p>
                              {item.salePaymentRecived.length >= 1 &&
                                item.salePaymentRecived.map((ele) => "-")}
                            </p>
                            <div>
                              {item.supplierName
                                ? item.generalProductBils.map((item, i) => {
                                    return (
                                      <p className="text-sm font-normal px-3 ">
                                        {item.productPriceCr}
                                      </p>
                                    );
                                  })
                                : item.returnProductPrice.map((ele) => {
                                    return (
                                      <p className="text-sm font-normal px-3 ">
                                        {ele.totalPriceDr}
                                      </p>
                                    );
                                  })}

                              {item.sale.length >= 1 &&
                                item.sale.map((ele) =>
                                  ele.saleProductPrice.map((ele) => {
                                    return (
                                      <p className="text-sm font-normal px-3 text-white">
                                        {ele.sale_productPriceDr}
                                      </p>
                                    );
                                  })
                                )}

                              <p className="text-white">
                                {item.expenses.length >= 1 &&
                                  item.expenses.map((ele) =>
                                    ele.expensesBill.map(
                                      (ele) => ele.expensesAmountDr
                                    )
                                  )}
                              </p>
                              {item.salary.length >= 1 &&
                                item.salary.map((ele) =>
                                  ele.employeeDetails.map((ele) => {
                                    return (
                                      <p className="text-sm font-normal px-3 text-white">
                                        {ele.paymentDr}
                                      </p>
                                    );
                                  })
                                )}
                              {item.badDebt.length >= 1 &&
                                item.badDebt.map((ele) => {
                                  return (
                                    <p className="text-sm font-normal px-3 text-white">
                                      {ele.AmountDr}
                                    </p>
                                  );
                                })}
                              {item.badDebtRecovery.length >= 1 && (
                                <p className="text-sm font-normal px-3 text-white">
                                  -
                                </p>
                              )}
                            </div>
                            <div>
                              <p className="text-sm font-normal px-3 text-white">
                                {item.supplierName &&
                                  item.productDiscountAmountDr}
                              </p>
                              {item.sale.length >= 1 &&
                                item.sale.map((ele) => (
                                  <p className="text-sm font-normal px-3 text-white">
                                    {ele.sale_discountAmountDr}
                                  </p>
                                ))}
                              <p className="text-sm font-normal px-3 text-white">
                                {item.productCreditType === "Partial Credit" ? (
                                  <>
                                    <p>{item.productPartialCreditCashDr}</p>
                                    <p>{item.productPartialCreditDr}</p>
                                  </>
                                ) : item.productCreditType === "Credit" ? (
                                  item.productFullCreditDr
                                ) : item.productCreditType === "No Credit  " ? (
                                  item.productCreditCashDr
                                ) : null}
                              </p>

                              {item.sale.length >= 1 && (
                                <p className="text-sm font-normal px-3 text-white">
                                  {item.sale.map((ele) =>
                                    ele.sale_productCreditType ===
                                    "Partial Credit" ? (
                                      <>
                                        <p>
                                          {ele.sale_productPartialCreditCashDr}
                                        </p>
                                        <p>{ele.sale_productPartialCreditDr}</p>
                                      </>
                                    ) : ele.sale_productCreditType ===
                                      "Credit" ? (
                                      ele.sale_productFullCreditDr
                                    ) : ele.sale_productCreditType ===
                                      "No Credit" ? (
                                      ele.sale_productCreditCashDr
                                    ) : null
                                  )}
                                </p>
                              )}

                              <p className="text-sm font-normal px-3 text-white">
                                {item.productTransctionType === "Cash" ? (
                                  item.productTransctionCashDr
                                ) : item.productTransctionType === "Bank" ? (
                                  item.productTransctiontBankDr
                                ) : item.productTransctionType === "Both" ? (
                                  <>
                                    <>
                                      <p>{item.product_B_TransctionBankDr}</p>
                                      <p>{item.product_B_TransctionCashDr}</p>
                                    </>
                                  </>
                                ) : null}
                                {item.sale.length >= 1 &&
                                  item.sale.map((ele) =>
                                    ele.sale_productTransctionType ===
                                    "Cash" ? (
                                      ele.sale_productTransctionCashDr
                                    ) : ele.sale_productTransctionType ===
                                      "Bank" ? (
                                      ele.sale_productTransctiontBankDr
                                    ) : ele.sale_productTransctionType ===
                                      "Both" ? (
                                      <>
                                        <p>
                                          {ele.sale_product_B_TransctionCashDr}
                                        </p>
                                        <p>
                                          {ele.sale_product_B_TransctionBankDr}
                                        </p>
                                      </>
                                    ) : null
                                  )}
                                {item.expenses.length >= 1 &&
                                  item.expenses.map((ele) =>
                                    ele.expenses_transction_Type === "Cash" ? (
                                      ele.expenses_transctionCashDr
                                    ) : ele.expenses_transction_Type ===
                                      "Bank" ? (
                                      ele.expenses_transctionBankDr
                                    ) : ele.expenses_transction_Type ===
                                      "Both" ? (
                                      <>
                                        <p>
                                          {ele.expenses_transction_B_CashDr}
                                        </p>
                                        <p>
                                          {ele.expenses_transction_B_BankDr}
                                        </p>
                                      </>
                                    ) : null
                                  )}
                                {item.salary.length >= 1 &&
                                  item.salary.map((ele) =>
                                    ele.transctionType === "Cash" ? (
                                      ele.cashTransctionDr
                                    ) : ele.transctionType === "Bank" ? (
                                      ele.bankTransctionDr
                                    ) : ele.transctionType === "Both" ? (
                                      <>
                                        <p>{ele.B_cashTransctionDr}</p>
                                        <p>{ele.B_bankTransctionDr}</p>
                                      </>
                                    ) : null
                                  )}
                              </p>
                              <p>
                                {item.productStatus ===
                                "Normal" ? null : item.productStatus ===
                                  "Ab-Normal" ? (
                                  <>
                                    <p>{item.totalDefeactBill}</p>
                                    <p>
                                      {item.defeactGeneralProductBils.map(
                                        (item, i) => {
                                          return (
                                            <>
                                              <p>{item.defProductPricecr}</p>
                                            </>
                                          );
                                        }
                                      )}
                                    </p>
                                  </>
                                ) : null}
                              </p>
                              <p>
                                {item.sale.length >= 1 &&
                                  item.sale.map((ele) =>
                                    ele.sale_productStatus ===
                                    "Normal" ? null : ele.sale_productStatus ===
                                      "Ab-Normal" ? (
                                      <>
                                        <p>{ele.totaldefeactSaleBill}</p>
                                        <p>
                                          {ele.saleDefeactProductBils.map(
                                            (ele) => {
                                              return (
                                                <p>
                                                  {ele.sale_defProductPriceCr}
                                                </p>
                                              );
                                            }
                                          )}
                                        </p>
                                      </>
                                    ) : null
                                  )}
                                {item.inventeryExpenses.length >= 0 &&
                                  `  ${item.inventeryExpenses.map(
                                    (ele) => ele.inventeryAmount
                                  )}`}
                                {item.inventeryExpenses.length >= 0 && (
                                  <p className="text-white">-</p>
                                )}
                              </p>
                            </div>
                          </td>
                          <td className=" py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                            <div>
                              <p className="text-white">-</p>
                              <p>
                                {item.generalProductBils.map((item, i) => {
                                  return (
                                    <p className="text-sm font-normal px-3 ">
                                      {item.productPriceDr}
                                    </p>
                                  );
                                })}
                                {item.sale.length >= 1 &&
                                  item.sale.map((ele) =>
                                    ele.saleProductPrice.map((ele) => {
                                      return (
                                        <p className="text-sm font-normal px-3  ">
                                          {ele.sale_productPriceCr}
                                        </p>
                                      );
                                    })
                                  )}
                                {item.expenses.length >= 1 &&
                                  item.expenses.map((ele) =>
                                    ele.expensesBill.map((ele) => {
                                      return (
                                        <p className="text-sm font-normal px-3  ">
                                          {ele.expensesAmountCr}
                                        </p>
                                      );
                                    })
                                  )}
                                {item.salary.length >= 1 &&
                                  item.salary.map((ele) =>
                                    ele.employeeDetails.map((ele) => {
                                      return (
                                        <p className="text-sm font-normal px-3 ">
                                          {ele.paymentCr}
                                        </p>
                                      );
                                    })
                                  )}
                              </p>
                            </div>
                            <div>
                              <p className="text-sm font-normal px-3">
                                {item.supplierName &&
                                item.productDiscountType ===
                                  "Percent Discount(%)"
                                  ? (item.productDiscountAmountCr / 100) *
                                    item.generalProductBils.reduce(
                                      (total, bill) => {
                                        const price = parseInt(
                                          bill.productPriceCr
                                        );
                                        return total + price;
                                      },
                                      0
                                    )
                                  : item.productDiscountType ===
                                    "Amount Discount(Rs.)"
                                  ? item.productDiscountAmountCr
                                  : null}

                                {item.sale.length >= 1 &&
                                  item.sale.map((item) =>
                                    item.sale_discountType ===
                                    "Percent Discount(%)"
                                      ? item.totalSaleBill -
                                        (item.sale_discountAmountCr / 100) *
                                          item.totalSaleBill
                                      : item.sale_discountType ===
                                        "Amount Discount(Rs.)"
                                      ? item.sale_discountAmountCr
                                      : null
                                  )}
                              </p>
                              <p className="text-sm font-normal px-3">
                                {item.productCreditType === "Partial Credit" ? (
                                  <>
                                    <p>{item.productPartialCreditCashCr}</p>
                                    <p>{item.productPartialCreditCr}</p>
                                  </>
                                ) : item.productCreditType === "Credit" ? (
                                  item.productFullCreditCr
                                ) : item.productCreditType === "No Credit" ? (
                                  item.productCreditCashCr
                                ) : null}
                              </p>
                              {item.sale.length >= 1 && (
                                <p className="text-sm font-normal px-3 ">
                                  {item.sale.map((ele) =>
                                    ele.sale_productCreditType ===
                                    "Partial Credit" ? (
                                      <>
                                        <p>{ele.sale_productPartialCreditCr}</p>
                                        <p>
                                          {ele.sale_productPartialCreditCashtCr}
                                        </p>
                                      </>
                                    ) : ele.sale_productCreditType ===
                                      "Credit" ? (
                                      ele.sale_productFullCreditCr
                                    ) : ele.sale_productCreditType ===
                                      "No Credit" ? (
                                      ele.sale_productCreditCashCr
                                    ) : null
                                  )}
                                </p>
                              )}
                              <p className="text-sm font-normal px-3">
                                {item.productTransctionType === "Cash" ? (
                                  item.productTransctionCashCr
                                ) : item.productTransctionType === "Bank" ? (
                                  item.productTransctiontBankCr
                                ) : item.productTransctionType === "Both" ? (
                                  <>
                                    <>
                                      <p>{item.product_B_TransctionBankCr}</p>
                                      <p>{item.product_B_TransctionCashCr}</p>
                                    </>
                                  </>
                                ) : null}
                                {item.sale.length >= 1 &&
                                  item.sale.map((ele) =>
                                    ele.sale_productTransctionType ===
                                    "Cash" ? (
                                      ele.sale_productTransctionCashCr
                                    ) : ele.sale_productTransctionType ===
                                      "Bank" ? (
                                      ele.sale_productTransctiontBankCr
                                    ) : ele.sale_productTransctionType ===
                                      "Both" ? (
                                      <>
                                        <p>
                                          {ele.sale_product_B_TransctionCashCr}
                                        </p>
                                        <p>
                                          {ele.sale_product_B_TransctionBankCr}
                                        </p>
                                      </>
                                    ) : null
                                  )}
                                {item.expenses.length >= 1 &&
                                  item.expenses.map((ele) =>
                                    ele.expenses_transction_Type === "Cash" ? (
                                      ele.expenses_transctionCashCr
                                    ) : ele.expenses_transction_Type ===
                                      "Bank" ? (
                                      ele.expenses_transctionBankCr
                                    ) : ele.expenses_transction_Type ===
                                      "Both" ? (
                                      <>
                                        <p>
                                          {ele.expenses_transction_B_CashCr}
                                        </p>
                                        <p>
                                          {ele.expenses_transction_B_BankCr}
                                        </p>
                                      </>
                                    ) : null
                                  )}
                                {item.salary.length >= 1 &&
                                  item.salary.map((ele) =>
                                    ele.transctionType === "Cash" ? (
                                      ele.cashTransctionCr
                                    ) : ele.transctionType === "Bank" ? (
                                      ele.bankTransctionCr
                                    ) : ele.transctionType === "Both" ? (
                                      <>
                                        <p>{ele.B_cashTransctionCr}</p>
                                        <p>{ele.B_bankTransctionCr}</p>
                                      </>
                                    ) : null
                                  )}
                                {item.badDebtRecovery.length >= 1 &&
                                  item.badDebtRecovery.map((ele) => (
                                    <p>{ele.recovery_AmountCr}</p>
                                  ))}
                                {item.inventeryExpenses.length >= 0 &&
                                  `  ${item.inventeryExpenses.map(
                                    (ele) => ele.inventeryAmount
                                  )}`}
                              </p>
                              <p>
                                {item.productStatus ===
                                "Normal" ? null : item.productStatus ===
                                  "Ab-Normal" ? (
                                  <>
                                    <p>-</p>
                                    <p className="text-sm font-normal px-3 text-white">
                                      {item.defeactGeneralProductBils.map(
                                        (item, i) => {
                                          return (
                                            <>
                                              <p>{item.defProductPriceDr}</p>
                                            </>
                                          );
                                        }
                                      )}
                                    </p>
                                  </>
                                ) : null}
                              </p>
                              <p>
                                {item.salePaymentRecived.length >= 1 &&
                                  item.salePaymentRecived.map((ele) =>
                                    ele.transctionType === "Cash" ? (
                                      "-"
                                    ) : ele.transctionType === "Bank" ? (
                                      "-"
                                    ) : ele.transctionType === "Both" ? (
                                      <>
                                        <p> -</p>
                                        <p>-</p>
                                      </>
                                    ) : null
                                  )}
                              </p>
                              <p>
                                {item.salePaymentRecived.length >= 1 &&
                                  item.salePaymentRecived.map(
                                    (ele) => ele.totalAmount
                                  )}
                              </p>
                              <p>
                                {item.sale.length >= 1 &&
                                  item.sale.map((ele) =>
                                    ele.sale_productStatus ===
                                    "Normal" ? null : ele.sale_productStatus ===
                                      "Ab-Normal" ? (
                                      <>
                                        <p className="text-white">-</p>
                                        <p>
                                          {ele.saleDefeactProductBils.map(
                                            (ele) => {
                                              return (
                                                <p className="text-white">
                                                  {ele.sale_defProductPriceDr}
                                                </p>
                                              );
                                            }
                                          )}
                                        </p>
                                      </>
                                    ) : null
                                  )}
                                {item.badDebt.length >= 1 &&
                                  item.badDebt.map((ele) => {
                                    return (
                                      <p className="text-sm font-normal px-3 ">
                                        {ele.AmountCr}
                                      </p>
                                    );
                                  })}
                              </p>
                            </div>
                          </td>
                          <td className=" py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                            <div className="flex items-center gap-x-6">
                              <button
                                className="  transition-colors duration-200 text-red-500  focus:outline-none"
                                onClick={() => dltJournal(item._id)}
                              >
                                <DeleteForeverIcon />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Pagination page={1} count={count} />
      </section>
    </div>
  );
};

const th = [
  {
    name: "Date",
  },
  {
    name: "Particular Name",
  },
  {
    name: "J.F",
  },
  {
    name: "Amount(Debit)",
  },
  {
    name: "Amount(Criedit)",
  },
];

export default GeneraalJournalList;

// import React, { useRef } from "react";
// import { DownloadTableExcel } from "react-export-table-to-excel";

// const Test = () => {
//   const tableRef = useRef(null);

//   return (
//     <div>
//       <DownloadTableExcel
//         filename="users table"
//         sheet="users"
//         currentTableRef={tableRef.current}
//       >
//         <button> Export excel </button>
//       </DownloadTableExcel>
//       <div ref={tableRef}>
//         <table>
//           <tr aria-colspan={2} align="right">
//             <td className="text-center">hhhhhhhhhhh</td>
//           </tr>
//           <tr>
//             <td>hhhhhhhhhhh</td>
//           </tr>{" "}
//           <tr>
//             <td>hhhhhhhhhhh</td>
//           </tr>
//         </table>
//         <table>
//           <tbody>
//             <tr>
//               <th>Firstname</th>
//               <th>Lastname</th>
//               <th>Age</th>
//             </tr>
//             <tr>
//               <td>Edison</td>
//               <td>Padilla</td>
//               <td>20</td>
//             </tr>
//             <tr>
//               <td>Alberto</td>
//               <td>Lopez</td>
//               <td>94</td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Test;

import React from "react";

const Notice = () => {
  return (
    <div className="my-5 flex gap-2">
      <button className="border bg-[red] px-3 py-2 text-[white] rounded-[6px] ">
        Notice
      </button>
      {/* <marquee className="my-2" behavior="alternate">
          Dear User , The stock is minimum Stock completed please add the stock
          as soon as possible Thank you
        </marquee> */}
    </div>
  );
};

export default Notice;

import React from "react";
import { useSelector } from "react-redux";
import { Spinner } from "../../../Components/Spinner/Spinner";
import Tittle from "../../../Components/Tittle/Tittle";

const CreditTranscationHistory = () => {
  const { singleSupply, isLoading } = useSelector((state) => state.supplier);
  console.log(singleSupply, isLoading);
  return (
    <div>
      {isLoading && <Spinner />}
      <Tittle
        tittle={
          "Transcation History-" + singleSupply.purchaseCreditReturn.length
        }
      />
      <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-flow-row gap-2 m-5">
        {singleSupply.purchaseCreditReturn.map((ele, i) => (
          <div>
            <p
              className="border bg-yellow-400 text-start p-2    shadow-md rounded-md "
              key={i}
            >
              {singleSupply.supplierName} Recived{" "}
              <span className=" text-red-900 font-bold px-2 ">
                Rs.{ele.amount}
              </span>
              through{" "}
              {ele.transactionType === "Both"
                ? "Bank & Cash"
                : ele.transactionType}
              &nbsp; at &nbsp;
              <span className="underline text-[green] font-medium">
                {ele.date.replace("T00:00:00.000Z", "")}
              </span>
            </p>{" "}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CreditTranscationHistory;

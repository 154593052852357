import React, { useState } from "react";
import Button from "../../Components/Button/Button";
import { useDispatch } from "react-redux";
import { addSupplier } from "../../Redux/Feature/supplier/supplierAction";
import { toast } from "react-toastify";

const AddSuppliers = () => {
  const [registrationType, setRegistrationType] = useState();
  const [registrationNumber, setRegistrationNumber] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // New state for button disable

  const [data, setData] = useState({
    supplierName: "",
    supplierLocation: "",
    supplierContact: "",
    supplierEmail: "",
    supplierRemarks: "N/A",
    country: "",
    countryCode: "",
    supplierType: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((pre) => {
      return {
        ...pre,
        [name]: value,
      };
    });
  };

  const token = localStorage.getItem("NHHD:token");

  console.log(data);
  const dispatch = useDispatch();
  const submit = () => {
    if (
      data.supplierName === "" ||
      data.supplierLocation === "" ||
      data.supplierContact === "" ||
      data.supplierEmail === "" ||
      data.supplierRemarks === "" ||
      data.country === "" ||
      registrationType === "" ||
      registrationType === "Select Registration Number" ||
      registrationNumber === ""
    ) {
      toast.error("Please Fill Out All Fields");
    } else {
      setIsButtonDisabled(true);
      dispatch(
        addSupplier({ ...data, token, registrationType, registrationNumber })
      ).then(() => {
        setIsButtonDisabled(false);
      });
    }
  };

  if (data.country === "Nepal") {
    data.supplierType = "Local Supplier";
  } else if ((data.country = "India")) {
    data.supplierType = "Importer";
  }
  if (data.country === "Nepal") {
    data.countryCode = "+977";
  } else if ((data.country = "India")) {
    data.countryCode = "+91";
  }

  return (
    <>
      <div className=" lg:flex md:flex justify-center">
        <div className="border border-gray-100 w-auto h-auto bg-gray-200 py-4 rounded-[6px]  ">
          {/* <p className="p-4 text-[1.5rem] text-[green]">Add Suppiler</p> */}
          <div className=" my-2 mx-2">
            <div className=" grid grid-flow-row lg:grid-cols-3 md:grid-cols-3 gap-3 my-2">
              <div>
                <p className="uppercase opacity-30 pt-3">Supplier Name</p>
                <input
                  type="text"
                  name="supplierName"
                  value={data.supplierName}
                  onChange={handleChange}
                  className="border py-3 rounded-md placeholder:px-2 px-2  my-2 lg:my-0 md:my-0"
                  placeholder="Supplier Name "
                />{" "}
              </div>

              <div>
                <p className="uppercase opacity-30 pt-3">Supplier Country</p>
                <select
                  defaultValue={"Nepal"}
                  name="country"
                  value={data.country}
                  onChange={handleChange}
                  className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full "
                >
                  <option>Nepal</option>
                  <option>India</option>
                </select>
              </div>

              <div>
                <p className="uppercase opacity-30 pt-3">Supplier Location</p>
                <input
                  type="text"
                  name="supplierLocation"
                  value={data.supplierLocation}
                  onChange={handleChange}
                  className="border py-3 rounded-md placeholder:px-2 px-2"
                  placeholder="Supplier Location "
                />{" "}
              </div>
            </div>
            <div className="grid grid-flow-row lg:grid-cols-3 md:grid-cols-3 gap-3 my-2">
              <div className="w-full h-full">
                <p className="uppercase opacity-30 pt-3">Supplier Contact</p>

                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  name="supplierContact"
                  required
                  value={data.supplierContact}
                  onChange={handleChange}
                  className={`border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 ${
                    data.supplierContact.length > 10 ? "text-red-900" : null
                  }`}
                  placeholder="Supplier Contact "
                />

                <p className="text-red-800">
                  {data.supplierContact.length > 10 &&
                    "Please Enter Your Phone!"}
                </p>
              </div>
              <div>
                <p className="uppercase opacity-30 pt-3">Supplier Email</p>
                <input
                  type="email"
                  name="supplierEmail"
                  value={data.supplierEmail}
                  onChange={handleChange}
                  className="border py-3 rounded-md placeholder:px-2 px-2 w-full"
                  placeholder="Supplier Email "
                />
              </div>
              <div>
                <p className="uppercase opacity-30 pt-3">Suppiler Remarks</p>
                <input
                  type="text"
                  name="supplierRemarks"
                  value={data.supplierRemarks}
                  onChange={handleChange}
                  defaultValue="N/A"
                  className="border py-3 rounded-md placeholder:px-2 px-2 lg:w-full md:w-full"
                  placeholder="Supplier Remark "
                />
              </div>
            </div>
            <div className="grid grid-flow-row lg:grid-cols-3 md:grid-cols-3 gap-3 my-2">
              {/* <div>
                <p className="uppercase opacity-30 pt-3">Supplier Type</p>
                <select
                  defaultValue={"Local Supplier"}
                  name="supplierType"
                  onChange={handleChange}
                  value={data.supplierType}
                  className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full"
                >
                  <option>Local Supplier</option>
                  <option>Importer</option>
                </select>
              </div> */}
              <div>
                <p className="uppercase opacity-30 pt-3">Registration Type</p>
                <div className="mb-4">
                  <select
                    className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                    value={registrationType}
                    onChange={(e) => setRegistrationType(e.target.value)}
                  >
                    <option>Select Registration Number</option>
                    <option>PAN Number</option>
                    <option>VAT Number</option>
                  </select>
                </div>
              </div>

              {registrationType === "PAN Number" ? (
                <div className="mt-9">
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    value={registrationNumber}
                    onChange={(e) => setRegistrationNumber(e.target.value)}
                    placeholder={`Enter PAN Number:`}
                    className="border py-3 rounded-md placeholder:px-2 px-2 w-full"

                    // className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                  />
                </div>
              ) : registrationType === "VAT Number" ? (
                <div className="mt-9">
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    value={registrationNumber}
                    onChange={(e) => setRegistrationNumber(e.target.value)}
                    placeholder={`Enter VAT Number`}
                    className="border py-3 rounded-md placeholder:px-2 px-2 w-full"

                    // className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                  />
                </div>
              ) : null}
            </div>
          </div>

          <div className="flex justify-center my-3 gap-3 ">
            <Button
              btnName={"Add"}
              click={submit}
              isLoading={isButtonDisabled}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSuppliers;

const { createSlice } = require("@reduxjs/toolkit");
const {
  getAllLedger,
  getcashLedger,
  getBankLedger,
  getDiscountLedger,
  getAbnormalLedger,
  getSupplierAccountLedger,
  cashFilterByDate,
  bankFilterByDate,
  discountFilterByDate,
  abnormalFilterByDate,
  supplierAccountFilterByDate,
  filterBysupplierAccountName,
} = require("./ledgerAction");

const ledgerSlice = createSlice({
  name: "ledger",
  initialState: {
    isLoading: true,
    success: false,
    ledgers: [],
    bank: [],
    cash: [],
    discount: [],
    abnormal: [],
    supplierAccount: [],
    count: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllLedger.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(getAllLedger.fulfilled, (state, action) => {
      console.log(action.payload.allLedger);
      state.isLoading = false;
      state.success = action.payload.success;
      state.ledgers = action.payload.allLedger;
    });
    builder.addCase(getAllLedger.rejected, (state) => {
      state.isLoading = true;
      state.success = false;
    });

    builder.addCase(getcashLedger.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(getcashLedger.fulfilled, (state, action) => {
      console.log(action.payload.cashLedger);
      state.isLoading = false;
      state.success = action.payload.success;
      state.cash = action.payload.cashLedger;
    });
    builder.addCase(getcashLedger.rejected, (state) => {
      state.isLoading = true;
      state.success = false;
    });

    builder.addCase(getBankLedger.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(getBankLedger.fulfilled, (state, action) => {
      console.log(action.payload.bankLedger);
      state.isLoading = false;
      state.success = action.payload.success;
      state.bank = action.payload.bankLedger;
    });
    builder.addCase(getBankLedger.rejected, (state) => {
      state.isLoading = true;
      state.success = false;
    });

    builder.addCase(getDiscountLedger.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(getDiscountLedger.fulfilled, (state, action) => {
      console.log(action.payload.discountLedger);
      state.isLoading = false;
      state.success = action.payload.success;
      state.discount = action.payload.discountLedger;
    });
    builder.addCase(getDiscountLedger.rejected, (state) => {
      state.isLoading = true;
      state.success = false;
    });

    builder.addCase(getAbnormalLedger.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(getAbnormalLedger.fulfilled, (state, action) => {
      console.log(action.payload.abnormalLedger);
      state.isLoading = false;
      state.success = action.payload.success;
      state.abnormal = action.payload.abNormalLedger;
    });
    builder.addCase(getAbnormalLedger.rejected, (state) => {
      state.isLoading = true;
      state.success = false;
    });

    builder.addCase(getSupplierAccountLedger.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(getSupplierAccountLedger.fulfilled, (state, action) => {
      console.log(action.payload.supplierAccountLedger);
      state.isLoading = false;
      state.success = action.payload.success;
      state.supplierAccount = action.payload.supplierAccountLedger;
    });
    builder.addCase(getSupplierAccountLedger.rejected, (state) => {
      state.isLoading = true;
      state.success = false;
    });

    builder.addCase(cashFilterByDate.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(cashFilterByDate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
      state.cash = action.payload.cash;
    });
    builder.addCase(cashFilterByDate.rejected, (state) => {
      state.isLoading = true;
      state.success = false;
      state.sales = null;
    });

    builder.addCase(bankFilterByDate.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(bankFilterByDate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
      state.bank = action.payload.bank;
    });
    builder.addCase(bankFilterByDate.rejected, (state) => {
      state.isLoading = true;
      state.success = false;
      state.sales = null;
    });

    builder.addCase(discountFilterByDate.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(discountFilterByDate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
      state.discount = action.payload.discount;
    });
    builder.addCase(discountFilterByDate.rejected, (state) => {
      state.isLoading = true;
      state.success = false;
      state.sales = null;
    });

    builder.addCase(abnormalFilterByDate.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(abnormalFilterByDate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
      state.abnormal = action.payload.abnormal;
    });
    builder.addCase(abnormalFilterByDate.rejected, (state) => {
      state.isLoading = true;
      state.success = false;
      state.sales = null;
    });

    builder.addCase(supplierAccountFilterByDate.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(supplierAccountFilterByDate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
      state.supplierAccount = action.payload.supplierAccount;
    });
    builder.addCase(supplierAccountFilterByDate.rejected, (state) => {
      state.isLoading = true;
      state.success = false;
      state.sales = null;
    });

    builder.addCase(filterBysupplierAccountName.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(filterBysupplierAccountName.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
      state.supplierAccount = action.payload.supplierAccount;
    });
    builder.addCase(filterBysupplierAccountName.rejected, (state) => {
      state.isLoading = true;
      state.success = false;
      state.sales = null;
    });
  },
});

export const ledgerReducer = ledgerSlice.reducer;

import React from "react";
import EmployeeList from "./EmployeeList";

const index = () => {
  return (
    <div>
      <EmployeeList />
    </div>
  );
};

export default index;

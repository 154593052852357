import { createSlice } from "@reduxjs/toolkit";
import { getSalesAccount, getSalesAccountByMonth } from "./salesAccountAction";

const salesAccountSlice = createSlice({
  name: "salesAccount",
  initialState: {
    salesAccount: [],
    error: null,
    isLoading: true,
    count: 0,
    salesAccountByMonth: [],
  },
  reducers: {},
  extraReducers: {
    [getSalesAccount.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getSalesAccount.fulfilled]: (state, action) => {
      // console.log(action.payload);
      state.isLoading = false;
      state.count = action.payload.count;
      console.log(action.payload);
      state.salesAccount = action.payload.saleAccount;
    },
    [getSalesAccount.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getSalesAccountByMonth.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getSalesAccountByMonth.fulfilled]: (state, action) => {
      // console.log(action.payload);
      state.isLoading = false;
      console.log(action.payload);
      state.salesAccountByMonth = action.payload.saleAccount;
    },
    [getSalesAccountByMonth.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export const salesAccountReducer = salesAccountSlice.reducer;

import React, { useState } from "react";
import Button from "../../Components/Button/Button";
import { useDispatch } from "react-redux";
import { addEmployee } from "../../Redux/Feature/staff/staffAction";
import { toast } from "react-toastify";
import { Scrollbars } from "react-custom-scrollbars-2";
import "../../index.css";

import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";

const AddStaff = () => {
  const [employeeName, setEmployeeName] = useState("");
  const [employeeEmail, setEmployeeEmail] = useState("");
  const [employeeAddress, setEmployeeAddress] = useState("");
  const [employeePosition, setEmployeePosition] = useState("");
  const [employeeSalary, setEmployeeSalary] = useState("");
  const [employeeProfile, setEmployeeProfile] = useState("");
  const [frontPicture, setFrontPicture] = useState("");
  const [backPicture, setBackPicture] = useState("");
  const [employeePhone, setEmployeePhone] = useState("");
  const [employeeStatus, setEmployeeStatus] = useState("");
  const [employeeJoiningDate, setEmployeeJoiningDate] = useState("");
  const [previePP, setPreviewPP] = useState("");
  const [previeCF, setPreviewCF] = useState("");
  const [previeCB, setPreviewCB] = useState("");

  console.log(employeeJoiningDate);

  const formData = new FormData();
  formData.append("employeeName", employeeName);
  formData.append("employeeEmail", employeeEmail);
  formData.append("employeeAddress", employeeAddress);
  formData.append("employeePosition", employeePosition);
  formData.append("employeePhone", employeePhone);
  formData.append("employeeSalary", employeeSalary);
  formData.append("employeeProfile", employeeProfile);
  formData.append("frontPicture", frontPicture);
  formData.append("backPicture", backPicture);
  formData.append("employeeStatus", employeeStatus);
  formData.append("employeeJoiningDate", employeeJoiningDate);

  const dispatch = useDispatch();
  const token = localStorage.getItem("NHHD:token");
  const submit = () => {
    if (
      employeeName === "" ||
      employeeAddress === "" ||
      employeeEmail === "" ||
      employeePosition === "" ||
      employeeSalary === "" ||
      employeeStatus === "" ||
      employeeProfile === "" ||
      backPicture === "" ||
      frontPicture === "" ||
      employeeJoiningDate === ""
    ) {
      toast.error("Feild Cannot be null");
    } else {
      dispatch(addEmployee({ formData, token }));
    }
  };
  return (
    <>
      {" "}
      <div className=" lg:flex  md:flex justify-center">
        <div className="border border-gray-100 w-auto h-auto bg-gray-200 py-4 rounded-[6px] ">
          {/* <p className="p-4 text-[1.5rem] text-[green]">Add New Employee</p> */}
          <Scrollbars
            style={{ width: 720, height: 500 }}
            className="staff-mbl-view"
          >
            <div className=" my-2 mx-2">
              <div className=" lg:flex md:flex gap-3 my-2">
                <div>
                  <input
                    type="text"
                    value={employeeName}
                    onChange={(e) => setEmployeeName(e.target.value)}
                    className="border py-3 rounded-md placeholder:px-2 px-2 my-2 md:my-0 lg:my-0"
                    placeholder="Employee Name"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    value={employeeAddress}
                    onChange={(e) => setEmployeeAddress(e.target.value)}
                    className="border py-3 rounded-md placeholder:px-2 px-2"
                    placeholder="Employee Address "
                  />
                </div>
              </div>
              <div className="lg:flex  md:flex gap-3 my-2">
                <div>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    value={employeePhone}
                    onChange={(e) => setEmployeePhone(e.target.value)}
                    className="border py-3 rounded-md placeholder:px-2 px-2 my-2 md:my-0 lg:my-0"
                    placeholder="Employee Phone Number"
                  />
                </div>
                <div>
                  <input
                    type="email"
                    value={employeeEmail}
                    onChange={(e) => setEmployeeEmail(e.target.value)}
                    className="border py-3 rounded-md placeholder:px-2 px-2"
                    placeholder="Employee Email "
                  />
                </div>
              </div>
              <div className="lg:flex  md:flex gap-3 my-2">
                <div>
                  <input
                    type="text"
                    value={employeePosition}
                    onChange={(e) => setEmployeePosition(e.target.value)}
                    className="border py-3 rounded-md placeholder:px-2 px-2 my-2 md:my-0 lg:my-0"
                    placeholder="Employee Position "
                  />
                </div>
                <div>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    value={employeeSalary}
                    onChange={(e) => setEmployeeSalary(e.target.value)}
                    className="border py-3 rounded-md placeholder:px-2 px-2"
                    placeholder="Customer  Salary "
                  />
                </div>
              </div>
              <div className="lg:flex  md:flex gap-3 my-2">
                {" "}
                <select
                  className="border py-3 md:w-full rounded-md placeholder:px-2 px-2   my-2 md:my-0 lg:my-0"
                  value={employeeStatus}
                  onChange={(e) => setEmployeeStatus(e.target.value)}
                >
                  <option>Select Status</option>
                  <option>Active</option>
                  <option>Inactive</option>
                </select>
                <div class="w-full">
                  <label
                    class="block mb-2 text-sm font-medium text-gray-900 "
                    for="file_input"
                  >
                    Upload Employe Profile
                  </label>
                  <input
                    class="block md:w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50   focus:outline-none    "
                    id="file_input"
                    type="file"
                    name={employeeProfile}
                    onChange={(e) => {
                      const file = e.currentTarget.files[0];
                      setEmployeeProfile(file);
                      file
                        ? setPreviewPP(URL.createObjectURL(file))
                        : setPreviewPP(null);
                    }}
                  />
                </div>
              </div>
              <p className="py-2 text-green-500">Upload Citizenship Picture</p>
              <div className="lg:flex  md:flex gap-3 my-2">
                <div class="w-full">
                  <label
                    class="block mb-2 text-sm font-medium text-gray-900 "
                    for="file_input"
                  >
                    Front Picture
                  </label>
                  <input
                    class="block md:w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50   focus:outline-none    "
                    id="file_input"
                    type="file"
                    name={frontPicture}
                    onChange={(e) => {
                      const file = e.currentTarget.files[0];
                      setFrontPicture(file);
                      file
                        ? setPreviewCF(URL.createObjectURL(file))
                        : setPreviewCF(null);
                    }}
                  />
                </div>
                <div class="w-full">
                  <label
                    class="block mb-2 text-sm font-medium text-gray-900 "
                    for="file_input"
                  >
                    Back Picture
                  </label>
                  <input
                    class="block lg:flex md:w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50   focus:outline-none    "
                    id="file_input"
                    type="file"
                    name={backPicture}
                    onChange={(e) => {
                      const file = e.currentTarget.files[0];
                      setBackPicture(file);
                      file
                        ? setPreviewCB(URL.createObjectURL(file))
                        : setPreviewCB(null);
                    }}
                  />
                </div>
              </div>
              <div class="w-full">
                <p className="uppercase text-green-800 py-1">Joining Date</p>

                <NepaliDatePicker
                  className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0   "
                  id="file_input"
                  value={employeeJoiningDate}
                  onChange={(value) => setEmployeeJoiningDate(value)}
                  options={{ calenderLocale: "ne", valueLocale: "en" }}
                />
                <p className="text-red-500 px-3">
                  Please Select the date manually
                </p>
              </div>
              <div className="lg:grid md:grid grid-cols-3 grid-flow-row">
                <div>
                  {previePP && (
                    <>
                      {" "}
                      <img
                        src={previePP}
                        alt=""
                        className="w-[50px] h-[50px]"
                      />
                      <p>profile Picture</p>
                    </>
                  )}
                </div>
                <div>
                  {previeCF && (
                    <>
                      {" "}
                      <img
                        src={previeCF}
                        alt=""
                        className="w-[50px] h-[50px]"
                      />
                      <p>Citizen Front Picture</p>
                    </>
                  )}
                </div>
                <div>
                  {previeCB && (
                    <>
                      {" "}
                      <img
                        src={previeCB}
                        alt=""
                        className="w-[50px] h-[50px]"
                      />
                      <p>Citizen Back Picture</p>
                    </>
                  )}
                </div>
              </div>
              <div className="flex lg:justify-center my-3 gap-3 ">
                <Button btnName={"Add New Employe"} click={submit} />
              </div>
            </div>
          </Scrollbars>
        </div>
      </div>
    </>
  );
};

export default AddStaff;

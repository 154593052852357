import Product from "../Screens/Product/Index";
import Category from "../Screens/Category/Index";
import Supplier from "../Screens/Suppiler/index";
import SingleProduct from "../Screens/SingleProduct/SingleProduct";
import Sale from "../Screens/Sale";
import AddStaff from "../Screens/Staff/AddStaff";
import Home from "../Screens/Home/Home";
import UpdateProduct from "../Screens/Product/UpdateProduct";
import Staff from "../Screens/Staff";
import SingleEmployee from "../Screens/Staff/SingleEmployee";
import SingleSale from "../Screens/Sale/SingleSale";
import Bill from "../Screens/Bills/index";
import SingleBill from "../Screens/Bills/SingleBill";
import Salary from "../Screens/Salary/index";
// import UpdateSalary from "../Screens/Salary/UpdateSalary";
import Expenses from "../Screens/Expenses/index";
import CategoryInventory from "../Screens/Inventery/Category/index";
import CategoryExpenses from "../Screens/Expenses/Category/index";
import ExcelTest from "../Screens/excelTest";
import SingleEmployeePage from "../Screens/Salary/SIngleEmployeePage";
import SalaryLog from "../Screens/Salary/SalaryLog";
import GeneralJournal from "../Screens/General-Journal/index";
import AddPurchaseProduct from "../Screens/Product/AddPurchaseProduct";
import ReturnProductList from "../Screens/Product/ReturnProduct/ReturnProductList";
import Inventery from "../Screens/Inventery/index";
import Assest from "../Screens/Assets/Assest";
import SingleAssests from "../Screens/Assets/Assest/SingleAssests";
import SoldAssest from "../Screens/Assets/Sold Assests";
import EmployeeSalary from "../Screens/EmployeeSalary";
import SingleSalary from "../Screens/EmployeeSalary/SingleSalary";
import GeneraalJournalList from "../Screens/General-Journal/GeneraalJournalList";
import Ledger from "../Screens/Ledger/index";
import SalaryHistory from "../Screens/EmployeeSalary/SalaryHistory";
import Maskebari from "../Screens/Maskebari";
import PurchaseAccount from "../Screens/PurchaseAccount";
import SalesAccount from "../Screens/SalesAccount";
import PurchaseReturnAccount from "../Screens/PurchaseReturnAccount";
import SalesReturnAccount from "../Screens/SalesReturnAccount";
import Test from "../Screens/PurchaseAccount/Test";
import SingleSupplier from "../Screens/Suppiler/SingleSupplier/SingleSupplier";
import AddExistingProduct from "../Screens/Product/AddExistingProduct";
import CreditTranscationHistory from "../Screens/Suppiler/SingleSupplier/CreditTranscationHistory";
import Customer from "../Screens/Customer/index";
import CustomerSinglePage from "../Screens/Customer/CustomerSinglePage";

export const Links = [
  {
    Path: "/",
    Components: <Home />,
  },
  {
    Path: "/product",
    Components: <Product />,
  },
  {
    Path: "/add-product",
    Components: <AddPurchaseProduct />,
  },
  {
    Path: "/add-existing-product",
    Components: <AddExistingProduct />,
  },
  {
    Path: "/product/:id",
    Components: <SingleProduct />,
  },
  {
    Path: "/update-product/:id",
    Components: <UpdateProduct />,
  },
  {
    Path: "/product/return-product-list",
    Components: <ReturnProductList />,
  },
  {
    Path: "/category",
    Components: <Category />,
  },
  {
    Path: "/suppliers",
    Components: <Supplier />,
  },
  {
    Path: "/suppliers/:id",
    Components: <SingleSupplier />,
  },
  {
    Path: "/suppliers/:id/history",
    Components: <CreditTranscationHistory />,
  },
  {
    Path: "/suppliers",
    Components: <Supplier />,
  },
  {
    Path: "/sale",
    Components: <Sale />,
  },
  {
    Path: "/sale/single-sale",
    Components: <SingleSale />,
  },
  {
    Path: "/add-staff",
    Components: <AddStaff />,
  },
  {
    Path: "/staff",
    Components: <Staff />,
  },
  {
    Path: "/staff/:id",
    Components: <SingleEmployee />,
  },
  {
    Path: "/bil",
    Components: <Bill />,
  },
  {
    Path: "/bil/:id",
    Components: <SingleBill />,
  },

  {
    Path: "/salary",
    Components: <Salary />,
  },
  {
    Path: "/Employee-salary",
    Components: <EmployeeSalary />,
  },
  {
    Path: "/Employee-salary/:id",
    Components: <SingleSalary />,
  },
  {
    Path: "/salary/:id",
    Components: <SingleEmployeePage />,
  },
  {
    Path: "/salary-history/:id",
    Components: <SalaryHistory />,
  },

  {
    Path: "/salary/:id/salary-log/:id",
    Components: <SalaryLog />,
  },
  {
    Path: "/expense",
    Components: <Expenses />,
  },
  {
    Path: "/add-inventory-loss-category",
    Components: <CategoryInventory />,
  },
  {
    Path: "/Inventery-Expenses",
    Components: <Inventery />,
  },
  {
    Path: "/category-expense",
    Components: <CategoryExpenses />,
  },
  {
    Path: "/general-journal",
    Components: <GeneralJournal />,
  },
  {
    Path: "/journal",
    Components: <GeneraalJournalList />,
  },
  {
    Path: "/expense/excel-test",
    Components: <ExcelTest />,
  },
  {
    Path: "/product/purchase-test",
    Components: <AddPurchaseProduct />,
  },
  {
    Path: "/assets",
    Components: <Assest />,
  },
  {
    Path: "/assets/:id",
    Components: <SingleAssests />,
  },
  {
    Path: "/sold-assets",
    Components: <SoldAssest />,
  },
  {
    Path: "/ledger",
    Components: <Ledger />,
  },
  {
    Path: "/maskebari",
    Components: <Maskebari />,
  },
  {
    Path: "/purchase-account",
    Components: <PurchaseAccount />,
  },
  {
    Path: "/sales-account",
    Components: <SalesAccount />,
  },
  {
    Path: "/purchase-return-account",
    Components: <PurchaseReturnAccount />,
  },
  {
    Path: "/sales-return-account",
    Components: <SalesReturnAccount />,
  },
  {
    Path: "/customer",
    Components: <Customer />,
  },
  {
    Path: "/customer/:id",
    Components: <CustomerSinglePage />,
  },
  {
    Path: "/test",
    Components: <Test />,
  },
];

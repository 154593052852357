import React from "react";
import PurchaseAccountTable from "./PurchaseAccountTable";

const index = () => {
  return (
    <div>
      <PurchaseAccountTable />
    </div>
  );
};

export default index;

import { Container } from "@mui/material";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
// import Logo from "../../Assets/Nepal Hulas AluMinium big.png";
import Logos from "../../Assets/Ram Bharat Trade link.png";
import { useSelector } from "react-redux";
const BillGenerated = () => {
  const componentPDF = useRef();
  const GeneratePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "salebill",
  });
  const { singleSale } = useSelector((state) => state.sale);
  console.log(singleSale);
  return (
    <>
      <div className="bg-green-700 py-4 h-[100vh]">
        <div className="flex justify-end mx-3">
          <p
            onClick={GeneratePDF}
            className="border border-white  w-[150px] text-center py-3 uppercase rounded-[4px] text-white hover:bg-white hover:text-green-600 "
          >
            print
          </p>
        </div>

        <Container ref={componentPDF} className="bg-white">
          <div className="flex gap-3 px-3 justify-center py-2">
            <p>+977-980783456</p>
            <p>rambharattrade@gmail.com</p>
            <p>www.rambharat.com</p>
          </div>
          <div>
            <p className="text-center text-xl">Ram Bharat Trade Link</p>
            <div className="grid grid-cols-2  grid-flow-row gap-3 px-3">
              <img src={Logos} className="w-[100px] h-[100px]" alt="" />
              <div className="text-end">
                <p className="text-end">Tarahara</p>
                <p>Itahari , Sunsari , Nepal</p>{" "}
                <p>
                  <span>PAN : </span> <span>0877767-99</span>
                </p>
              </div>
            </div>
          </div>

          <div className="my-3">
            <p>Billed To:</p>
            <p className="capitalize">
              <span>Customer Name:- </span>
              <span>{singleSale.customerName}</span>
            </p>
            <p className="capitalize">
              <span>Customer Phone:- </span>
              <span>{singleSale.customerPhone}</span>
            </p>
            <p className="capitalize">
              <span>Customer Address:- </span>
              <span>{singleSale.customerAddress}</span>
            </p>
          </div>
          <div>
            <p className="text-2xl font-bold">#Invoice</p>
            <section class="container px-4 mx-auto my-4">
              <div class="flex flex-col">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                      <table class="min-w-full divide-y divide-gray-200  ">
                        <thead class="bg-gray-50  ">
                          <tr>
                            {th.map((item, i) => {
                              return (
                                <th
                                  scope="col col-span-5"
                                  key={i}
                                  class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                                >
                                  {item.name}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>

                        <tbody class="bg-white divide-y divide-gray-200  ">
                          {singleSale.soldProducts.map((ele, i) => {
                            return (
                              <tr>
                                <td class="px-4 py-4 text-sm font-medium text-gray-700  ">
                                  {i + 1}
                                </td>

                                <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                  <div class="flex items-center gap-x-2">
                                    <div>
                                      <h2 class="text-sm capitalize font-medium text-gray-800   ">
                                        {ele.productId}
                                      </h2>
                                    </div>
                                  </div>
                                </td>
                                <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                  <div class="flex items-center gap-x-2">
                                    <div>
                                      <h2 class="text-sm capitalize font-medium text-gray-800   ">
                                        {ele.soldPrice}
                                      </h2>
                                    </div>
                                  </div>
                                </td>
                                <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                  <div class="flex items-center gap-x-2">
                                    <div>
                                      <h2 class="text-sm capitalize font-medium text-gray-800   ">
                                        {ele.soldQuantity}
                                      </h2>
                                    </div>
                                  </div>
                                </td>
                                <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                  <div class="flex items-center gap-x-2">
                                    <div>
                                      <h2 class="text-sm uppercase opacity-45 font-medium text-gray-800">
                                        {ele.subTotal}
                                      </h2>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="my-2 text-end">
              <p className="uppercase opacity-30 pt-3">Transction Details</p>
              <p>Total : {singleSale.totalSaleBill}</p>
              <p>
                Discount:
                <span className="px-2">
                  {singleSale.discountType === "Percent Discount(%)" ? (
                    <>{singleSale.discount}%</>
                  ) : singleSale.discountType === "Amount Discount(Rs.)" ? (
                    <>Rs.{singleSale.discount}</>
                  ) : (
                    "No Dscount"
                  )}
                </span>
              </p>
              <p>
                <spna>creditType : {singleSale.creditType}</spna>
                <p>
                  {singleSale.creditType === "Partial Credit" ? (
                    <>
                      <p>Partial-Cash: {singleSale.partial_cash}</p>
                      <p>Partial-Credit: {singleSale.partial_credit}</p>
                    </>
                  ) : singleSale.creditType === "Credit" ? (
                    <p>Credit: {singleSale.credit}</p>
                  ) : singleSale.creditType === "No Credit" ? (
                    <p>Cash: {singleSale.cash}</p>
                  ) : null}
                </p>
              </p>
            </div>
            <p className="uppercase opacity-30 pt-3 text-end">Total Invoice</p>
            <p className="text-end">
              <span className="text-[2rem]">Rs.</span>
              <span>
                {singleSale.totalSaleBill - singleSale.discountType ===
                "Percent Discount(%)"
                  ? (singleSale.discount / 100) * singleSale.totalSaleBill
                  : singleSale.discountType === "Amount Discount(Rs.)"
                  ? singleSale.discount
                  : 0}
              </span>
            </p>
          </div>
        </Container>
      </div>
    </>
  );
};

const th = [
  {
    name: "SN.",
  },
  {
    name: "Particular",
  },
  {
    name: "Unit Cost",
  },
  {
    name: "Quantity",
  },
  {
    name: "Amount",
  },
];

export default BillGenerated;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../utils/API";
// import { toast } from "react-toastify";

export const getAllLedger = createAsyncThunk("get/all-ledger", async () => {
  try {
    const response = await api.get("/ledger/all-ledger", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const getcashLedger = createAsyncThunk("get/all-cash", async () => {
  try {
    const response = await api.get("/ledger/all-cash", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
      },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const getBankLedger = createAsyncThunk("get/all-bank", async () => {
  try {
    const response = await api.get("/ledger/all-bank", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const getDiscountLedger = createAsyncThunk(
  "get/all-discount",
  async () => {
    try {
      const response = await api.get("/ledger/all-discount", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
        },
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAbnormalLedger = createAsyncThunk(
  "get/all-abnormal",
  async () => {
    try {
      const response = await api.get("/ledger/all-abnormal", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
        },
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getSupplierAccountLedger = createAsyncThunk(
  "get/all-supplier-account",
  async () => {
    try {
      const response = await api.get("/ledger/all-supplier-account", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
        },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const cashFilterByDate = createAsyncThunk(
  "filter/cash/date",
  async ({ startDate, endDate }) => {
    try {
      const response = await api.post(
        `/ledger/all-cash-date`,
        { startDate, endDate },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const bankFilterByDate = createAsyncThunk(
  "filter/bank/date",
  async ({ startDate, endDate }) => {
    try {
      const response = await api.post(
        `/ledger/all-bank-date`,
        { startDate, endDate },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const discountFilterByDate = createAsyncThunk(
  "filter/discount/date",
  async ({ startDate, endDate }) => {
    try {
      const response = await api.post(
        `/ledger/all-diccount-date`,
        { startDate, endDate },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const supplierAccountFilterByDate = createAsyncThunk(
  "filter/supplier-account/date",
  async ({ startDate, endDate }) => {
    try {
      const response = await api.post(
        `/ledger/all-supplier-account-date`,
        { startDate, endDate },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const abnormalFilterByDate = createAsyncThunk(
  "filter/abnormal/date",
  async ({ startDate, endDate }) => {
    try {
      const response = await api.post(
        `/ledger/all-abnormal-date`,
        { startDate, endDate },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const filterBysupplierAccountName = createAsyncThunk(
  "get/supplier-account/name",

  async (payload) => {
    try {
      const response = await api.get(
        `/ledger/all-supplier-account-name?supplierName=${payload.supplierName}`,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

import React from "react";
import { useSelector } from "react-redux";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaUserAlt } from "react-icons/fa";
import { MdOutlineLocationOn } from "react-icons/md";
import { Spinner } from "../../Components/Spinner/Spinner";
import { Container } from "@mui/material";

import PurchaseDetails from "./PurchaseDetails";
import TranscationDetails from "./TranscationDetails";
import ReturnSaleDetails from "./ReturnSaleDetails";

const CustomerSinglePage = () => {
  const { singleSale, isLoading } = useSelector((state) => state.sale);
  console.log(singleSale);
  return (
    <>
      {isLoading === true ? (
        <Spinner />
      ) : (
        <div>
          <>
            <Container>
              <div className="lg:mt-[3rem] md:mt-[3rem] ">
                <div className=" ">
                  <h1 className=" text-xl underline lg:py-3 md:py-3 ">
                    Customer Details
                  </h1>{" "}
                  <h1 className=" text-xl underline lg:py-3 md:py-3 ">
                    Reg No.-
                    <span className="text-green-900 font-bold">
                      {singleSale.registrationNumber}
                    </span>
                  </h1>
                </div>
                <div className="grid lg:grid-cols-3  md:grid-cols-3 grid-flow-row gap-3 border-green-800 p-3">
                  <div className="flex gap-2 border ">
                    <span>
                      <FaUserAlt className="text-5xl border rounded-[6px] bg-slate-300 " />
                    </span>
                    <p className="px-2">
                      <span className="text-[0.7rem]">
                        Suppiler Name
                        <br />
                      </span>
                      <span className="text-[0.9rem]">
                        {singleSale.customerName}
                      </span>
                    </p>
                  </div>
                  <div className="flex gap-2 border ">
                    <span>
                      <BsFillTelephoneFill className="text-5xl border rounded-[6px] bg-slate-300 " />
                    </span>
                    <p className="px-2">
                      <span className="text-[0.7rem]">
                        Suppiler Phone
                        <br />
                      </span>
                      <span className="text-[0.9rem]">
                        {singleSale.customerPhone}
                      </span>
                    </p>
                  </div>
                  <div className="flex gap-2 border ">
                    <span>
                      <MdOutlineLocationOn className="text-5xl border rounded-[6px] bg-slate-300 " />
                    </span>
                    <p className="px-2">
                      <span className="text-[0.7rem]">
                        Supplier Address
                        <br />
                      </span>
                      <span className="text-[0.9rem]">
                        {singleSale.customerAddress}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="gap-10 mt-5 ">
                  <div className="mx-4">
                    <p className=" text-xl underline py-3  ">Product Details</p>

                    <PurchaseDetails singleSale={singleSale} />
                  </div>
                  <div className="mx-4">
                    <p className=" my-3  ">
                      <p className="text-xl underline py-3 mx-4 ">
                        Sale Return Details
                      </p>
                      <ReturnSaleDetails />
                    </p>
                  </div>{" "}
                  <div className="mx-4">
                    <p className=" my-3  ">
                      <p className="text-xl underline py-3 mx-4 ">
                        Transaction Details
                      </p>
                      <TranscationDetails />
                    </p>
                  </div>
                </div>
              </div>
            </Container>
          </>
        </div>
      )}
    </>
  );
};

export default CustomerSinglePage;

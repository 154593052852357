import { createSlice } from "@reduxjs/toolkit";
import { userLogin } from "./authAction";

const auhtSlice = createSlice({
  name: "auth",
  initialState: {
    token: localStorage.getItem("token") || null,
    user: [],
    isLoading: true,
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.token = null;
      state.isLoading = false;
      localStorage.removeItem("NHHD:token");
    },
  },
  extraReducers: {
    [userLogin.pending]: (state) => {
      state.isLoading = true;
      state.token = null;
      state.error = null;
    },
    [userLogin.fulfilled]: (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.error = null;
    },
    [userLogin.rejected]: (state, action) => {
      state.isLoading = false;
      state.token = null;
      state.user = null;
    },
  },
});

export const { logout } = auhtSlice.actions;

export const authReducer = auhtSlice.reducer;

import React, { useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Credit from "./Ledgers/SupplierAccount/Credit";
import Debit from "./Ledgers/SupplierAccount/Debit";
import {
  getAbnormalLedger,
  getBankLedger,
  getDiscountLedger,
  getSupplierAccountLedger,
  getcashLedger,
} from "../../Redux/Feature/Ledger/ledgerAction";

const items = [
  { value: "", label: "Select Ledger" },
  { value: "cash", label: "Cash" },
  { value: "bank", label: "Bank" },
  { value: "discount", label: "Discount" },
  { value: "abnormal", label: "Ab-Normal" },
  // { value: "supplieraccount", label: "Supplier Account" },
];

const Ledger = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getcashLedger());
    dispatch(getDiscountLedger());
    dispatch(getBankLedger());
    dispatch(getAbnormalLedger());
    dispatch(getSupplierAccountLedger());
  }, [dispatch]);

  const { supplierAccount, bank, cash, discount, abnormal } = useSelector(
    (state) => state.ledger
  );
  console.log(supplierAccount, "supp");

  const suppName = supplierAccount.map((item) => {
    return (
      (item.credit && item.credit.creditorName) ||
      (item.debit && item.debit.creditorName)
    );
  });
  console.log(suppName, "this");

  const transformedArray = suppName.map((item) => {
    const value = item.replace(/\s+/g, "").toLowerCase();
    const label = item;
    return { value, label };
  });
  const newItems = [...items, ...transformedArray];

  const [selectedItem, setSelectedItem] = React.useState(null);

  const handleOptionChange = (event, value) => {
    if (value) {
      // console.log("Selected option object:", value);
      setSelectedItem(value.value);
    } else {
      // console.log("Selected option cleared");
      setSelectedItem(null);
    }
  };

  const getDataToShow = () => {
    if (!selectedItem) {
      return (
        <p className="my-2 text-gray-700">
          Which ledger do you want to create? Select the name from above the
          dropdown menu.
        </p>
      );
    }
    let creditArray = [];
    let debitArray = [];
    let mainArray = [];
    let maxLength;
    let totalCreditAmount;
    let totalDebitAmount;

    switch (selectedItem) {
      case "cash":
        creditArray = cash.filter((item) => item.credit);
        debitArray = cash.filter((item) => item.debit);
        maxLength = Math.max(creditArray.length, debitArray.length);
        mainArray = { ...cash };

        totalDebitAmount = debitArray.reduce((total, item) => {
          const amount = parseInt(item?.debit?.name ? item?.debit?.amount : 0);
          return total + (isNaN(amount) ? 0 : amount);
        }, 0);
        totalCreditAmount = creditArray.reduce((total, item) => {
          const amount = parseInt(
            item?.credit?.name ? item?.credit?.amount : 0
          );
          return total + (isNaN(amount) ? 0 : amount);
        }, 0);

        break;
      // return <Cash />;
      case "bank":
        creditArray = bank.filter((item) => item.credit);
        debitArray = bank.filter((item) => item.debit);
        maxLength = Math.max(creditArray.length, debitArray.length);
        mainArray = { ...bank };

        totalDebitAmount = debitArray.reduce((total, item) => {
          const amount = parseInt(item?.debit?.name ? item?.debit?.amount : 0);
          return total + (isNaN(amount) ? 0 : amount);
        }, 0);
        totalCreditAmount = creditArray.reduce((total, item) => {
          const amount = parseInt(
            item?.credit?.name ? item?.credit?.amount : 0
          );
          return total + (isNaN(amount) ? 0 : amount);
        }, 0);

        break;
      // return <Bank />;
      case "discount":
        creditArray = discount.filter((item) => item.credit);
        debitArray = discount.filter((item) => item.debit);
        maxLength = Math.max(creditArray.length, debitArray.length);
        mainArray = { ...discount };

        totalDebitAmount = debitArray.reduce((total, item) => {
          const amount = parseInt(item?.debit?.name ? item?.debit?.amount : 0);
          return total + (isNaN(amount) ? 0 : amount);
        }, 0);
        totalCreditAmount = creditArray.reduce((total, item) => {
          const amount = parseInt(
            item?.credit?.name ? item?.credit?.amount : 0
          );
          return total + (isNaN(amount) ? 0 : amount);
        }, 0);

        break;
      // return <Discount />;
      case "abnormal":
        creditArray = abnormal.filter((item) => item.credit);
        debitArray = abnormal.filter((item) => item.debit);
        maxLength = Math.max(creditArray.length, debitArray.length);
        mainArray = { ...abnormal };

        totalDebitAmount = debitArray.reduce((total, item) => {
          const amount = parseInt(item.debit.name ? item?.debit?.amount : 0);
          return total + (isNaN(amount) ? 0 : amount);
        }, 0);
        totalCreditAmount = creditArray.reduce((total, item) => {
          const amount = parseInt(item.credit.name ? item?.credit?.amount : 0);
          return total + (isNaN(amount) ? 0 : amount);
        }, 0);

        break;
      // return <Abnormal />;

      default:
        console.log(supplierAccount);
        const optionData = supplierAccount.find((item) => {
          if (item.credit && item.credit.creditorName) {
            return (
              item.credit.creditorName.replace(/\s+/g, "").toLowerCase() ===
              selectedItem
            );
          }
          if (item.debit && item.debit.creditorName) {
            return (
              item.debit.creditorName.replace(/\s+/g, "").toLowerCase() ===
              selectedItem
            );
          }
          return false; // If neither credit nor debit object is present or their creditorName is undefined
        });

        console.log(optionData, "options");
        if (optionData) {
          creditArray = optionData.credit.particularName.map((ele) => ({
            name: ele.name,
            amount: ele.amount,
            createdAt: optionData.createdAt,
          }));

          debitArray = optionData.debit.particularName.map((ele) => ({
            name: ele.name,
            amount: ele.amount,
            createdAt: optionData.createdAt,
          }));

          maxLength = Math.max(creditArray.length, debitArray.length);

          console.log("credit", creditArray);
          console.log("debit", debitArray);

          mainArray = { ...bank };
          totalDebitAmount = debitArray.reduce((total, item) => {
            const amount = parseInt(item.name ? item.amount : 0);
            return total + (isNaN(amount) ? 0 : amount);
          }, 0);
          totalCreditAmount = creditArray.reduce((total, item) => {
            const amount = parseInt(item.name ? item.amount : 0);
            return total + (isNaN(amount) ? 0 : amount);
          }, 0);
        }
    }

    return (
      <div>
        <div className="container">
          {/* Ensure that the container has a class or style that sets its width */}
          <div className="flex justify-between w-full">
            {/* Check if the Debit and Credit components have any CSS styles that affect their width */}
            <Debit
              creditArray={creditArray}
              debitArray={debitArray}
              maxLength={maxLength}
              mainArray={mainArray}
              totalCreditAmount={totalCreditAmount}
              totalDebitAmount={totalDebitAmount}
            />
            <Credit
              creditArray={creditArray}
              debitArray={debitArray}
              maxLength={maxLength}
              mainArray={mainArray}
              totalCreditAmount={totalCreditAmount}
              totalDebitAmount={totalDebitAmount}
            />
          </div>
        </div>
      </div>
    );
  };

  const dataToShow = getDataToShow();

  return (
    <div>
      <div className="w-[300px] my-4">
        <Autocomplete
          options={newItems}
          getOptionLabel={(item) => {
            // console.log("getOptionLabel:", item);
            return item.label;
          }}
          renderInput={(params) => (
            <TextField {...params} label="Select an option" variant="filled" />
          )}
          onChange={handleOptionChange}
          sx={{ mt: 1 }}
        />
      </div>

      <div>{dataToShow}</div>
    </div>
  );
};

export default Ledger;

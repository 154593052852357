import { createSlice } from "@reduxjs/toolkit";
import {
  allJournal,
  deleteJournal,
  findJournal,
  getAllJournal,
} from "./journalAction";

const journalSlice = createSlice({
  name: "journal",

  initialState: {
    journals: [],
    isLoading: true,
    success: false,
    error: null,
    count: 0,
    journal: [],
  },

  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllJournal.pending, (state, action) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(getAllJournal.fulfilled, (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      state.success = action.payload.success;
      state.journals = action.payload.journal;
      state.count = action.payload.count;
    });
    builder.addCase(getAllJournal.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      // state.error = action.payload.error;
    });
    builder.addCase(deleteJournal.pending, (state, action) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(deleteJournal.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success;
    });
    builder.addCase(deleteJournal.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.error = action.payload.error;
    });
    builder.addCase(findJournal.pending, (state, action) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(findJournal.fulfilled, (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      state.success = action.payload.success;
      state.journal = action.payload.journal;
    });
    builder.addCase(findJournal.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.error = action.payload.error;
    });
    builder.addCase(allJournal.pending, (state, action) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(allJournal.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success;
      state.journal = action.payload.allJournal;
      state.count = action.payload.count;
    });
    builder.addCase(allJournal.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      // state.error = action.payload.error;
    });
  },
});

export const journalReducer = journalSlice.reducer;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addCategoryExpenses } from "../../../Redux/Feature/Expenses/expensesAction";
import Button from "../../../Components/Button/Button";

const AddCategoryExpenses = () => {
  const [expenseCategory, setExpenseCategory] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // New state for button disable

  const token = localStorage.getItem("NHHD:token");
  const dispatch = useDispatch();
  const submit = () => {
    if (expenseCategory === "") {
      toast.error("Feild cannot be null");
    } else {
      setIsButtonDisabled(true);
      dispatch(addCategoryExpenses({ token, expenseCategory })).then(() => {
        setIsButtonDisabled(false);
      });
    }
  };
  return (
    <>
      <div class="container mx-auto">
        <div class="lg:flex md:flex justify-center px-6 ">
          <div class="w-full lg:w-9/12 px-4">
            <div>
              <div
                className=" transition duration-150 ease-in-out z-10  "
                id="modal"
              >
                <div
                  role="alert"
                  className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
                >
                  <div className=" ">
                    <div className=" lg:flex md:flex justify-center">
                      <div className="border border-gray-100 w-auto h-auto bg-gray-200 py-4 px-3 rounded-[6px] ">
                        {/* <p className="p-4 text-[1.5rem] text-[green]">
                          Add category Expenses
                        </p> */}
                        <div className="lg:flex md:flex gap-3 my-2">
                          <input
                            type="text"
                            className="border py-3 rounded-md w-full h-full placeholder:px-2 px-2 lg:my-0 md:y-0 my-2"
                            placeholder="Enter Expenses Category"
                            value={expenseCategory}
                            onChange={(e) => setExpenseCategory(e.target.value)}
                          />
                        </div>

                        <div className="flex justify-center my-3 gap-3 ">
                          <Button
                            btnName={"Add"}
                            click={submit}
                            isLoading={isButtonDisabled}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCategoryExpenses;

import { createSlice } from "@reduxjs/toolkit";
import {
  dltProduct,
  fetchProduct,
  fetchProductById,
  filterProductByProductName,
  purchasePayCredit,
  purchaseProduct,
  updateExistingPoduct,
  updateProductQuantity,
} from "./productAction";

const productSlice = createSlice({
  name: "product",
  initialState: {
    error: null,
    data: [],
    isLoading: true,
    sucess: false,
    count: 0,
    singleProduct: [],
    updateProductList: [],
  },
  reducers: {},
  extraReducers: {
    [purchaseProduct.pending]: (state) => {
      state.error = null;
      state.isLoading = true;
      state.sucess = false;
    },
    [purchaseProduct.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [purchaseProduct.rejected]: (state, { payload }) => {
      state.error = payload.error;
      state.isLoading = true;
      state.sucess = false;
    },
    [updateExistingPoduct.pending]: (state) => {
      state.error = null;
      state.isLoading = true;
      state.sucess = false;
    },
    [updateExistingPoduct.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [updateExistingPoduct.rejected]: (state, { payload }) => {
      state.error = payload.error;
      state.isLoading = true;
      state.sucess = false;
    },

    [fetchProduct.pending]: (state, action) => {
      state.error = null;

      state.isLoading = true;
      state.sucess = false;
    },
    [fetchProduct.fulfilled]: (state, action) => {
      state.error = null;
      state.isLoading = false;
      state.data = action.payload.products;
      state.sucess = action.payload.success;
      state.count = action.payload.total;
    },
    [fetchProduct.rejected]: (state, { payload }) => {
      state.error = payload;

      state.isLoading = true;
    },

    [dltProduct.pending]: (state) => {
      state.error = null;

      state.isLoading = true;
      state.sucess = false;
    },
    [dltProduct.fulfilled]: (state, { payload }) => {
      state.error = null;

      state.isLoading = false;
      state.sucess = payload.sucess;
    },
    [dltProduct.rejected]: (state, { payload }) => {
      state.error = payload.error;
      state.data = null;
      state.isLoading = true;
      state.sucess = false;
    },

    [fetchProductById.pending]: (state, payload) => {
      state.error = null;

      state.isLoading = true;
      state.sucess = false;
    },
    [fetchProductById.fulfilled]: (state, action) => {
      console.log(action.payload);
      state.error = null;
      state.isLoading = false;
      state.sucess = action.payload;
      state.singleProduct = action.payload.product;
    },
    [fetchProductById.rejected]: (state, { payload }) => {
      state.error = payload.error;
      state.data = null;
      state.isLoading = true;
      state.sucess = false;
    },
    [filterProductByProductName.pending]: (state, action) => {
      state.error = null;

      state.isLoading = true;
      state.sucess = false;
    },
    [filterProductByProductName.fulfilled]: (state, action) => {
      console.log(action.payload, "hhh");
      state.error = null;
      state.isLoading = false;
      state.data = action.payload.products;
      state.sucess = action.payload;
      state.count = action.payload;
    },
    [filterProductByProductName.rejected]: (state, { payload }) => {
      state.error = payload;

      state.isLoading = true;
    },

    [updateProductQuantity.pending]: (state) => {
      state.error = null;
      state.isLoading = true;
      state.sucess = false;
    },
    [updateProductQuantity.fulfilled]: (state, action) => {
      state.error = null;
      state.success = action.payload.success;
      state.isLoading = false;
    },
    [updateProductQuantity.rejected]: (state) => {
      state.error = null;
      state.isLoading = true;
      state.sucess = false;
    },
    [purchasePayCredit.pending]: (state) => {
      state.error = null;
      state.isLoading = true;
      state.sucess = false;
    },
    [purchasePayCredit.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [purchasePayCredit.rejected]: (state, { payload }) => {
      state.error = payload.error;
      state.isLoading = true;
      state.sucess = false;
    },
  },
});

export const productReducer = productSlice.reducer;

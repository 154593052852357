import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../../../Components/Spinner/Spinner";
import { Link } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { fetchAssestSale } from "../../../Redux/Feature/Assest/assestAction";

const SoldAssestList = () => {
  const { assestSale, isLoading } = useSelector((state) => state.assest);
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetch() {
      dispatch(fetchAssestSale());
    }
    fetch();
  }, [dispatch]);
  console.log(assestSale);
  return (
    <>
      {" "}
      <div class="flex flex-col">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div class="overflow-hidden border border-gray-200   md:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200  ">
                <thead class="bg-gray-50 ">
                  <tr>
                    {th.map((item, i) => {
                      return (
                        <th
                          scope="col col-span-5"
                          key={i}
                          class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                        >
                          {item.name}
                        </th>
                      );
                    })}
                    <th
                      scope="col col-span-5"
                      class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                {isLoading === true ? (
                  <Spinner />
                ) : (
                  <tbody class="bg-white divide-y divide-gray-200">
                    {assestSale.length === 0 ? (
                      <p className="p-1 py-2">No Data Found!!</p>
                    ) : (
                      assestSale.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                              <div class="inline-flex items-center gap-x-3">
                                <span>{i + 1}</span>
                              </div>
                            </td>
                            <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap ">
                              <div class="flex items-center gap-x-2">
                                <Link to={`${item._id}`}>
                                  <div className="flex gap-2">
                                    <img
                                      src={
                                        item.assestId && item.assestId.image.url
                                      }
                                      alt={
                                        item.assestId &&
                                        item.assestId.assestName
                                      }
                                      className="w-[60px] h-[60px] rounded-full"
                                    />
                                    <h2 class=" capitalize   text-md font-medium text-gray-800 pt-5     ">
                                      {item.assestId &&
                                        item.assestId.assestName}
                                    </h2>
                                  </div>
                                </Link>
                              </div>
                            </td>

                            <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                              <div class="flex items-center gap-x-2">
                                <div>
                                  <h2 class="text-sm capitalize font-medium text-gray-800   ">
                                    Rs. {item.price}
                                  </h2>
                                </div>
                              </div>
                            </td>
                            <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                              <div class="flex items-center gap-x-2">
                                <div>
                                  <h2
                                    class={`text-sm   font-medium uppercase  ${
                                      item.assessmentMethod === "Credit"
                                        ? "text-white  border bg-red-500 p-2 rounded-md font-bold "
                                        : item.assessmentMethod === "No Credit"
                                        ? "text-white  border bg-blue-500 p-2 rounded-md font-bold"
                                        : item.assessmentMethod ===
                                          "Partial Credit"
                                        ? "text-white  border bg-green-500 p-2 rounded-md font-bold "
                                        : null
                                    }`}
                                  >
                                    Rs. {item.remainingToPay}
                                  </h2>
                                </div>
                              </div>
                            </td>
                            <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                              <div class="flex items-center gap-x-2">
                                <div>
                                  <h2 class="text-sm capitalize font-medium text-gray-800   ">
                                    {new Date(item.soldDate).toDateString()}
                                  </h2>
                                </div>
                              </div>
                            </td>
                            <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                              <div class="flex items-center gap-x-2">
                                <div>
                                  <h2
                                    class={`text-sm   font-medium uppercase  ${
                                      item.assessmentMethod === "Credit"
                                        ? "text-white  border bg-red-500 p-2 rounded-md font-bold "
                                        : item.assessmentMethod === "No Credit"
                                        ? "text-white  border bg-blue-500 p-2 rounded-md font-bold"
                                        : item.assessmentMethod ===
                                          "Partial Credit"
                                        ? "text-white  border bg-green-500 p-2 rounded-md font-bold "
                                        : null
                                    }`}
                                  >
                                    {item.remainingToPay === 0
                                      ? "Full Paid"
                                      : item.assessmentMethod}
                                  </h2>
                                </div>
                              </div>
                            </td>
                            <td class=" py-4 text-sm whitespace-nowrap">
                              <div class="flex items-center gap-x-6">
                                <button class="  transition-colors duration-200 text-red-500      focus:outline-none">
                                  <DeleteForeverIcon />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const th = [
  {
    name: "SN.",
  },
  {
    name: "Assest Name",
  },

  {
    name: "Assest Price",
  },
  {
    name: "Remaing to recive",
  },
  {
    name: "Assest Date",
  },
  {
    name: "Assessment Method",
  },
];

export default SoldAssestList;

import React from "react";

import Tittle from "../../../Components/Tittle/Tittle";
import BasicModal from "../../../Components/Modal/Modal";
import Button from "../../../Components/Button/Button";
// import ExpensesCategoryList from "./expensesCategoryList";
import AddInventeryCategory from "./AddInventeryCategory";
import InventoryCategoryList from "./InventeryCategoryList";

const index = () => {
  return (
    <>
      <div>
        <Tittle tittle={"Add Inventory Loss Category"} />
        <BasicModal
          btn={<Button btnName={"Add Inventery Loss Category"} />}
          tittle={"Add Inventery Loss Category"}
          modalDetails={<AddInventeryCategory />}
        />
        <div className="mt-[2rem]">
          <InventoryCategoryList />
        </div>
      </div>
    </>
  );
};

export default index;

import React from "react";
import Tittle from "../../Components/Tittle/Tittle";
import BasicModal from "../../Components/Modal/Modal";
import Button from "../../Components/Button/Button";
import AddInventery from "./AddInventery";
import InventoryList from "./InventoryList";

function index() {
  return (
    <>
      <div>
        <Tittle tittle={"Inventery Loss Expenses"} />
        <div className="flex gap-2">
          <BasicModal
            btn={<Button btnName={"Add Inventery Expenses"} />}
            tittle={"Add Inventery Expenses"}
            modalDetails={<AddInventery />}
          />{" "}
        </div>

        <div className="mt-[2rem]">
          <InventoryList />
        </div>
      </div>
    </>
  );
}

export default index;

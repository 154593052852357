import { Container } from "@mui/material";
import TotalCount from "./TotalCount";

import CategoryCard from "./Card/CategoryCard";
import SupplierCard from "./Card/SupplierCard";
import SaleCard from "./Card/SaleCard";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getDashboard } from "../../Redux/Feature/dashboard/dashboardAction";
import Tittle from "../../Components/Tittle/Tittle";
import { useSelector } from "react-redux";
import { ImNotification } from "react-icons/im";

const Home = () => {
  const token = localStorage.getItem("NHHD:token");
  const dispatch = useDispatch();
  useEffect(() => {
    const fetch = async () => {
      dispatch(getDashboard({ token }));
    };
    fetch();
  }, [dispatch, token]);

  const { maskiBariNotification } = useSelector((state) => state.dashboard);
  console.log(maskiBariNotification);

  return (
    <p>
      {/* <Scrollbars style={{ height: 852, width: 0 }}> */}
      <Container>
        <Tittle tittle={"dashboard"} />
        {maskiBariNotification && (
          <div className="flex justify-start">
            {" "}
            <p className="border bg-red-300 w-auto p-3 text-center rounded text-gray-800 font-[500] flex">
              <ImNotification className="my-1 text-xl mx-2" />
              <span>{maskiBariNotification}</span>
            </p>
          </div>
        )}

        <TotalCount />
        <div className="grid grid-flow-row lg:grid-cols-3 md:grid-cols-2">
          {/* <ProductCard /> */}
          <SupplierCard />
          <SaleCard />
          <CategoryCard />
        </div>
        {/* <Graph /> */}
      </Container>
      {/* </Scrollbars> */}
    </p>
  );
};

export default Home;

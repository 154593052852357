import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  filterSupplier,
  getSupplier,
} from "../../Redux/Feature/supplier/supplierAction";
import { Spinner } from "../../Components/Spinner/Spinner";
import Pagination from "./Pagination";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const SuppliersList = () => {
  const token = localStorage.getItem("NHHD:token");
  const { isLoading, supplies, count } = useSelector((state) => state.supplier);
  // let deleteButtonClicked = false;

  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    async function fetch() {
      dispatch(getSupplier({ token }));
    }
    fetch();
  }, [dispatch, token]);

  // const deleteSupplier = (_id) => {
  //   dispatch(dltSupplier({ id: _id, token }));
  // };

  const [supplierEmail, setupplierEmail] = useState("");
  useEffect(() => {
    dispatch(filterSupplier({ token, supplierEmail }));
  }, [dispatch, token, supplierEmail]);
  console.log(supplies);

  return (
    <>
      {/* filter-start */}

      <div class="relative mx-3 my-5">
        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <svg
            class="w-5 h-5 text-gray-500 "
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
        </div>
        <input
          type="search"
          value={supplierEmail}
          onChange={(e) => setupplierEmail(e.target.value)}
          id="default-search"
          class="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-green-500 focus:border-green-500   "
          placeholder="Search Email Address..."
          required
        />
      </div>

      {/* filter-end */}
      <section class="container px-4 mx-auto">
        <div class="flex flex-col">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div class="overflow-hidden border border-gray-200 md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      {th.map((item, i) => {
                        return (
                          <th
                            key={i}
                            class="  py-3.5  px-3 text-sm font-normal text-left rtl:text-right text-gray-500"
                          >
                            {item.name}
                          </th>
                        );
                      })}
                      {/* <th
                        scope="col col-span-5"
                        class=" py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500"
                      >
                        Action
                      </th> */}
                    </tr>
                  </thead>
                  {isLoading === true ? (
                    <Spinner />
                  ) : (
                    <tbody class="bg-white divide-y divide-gray-200    ">
                      {supplies?.length === 0 ? (
                        <p className="p-1 py-2">No Data Found!!</p>
                      ) : (
                        supplies?.slice(0, 20).map((item, i) => {
                          console.log(item.inventoryDetails.length < 0);
                          return (
                            <tr key={i}>
                              <td class=" py-4 text-sm font-medium  text-black   whitespace-nowrap">
                                <div class="inline-flex items-center gap-x-3 px-3">
                                  <span>{i + 1}</span>
                                </div>
                              </td>
                              <td
                                class=" py-4 text-sm text-gray-500   whitespace-nowrap  cursor-pointer"
                                onClick={() => {
                                  if (item.inventoryDetails.length > 0) {
                                    navigate(`${item._id}`, {
                                      state: { item: item },
                                    });
                                  } else {
                                    toast.info(
                                      "Please purchase product from this supplier"
                                    );
                                  }
                                }}
                              >
                                <p
                                  className={`px-3 capitalize py-1 text-xl font-[400] text-green-600  
                                     `}
                                >
                                  {item.supplierName}
                                </p>
                                <p className="px-3">
                                  <span>{item.registrationType} : </span> &nbsp;
                                  <span>{item.registrationNumber}</span>
                                </p>
                              </td>
                              <td class=" py-4 text-sm font-medium   whitespace-nowrap cursor-not-allowed">
                                <div className="px-3">
                                  <span class="text-sm font-normal">
                                    ( {item.countryCode}) &nbsp;
                                  </span>
                                  <span class="text-sm font-normal">
                                    {item.supplierContact}
                                  </span>
                                  <p class="text-sm font-normal py-1">
                                    {item.supplierEmail}
                                  </p>
                                </div>
                              </td>
                              <td class=" py-4 text-sm font-medium   whitespace-nowrap cursor-not-allowed">
                                <div>
                                  <span class="text-sm font-normal">
                                    ({item.country}) &nbsp;
                                  </span>
                                  <span class="text-sm font-normal">
                                    {item.supplierLocation}
                                  </span>
                                </div>
                              </td>

                              {/* <td class=" py-4 text-sm whitespace-nowrap">
                                <div class="flex items-center gap-x-6">
                                  <button
                                    class="  transition-colors duration-200 text-red-500      focus:outline-none"
                                    onClick={() => {
                                      deleteButtonClicked = true;
                                      deleteSupplier(item._id);
                                    }}
                                  >
                                    <DeleteForeverIcon />
                                  </button>
                                </div>
                              </td> */}
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
        <Pagination page={1} count={count} />
      </section>
    </>
  );
};

const th = [
  {
    name: "SN.",
  },
  {
    name: "Supplier Name",
  },
  {
    name: "Supplier Contact",
  },
  {
    name: "Supplier Loaction",
  },
];
export default SuppliersList;

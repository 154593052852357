import React from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";

const BreadCrumb = ({ name, subName }) => {
  return (
    <>
      <div className="flex gap-2  my-2">
        <Link to="/ ">
          <p>Home</p>{" "}
        </Link>

        <span className="text-[1.3rem] py-[2px]">
          <MdOutlineKeyboardArrowRight />
        </span>
        <Link to={`/${name}`}>
          <p>{name}</p>
        </Link>
        <span className="text-[1.3rem] py-[2px]">
          <MdOutlineKeyboardArrowRight />
        </span>
        <p>{subName}</p>
      </div>
      <hr />
    </>
  );
};

export default BreadCrumb;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addAssestSale } from "../../../Redux/Feature/Assest/assestAction";

import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";

const AssestToSale = () => {
  const { assests, assestSale } = useSelector((state) => state.assest);
  const [assestId, setAssestId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [price, setPrice] = useState("");
  const [soldDate, setSoldDate] = useState("");
  const [transctionType, setTransctionType] = useState("");
  const [partial_bank, setPartial_bank] = useState("");
  const [partial_cash, setPartial_cash] = useState("");
  const [assessmentMethod, setAssessmentMethod] = useState("");
  const [partial_Credit, setPartial_Credit] = useState("");
  const [partial_Amount, setPartial_Amount] = useState("");

  const [forTransaction, setForTransaction] = useState("");
  const [credit, setCredit] = useState("");
  const [cash, setCash] = useState("");
  const [bank_amount, setBank_amount] = useState("");
  const [cash_amount, setCash_amount] = useState("");

  const [both_Cash_amount, setBoth_Cash_amount] = useState("");
  const [both_Bank_amount, setBoth_Bank_amount] = useState("");

  const [billNo, setBillNo] = useState("");

  const dispatch = useDispatch();

  const monthInBS = parseInt(soldDate.slice(5, 7)) - 1;
  const yearInBS = soldDate.slice(0, 4);

  const currentMonthInBS = monthOrder[monthInBS];

  const saleAssestMonth = currentMonthInBS + "-" + yearInBS;

  const submit = () => {
    if (
      assestId === "" ||
      soldDate === "" ||
      saleAssestMonth === "" ||
      price === "" ||
      customerName === ""
    )
      return toast.error("Some Feilds are missing");
    dispatch(
      addAssestSale({
        assestId,
        customerName,
        price,
        soldDate,
        transctionType,
        partial_bank,
        partial_cash,
        assessmentMethod,
        partial_Credit,
        partial_Amount,
        saleAssestMonth,
      })
    );
  };

  const filterId = assests.filter(
    (objAssest) =>
      !assestSale.some(
        (objAssestSale) =>
          objAssestSale.assestId.toString() === objAssest._id.toString()
      )
  );

  console.log(filterId);

  return (
    <>
      <div class="container mx-auto">
        <div class="lg:flex md:flex justify-center px-6 ">
          <div class="w-full lg:w-9/12 px-4">
            <div>
              <div
                className=" transition duration-150 ease-in-out z-10  "
                id="modal"
              >
                <div
                  role="alert"
                  className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
                >
                  <div className=" ">
                    <div className=" lg:flex md:flex justify-center">
                      <div className="border border-gray-100 w-auto h-auto bg-gray-200 py-4 px-3 rounded-[6px] ">
                        <div className="flex">
                          <div className=" items-center">
                            <p className="text-red-500 px-3">
                              Please Select the Date
                            </p>

                            <NepaliDatePicker
                              className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0   "
                              value={soldDate}
                              onChange={(value) => setSoldDate(value)}
                              options={{
                                calenderLocale: "ne",
                                valueLocale: "en",
                              }}
                            />
                          </div>
                          <div>
                            <p className="uppercase text-green-800 py-1">
                              Bill No.
                            </p>
                            <input
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              value={billNo}
                              onChange={(e) => setBillNo(e.target.value)}
                              className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full"
                              placeholder="Enter Bill Number"
                            />
                          </div>
                        </div>
                        <div className="lg:flex md:flex gap-3 my-2">
                          <select
                            className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                            value={assestId}
                            onChange={(e) => setAssestId(e.target.value)}
                          >
                            <option>Select Assest</option>
                            {assests.map((ele) => {
                              return ele.remainingToPay !== 0 ? (
                                <option value={ele._id}>
                                  <p className="flex ">
                                    <span>{ele.assestName}</span>

                                    <img
                                      src={ele.image.url}
                                      alt={ele.assestName}
                                      className="w-[100px] h-[100px] "
                                    />
                                  </p>
                                </option>
                              ) : null;
                            })}
                          </select>
                        </div>
                        <div className="lg:flex md:flex gap-3 ">
                          <input
                            type="text"
                            className="border py-3 rounded-md w-full h-full placeholder:px-2 px-2"
                            placeholder="Enter Customer Name"
                            value={customerName}
                            onChange={(e) => setCustomerName(e.target.value)}
                          />
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            className="border py-3 rounded-md w-full h-full placeholder:px-2 px-2"
                            placeholder="Enter  Sold Price"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                          />
                        </div>
                        <div>
                          <p className="uppercase opacity-30 pt-3">Credit</p>
                          <div className="mb-4">
                            <select
                              className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                              value={assessmentMethod}
                              onChange={(e) =>
                                setAssessmentMethod(e.target.value)
                              }
                            >
                              <option>Select Credit Type</option>
                              <option>No Credit</option>
                              <option>Credit</option>
                              <option>Partial Credit</option>
                            </select>
                          </div>
                          {assessmentMethod === "No Credit" ? (
                            <input
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              disabled
                              value={price}
                              onChange={(e) => {
                                setCash(price);
                                console.log(price, "here");
                              }}
                              placeholder="Enter Cash Amount"
                              className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                            />
                          ) : assessmentMethod === "Credit" ? (
                            <input
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              disabled
                              value={price}
                              onChange={(e) => setCredit(e.target.value)}
                              placeholder="Enter Credit Amount"
                              className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                            />
                          ) : assessmentMethod === "Partial Credit" ? (
                            <div className="lg:flex md:flex gap-3 my-2">
                              {/* Input field for Partial Cash */}
                              <div>
                                <label
                                  htmlFor="partialCashInput"
                                  className="block mb-1"
                                >
                                  Payment:
                                </label>
                                <input
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  id="partialCashInput"
                                  value={partial_cash}
                                  onChange={(e) => {
                                    setPartial_cash(e.target.value);
                                    setForTransaction(e.target.value);
                                    setBank_amount(e.target.value);
                                    setCash_amount(e.target.value);
                                    // Recalculate partial credit when partial cash changes
                                    const calculatedPartialCredit =
                                      price - e.target.value;
                                    setPartial_Credit(calculatedPartialCredit);
                                  }}
                                  placeholder="Enter the Amount"
                                  className="border rounded-md px-2 py-3 my-2 lg:my-0 md:my-0"
                                />
                              </div>

                              {/* Display the calculated Partial Credit Amount */}
                              <div>
                                <label
                                  htmlFor="partialCreditInput"
                                  className="block mb-1"
                                >
                                  Credit:
                                </label>
                                <input
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  id="partialCreditInput"
                                  value={partial_Credit}
                                  readOnly
                                  placeholder="Partial Credit Amount"
                                  className="border rounded-md px-2 py-3 my-2 lg:my-0 md:my-0"
                                />
                              </div>
                            </div>
                          ) : null}
                        </div>

                        {assessmentMethod === "No Credit" ? (
                          <>
                            <p className="uppercase opacity-30 pt-3">
                              Transaction{" "}
                            </p>
                            <div className="lg:flex md:flex gap-3  my-4">
                              <select
                                className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                                name="transictionType"
                                value={transctionType}
                                onChange={(e) =>
                                  setTransctionType(e.target.value)
                                }
                              >
                                <option>Select Transaction Type</option>
                                {caseType.map((item, i) => {
                                  return <option>{item.TypeName}</option>;
                                })}
                              </select>

                              <div>
                                <p>
                                  {transctionType === "Bank" ? (
                                    <input
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      readOnly
                                      value={price}
                                      // value={bank_amount}
                                      onChange={(e) => {
                                        console.log(e.target.value);
                                        setBank_amount(e.target.value);
                                      }}
                                      placeholder="Enter BANK Amount"
                                      className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                                    />
                                  ) : transctionType === "Cash" ? (
                                    <input
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      readOnly
                                      value={price}
                                      // value={cash_amount}
                                      onChange={(e) => {
                                        setCash_amount(e.target.value);
                                      }}
                                      placeholder="Enter CASH Amount"
                                      className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                                    />
                                  ) : transctionType === "Both" ? (
                                    <>
                                      <div className="">
                                        <input
                                          type="number"
                                          onWheel={(e) => e.target.blur()}
                                          value={both_Bank_amount}
                                          onChange={(e) => {
                                            setBoth_Bank_amount(e.target.value);
                                            console.log(e.target.value);
                                            const calculatedCash =
                                              price - e.target.value;
                                            console.log(
                                              calculatedCash,
                                              "calculated cash"
                                            );
                                            setBoth_Cash_amount(calculatedCash);
                                          }}
                                          placeholder="Enter  BANK Amount"
                                          className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                                        />
                                      </div>
                                      <div className="my-2">
                                        {" "}
                                        <input
                                          type="number"
                                          onWheel={(e) => e.target.blur()}
                                          value={both_Cash_amount}
                                          readOnly
                                          placeholder="Enter  CASH Amount"
                                          className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                                        />
                                      </div>
                                    </>
                                  ) : null}
                                </p>
                              </div>
                            </div>
                          </>
                        ) : assessmentMethod === "Partial Credit" ? (
                          <>
                            <div className="lg:flex md:flex gap-3  my-4">
                              <select
                                className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                                name="transictionType"
                                value={transctionType}
                                onChange={(e) =>
                                  setTransctionType(e.target.value)
                                }
                              >
                                <option>Select Transaction Type</option>
                                {caseType.map((item, i) => {
                                  return <option>{item.TypeName}</option>;
                                })}
                              </select>

                              <div>
                                <p>
                                  {transctionType === "Bank" ? (
                                    <input
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      readOnly
                                      value={bank_amount}
                                      // onChange={(e) => setBank_amount(e.target.value)}
                                      placeholder="Enter BANK Amount"
                                      className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                                    />
                                  ) : transctionType === "Cash" ? (
                                    <input
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      readOnly
                                      value={cash_amount}
                                      // onChange={(e) => setCash_amount(e.target.value)}
                                      placeholder="Enter CASH Amount"
                                      className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                                    />
                                  ) : transctionType === "Both" ? (
                                    <>
                                      <div className="flex ">
                                        <input
                                          type="number"
                                          onWheel={(e) => e.target.blur()}
                                          value={both_Bank_amount}
                                          onChange={(e) => {
                                            setBoth_Bank_amount(e.target.value);
                                            const calculatedCash =
                                              forTransaction - e.target.value;
                                            console.log(
                                              calculatedCash,
                                              "calculated cash"
                                            );
                                            setBoth_Cash_amount(calculatedCash);
                                          }}
                                          placeholder="Enter  BANK Amount"
                                          className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                                        />
                                      </div>
                                      <div className="my-2">
                                        <input
                                          type="number"
                                          onWheel={(e) => e.target.blur()}
                                          value={both_Cash_amount}
                                          placeholder="Enter  CASH Amount"
                                          className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                                        />
                                      </div>
                                    </>
                                  ) : null}
                                </p>
                              </div>
                            </div>
                          </>
                        ) : null}
                        {/* <>
                          <div>
                            <p className="uppercase opacity-30 pt-3">Credit</p>
                            <div className="mb-4">
                              <select
                                className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                                value={assessmentMethod}
                                onChange={(e) =>
                                  setAssessmentMethod(e.target.value)
                                }
                              >
                                <option>Select Credit Type</option>
                                <option>No Credit</option>
                                <option>Credit</option>
                                <option>Partial Credit</option>
                              </select>
                            </div>

                            {assessmentMethod === "No Credit" ? (
                              <input
                                type="number" onWheel={(e) => e.target.blur()}
                                disabled
                                value={price}
                                onChange={(e) => setCash(e.target.value)}
                                placeholder="Enter Cash Amount"
                                className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                              />
                            ) : assessmentMethod === "Credit" ? (
                              <input
                                type="number" onWheel={(e) => e.target.blur()}
                                disabled
                                value={price}
                                onChange={(e) => setCredit(e.target.value)}
                                placeholder="Enter Credit Amount"
                                className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                              />
                            ) : (
                              assessmentMethod === "Partial Credit" && (
                                <div className="lg:flex md:flex gap-3 my-2 ">
                                  <div>
                                    <input
                                      type="number" onWheel={(e) => e.target.blur()}
                                      value={partial_Amount}
                                      onChange={(e) => {
                                        setPartial_Amount(e.target.value);
                                        setForTransaction(e.target.value);
                                        setBank_amount(e.target.value);
                                        setCash_amount(e.target.value);
                                        // Recalculate partial credit when partial cash changes
                                        const calculatedPartialCredit =
                                          price - e.target.value;
                                        setPartial_Credit(
                                          calculatedPartialCredit
                                        );
                                      }}
                                      placeholder="Enter Cash Amount"
                                      className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 w-full"
                                    />
                                  </div>
                                  <div>
                                    <input
                                      type="number" onWheel={(e) => e.target.blur()}
                                      value={partial_Credit}
                                      placeholder="Enter Credit Amount"
                                      className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 w-full"
                                    />
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </>
                        {assessmentMethod === "Credit" ? null : (
                          <>
                            <p className="uppercase opacity-30 pt-1">
                              Transaction{" "}
                            </p>
                            <div className="  my-2">
                              <select
                                className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                                name="transictionType"
                                value={transctionType}
                                onChange={(e) =>
                                  setTransctionType(e.target.value)
                                }
                              >
                                <option>Select Transiction Type</option>
                                {caseType.map((item, i) => {
                                  return <option>{item.TypeName}</option>;
                                })}
                              </select>

                              <div className="flex gap-2 my-2">
                                {transctionType === "Bank" ? (
                                  <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    readOnly
                                    value={cash}
                                    // onChange={(e) => setBank_amount(e.target.value)}
                                    placeholder="Enter BANK Amount"
                                    className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                                  />
                                ) : transctionType === "Cash" ? (
                                  <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    readOnly
                                    value={cash}
                                    // onChange={(e) => setCash_amount(e.target.value)}
                                    placeholder="Enter CASH Amount"
                                    className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                                  />
                                ) : (
                                  transctionType === "Both" && (
                                    <>
                                      <div className="">
                                        <input
                                          type="number" onWheel={(e) => e.target.blur()}
                                          value={partial_bank}
                                          onChange={(e) => {
                                            setPartial_bank(e.target.value);
                                            console.log(forTransaction, "here");
                                            const calculatedCash =
                                              forTransaction - e.target.value;
                                            console.log(
                                              calculatedCash,
                                              "calculated cash"
                                            );
                                            setPartial_cash(calculatedCash);
                                          }}
                                          placeholder="Enter  BANK Amount"
                                          className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0  w-full"
                                        />
                                      </div>
                                      <div className="">
                                        {" "}
                                        <input
                                          type="number" onWheel={(e) => e.target.blur()}
                                          value={partial_cash}
                                          placeholder="Enter  CASH Amount"
                                          className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 w-full"
                                        />
                                      </div>
                                    </>
                                  )
                                )}
                              </div>
                            </div>
                          </>
                        )} */}

                        <p
                          className="border bg-green-700 w-[200px] text-center text-white font-[500] text-xl py-2 px-3 rounded-[6px] my-4 uppercase  cursor-pointer"
                          onClick={submit}
                        >
                          Add Now
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const caseType = [
  {
    TypeName: "Cash ",
  },
  {
    TypeName: "Bank ",
  },
  {
    TypeName: "Both ",
  },
];

const monthOrder = [
  "बैशाख",
  "जेठ",
  "असार",
  "श्रावण",
  "भाद्र",
  "असोज",
  "कार्तिक",
  "मंसिर",
  "पुष",
  "माघ",
  "फागुन",
  "चैत्र",
];
export default AssestToSale;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../utils/API";

export const getSalesAccount = createAsyncThunk(
  "get/sales-account",
  async ({ token }) => {
    try {
      const response = await api.get(`/sale-account/all-sales-account`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const getSalesAccountByMonth = createAsyncThunk(
  "get/sales-account/month",
  async ({ token, selectedItem }) => {
    console.log(selectedItem, "gello");
    try {
      const response = await api.get(
        `/sale-account/sale-account-month?month=${selectedItem}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

import React from "react";
import { useSelector } from "react-redux";

const PurchaseReturn = ({ single }) => {
  const { data } = useSelector((state) => state.products);

  // Extract product details from purchaseReturnDetails
  const purchaseReturnDetails = single?.purchaseReturnDetails || [];
  // const productDetails = purchaseReturnDetails.map(
  //   (item) => item.productDetails
  // );
  const flattenedProductDetails = purchaseReturnDetails
    .map((item) => item.details)
    .flat();

  // console.log(single, "single");

  // const defactProductDetails = single?.purchaseReturnDetails.map(
  //   (item) => item?.defactProductDetails?.details?.$each
  // );
  // const flattenedDefactDetails = [].concat(...defactProductDetails);

  // console.log(purchaseReturnDetails, "kkkk");

  const productMap = new Map();
  data?.forEach((product) => {
    product?.purchaseFrom?.forEach((purchaseInfo) => {
      productMap.set(
        purchaseInfo._id,
        purchaseInfo.productName,
        purchaseInfo.productPrice,
        purchaseInfo.productQuantity
      );
    });
  });

  // console.log(productMap, "product map");

  const extractedProductNames = [];

  flattenedProductDetails?.forEach((item) => {
    const productName = productMap.get(item.productId);
    // const productPrice = productMap.get(item.productPrice);
    // const productQuantity = productMap.get(item.productQuantity);
    if (
      productName
      // || productPrice || productQuantity
    ) {
      extractedProductNames.push(
        productName
        // , productPrice, productQuantity
      );
    }
  });

  function addProductNameToDetails(productDetails, productMap) {
    for (const productDetail of productDetails) {
      const details = productDetail.details;
      for (const detail of details) {
        const productId = detail.productId;
        if (productMap.has(productId)) {
          detail.productName = productMap.get(productId);
        }
      }
    }
  }

  // Call the function to add productName to productDetails
  addProductNameToDetails(purchaseReturnDetails, productMap);

  // Now purchaseReturnDetails will contain productName for each detail

  console.log(purchaseReturnDetails);

  return (
    <>
      <section class="container px-1 mx-auto">
        {/* <div className="grid md:grid-cols-3 lg:grid-cols-3 grid-flow-row">
          <p>
            Total Credit Amount :
            <span className="text-xl text-green-800 font-semibold">
              Rs.{totalCreditAmount}
            </span>
          </p>
          <p>
            Total Credit Transaction :
            <span className="text-xl text-green-800 font-semibold">
              Rs.{totalCreditTransactionAmount}
            </span>
          </p>
          <p>
            Total Purchase Amount :
            <span className="text-xl text-green-800 font-semibold">
              Rs.{totalPurchaseAmount}
            </span>
          </p>
        </div> */}

        <div class="flex flex-col">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-2 lg:px-2">
              <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200  ">
                  <thead class="bg-gray-50  ">
                    <tr>
                      {th.map((item, i) => {
                        return (
                          <th
                            scope="col col-span-5"
                            key={i}
                            class="px-3 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                          >
                            {item.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    {purchaseReturnDetails.map((details, i) => {
                      // const amountWithoutVatAndDiscount =
                      //   details.details.reduce((total, ele) => {
                      //     const subTotal1 = ele.subTotal;
                      //     return total + Number(subTotal1);
                      //   }, 0);

                      return (
                        <tr key={i}>
                          <td class="px-1 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                            <div class="inline-flex items-center">
                              <span>{details.returnDate}</span>
                            </div>
                          </td>

                          <td class="px-1 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                            <div class="flex flex-col">
                              {details.details.map((ele) => (
                                <span className="pt-3 px-2">
                                  {ele.length > 1
                                    ? `${ele.productName} ,`
                                    : ele.productName}
                                </span>
                              ))}
                            </div>
                          </td>
                          <td class="px-3 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                            <div class="flex flex-col">
                              {details.details.map((ele) => (
                                <span className="pt-3 px-2">
                                  {ele.length > 1
                                    ? `${ele.productPrice} ,`
                                    : ele.productPrice}
                                </span>
                              ))}
                            </div>
                          </td>
                          <td class="px-3 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                            <div class="flex flex-col">
                              {details.details.map((ele) => (
                                <span className="pt-3 px-2">
                                  {ele.length > 1
                                    ? `${ele.productQuantity} ,`
                                    : ele.productQuantity}
                                </span>
                              ))}
                            </div>
                          </td>
                          <td class="px-3 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                            <div class="flex flex-col">
                              {details.details.map((ele) => (
                                <span className="pt-3 px-2">
                                  {ele.length > 1
                                    ? `${ele.subTotal}  ,`
                                    : ele.subTotal}
                                </span>
                              ))}
                            </div>
                          </td>

                          <td class="px-1 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                            <div class="inline-flex items-center">
                              <span>{details.amountExcludeVat}</span>
                            </div>
                          </td>
                          <td class="px-1 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                            <div class="inline-flex items-center">
                              <span>{details.vatAmount}</span>
                            </div>
                          </td>
                          <td class="px-1 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                            <div class="inline-flex items-center">
                              <span>{details.amountWithVat}</span>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
// discount

const th = [
  {
    name: "Date",
  },

  {
    name: "Product Name",
  },
  {
    name: "Unit Price",
  },
  {
    name: "Quantity",
  },
  {
    name: "Sub Total",
  },
  {
    name: "Amount Without VAT ",
  },
  // {
  //   name: "Amount Without VAT",
  // },
  {
    name: "VAT Amount ",
  },
  {
    name: "Total Amount ",
  },
];

export default PurchaseReturn;

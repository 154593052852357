import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../utils/API";
import { toast } from "react-toastify";

export const addSalary = createAsyncThunk("post/salary", async (payload) => {
  try {
    const response = await api.post("/salary/add-salary", payload, {
      headers: {
        Authorization: `Bearer ${payload.token}`,
      },
    });
    console.log(response.data._id);

    if (response.data.success === true) {
      toast.success(response.data.message);
      payload.navigate(`/salary`);
    }

    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const updateSalary = createAsyncThunk(
  "update/salary",
  async (payload) => {
    console.log(payload);
    try {
      const response = await api.post("/salary/update-salary", payload, {
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
      });
      if (response.data.success === true) {
        toast.success(response.data.message);
        payload.navigate("/salary");
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getSingleSalary = createAsyncThunk(
  "single/salary",
  async (payload) => {
    try {
      const response = await api.post(`/salary/single-salary`, payload, {
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const filtersalaryByStatus = createAsyncThunk(
  "/filter/salary/status",
  async ({ status, token }) => {
    try {
      const response = await api.get(
        `/salary/search-salary-status?status=${status}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const filtersalaryByEmployeeId = createAsyncThunk(
  "/filter/salary/employeeId",
  async ({ employeeId, token }) => {
    try {
      const response = await api.post(
        `/salary/salary-employeeId`,
        { employeeId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const filtersalaryByPaidDate = createAsyncThunk(
  "/filter/salary/paidate",
  async ({ startDate, endDate, token }) => {
    try {
      const response = await api.post(
        `/salary/salary-by-salaryDate`,
        { startDate, endDate },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

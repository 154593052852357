import { createSlice } from "@reduxjs/toolkit";
import { addBadDebttoRecovery, addtoBadDebt } from "./BadDebtAction";

const badDebtebtSlice = createSlice({
  name: "badbebt",
  initialState: {
    isLoading: true,
    success: false,
    badDebtData: [],
  },
  extraReducers: (builder) => {
    builder.addCase(addtoBadDebt.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addtoBadDebt.fulfilled, (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      state.success = action.payload.success;
    });
    builder.addCase(addtoBadDebt.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(addBadDebttoRecovery.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addBadDebttoRecovery.fulfilled, (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      state.success = action.payload.success;
    });
    builder.addCase(addBadDebttoRecovery.rejected, (state, action) => {
      state.isLoading = true;
    });
  },
});

export const badDebtebtReducer = badDebtebtSlice.reducer;

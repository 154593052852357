import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../Components/Spinner/Spinner";
import { fetchSaleById } from "../../Redux/Feature/sale/saleAction";

const CustomerList = () => {
  const { isLoading, sales } = useSelector((state) => state.sale);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("NHHD:token");
  console.log(token);
  const navigateToSinglePage = (id) => {
    navigate(id);
    dispatch(fetchSaleById({ id, token }));
  };

  return (
    <div>
      <section class="container px-4 mx-auto mt-3">
        <div class="flex flex-col">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div class="overflow-hidden border border-gray-200 md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      {th.map((item, i) => {
                        return (
                          <th
                            key={i}
                            class="  py-3.5  px-3 text-sm font-normal text-left rtl:text-right text-gray-500"
                          >
                            {item.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  {isLoading === true ? (
                    <Spinner />
                  ) : (
                    <tbody class="bg-white divide-y divide-gray-200  ">
                      {sales?.length === 0 ? (
                        <p className="p-1 py-2">No Data Found!!</p>
                      ) : (
                        sales?.slice(0, 20).map((item, i) => {
                          return (
                            <tr key={i}>
                              <td class=" py-4 text-sm font-medium   whitespace-nowrap cursor-not-allowed  px-2 ">
                                <div class=" text-sm font-normal">
                                  <p className="text-center">{i + 1}</p>
                                  <p>(Bill No.{item.billNo})</p>
                                </div>
                              </td>

                              <td
                                class=" py-4 text-sm text-gray-500   whitespace-nowrap  cursor-pointer"
                                onClick={() => navigateToSinglePage(item._id)}
                              >
                                <p
                                  className={`px-3 capitalize py-1 text-xl font-[400] text-green-600  
                                     `}
                                >
                                  {item.customerName}
                                </p>
                                <p className="px-3">
                                  <span>Reg.{item.registrationNumber}</span>
                                </p>
                              </td>

                              <td class=" py-4 text-sm font-medium   whitespace-nowrap cursor-not-allowed">
                                <div className="px-3">
                                  <span class="text-sm font-normal">
                                    ( +977) &nbsp;
                                  </span>
                                  <span class="text-sm font-normal">
                                    {item.customerPhone}
                                  </span>
                                </div>
                              </td>
                              <td class=" py-4 text-sm font-medium   whitespace-nowrap cursor-not-allowed  px-2 ">
                                <div>
                                  <span class="text-sm font-normal">
                                    (Nepal) &nbsp;
                                  </span>
                                  <span class="text-sm font-normal">
                                    {item.customerAddress}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* <Pagination page={1} count={count} /> */}
      </section>
    </div>
  );
};

const th = [
  {
    name: "SN.",
  },
  {
    name: "Customer Name",
  },
  {
    name: "Customer Contact",
  },
  {
    name: "Customer Loaction",
  },
];
export default CustomerList;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSingleSupplier } from "../../../Redux/Feature/supplier/supplierAction";
import { useLocation, useParams } from "react-router-dom";
import { Spinner } from "../../../Components/Spinner/Spinner";
import { Container } from "@mui/material";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaUserAlt } from "react-icons/fa";
import {
  MdOutlineAppRegistration,
  MdOutlineEmail,
  MdOutlineLocationOn,
} from "react-icons/md";
import PurchaseDetails from "./PurchaseDetails";
import CreditTranscation from "./CreditTranscation";
import PurchaseReturn from "./PurchaseReturn";
const SingleSupplier = () => {
  const location = useLocation();
  const item = location.state.item;

  const { id } = useParams();
  const token = localStorage.getItem("NHHD:token");

  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.supplier);
  useEffect(() => {
    dispatch(getSingleSupplier({ token, id }));
  }, [dispatch, token, id]);
  return (
    <>
      {isLoading === true ? (
        <Spinner />
      ) : (
        <div>
          <>
            <Container>
              <div className="lg:mt-[3rem] md:mt-[3rem] ">
                <div className="flex">
                  <h1 className=" text-xl underline lg:py-3 md:py-3 ">
                    Supplier Details
                  </h1>
                  <span>
                    {/* <BasicModal
                      btn={
                        <BsPencil className="text-green-800 text-2xl m-2 text-bold" />
                      }
                      modalDetails={<UpdateCustomer />}
                    ></BasicModal> */}
                  </span>
                </div>

                <div className="grid lg:grid-cols-3  md:grid-cols-3 grid-flow-row gap-3 border-green-800 p-3">
                  <div className="flex gap-2 border ">
                    <span>
                      <FaUserAlt className="text-5xl border rounded-[6px] bg-slate-300 " />
                    </span>
                    <p className="px-2">
                      <span className="text-[0.7rem]">
                        Suppiler Name
                        <br />
                      </span>
                      <span className="text-[0.9rem]">{item.supplierName}</span>
                    </p>
                  </div>
                  <div className="flex gap-2 border ">
                    <span>
                      <BsFillTelephoneFill className="text-5xl border rounded-[6px] bg-slate-300 " />
                    </span>
                    <p className="px-2">
                      <span className="text-[0.7rem]">
                        Suppiler Phone
                        <br />
                      </span>
                      <span className="text-[0.9rem]">
                        {item.supplierContact}
                      </span>
                    </p>
                  </div>
                  <div className="flex gap-2 border ">
                    <span>
                      <MdOutlineLocationOn className="text-5xl border rounded-[6px] bg-slate-300 " />
                    </span>
                    <p className="px-2">
                      <span className="text-[0.7rem]">
                        Supplier Address
                        <br />
                      </span>
                      <span className="text-[0.9rem]">
                        {item.supplierLocation}
                      </span>
                    </p>
                  </div>
                  <div className="flex gap-2 border ">
                    <span>
                      <MdOutlineAppRegistration className="text-5xl border rounded-[6px] bg-slate-300 " />
                    </span>
                    <p className="px-2">
                      <span className="text-[0.7rem]">
                        {item.registrationType}
                        <br />
                      </span>
                      <span className="text-[0.9rem]">
                        {item.registrationNumber}
                      </span>
                    </p>
                  </div>
                  <div className="flex gap-2 border ">
                    <span>
                      <MdOutlineEmail className="text-5xl border rounded-[6px] bg-slate-300 " />
                    </span>
                    <p className="px-2">
                      <span className="text-[0.7rem]">
                        Supplier Email
                        <br />
                      </span>
                      <span className="text-[0.9rem]">
                        {item.supplierEmail}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="gap-10 mt-5 ">
                  <div className="mx-4">
                    <p className=" text-xl underline py-3  ">Product Details</p>

                    <PurchaseDetails single={item} />
                  </div>
                  <div className="mx-4 mt-4">
                    <p className=" text-xl underline py-3  ">
                      Returned Product Details
                    </p>

                    <PurchaseReturn single={item} />
                  </div>
                  <div className="mx-4 mt-4">
                    <span className="text-xl underline py-3  ">
                      Transaction Details
                    </span>
                    {/* <Link to={"history"}>
                        <span className="bg-green-900 uppercase p-2  text-white font-bold  rounded-md">
                          {item.creditDetails.length <= 5 ? "View more" : null}
                        </span>
                      </Link> */}
                    <CreditTranscation single={item} />
                  </div>
                </div>
              </div>
            </Container>
          </>
        </div>
      )}
    </>
  );
};

export default SingleSupplier;

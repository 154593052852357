import React, { useState } from "react";
import Button from "../../Components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { updateExistingPoduct } from "../../Redux/Feature/Product/productAction";

import "../../index.css";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { toast } from "react-toastify";
import { Container } from "@mui/material";
import Tittle from "../../Components/Tittle/Tittle";

import { Link } from "react-router-dom";

const AddExistingProduct = () => {
  const { supplies } = useSelector((state) => state.supplier);
  const { data } = useSelector((state) => state.products);
  const dispatch = useDispatch();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // New state for button disable

  const [existingProduct, setExistingProduct] = useState([
    {
      productId: "",
      productUnitPrice: 0,
      productRemarks: "",
      productQuantity: 0,
    },
  ]);

  const onChangeFeild = (e, i) => {
    const { name, value } = e.target;
    const list = [...existingProduct];
    list[i][name] = value;
    setExistingProduct(list);
  };

  const removeClick = (i) => {
    const list = [...existingProduct];
    list.splice(i, 1);
    setExistingProduct(list);
  };

  const addClick = () => {
    setExistingProduct([
      ...existingProduct,
      {
        existingProduct,
      },
    ]);
  };

  const [supplierId, setSupplierId] = useState("");
  const [purchaseId, setPurchaseId] = useState("");

  const addToPurchase = () => {
    if (existingProduct === "" || supplierId === "") {
      toast.error("Some Feilds are missing");
    } else {
      setIsButtonDisabled(true);
      dispatch(
        updateExistingPoduct({
          existingProduct,
          supplierId,
          purchaseId,
        })
      ).then(() => {
        setIsButtonDisabled(false);
      });
    }
  };

  const allPurchaseBill = data
    .filter((ele) => ele.supplierId === supplierId.toString())
    .map((ele) => ele);

  const productExistInBill = allPurchaseBill
    .filter((ele) => ele._id === purchaseId.toString())
    .map((ele) => ele.purchaseFrom)
    .flat()
    .map((ele) => ele);

  return (
    <Container>
      <div className="flex items-center justify-between pt-3">
        <Tittle tittle={"Add Existing Product"} />
        <Link to="/product">
          <Button btnName={"Go Back"} />
        </Link>
      </div>

      <div className="flex items-center">
        <p className="uppercase opacity-30 py-3">Select Supplier</p>
        <div className="w-[85%] bg-green-600 h-[0.1px]  mx-2 "></div>
      </div>
      <div className="lg:flex md:flex items-center  gap-3 ">
        <div>
          <p className="uppercase text-green-800 py-1">Supplier Name</p>
          <select
            value={supplierId}
            onChange={(e) => setSupplierId(e.target.value)}
            className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0  w-[400px] h-full"
          >
            <option value="">Select Supplier</option>
            {supplies.map((item, i) => (
              <option key={item._id} value={item._id}>
                {item.supplierName}
              </option>
            ))}
          </select>
        </div>

        <div>
          <p className="uppercase text-green-800 py-1">Purchase Bill NO.</p>
          <select
            className="border py-3 rounded-md   px-2 my-2 lg:my-0 md:my-0 w-[335px] "
            value={purchaseId}
            onChange={(e) => setPurchaseId(e.target.value)}
          >
            <option>Select Bill NO</option>
            {allPurchaseBill.map((ele) => {
              return <option value={ele._id}>{ele.billNo}</option>;
            })}
          </select>
        </div>
      </div>
      <div className="flex items-center">
        <p className="uppercase opacity-30 py-3">Product Details </p>

        <div className="w-[85%] bg-green-600 h-[0.1px]  mx-2 "></div>
      </div>
      <div>
        {existingProduct.map((item, i) => {
          return (
            <div className="my-5">
              <p className="py-1">Product No:-{i + 1}</p>
              <div className=" grid grid-flow-row lg:grid-cols-3  md:grid-cols-3  gap-3  ">
                <div>
                  <p className="uppercase text-green-800 py-1">Product Name</p>
                  <select
                    className="border py-3 rounded-md   px-2 my-2 lg:my-0 md:my-0 w-[335px] "
                    name="productId"
                    onChange={(e) => onChangeFeild(e, i)}
                  >
                    <option>Select Product Name</option>
                    {productExistInBill.map((ele) => {
                      return <option value={ele._id}>{ele.productName}</option>;
                    })}
                  </select>
                </div>
                <div>
                  <p className="uppercase text-green-800 py-1">
                    Product Quantity
                  </p>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => onChangeFeild(e, i)}
                    className="border py-3 rounded-md placeholder:px-2 px-2"
                    name="productQuantity"
                    placeholder="Product-Quality "
                  />
                </div>
                <div>
                  <p className="uppercase text-green-800 py-1">Product Price</p>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => onChangeFeild(e, i)}
                    className="border py-3 rounded-md placeholder:px-2 px-2"
                    name="productUnitPrice"
                    placeholder="Product-Price "
                  />
                </div>
              </div>
              <div className=" grid grid-flow-row lg:grid-cols-3  md:grid-cols-3  gap-3  my-2">
                <div>
                  <p className="uppercase text-green-800 py-1">
                    Product Remarks
                  </p>
                  <input
                    type="text"
                    onChange={(e) => onChangeFeild(e, i)}
                    className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                    name="productRemarks"
                    placeholder="Product-Remarks "
                  />
                </div>
              </div>

              <div className="flex gap-3 my-2">
                {existingProduct.length !== 1 && (
                  <p
                    onClick={removeClick}
                    className="flex border border-black-600 bg-[white] p-2 text-red-800 hover:bg-red-800 hover:text-white text-bold rounded-[6px] "
                  >
                    <AiOutlineMinus className="mt-1 " />
                    <span className="mx-1">Remove</span>
                  </p>
                )}
                {existingProduct.length - 1 === i && (
                  <p
                    onClick={addClick}
                    className="flex border border-black-600 bg-[white] p-2 text-green-800 hover:bg-green-800 hover:text-white text-bold rounded-[6px] "
                  >
                    <AiOutlinePlus className="mt-1 " />
                    <span className="mx-1">Add</span>
                  </p>
                )}
              </div>
            </div>
          );
        })}
      </div>

      <div className="flex justify-center my-3 gap-3 ">
        <Button
          btnName={"Add"}
          click={addToPurchase}
          isLoading={isButtonDisabled}
        />
      </div>
    </Container>
  );
};

export default AddExistingProduct;

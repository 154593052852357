import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../.././utils/API";
import { toast } from "react-toastify";

export const getAllExpenses = createAsyncThunk(
  "get/expenses",
  async (payload) => {
    try {
      const response = await api.get(
        `/expenses/all-expenses?limit=${20}&page=${payload.pageNo}`,
        {
          headers: { Authorization: `Bearer ${payload.token}` },
        }
      );

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addExpenses = createAsyncThunk(
  "post/expenses",
  async (payload) => {
    try {
      const response = await api.post("/expenses/add-expense", payload, {
        headers: { Authorization: `Bearer ${payload.token}` },
      });
      if (response.data.success === true) {
        toast.success(response.data.message);
        window.location.reload();
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const dltExpenses = createAsyncThunk(
  "delete/expenses",
  async ({ id, token }) => {
    console.log(id);
    try {
      const response = await api.post(
        "/expenses/delete-expenses",
        { id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response);
      if (response.data.success === true) {
        toast.success(response.data.message);
        window.location.reload();
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const filterExpensesByDate = createAsyncThunk(
  "filter/expenses/date",
  async ({ token, startDate, endDate }) => {
    try {
      const response = await api.post(
        `/expenses/expenses-by-date`,
        { startDate, endDate },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addCategoryExpenses = createAsyncThunk(
  "post/expenses/category",
  async ({ token, expenseCategory }) => {
    try {
      const response = await api.post(
        "/expenses/add-expenses-category",
        { expenseCategory },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data.success === true) {
        toast.success(response.data.message);
        window.location.reload();
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAllExpensesCategory = createAsyncThunk(
  "get/expenses-category",
  async (payload) => {
    try {
      const response = await api.get(`/expenses/all-expenses-category`, {
        headers: { Authorization: `Bearer ${payload.token}` },
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const dltExpensesCategory = createAsyncThunk(
  "delete/expenses/category",
  async ({ id, token }) => {
    try {
      const response = await api.post(
        "/expenses/delete-expenses-category",
        { id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response);
      if (response.data.success === true) {
        toast.success(response.data.message);
        window.location.reload();
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const filterExpensesCategory = createAsyncThunk(
  "filter/expenses/category",
  async ({ expensesCategorys, token }) => {
    try {
      const response = await api.get(
        `/expenses/search-category?expensesCategorys=${expensesCategorys}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const filterExpensesName = createAsyncThunk(
  "filter/expenses/name",
  async ({ expenseName, token }) => {
    try {
      const response = await api.get(
        `/expenses/search-expenses?expenseName=${expenseName}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

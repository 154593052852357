import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getSingleBill } from "../../Redux/Feature/Bill/billAction";
import Button from "../../Components/Button/Button";

const SingleBill = () => {
  const dispatch = useDispatch();
  const param = useParams();
  const token = localStorage.getItem("NHHD:token");
  const { singleBills } = useSelector((state) => state.bill);
  const id = param.id;
  console.log(id);
  useEffect(() => {
    const fetch = async () => {
      dispatch(getSingleBill({ id: id, token }));
    };
    fetch();
  }, [dispatch, token, id]);

  return (
    <div>
      <div className="flex gap-3 p-4 justify-end">
        <Link
          to={`${singleBills.saleId && singleBills.saleId._id}+${
            singleBills && singleBills._id
          }`}
        >
          <Button btnName={"VIEW / PRINT"} />
        </Link>
      </div>
      <p className="opacity-60 uppercase cursor-not-allowed my-2">
        Sold to | Customer | &#160;
        {/* <span
          style={{
            color: singleBills.billStatus === "pending" ? "red" : "green",
            fontWeight: "bold",
          }}
        >
          {singleBills.billStatus}
        </span> */}
      </p>
      {[singleBills].map((item, i) => {
        return (
          <>
            <span className="px-3 text-text-[14px]">
              {/* {item.saleId && item.saleId.soldTo.customerName} */}
            </span>
            <span className="px-3">
              {/* {item.saleId && item.saleId.soldTo.customerAddress} */}
            </span>
            <span className="px-3">
              {/* {item.saleId && item.saleId.soldTo.customerPhone} */}
            </span>
            <p className="opacity-60 uppercase cursor-not-allowed my-2">
              Sold Product | Product details
            </p>
            {/* <ProductTable /> */}
            <p className="opacity-60 uppercase cursor-not-allowed my-2">
              Sold Product | Category details
            </p>
            {/* <CategoryTable /> */}
            <p className="opacity-60 uppercase cursor-not-allowed my-2">
              Sold Product | Supplier details
            </p>
            {/* <SupplierTable /> */}
            <div className="py-3">
              <p className=" text-end capitalize ">Due Amount</p>
              {/* <p className=" text-bold  text-end">Rs.{item.dueAmount}</p> */}
            </div>
          </>
        );
      })}
    </div>
  );
};

export default SingleBill;

import React, { useEffect } from "react";
import { Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { allReturnProduct } from "../../../Redux/Feature/RetunProduct/returnProductAction";
import { Spinner } from "../../../Components/Spinner/Spinner";

const ReturnProductList = () => {
  const dispatch = useDispatch();

  const { productReturn, isLoading } = useSelector(
    (state) => state.returnProduct
  );

  console.log(productReturn);

  useEffect(() => {
    dispatch(allReturnProduct());
  }, [dispatch]);
  return (
    <>
      <Container className="container px-4 mx-auto">
        {/* <div className="my-2 lg:grid md:grid lg:grid-cols-3 md:grid-cols-2 gap-4 grid-flow-row">
          <div className="flex gap-2 mt-4">
            <span className=" text-2xl border bg-green-800 text-white rounded-[6px] p-2 h-[50px]">
              <AiOutlineSearch />
            </span>
            <input
              type="search"
              placeholder="Search by Customer Name"
              className="rounded-[6px] h-[50px] w-full"
            />
          </div>
          <div className="  flex gap-2">
            <div>
              <p>Start Date</p>
              <input type="date" />
            </div>

            <div>
              <p>End Date</p>
              <div className="flex">
                <input type="date" />
              </div>
            </div>
          </div>
          <div className="lg:flex lg:justify-center">
            <p className="uppercase border border-white mx-2  bg-green-800  text-white text-bold rounded-[6px]    w-[100px] text-center pt-2 h-[40px] lg:mt-[1.4rem] md:mt-0 mt-2">
              Find
            </p>
          </div>
        </div> */}

        <div className="flex flex-col">
          <div className=" -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden border border-gray-200   md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200  ">
                  <thead className="bg-gray-50  ">
                    <tr>
                      {th.map((item, i) => {
                        return (
                          <th
                            scope="col col-span-5"
                            key={i}
                            className="px-2 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                          >
                            {item.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  {isLoading === true ? (
                    <Spinner />
                  ) : (
                    <tbody className="bg-white divide-y divide-gray-200    ">
                      {productReturn && productReturn.length === 0
                        ? "no data found"
                        : productReturn &&
                          productReturn.slice(0, 6).map((item, i) => {
                            return (
                              <tr>
                                <td className="px-2 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                  <div className="inline-flex items-center gap-x-3">
                                    <span>{i + 1}</span>
                                  </div>
                                </td>
                                <td className="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                  <div className="flex items-center gap-x-2">
                                    <div>
                                      <h2 className="text-sm capitalize font-medium text-gray-800   ">
                                        {item.supplierId}
                                      </h2>
                                    </div>
                                  </div>
                                </td>

                                <td className="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                  <div className="flex items-center gap-x-2">
                                    <div>
                                      <h2 className="text-sm capitalize font-medium text-gray-800   ">
                                        {new Date(
                                          item.createdAt
                                        ).toDateString()}
                                      </h2>
                                    </div>
                                  </div>
                                </td>
                                <td className="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                  <div className="flex items-center gap-x-2">
                                    <div>
                                      <h2 className="text-sm capitalize font-medium text-gray-800   ">
                                        {item.returnProucts.map(
                                          (ele) => ele.ret_productId
                                        )}
                                      </h2>
                                    </div>
                                  </div>
                                </td>
                                <td className="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                  <div className="flex items-center gap-x-2">
                                    <div>
                                      <h2 className="text-sm capitalize font-medium text-gray-800   ">
                                        {item.returnProucts.map(
                                          (ele) => ele.ret_productQuantity
                                        )}
                                      </h2>
                                    </div>
                                  </div>
                                </td>
                                <td className="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                  <div className="flex items-center gap-x-2">
                                    <div>
                                      <h2 className="text-sm capitalize font-medium text-gray-800   ">
                                        {item.returnProucts.map(
                                          (ele) => ele.ret_productPrice
                                        )}
                                      </h2>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
          {/* <Pagination page={1} count={count} /> */}
        </div>
      </Container>
    </>
  );
};

const th = [
  {
    name: "SN.",
  },
  {
    name: "Supplier",
  },
  {
    name: "Return Date",
  },
  {
    name: "Product Name",
  },
  {
    name: "Ret.Quantity",
  },
  {
    name: "Re.Price",
  },
];
export default ReturnProductList;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../utils/API";
import { toast } from "react-toastify";
export const getStaff = createAsyncThunk(
  "staff/getStaff",
  async ({ token, pageNo }) => {
    console.log(pageNo);
    try {
      const response = await api.get(
        `/employees/all-employees?limit=${20}&page=${pageNo}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const dltEmployee = createAsyncThunk("dlt/:id", async (payload) => {
  try {
    const response = await api.post("/employees/delete-employee", payload, {
      headers: {
        Authorization: `Bearer ${payload.token}`,
      },
    });
    if (response.data.success === true) {
      window.location.reload();
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const addEmployee = createAsyncThunk(
  "add",
  async ({ formData, token }) => {
    try {
      const response = await api.post("/employees/add-employee", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success === true) {
        toast.success(response.data.message);
        window.location.reload();
      }
      if (response.data.success === false) {
        toast.success(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getSingleEmployee = createAsyncThunk(
  "get/employee/id",
  async ({ id, token }) => {
    try {
      const response = await api.get(`/employees/single-employee/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateEmployee = createAsyncThunk(
  "update/employee",
  async (payload) => {
    console.log(payload.id);
    try {
      const response = await api.post("employees/update-employee", payload, {
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
      });
      if (response.data.success === true) {
        toast.success(response.data.message);
        window.location.reload();
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getEmployeeByStatus = createAsyncThunk(
  "status/employee",
  async (payload) => {
    console.log(payload.status);
    try {
      const response = await api.get(
        `employees/filter-employees-by-status?status=${payload.status}`,
        {
          headers: {
            Authorization: `Bearer ${payload.token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getEmployeeByPosition = createAsyncThunk(
  "postion/employe",
  async (payload) => {
    try {
      const response = await api.get(
        `employees/filter-employees-by-position?position=${payload.position}`,
        {
          headers: {
            Authorization: `Bearer ${payload.token}`,
          },
        }
      );
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

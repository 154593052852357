import React from "react";
import Tittle from "../../../Components/Tittle/Tittle";
import BasicModal from "../../../Components/Modal/Modal";
import Button from "../../../Components/Button/Button";
import AddAssets from "./AddAssets";
import AssestList from "./AssestList";
import ReturnAsset from "./ReturnAsset";

const index = () => {
  return (
    <>
      <div>
        <Tittle tittle={"Assest"} />
        <div className="flex gap-4">
          <BasicModal
            btn={<Button btnName={"Add Assets"} />}
            tittle={"Add Assets"}
            modalDetails={<AddAssets />}
          />
          <BasicModal
            btn={<Button btnName={"Return Assets"} />}
            tittle={"Return Assets"}
            modalDetails={<ReturnAsset />}
          />
        </div>
        <div className="mt-[2rem]">
          <Tittle tittle={"Assets"} />
          <AssestList />
        </div>
      </div>
    </>
  );
};

export default index;

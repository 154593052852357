import * as React from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import "../../index.css";

export default function BasicModal({ modalDetails, btn, tittle }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="">
      <Button onClick={handleOpen}>{btn}</Button>
      {open ? (
        <Modal
          open={open}
          data-aos="zoom-in"
          className=" rounded-[1rem] "
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div>
            <p className="bg-[white] justify-center flex py-2">
              <span className="px-2 mbl-name">Ram Bharat Trade Link</span>|
              <span className="text-green-900 font-[700] capitalize px-3">
                {tittle}
              </span>{" "}
              |{" "}
              <p className="flex gap-3 bg-red-800 rounded-[4px] text-white  mx-3   uppercase px-2">
                <span onClick={handleClose} className="cursor-pointer">
                  Close
                </span>
              </p>
            </p>
            <div>{modalDetails}</div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
}

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSalary } from "../../Redux/Feature/Salary/salaryAction";
import { Spinner } from "../../Components/Spinner/Spinner";
import { useNavigate } from "react-router-dom";

const UpdateSalary = ({ id }) => {
  const { singleSalarys, isLoading } = useSelector((state) => state.salary);
  console.log(singleSalarys);
  const [amount, setAmount] = useState("");
  const dispatch = useDispatch();
  const token = localStorage.getItem("NHHD:token");
  const navigate = useNavigate();
  const salaryUpdate = (id) => {
    dispatch(updateSalary({ token, amount, salaryId: id, navigate }));
  };

  return (
    <>
      <div class="container mx-auto">
        <div class="flex justify-center px-6 ">
          <div class="w-full lg:w-9/12 px-4">
            <div>
              <div
                className="    transition duration-150 ease-in-out z-10  "
                id="modal"
              >
                <div
                  role="alert"
                  className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
                >
                  <div className=" ">
                    <div className=" flex justify-center">
                      {isLoading === true ? (
                        <Spinner />
                      ) : (
                        <div className="border border-gray-100 w-auto h-auto bg-gray-200 py-4 px-3 rounded-[6px]  ">
                          <div className="flex gap-3  my-3">
                            <input
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              className="border py-3 rounded-md placeholder:px-2 px-2 w-full h-full"
                              placeholder="Enter Amount"
                              value={amount}
                              onChange={(e) => setAmount(e.target.value)}
                            />
                          </div>
                          <div></div>
                          <p
                            className="border bg-green-700 w-[200px] text-center text-white font-[500] text-xl py-2 px-3 rounded-[6px] my-4 uppercase"
                            onClick={() => salaryUpdate(singleSalarys._id)}
                          >
                            Update Now
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateSalary;

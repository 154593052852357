import { createSlice } from "@reduxjs/toolkit";
import { fetchAllBill, getSingleBill } from "./billAction";

const bilSlice = createSlice({
  name: "bilSale",
  initialState: {
    isLoading: true,
    success: false,
    billData: [],
    singleBills: [],
    netTotalBill: 0,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllBill.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllBill.fulfilled, (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      state.success = action.payload.success;
      state.billData = action.payload.allBills;
    });
    builder.addCase(fetchAllBill.rejected, (state, action) => {
      state.isLoading = true;
      state.billData = action.payload.error;
    });
    builder.addCase(getSingleBill.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSingleBill.fulfilled, (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      state.success = action.payload.success;
      state.singleBills = action.payload.bill;
      state.netTotalBill = action.payload.netTotalAmount;
    });
    builder.addCase(getSingleBill.rejected, (state, action) => {
      state.isLoading = true;
      state.singleBills = action.payload.error;
    });
  },
});

export const billReducer = bilSlice.reducer;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import Scrollbars from "react-custom-scrollbars-2";
import { addEmployeeSalary } from "../../Redux/Feature/EmployeeSalary/EmpSalaryAction";
import { toast } from "react-toastify";

const GiveSalary = () => {
  const { singleEmployee } = useSelector((state) => state.employee);
  const { singleEmp_salaries } = useSelector((state) => state.employeeSalary);

  // const [remainingMonths, setRemainingMonths] = useState([]);

  useEffect(() => {
    // const matchedMonths = singleEmp_salaries.map((ele) =>
    //   ele.salaryDetails.map((item) => item.month)
    // );
    // const updatedMonths = month.filter(
    //   (ele) => !matchedMonths.flat().includes(ele.name)
    // );
    // setRemainingMonths(updatedMonths);
  }, [singleEmp_salaries]);

  const [actualRemainingMonths, setActualRemainingMonths] = useState([]);

  useEffect(() => {
    const currentMonthIndex = new Date().getMonth(); // Get the index of the current month (0-11)
    const currentYear = new Date().getFullYear();

    const matchedMonths = singleEmp_salaries.map((ele) =>
      ele.salaryDetails.map((item) => item.month)
    );

    const updatedMonths = month.filter((ele, index) => {
      // Compare the current year and index of the month with the matchedMonths array to filter out future months
      return (
        !matchedMonths.flat().includes(ele.name) &&
        (year > currentYear || index < currentMonthIndex)
      );
    });

    setActualRemainingMonths(updatedMonths);
  }, [singleEmp_salaries]);

  const [transctionType, setTransctionType] = useState("");
  const [partial_Bank, setPartial_bank] = useState("");
  const [partial_Cash, setPartial_cash] = useState("");
  const [salaryDetails, setSalaryDetails] = useState([
    {
      amount: "",
      month: "",
      paidDate: "",
    },
  ]);

  const dispatch = useDispatch();
  const submit = () => {
    if (salaryDetails === "" || transctionType === "")
      return toast.error("Some feilds are missing");
    dispatch(
      addEmployeeSalary({
        employeeId: singleEmployee._id,
        transctionType,
        partial_Bank,
        partial_Cash,
        salaryDetails,
      })
    );
  };

  const onChangeFeilds = (e, i) => {
    const { name, value } = e.target;
    const list = [...salaryDetails];
    list[i][name] = value;
    setSalaryDetails(list);
  };

  const removeClick = (i) => {
    const list = [...salaryDetails];
    list.splice(1, 1);
    setSalaryDetails(list);
  };

  const addClick = () => {
    setSalaryDetails([
      ...salaryDetails,
      {
        salaryDetails,
      },
    ]);
  };
  return (
    <>
      <Scrollbars style={{ height: 500 }}>
        <div class="container mx-auto">
          <div class="lg:flex md:flex justify-center px-6 ">
            <div class="w-full lg:w-9/12 px-4">
              <div>
                <div
                  className=" transition duration-150 ease-in-out z-10  "
                  id="modal"
                >
                  <div
                    role="alert"
                    className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
                  >
                    <div>
                      <div className=" lg:flex md:flex justify-center">
                        <div className="border border-gray-100 w-auto h-auto bg-gray-200 py-4 px-3 rounded-[6px] ">
                          {salaryDetails.map((item, i) => {
                            return (
                              <>
                                <div>
                                  <div key={i}>
                                    <input
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      className={`py-3 rounded-md w-full h-full placeholder:px-2 px-2 mb-2    ${
                                        singleEmployee.employeeSalary >=
                                          item.amount ||
                                        singleEmployee.employeeSalary ===
                                          item.amount
                                          ? null
                                          : "text-red-900"
                                      }`}
                                      placeholder="Enter Amount"
                                      name="amount"
                                      onChange={(e) => onChangeFeilds(e, i)}
                                    />
                                  </div>
                                  <select
                                    className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                                    name="month"
                                    onChange={(e) => onChangeFeilds(e, i)}
                                  >
                                    <option>Select Month</option>
                                    {actualRemainingMonths.map((item, i) => {
                                      return <option>{item.name}</option>;
                                    })}
                                  </select>

                                  {singleEmployee.employeeSalary <
                                    item.amount && (
                                    <p className="text-[13px] py-2 text-red-900">
                                      Employee Salary must be less than or
                                      equals to Monthy Salary:{" "}
                                      {singleEmployee.employeeSalary}
                                    </p>
                                  )}

                                  <input
                                    type="Date"
                                    className={`border py-3 rounded-md placeholder:px-2 px-2 w-full my-2 `}
                                    name="paidDate"
                                    onChange={(e) => onChangeFeilds(e, i)}
                                  />
                                </div>
                                <div className="flex gap-3 my-2">
                                  {salaryDetails.length !== 1 && (
                                    <p
                                      onClick={removeClick}
                                      className="border border-black-600 bg-[white] p-2 text-red-800 hover:bg-red-800 hover:text-white text-bold rounded-[6px] "
                                    >
                                      <AiOutlineMinus />
                                    </p>
                                  )}
                                  {salaryDetails.length - 1 === i && (
                                    <p
                                      onClick={addClick}
                                      className="border border-black-600 bg-[white] p-2 text-green-800 hover:bg-green-800 hover:text-white text-bold rounded-[6px] "
                                    >
                                      <AiOutlinePlus />
                                    </p>
                                  )}
                                </div>
                              </>
                            );
                          })}
                          <>
                            <p className="uppercase opacity-30 pt-3">
                              Transiction{" "}
                            </p>
                            <div className="  gap-3  my-4">
                              <select
                                className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                                name="transictionType"
                                value={transctionType}
                                onChange={(e) =>
                                  setTransctionType(e.target.value)
                                }
                              >
                                <option>Select Transiction Type</option>
                                {caseType.map((item, i) => {
                                  return <option>{item.TypeName}</option>;
                                })}
                              </select>

                              <div className="my-2">
                                <p>
                                  {transctionType === "Both" && (
                                    <>
                                      <div className="">
                                        <input
                                          type="number"
                                          onWheel={(e) => e.target.blur()}
                                          name="partial_Bank"
                                          value={partial_Bank}
                                          onChange={(e) =>
                                            setPartial_bank(e.target.value)
                                          }
                                          placeholder="Enter  BANK Amount"
                                          className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 w-full h-full"
                                        />
                                      </div>
                                      <div className="my-2">
                                        <input
                                          type="number"
                                          onWheel={(e) => e.target.blur()}
                                          name="partial_Cash"
                                          value={partial_Cash}
                                          onChange={(e) =>
                                            setPartial_cash(e.target.value)
                                          }
                                          placeholder="Enter  CASH Amount"
                                          className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0  w-full h-full"
                                        />
                                      </div>
                                    </>
                                  )}
                                </p>
                              </div>
                            </div>
                          </>

                          <button
                            className="border bg-green-700 w-[200px] text-center text-white font-[500] text-xl py-2 px-3 rounded-[6px] my-4 uppercase"
                            onClick={submit}
                          >
                            Add Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Scrollbars>
    </>
  );
};

const caseType = [
  {
    TypeName: "Cash ",
  },
  {
    TypeName: "Bank ",
  },
  {
    TypeName: "Both ",
  },
];

const date = new Date();
const year = date.getFullYear();

const month = [
  {
    name: `January-${year}`,
  },
  {
    name: `Febuary-${year}`,
  },
  {
    name: `March-${year}`,
  },
  {
    name: `April-${year}`,
  },
  {
    name: `May-${year}`,
  },
  {
    name: `June-${year}`,
  },
  {
    name: `July-${year}`,
  },
  {
    name: `August-${year}`,
  },
  {
    name: `September-${year}`,
  },
  {
    name: `October-${year}`,
  },
  {
    name: `November-${year}`,
  },
  {
    name: `December-${year}`,
  },
];
export default GiveSalary;

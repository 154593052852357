import React from "react";
import { useSelector } from "react-redux";

const PurchaseDetails = () => {
  const { singleSale } = useSelector((state) => state.sale);
  const { data } = useSelector((state) => state.products);
  console.log(data);
  const totalCreditAmount =
    singleSale.creditType === "Credit"
      ? singleSale.credit
      : singleSale.creditType === "Partial Credit"
      ? singleSale.partial_credit
      : 0;
  const totalPurchaseAmount = singleSale.soldProducts.reduce(
    (total, ele) => total + Number(ele.subTotal),
    0
  );
  const filterProductName = singleSale.soldProducts.map((product) =>
    data
      .map((ele) => ele.purchaseFrom)
      .flat()
      .filter((ele) => ele._id === product.productId.toString())
      .flat()
  );
  return (
    <>
      <section class="container px-1 mx-auto">
        <div className="grid md:grid-cols-3 lg:grid-cols-3 grid-flow-row">
          <p>
            Total Credit Amount :
            <span className="text-xl text-green-800 font-semibold">
              Rs.{totalCreditAmount}
            </span>
          </p>
          <p>
            Total Credit Transaction :
            <span className="text-xl text-green-800 font-semibold">
              {/* Rs.{totalCreditTransactionAmount} */}
            </span>
          </p>
          <p>
            Total Purchase Amount :
            <span className="text-xl text-green-800 font-semibold">
              Rs.{totalPurchaseAmount}
            </span>
          </p>
        </div>

        <div class="flex flex-col">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-2 lg:px-2">
              <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200  ">
                  <thead class="bg-gray-50  ">
                    <tr>
                      {th.map((item, i) => {
                        return (
                          <th
                            scope="col col-span-5"
                            key={i}
                            class="px-3 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                          >
                            {item.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr>
                      <td class="px-1 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                        <div class="inline-flex items-center">
                          <span>{singleSale.saleDate}</span>
                        </div>
                      </td>
                      <td class="px-3 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                        <div class="inline-flex items-center">
                          <span>{singleSale.billNo}</span>
                        </div>
                      </td>

                      <td class="px-3 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                        <div class=" items-center">
                          {filterProductName
                            .map((ele) => ele)
                            .flat()
                            .map((ele) => (
                              <p className="py-1">-{ele.productName}</p>
                            ))}
                        </div>
                      </td>
                      <td className="px-3 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                        {singleSale?.soldProducts.map((obj) => {
                          return (
                            <div className=" items-center">
                              <p className="py-1">{obj.soldPrice}</p>
                            </div>
                          );
                        })}
                      </td>
                      <td className="px-3 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                        {singleSale?.soldProducts.map((obj) => {
                          return (
                            <div className="  items-center">
                              <p className="py-1">{obj.soldQuantity}</p>
                            </div>
                          );
                        })}
                      </td>
                      <td className="px-3 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                        {singleSale?.soldProducts.map((obj) => {
                          return (
                            <div className="  items-center">
                              <p className="py-1">{obj.subTotal}</p>
                            </div>
                          );
                        })}
                      </td>
                      <td class="px-3 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                        <div class="inline-flex items-center">
                          <span>{singleSale.discount}</span>
                        </div>
                      </td>
                      <td class="px-3 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                        <div class="inline-flex items-center">
                          <span>{singleSale.vatAmount}</span>
                        </div>
                      </td>
                      <td class="px-3 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                        <div class="inline-flex items-center">
                          <span>{singleSale.amountWithVat}</span>
                        </div>
                      </td>
                      <td class="px-3 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                        <div class="inline-flex items-center">
                          <span>
                            {singleSale.creditType === "Credit"
                              ? singleSale.amountWithVat - singleSale.credit
                              : singleSale.creditType === "Partial Credit"
                              ? singleSale.amountWithVat -
                                singleSale.partial_credit
                              : 0}
                          </span>
                        </div>
                      </td>
                      <td class="px-3 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                        <div class="inline-flex items-center">
                          <span>
                            {singleSale.creditType === "Credit"
                              ? singleSale.credit
                              : singleSale.creditType === "Partial Credit"
                              ? singleSale.partial_credit
                              : 0}
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const th = [
  {
    name: "Date.",
  },
  {
    name: "Bill No.",
  },

  {
    name: "Product Name",
  },
  {
    name: "Unit Price",
  },
  {
    name: "Quanitity",
  },
  {
    name: "Sub Total",
  },

  {
    name: "Discount Amount",
  },

  {
    name: "VAT amount",
  },
  {
    name: "Total Amount(included-VAT & Discount) ",
  },

  {
    name: "Paid Amount",
  },
  {
    name: "Credit Amount  ",
  },
];
export default PurchaseDetails;

const { createSlice } = require("@reduxjs/toolkit");
const {
  addAssest,
  fetchAssest,
  deleteAssestById,
  fetchAssestById,
  addAssestSale,
  fetchAssestSale,
  assestPaymentRecived,
  assetPayCredit,
} = require("./assestAction");

const assestSlice = createSlice({
  name: "assests",
  initialState: {
    isLoading: true,
    success: false,
    error: null,
    assests: [],
    singleAssests: [],
    assestSale: [],
    count: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addAssest.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(addAssest.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success;
    });
    builder.addCase(addAssest.rejected, (state, payload) => {
      state.isLoading = false;
      state.success = false;
      state.sales = payload;
    });
    builder.addCase(fetchAssest.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(fetchAssest.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success;
      state.assests = action.payload.assets;
      state.count = action.payload.count;
    });
    builder.addCase(fetchAssest.rejected, (state, payload) => {
      state.isLoading = false;
      state.success = false;
    });
    builder.addCase(deleteAssestById.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(deleteAssestById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    });
    builder.addCase(deleteAssestById.rejected, (state, payload) => {
      state.isLoading = false;
      state.success = false;
    });
    builder.addCase(fetchAssestById.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(fetchAssestById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success;
      state.singleAssests = action.payload.assets;
      state.count = action.payload.count;
    });
    builder.addCase(fetchAssestById.rejected, (state, payload) => {
      state.isLoading = false;
      state.success = false;
    });

    builder.addCase(addAssestSale.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(addAssestSale.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success;
    });
    builder.addCase(addAssestSale.rejected, (state, payload) => {
      state.isLoading = false;
      state.success = false;
      state.sales = payload;
    });
    builder.addCase(fetchAssestSale.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(fetchAssestSale.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success;
      state.assestSale = action.payload.saleAssets;
      state.count = action.payload.count;
    });
    builder.addCase(fetchAssestSale.rejected, (state, payload) => {
      state.isLoading = false;
      state.success = false;
    });

    builder.addCase(assestPaymentRecived.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(assestPaymentRecived.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success;
    });
    builder.addCase(assestPaymentRecived.rejected, (state, payload) => {
      state.isLoading = false;
      state.success = false;
      state.sales = payload;
    });
    builder.addCase(assetPayCredit.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(assetPayCredit.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success;
    });
    builder.addCase(assetPayCredit.rejected, (state, payload) => {
      state.isLoading = false;
      state.success = false;
      state.sales = payload;
    });
  },
});

export const assestReducer = assestSlice.reducer;

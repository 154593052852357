import axios from "axios";

export const api = axios.create({
  // baseURL: "https://nhhd.server.main.domain.codeinnepal.com.np/api/",
  baseURL: "http://localhost:8000/api",
  headers: {
    "Content-Type": "application/json",
  },
});
export const apiImage = axios.create({
  // baseURL: "https://nhhd.server.main.domain.codeinnepal.com.np/api",
  baseURL: "http://localhost:8000/api",
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

// baseURL: "https://hhnd.server.domain.codeinnepal.com.np/api/",

// https://servernhhdpersonal.nepalhulas.com.np/

///https://hhnd.server.domain.codeinnepal.com.np/api/
//https://hulas-6ede669b5eaf.herokuapp.com/

// https://nhhd.server.main.domain.codeinnepal.com.np/

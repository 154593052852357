import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateEmployee } from "../../Redux/Feature/staff/staffAction";

const UpdateStaff = () => {
  const { singleEmployee } = useSelector((state) => state.employee);
  const [employeeName, setEmployeeName] = useState(singleEmployee.employeeName);
  const [employeeEmail, setEmployeeEmail] = useState(
    singleEmployee.employeeEmail
  );
  const [employeePhone, setEmployeePhone] = useState(
    singleEmployee.employeePhone
  );
  const [employeeAddress, setEmployeeAddress] = useState(
    singleEmployee.employeeAddress
  );
  const [employeeSalary, setEmployeeSalary] = useState(
    singleEmployee.employeeSalary
  );

  const [employeeStatus, setEmployeeStatus] = useState(
    singleEmployee.employeeStatus
  );
  const [employeeJoiningDate, setEmployeeJoiningDate] = useState(
    singleEmployee.employeeJoiningDate
  );
  const [employeePosition, setEmployeePosition] = useState(
    singleEmployee.employeePosition
  );

  const dispatch = useDispatch();
  const token = localStorage.getItem("NHHD:token");

  const update = () => {
    dispatch(
      updateEmployee({
        id: singleEmployee._id,
        token,
        employeeAddress,
        employeeEmail,
        employeePhone,
        employeeJoiningDate,
        employeeName,
        employeeSalary,
        employeeStatus,
        employeePosition,
      })
    );
  };

  return (
    <>
      <div class="container mx-auto">
        <div class="flex justify-center px-6 ">
          <div class="w-full lg:w-9/12 px-4">
            <div>
              <div
                className="py-4 transition duration-150 ease-in-out z-10  "
                id="modal"
              >
                <div
                  role="alert"
                  className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
                >
                  <div className="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
                    <h1 className="text-gray-800 font-lg font-bold tracking-normal leading-tight mb- py-3">
                      Updatde Employee Details
                    </h1>

                    <input
                      id="name"
                      className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                      placeholder="Employee Name"
                      value={employeeName}
                      onChange={(e) => setEmployeeName(e.target.value)}
                    />
                    <input
                      id="name"
                      className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                      placeholder="Employee Email"
                      value={employeeEmail}
                      onChange={(e) => setEmployeeEmail(e.target.value)}
                    />
                    <input
                      id="name"
                      className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                      placeholder="Employee Address"
                      value={employeeAddress}
                      onChange={(e) => setEmployeeAddress(e.target.value)}
                    />
                    <input
                      id="name"
                      className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                      placeholder="Employee Number"
                      value={`${employeePhone}`}
                      onChange={(e) => setEmployeePhone(e.target.value)}
                    />
                    <input
                      id="name"
                      className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                      placeholder="Employee Position"
                      value={`${employeePosition}`}
                      onChange={(e) => setEmployeePosition(e.target.value)}
                    />
                    <input
                      id="name"
                      className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                      placeholder="Employee Salary"
                      value={`${employeeSalary}`}
                      onChange={(e) => setEmployeeSalary(e.target.value)}
                    />

                    <select
                      className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                      value={employeeStatus}
                      onChange={(e) => setEmployeeStatus(e.target.value)}
                    >
                      <option>{employeeStatus}</option>

                      {employeeStatus === "Active" ? (
                        <option>Inactive</option>
                      ) : employeeStatus === "Inactive" ? (
                        <option>Active</option>
                      ) : (
                        { employeeStatus }
                      )}
                    </select>

                    <input
                      id="name"
                      type="date"
                      className=" mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                      placeholder="Employee Joining Date"
                      value={employeeJoiningDate}
                      onChange={(e) => setEmployeeJoiningDate(e.target.value)}
                    />
                    <p>
                      Join On :{" "}
                      <span className="font-bold">
                        {new Date(employeeJoiningDate).toDateString()}
                      </span>
                    </p>

                    <div className="flex items-center justify-start w-full mt-3">
                      <button
                        className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-700 transition duration-150 ease-in-out hover:bg-green-600 bg-green-700 rounded text-white px-8 py-2 text-md uppercase  font-[400]"
                        onClick={update}
                      >
                        Submit
                      </button>
                      <button className="focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-gray-400 ml-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out   border rounded px-8 py-2 text-sm">
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateStaff;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addAssest } from "../../../Redux/Feature/Assest/assestAction";
import { toast } from "react-toastify";
import Scrollbars from "react-custom-scrollbars-2";

import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";

const AddAssets = () => {
  const [assestName, setAssestName] = useState("");
  const [assestPrice, setAssestPrice] = useState("");
  const [shopName, setShopName] = useState("");
  const [assestDate, setAssestDate] = useState("");
  const [transctionType, setTransctionType] = useState("");
  const [partial_cash, setPartial_cash] = useState("");
  const [assessmentMethod, setAssessmentMethod] = useState("");
  // const [partial_Credit, setPartial_Credit] = useState("");
  // const [partial_Amount, setPartial_Amount] = useState("");
  const [image, setImage] = useState("");
  const [previeBI, setPreviewBI] = useState("");
  const [vatType, setVatType] = useState("");
  const [vatPercent, setVatPercent] = useState("");
  const [vatAmount, setVatAmount] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [discountPercent, setDiscountPercent] = useState("");
  const [discount, setDiscount] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [billNo, setBillNo] = useState("");

  const [forTransaction, setForTransaction] = useState("");
  const [credit, setCredit] = useState("");
  const [cash, setCash] = useState("");
  const [bank_amount, setBank_amount] = useState("");
  const [cash_amount, setCash_amount] = useState("");

  const [partial_credit, setPartial_credit] = useState("");

  const [both_Cash_amount, setBoth_Cash_amount] = useState("");
  const [both_Bank_amount, setBoth_Bank_amount] = useState("");

  const monthInBS = parseInt(assestDate.slice(5, 7)) - 1;
  const yearInBS = assestDate.slice(0, 4);

  const currentMonthInBS = monthOrder[monthInBS];

  const saleAssestMonth = currentMonthInBS + "-" + yearInBS;

  const formData = new FormData();
  formData.append("assestName", assestName);
  formData.append("assestPrice", assestPrice);
  formData.append("shopName", shopName);
  formData.append("assestDate", assestDate);
  formData.append("transctionType", transctionType);
  formData.append("partial_cash", partial_cash);
  formData.append("assessmentMethod", assessmentMethod);
  // formData.append("partial_Credit", partial_Credit);
  // formData.append("partial_Amount", partial_Amount);
  formData.append("discount", discount);
  formData.append("vatAmount", vatAmount);
  formData.append("saleAssestMonth", saleAssestMonth);
  formData.append("registrationNumber", registrationNumber);
  formData.append("billNo", billNo);
  formData.append("vatType", vatType);
  formData.append("cash", cash);
  formData.append("credit", credit);
  formData.append("image", image);

  const dispatch = useDispatch();

  const submit = () => {
    if (
      assestName === "" ||
      assestDate === "" ||
      saleAssestMonth === "" ||
      image === null
    )
      return toast.error("Some Feilds are missing");
    dispatch(addAssest(formData));
  };

  // const [totalPrice, setTotalPrice] = useState(0);

  // Function to calculate total amount including  VAT
  const calculateTotalAmount = (discount, vatAmount, vatType) => {
    const vat = vatType === "Taxable" ? parseFloat(vatAmount) : 0;

    const totalAmount = assestPrice - discount + vat;
    return totalAmount;

    // return totalAmount.toFixed(2); // You can adjust the precision as needed
  };

  useEffect(() => {
    // setTotalPrice(assestPrice);
  }, [assestPrice]);

  return (
    <>
      <div class="container mx-auto">
        <div class="lg:flex md:flex justify-center px-6 ">
          <Scrollbars style={{ width: 760, height: 600 }}>
            <div class="w-full lg:w-9/12 px-4">
              <div>
                <div
                  className=" transition duration-150 ease-in-out z-10  "
                  id="modal"
                >
                  <div
                    role="alert"
                    className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
                  >
                    <div className=" ">
                      <div className=" lg:flex md:flex justify-center">
                        <div className="border border-gray-100 w-auto h-auto bg-gray-200 py-4 px-3 rounded-[6px] ">
                          <div className="flex items-center px-3">
                            <p className="uppercase opacity-30 py-3">
                              Select Month
                            </p>
                            <div className="w-[75%] bg-green-600 h-[0.1px]  mx-2 "></div>
                          </div>
                          {/* <div className="p-2 lg:grid-cols-3  md:grid-cols-3 grid grid-flow-row gap-3 "> */}
                          <div className="flex">
                            <div className=" items-center">
                              <p className="text-red-500 px-3">
                                Please Select the Date
                              </p>

                              <NepaliDatePicker
                                className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0   "
                                value={assestDate}
                                onChange={(value) => setAssestDate(value)}
                                options={{
                                  calenderLocale: "ne",
                                  valueLocale: "en",
                                }}
                              />
                            </div>
                            <div>
                              <p className="uppercase text-green-800 py-1">
                                Bill No.
                              </p>
                              <input
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                value={billNo}
                                onChange={(e) => setBillNo(e.target.value)}
                                className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full"
                                placeholder="Enter Bill Number"
                              />
                            </div>
                          </div>

                          <div className="lg:flex md:flex gap-3 my-2">
                            <input
                              type="text"
                              className="border py-3 rounded-md w-full h-full placeholder:px-2 px-2 lg:my-0 md:y-0 my-2"
                              placeholder="EnterAssets Name"
                              value={assestName}
                              onChange={(e) => setAssestName(e.target.value)}
                            />
                            <input
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              className="border py-3 rounded-md w-full h-full placeholder:px-2 px-2"
                              placeholder="Enter Assets Price"
                              value={assestPrice}
                              onChange={(e) => {
                                setAssestPrice(e.target.value);
                                setForTransaction(e.target.value);
                              }}
                            />
                          </div>

                          <div className="lg:flex md:flex gap-3 ">
                            <input
                              type="text"
                              className="border py-3 rounded-md w-full h-full placeholder:px-2 px-2 lg:my-0 md:y-0 my-2"
                              placeholder="Enter Delearr/Shop Name"
                              value={shopName}
                              onChange={(e) => setShopName(e.target.value)}
                            />
                            <input
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              className="border py-3 rounded-md w-full h-full placeholder:px-2 px-2 lg:my-0 md:y-0 my-2"
                              placeholder="Enter  Registration Number"
                              value={registrationNumber}
                              onChange={(e) =>
                                setRegistrationNumber(e.target.value)
                              }
                            />
                          </div>
                          <div>
                            <p className="uppercase opacity-30 pt-3">
                              Discount
                            </p>
                            <div className="flex flex-col gap-2">
                              <div className="mb-4 w-full">
                                <select
                                  className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                                  value={discountType}
                                  onChange={(e) =>
                                    setDiscountType(e.target.value)
                                  }
                                >
                                  <option>No Discount(-0-)</option>
                                  <option>Amount Discount(Rs.)</option>
                                  <option>Percent Discount(%)</option>
                                </select>
                              </div>
                              <div className="w-full ">
                                {discountType === "Amount Discount(Rs.)" ? (
                                  <>
                                    <input
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      value={discount}
                                      onChange={(e) =>
                                        setDiscount(e.target.value)
                                      }
                                      placeholder={`Enter Discount-Amount(Rs.)`}
                                      className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                                    />
                                  </>
                                ) : discountType === "Percent Discount(%)" ? (
                                  <div className="lg:flex md:flex gap-3 my-2">
                                    <input
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      value={discountPercent}
                                      onChange={(e) => {
                                        const newDiscountPercent =
                                          e.target.value;
                                        setDiscountPercent(newDiscountPercent);
                                        const calculatedDiscount =
                                          newDiscountPercent *
                                          assestPrice *
                                          0.01;
                                        setDiscount(
                                          calculatedDiscount.toFixed(2)
                                        );
                                      }}
                                      placeholder={`Enter Discount-Percent(%)`}
                                      className={`border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 ${
                                        discountPercent > 100 ||
                                        discountPercent === 100
                                          ? "text-red-700 border-red-700"
                                          : null
                                      }`}
                                    />
                                    <input
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      value={discount}
                                      disabled
                                      className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            {discountType === "Percent Discount(%)" ? (
                              <p className="text-[14px] text-red-400 py-2">
                                Note: Discount Percent(%) should be less than or
                                equal to 100%
                              </p>
                            ) : null}
                          </div>
                          <div>
                            <p className="uppercase opacity-30 pt-3">VAT</p>
                            <div className="mb-4">
                              <select
                                className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                                value={vatType}
                                onChange={(e) => {
                                  const selectedVatType = e.target.value;
                                  setVatType(selectedVatType);
                                  if (selectedVatType === "Non Taxable") {
                                    setVatPercent(0);
                                    setVatAmount(0);
                                  }
                                }}
                              >
                                <option>Non Taxable</option>
                                <option>Taxable</option>
                              </select>
                              {vatType !== "Non Taxable" && vatType !== "" && (
                                <p className="text-[14px] text-gray-400 py-2">
                                  Note:- VAT Percent(%) should be less than or
                                  equal to 100%, not greater than 100%
                                </p>
                              )}
                            </div>

                            {vatType === "Taxable" ? (
                              <div className="lg:flex md:flex gap-3 my-2">
                                <input
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  value={vatPercent}
                                  onChange={(e) => {
                                    setVatPercent(e.target.value);
                                    const calculatedVatAmount = (
                                      (assestPrice - discount) *
                                      e.target.value *
                                      0.01
                                    ).toFixed(2);
                                    setVatAmount(calculatedVatAmount);
                                  }}
                                  placeholder={`Enter Taxable VAT-Percent(%)`}
                                  className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                                />
                                <input
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  disabled
                                  value={vatAmount}
                                  className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                                />
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <p className="uppercase opacity-30 pt-3">Credit</p>
                            <div className="mb-4">
                              <select
                                className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                                value={assessmentMethod}
                                onChange={(e) =>
                                  setAssessmentMethod(e.target.value)
                                }
                              >
                                <option>Select Credit Type</option>
                                <option>No Credit</option>
                                <option>Credit</option>
                                <option>Partial Credit</option>
                              </select>
                            </div>
                            {assessmentMethod === "No Credit" ? (
                              <input
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                disabled
                                value={calculateTotalAmount(
                                  discount,
                                  vatAmount,
                                  vatType
                                )}
                                onChange={(e) => {
                                  setCash(
                                    calculateTotalAmount(
                                      discount,
                                      vatAmount,
                                      vatType
                                    )
                                  );
                                  console.log(
                                    calculateTotalAmount(
                                      discount,
                                      vatAmount,
                                      vatType
                                    ),
                                    "here"
                                  );
                                }}
                                placeholder="Enter Cash Amount"
                                className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                              />
                            ) : assessmentMethod === "Credit" ? (
                              <input
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                disabled
                                value={calculateTotalAmount(
                                  discount,
                                  vatAmount,
                                  vatType
                                )}
                                onChange={(e) => setCredit(e.target.value)}
                                placeholder="Enter Credit Amount"
                                className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                              />
                            ) : assessmentMethod === "Partial Credit" ? (
                              <div className="lg:flex md:flex gap-3 my-2">
                                {/* Input field for Partial Cash */}
                                <div>
                                  <label
                                    htmlFor="partialCashInput"
                                    className="block mb-1"
                                  >
                                    Payment:
                                  </label>
                                  <input
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    id="partialCashInput"
                                    value={partial_cash}
                                    onChange={(e) => {
                                      setPartial_cash(e.target.value);
                                      setForTransaction(e.target.value);
                                      setBank_amount(e.target.value);
                                      setCash_amount(e.target.value);
                                      // Recalculate partial credit when partial cash changes
                                      const calculatedPartialCredit =
                                        calculateTotalAmount(
                                          discount,
                                          vatAmount,
                                          vatType
                                        ) - e.target.value;
                                      setPartial_credit(
                                        calculatedPartialCredit
                                      );
                                    }}
                                    placeholder="Enter the Amount"
                                    className="border rounded-md px-2 py-3 my-2 lg:my-0 md:my-0"
                                  />
                                </div>

                                {/* Display the calculated Partial Credit Amount */}
                                <div>
                                  <label
                                    htmlFor="partialCreditInput"
                                    className="block mb-1"
                                  >
                                    Credit:
                                  </label>
                                  <input
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    id="partialCreditInput"
                                    value={partial_credit}
                                    readOnly
                                    placeholder="Partial Credit Amount"
                                    className="border rounded-md px-2 py-3 my-2 lg:my-0 md:my-0"
                                  />
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <p className="uppercase opacity-90 text-bold pt-3">
                            Total Amount - Rs.
                            {calculateTotalAmount(discount, vatAmount, vatType)}
                          </p>
                          {assessmentMethod === "No Credit" ? (
                            <>
                              <p className="uppercase opacity-30 pt-3">
                                Transaction{" "}
                              </p>
                              <div className="lg:flex md:flex gap-3  my-4">
                                <select
                                  className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                                  name="transictionType"
                                  value={transctionType}
                                  onChange={(e) =>
                                    setTransctionType(e.target.value)
                                  }
                                >
                                  <option>Select Transaction Type</option>
                                  {caseType.map((item, i) => {
                                    return <option>{item.TypeName}</option>;
                                  })}
                                </select>

                                <div>
                                  <p>
                                    {transctionType === "Bank" ? (
                                      <input
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        readOnly
                                        value={calculateTotalAmount(
                                          discount,
                                          vatAmount,
                                          vatType
                                        )}
                                        // value={bank_amount}
                                        onChange={(e) => {
                                          console.log(e.target.value);
                                          setBank_amount(e.target.value);
                                        }}
                                        placeholder="Enter BANK Amount"
                                        className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                                      />
                                    ) : transctionType === "Cash" ? (
                                      <input
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        readOnly
                                        value={calculateTotalAmount(
                                          discount,
                                          vatAmount,
                                          vatType
                                        )}
                                        // value={cash_amount}
                                        onChange={(e) => {
                                          setCash_amount(e.target.value);
                                        }}
                                        placeholder="Enter CASH Amount"
                                        className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                                      />
                                    ) : transctionType === "Both" ? (
                                      <>
                                        <div className="">
                                          <input
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            value={both_Bank_amount}
                                            onChange={(e) => {
                                              setBoth_Bank_amount(
                                                e.target.value
                                              );
                                              console.log(e.target.value);
                                              const calculatedCash =
                                                calculateTotalAmount(
                                                  discount,
                                                  vatAmount,
                                                  vatType
                                                ) - e.target.value;
                                              console.log(
                                                calculatedCash,
                                                "calculated cash"
                                              );
                                              setBoth_Cash_amount(
                                                calculatedCash
                                              );
                                            }}
                                            placeholder="Enter  BANK Amount"
                                            className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                                          />
                                        </div>
                                        <div className="my-2">
                                          {" "}
                                          <input
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            value={both_Cash_amount}
                                            readOnly
                                            placeholder="Enter  CASH Amount"
                                            className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                                          />
                                        </div>
                                      </>
                                    ) : null}
                                  </p>
                                </div>
                              </div>
                            </>
                          ) : assessmentMethod === "Partial Credit" ? (
                            <>
                              <div className="lg:flex md:flex gap-3  my-4">
                                <select
                                  className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                                  name="transictionType"
                                  value={transctionType}
                                  onChange={(e) =>
                                    setTransctionType(e.target.value)
                                  }
                                >
                                  <option>Select Transaction Type</option>
                                  {caseType.map((item, i) => {
                                    return <option>{item.TypeName}</option>;
                                  })}
                                </select>

                                <div>
                                  <p>
                                    {transctionType === "Bank" ? (
                                      <input
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        readOnly
                                        value={bank_amount}
                                        // onChange={(e) => setBank_amount(e.target.value)}
                                        placeholder="Enter BANK Amount"
                                        className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                                      />
                                    ) : transctionType === "Cash" ? (
                                      <input
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        readOnly
                                        value={cash_amount}
                                        // onChange={(e) => setCash_amount(e.target.value)}
                                        placeholder="Enter CASH Amount"
                                        className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                                      />
                                    ) : transctionType === "Both" ? (
                                      <>
                                        <div className="flex ">
                                          <input
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            value={both_Bank_amount}
                                            onChange={(e) => {
                                              setBoth_Bank_amount(
                                                e.target.value
                                              );
                                              const calculatedCash =
                                                forTransaction - e.target.value;
                                              console.log(
                                                calculatedCash,
                                                "calculated cash"
                                              );
                                              setBoth_Cash_amount(
                                                calculatedCash
                                              );
                                            }}
                                            placeholder="Enter  BANK Amount"
                                            className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                                          />
                                        </div>
                                        <div className="my-2">
                                          <input
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            value={both_Cash_amount}
                                            placeholder="Enter  CASH Amount"
                                            className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                                          />
                                        </div>
                                      </>
                                    ) : null}
                                  </p>
                                </div>
                              </div>
                            </>
                          ) : null}

                          <div class="w-full">
                            <label
                              class="block mb-2 text-sm font-medium text-gray-900 "
                              for="file_input"
                            >
                              Upload Assest Image
                            </label>
                            <input
                              class="block md:w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50   focus:outline-none    "
                              id="file_input"
                              type="file"
                              name={image}
                              onChange={(e) => {
                                const file = e.currentTarget.files[0];
                                setImage(file);
                                file
                                  ? setPreviewBI(URL.createObjectURL(file))
                                  : setPreviewBI(null);
                              }}
                            />
                          </div>
                          <div>
                            {previeBI && (
                              <>
                                <img
                                  src={previeBI}
                                  alt=""
                                  className="w-[50px] h-[50px]"
                                />
                              </>
                            )}
                          </div>
                          <p
                            className="border bg-green-700 w-[200px] text-center text-white font-[500] text-xl py-2 px-3 rounded-[6px] my-4 uppercase "
                            onClick={submit}
                          >
                            Add Now
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
      </div>
    </>
  );
};
const caseType = [
  {
    TypeName: "Cash ",
  },
  {
    TypeName: "Bank ",
  },
  {
    TypeName: "Both ",
  },
];

const monthOrder = [
  "बैशाख",
  "जेठ",
  "असार",
  "श्रावण",
  "भाद्र",
  "असोज",
  "कार्तिक",
  "मंसिर",
  "पुष",
  "माघ",
  "फागुन",
  "चैत्र",
];
export default AddAssets;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProduct,
  filterProductByProductName,
} from "../../Redux/Feature/Product/productAction";
import Pagination from "./Pagination";

const th = [
  {
    name: "Product Name",
  },
  {
    name: "Product Code",
  },

  {
    name: "Product Quantity",
  },
  {
    name: "Product Price",
  },
  {
    name: "Product Total",
  },
];

const ProfuctList = () => {
  const token = localStorage.getItem("NHHD:token");

  const { data, count } = useSelector((state) => state.products);
  console.log(data, "products");
  // const [bill_id, setIbillId] = useState(null);
  const dispatch = useDispatch();

  // const DeleteProduct = (product_id) => {
  //   dispatch(dltProduct({ product_id: product_id, bill_id: bill_id, token }));
  // };

  const [productName, setProductName] = useState("");

  // const ProductIdClick = (id) => {
  //   dispatch(fetchProductById({ id, token }));
  // };
  useEffect(() => {
    async function fetch() {
      dispatch(fetchProduct({ token }));
      dispatch(filterProductByProductName({ productName, token }));
    }
    fetch();
  }, [dispatch, token, productName]);

  return (
    <>
      {/* filter-start */}

      <div class="relative mx-3 my-5">
        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <svg
            class="w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
        </div>
        <input
          type="search"
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          class="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-green-500 focus:border-green-500 dark:bg-gray-700  "
          placeholder="Search Product..."
          required
        />
        {/* <button
          onClick={findProduct}
          disabled={productName === "" ? true : false}
          class="text-white absolute right-2.5 bottom-2.5 bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2"
        >
          Search
        </button> */}
      </div>

      {/* filter-end */}

      <section class="container px-4 mx-auto">
        <div class="flex flex-col">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div class="overflow-hidden border border-gray-200 md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      {th.map((item, i) => {
                        return (
                          <th
                            scope="col col-span-5"
                            key={i}
                            class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                          >
                            {item.name}
                          </th>
                        );
                      })}
                      {/* <th
                        scope="col col-span-5"
                        class="px-[2rem] py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500"
                      >
                        Action
                      </th> */}
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    {data.length === 0 ? (
                      <p className="p-1 py-2">No Data Found!!</p>
                    ) : (
                      data.map((item, i) =>
                        item.purchaseFrom.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap cursor-not-allowed">
                                <div class="flex items-center gap-x-2">
                                  <div>
                                    <h2 class="text-sm capitalize font-medium text-gray-800   ">
                                      {item.productName}
                                    </h2>
                                  </div>
                                </div>
                              </td>
                              <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap cursor-not-allowed">
                                <div class="flex items-center gap-x-2">
                                  <div>
                                    <h2 class="text-sm capitalize font-medium text-gray-800   ">
                                      {item.productCode}
                                    </h2>
                                  </div>
                                </div>
                              </td>
                              <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap cursor-not-allowed">
                                <div class="flex items-center gap-x-2">
                                  <div>
                                    <h2 class="text-sm capitalize font-medium text-gray-800   ">
                                      {item.productQuantity}
                                    </h2>
                                  </div>
                                </div>
                              </td>
                              <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap cursor-not-allowed">
                                <div class="flex items-center gap-x-2">
                                  <div>
                                    <h2 class="text-sm capitalize font-medium text-gray-800   ">
                                      {item.productPrice}
                                    </h2>
                                  </div>
                                </div>
                              </td>
                              <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap cursor-not-allowed">
                                <div class="flex items-center gap-x-2">
                                  <div>
                                    <h2 class="text-sm capitalize font-medium text-gray-800   ">
                                      {item.productSubTotal}
                                    </h2>
                                  </div>
                                </div>
                              </td>
                              {/* <td class=" py-4 text-sm whitespace-nowrap">
                                <div class="flex items-center gap-x-2 mx-2">
                                  <BasicModal
                                    modalDetails={
                                      <UpdateQuantity id={item._id} />
                                    }
                                    btn={
                                      <button className="border bg-blue-600 text-white  uppercase  shadow-md py-2 font-bold px-1 cursor-pointer p-1 rounded-md">
                                        Add Quantity
                                      </button>
                                    }
                                    tittle={"Update Quantity"}
                                  />

                                  <button
                                    className="  transition-colors duration-200 text-red-500  border bg-white shadow-md p-2 rounded-md    focus:outline-none"
                                    onClick={() =>
                                      DeleteProduct(
                                        item._id,
                                        data.map((item) => setIbillId(item._id))
                                      )
                                    }
                                  >
                                    <DeleteForeverIcon />
                                  </button>
                                </div>
                              </td> */}
                            </tr>
                          );
                        })
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {productName === "" ? <Pagination page={1} count={count} /> : null}
        </div>
      </section>
    </>
  );
};

export default ProfuctList;

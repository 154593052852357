import React from "react";

import BillList from "./BillList";

import Tittle from "../../Components/Tittle/Tittle";

const index = (props) => {
  return (
    <>
      <div>
        <Tittle tittle={"Sale-bill"} />

        <div className="mt-[2rem]">
          <BillList />
        </div>
      </div>
    </>
  );
};

export default index;

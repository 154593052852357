import { createSlice } from "@reduxjs/toolkit";
import {
  getPurchaseReturn,
  getPurchaseReturnByMonth,
} from "./purchaseReturnAction";

const purchaseReturnSlice = createSlice({
  name: "purchaseReturn",
  initialState: {
    purchaseReturn: [],
    error: null,
    isLoading: true,
    count: 0,
    purchaseReturnByMonth: [],
  },
  reducers: {},
  extraReducers: {
    [getPurchaseReturn.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getPurchaseReturn.fulfilled]: (state, action) => {
      // console.log(action.payload);
      state.isLoading = false;
      state.count = action.payload.count;
      console.log(action.payload);
      state.purchaseReturn = action.payload.allPurchaseReturnAccount;
    },
    [getPurchaseReturn.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getPurchaseReturnByMonth.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getPurchaseReturnByMonth.fulfilled]: (state, action) => {
      // console.log(action.payload);
      state.isLoading = false;
      console.log(action.payload);
      state.purchaseReturnByMonth = action.payload.purchaseReturnAccount;
    },
    [getPurchaseReturnByMonth.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export const purchaseReturnReducer = purchaseReturnSlice.reducer;

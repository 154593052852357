import React from "react";
import Tittle from "../../Components/Tittle/Tittle";
import CustomerList from "./CustomerList";
import Modal from "../../Components/Modal/Modal";
import Button from "../../Components/Button/Button";
import AddToCreditReturn from "../Sale/CreditReturn/AddToCreditReturn";

const index = () => {
  return (
    <div>
      <Tittle tittle={"Customer List"} />
      <Modal
        btn={<Button btnName={"Recieved Credit"} />}
        modalDetails={<AddToCreditReturn />}
        tittle={"Payment Receipt"}
      />
      <CustomerList />
    </div>
  );
};

export default index;

import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useDispatch, useSelector } from "react-redux";
import { salePaymentRecived } from "../../../Redux/Feature/sale/saleAction";
import { toast } from "react-toastify";

import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";

const AddToCreditReturn = () => {
  const { sales } = useSelector((state) => state.sale);
  const saleData = sales
    .map((e) => {
      const checkPartialCredit = e.creditType === "Partial Credit";
      console.log(checkPartialCredit);
      const chekCredit = e.creditType === "Credit";
      const checkAmount = e.credit > 0;
      const checkParitialAmount = e.partial_credit > 0;
      if (checkAmount || checkParitialAmount) {
        if (chekCredit || checkPartialCredit) return e;
        return null;
      } else {
        return null;
      }
    })
    .filter(Boolean); /// remove null value if there is

  const [saleId, setSaleId] = useState("");
  const [amount, setAmount] = useState("");
  const [paidDate, setPaidDate] = useState("");
  const [transctionType, setTransctionType] = useState("");
  const [partial_cash, setPartial_cash] = useState("");
  const [partial_bank, setPartial_bank] = useState("");
  const dispatch = useDispatch();

  const monthInBS = parseInt(paidDate.slice(5, 7)) - 1;
  const yearInBS = paidDate.slice(0, 4);

  const currentMonthInBS = monthOrder[monthInBS];

  const paidMonth = currentMonthInBS + "-" + yearInBS;

  const submit = () => {
    if (saleId === "" || amount === "" || transctionType === "")
      return toast.error("Some Feild are missing Once chek");
    return dispatch(
      salePaymentRecived({
        saleId,
        amount,
        paidDate,
        transctionType,
        partial_bank,
        partial_cash,
        paidMonth,
      })
    );
  };

  return (
    <>
      <Scrollbars style={{ height: 500 }}>
        <div class="container mx-auto">
          <div class="lg:flex md:flex justify-center px-6 ">
            <div class="w-full lg:w-9/12 px-4">
              <div>
                <div
                  className=" transition duration-150 ease-in-out z-10  "
                  id="modal"
                >
                  <div
                    role="alert"
                    className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
                  >
                    <div className=" ">
                      <div className=" lg:flex md:flex justify-center">
                        <div className="border border-gray-100 w-auto h-auto bg-gray-200 py-4 px-3 rounded-[6px] ">
                          <div className="my-2 w-full items-center">
                            <p className="text-red-500 px-3">
                              Please Select the Date
                            </p>

                            <NepaliDatePicker
                              className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0   "
                              value={paidDate}
                              onChange={(value) => setPaidDate(value)}
                              options={{
                                calenderLocale: "ne",
                                valueLocale: "en",
                              }}
                            />
                          </div>
                          <select
                            className="border py-3 rounded-md  w-full placeholder:px-2 px-2 lg:my-0 md:y-0 my-2"
                            name="ret_supplierId"
                            value={saleId}
                            onChange={(e) => setSaleId(e.target.value)}
                          >
                            <option>Select CustomerName</option>
                            {saleData.map((item, i) => {
                              return (
                                <option value={item._id}>
                                  {item.customerName}-{item.customerPhone}-Rs.
                                  {item.creditType === "Credit"
                                    ? item.credit
                                    : item.creditType === "Partial Credit"
                                    ? item.partial_credit
                                    : 0}
                                </option>
                              );
                            })}
                          </select>
                          <div className="flex gap-3  ">
                            <div className="my-2 w-full">
                              <input
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                placeholder=" Enter Credit amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 w-full"
                              />
                            </div>
                          </div>
                          <>
                            <p className="uppercase opacity-30 pt-1">
                              Transiction{" "}
                            </p>
                            <div className="  gap-3  ">
                              <select
                                className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                                name="transictionType"
                                value={transctionType}
                                onChange={(e) =>
                                  setTransctionType(e.target.value)
                                }
                              >
                                <option>Select Transiction Type</option>
                                {caseType.map((item, i) => {
                                  return <option>{item.TypeName}</option>;
                                })}
                              </select>

                              <div className="my-2">
                                <p>
                                  {transctionType === "Both" && (
                                    <div className="flex gap-2">
                                      <div className="">
                                        <input
                                          type="number"
                                          onWheel={(e) => e.target.blur()}
                                          value={partial_bank}
                                          onChange={(e) =>
                                            setPartial_bank(e.target.value)
                                          }
                                          placeholder="Enter  BANK Amount"
                                          className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 w-full"
                                        />
                                      </div>
                                      <div className="">
                                        <input
                                          type="number"
                                          onWheel={(e) => e.target.blur()}
                                          value={partial_cash}
                                          onChange={(e) =>
                                            setPartial_cash(e.target.value)
                                          }
                                          placeholder="Enter  CASH Amount"
                                          className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 w-full"
                                        />
                                      </div>
                                    </div>
                                  )}
                                </p>
                              </div>
                            </div>
                          </>
                          <p
                            className="border bg-green-700 w-[200px] text-center text-white font-[500] text-xl py-2 px-3 rounded-[6px] my-4 uppercase cursor-pointer"
                            onClick={submit}
                          >
                            Retun
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Scrollbars>
    </>
  );
};

const caseType = [
  {
    TypeName: "Cash ",
  },
  {
    TypeName: "Bank ",
  },
  {
    TypeName: "Both ",
  },
];
const monthOrder = [
  "बैशाख",
  "जेठ",
  "असार",
  "श्रावण",
  "भाद्र",
  "असोज",
  "कार्तिक",
  "मंसिर",
  "पुष",
  "माघ",
  "फागुन",
  "चैत्र",
];

export default AddToCreditReturn;

import React, { useState } from "react";
import Button from "../../../Components/Button/Button";
import { useDispatch } from "react-redux";
import { addInventeryLossCategory } from "../../../Redux/Feature/InventeryExpenses/InventeryExpensesAction";

const AddInventeryCategory = () => {
  const [inventeryCategory, setInventeryCategory] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // New state for button disable

  const dispatch = useDispatch();
  const submit = () => {
    setIsButtonDisabled(false);
    dispatch(addInventeryLossCategory({ inventeryCategory })).then(() => {
      setIsButtonDisabled(true);
    });
  };
  return (
    <>
      <div className=" flex justify-center">
        <div className="border border-gray-100 w-auto h-auto bg-gray-200 py-4 rounded-[6px] ">
          {/* <p className="p-4 text-[1.5rem] text-[green]">Add Category</p> */}
          <div className="lg:flex md:flex gap-3 my-2 mx-2">
            <div className="flex  gap-3 my-2">
              <input
                type="text"
                name="category"
                value={inventeryCategory}
                onChange={(e) => setInventeryCategory(e.target.value)}
                className="border py-3 rounded-md placeholder:px-2 px-2"
                placeholder=" Inventery Category Name "
              />
            </div>
            <div className="flex justify-center my-3 gap-3 ">
              <Button
                btnName={"Add"}
                click={submit}
                isLoading={isButtonDisabled}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddInventeryCategory;

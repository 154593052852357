import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dltEmployee, getStaff } from "../../Redux/Feature/staff/staffAction";
import { Link, useNavigate } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { toast } from "react-toastify";
import { getEmployeeByPosition } from "../../Redux/Feature/staff/staffAction";
import { getEmployeeByStatus } from "../../Redux/Feature/staff/staffAction";
import { Spinner } from "../../Components/Spinner/Spinner";
import Pagination from "./Pagination";

const StaffList = () => {
  const { staff, count, isLoading } = useSelector((state) => state.employee);
  console.log(staff);

  const token = localStorage.getItem("NHHD:token");

  const dispatch = useDispatch();
  useEffect(() => {
    async function fetch() {
      dispatch(getStaff({ token }));
    }
    fetch();
  }, [dispatch, token]);

  const DeleteEmp = (id) => {
    dispatch(dltEmployee({ id, token }));
    toast.success("deleted success fully");
  };
  const [position, setPosition] = useState("");

  useEffect(() => {
    dispatch(getEmployeeByPosition({ token, position }));
  }, [dispatch, position, token]);

  const [status, setStatus] = useState("");

  useEffect(() => {
    async function fetch() {
      dispatch(getEmployeeByStatus({ status, token }));
    }
    fetch();
  }, [dispatch, status, token]);

  const navigate = useNavigate();
  const allClick = () => {
    navigate("/staff");
    return window.location.reload();
  };

  return (
    <>
      <div class="my-2 flex sm:flex-row flex-col">
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select class="h-full rounded-l border block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
              <option>{count}</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
          <div class="relative">
            <select
              class=" h-full rounded-r border-t sm:rounded-r-none sm:border-r-0 border-r border-b block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
              value={status}
              onClick={status === "All" ? allClick : null}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option>All</option>
              <option>Active</option>
              <option>Inactive</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
        <div class="block relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <svg viewBox="0 0 24 24" class="h-4 w-4 fill-current text-gray-500">
              <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
            </svg>
          </span>
          <input
            placeholder="Search By Position...."
            class="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
          />
        </div>
      </div>

      {/* <section className="container px-4 mx-auto flex gap-3">
        <div className="flex flex-col">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden border border-gray-200   md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200  ">
                  <thead className="bg-gray-50  ">
                    <tr>
                      {th.map((item, i) => {
                        return (
                          <th
                            scope="col col-span-5"
                            key={i}
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                          >
                            {item.name}
                          </th>
                        );
                      })}
                      <th
                        scope="col col-span-5"
                        className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  {isLoading === true ? (
                    <Spinner />
                  ) : (
                    <tbody className="bg-white divide-y divide-gray-200">
                      {staff.length === 0
                        ? "No data found"
                        : staff.map((item, i) => {
                            return (
                              <tr key={i}>
                                <td className="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                  <div className="inline-flex items-center gap-x-3">
                                    <span>{i + 1}</span>
                                  </div>
                                </td>
                                <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                  <div class="flex items-center gap-x-2">
                                    <div>
                                      <Link to={`${item._id}`}>
                                        {" "}
                                        <h2 class="text-sm capitalize font-medium text-gray-800   ">
                                          {item.employeeName}
                                        </h2>
                                      </Link>
                                    </div>
                                  </div>
                                </td>
                                <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                  <div class="flex items-center gap-x-2">
                                    <div>
                                      <h2 class="text-sm capitalize font-medium text-gray-800  ">
                                        {item.employeePhone}
                                      </h2>
                                    </div>
                                  </div>
                                </td>
                                <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                  <div class="flex items-center gap-x-2">
                                    <div>
                                      <h2 class="text-sm capitalize font-medium text-gray-800   ">
                                        {item.employeePosition}
                                      </h2>
                                    </div>
                                  </div>
                                </td>
                                <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                  <div class="flex items-center gap-x-2">
                                    <div className="lg:grid grid-cols-2 grid-flow-row ">
                                      <h2
                                        class="text-sm capitalize font-medium text-gray-800   "
                                        style={{
                                          color:
                                            item.employeeStatus === "Active"
                                              ? "green"
                                              : "red",
                                        }}
                                      >
                                        {item.employeeStatus}
                                      </h2>
                                    </div>
                                  </div>
                                </td>
                                <td class="px-4 py-4 text-sm whitespace-nowrap">
                                  <div class="flex items-center gap-x-6">
                                    <button class="  transition-colors duration-200 text-red-500  focus:outline-none">
                                      <DeleteForeverIcon
                                        onClick={() => DeleteEmp(item._id)}
                                      />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
          <Pagination page={1} count={count} />
        </div>
      </section> */}
      {/* new-tabel */}
      <div className="flex flex-col">
        <div className=" -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden border border-gray-200   md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200  ">
                <thead className="bg-gray-50  ">
                  <tr>
                    {th.map((item, i) => {
                      return (
                        <th
                          scope="col col-span-5"
                          key={i}
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                        >
                          {item.name}
                        </th>
                      );
                    })}
                    <th
                      scope="col col-span-5"
                      className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                {isLoading === true ? (
                  <Spinner />
                ) : (
                  <tbody className="bg-white divide-y divide-gray-200    ">
                    {staff.length === 0
                      ? "no data found"
                      : staff.slice(0, 20).map((item, i) => {
                          return (
                            <tr key={i}>
                              <td className="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                <div className="inline-flex items-center gap-x-3">
                                  <span>{i + 1}</span>
                                </div>
                              </td>

                              <td className="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                <div className="  items-center gap-x-2">
                                  <Link to={item._id}>
                                    <div>
                                      <h2 className="text-sm capitalize font-medium text-gray-800   ">
                                        {item.employeeName}
                                      </h2>
                                    </div>
                                  </Link>
                                </div>
                              </td>
                              <td className="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                <div className="flex items-center gap-x-2">
                                  <div>
                                    <h2 className="text-sm capitalize font-medium text-gray-800   ">
                                      {item.employeePhone}
                                    </h2>
                                  </div>
                                </div>
                              </td>
                              <td className="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                <div className="flex items-center gap-x-2">
                                  <div>
                                    <h2 className="text-sm capitalize font-medium text-gray-800   ">
                                      {item.employeePosition}
                                    </h2>
                                  </div>
                                </div>
                              </td>
                              <td className="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                <div className="flex items-center gap-x-2">
                                  <div>
                                    <h2
                                      className="text-sm capitalize font-medium text-gray-800"
                                      style={{
                                        color:
                                          item.employeeStatus === "Active"
                                            ? "green"
                                            : "red",
                                      }}
                                    >
                                      {item.employeeStatus}
                                    </h2>
                                  </div>
                                </div>
                              </td>

                              <td className="px-4 py-4 text-sm whitespace-nowrap">
                                <div className="flex items-center gap-x-6">
                                  <button
                                    className="  transition-colors duration-200 text-red-500  focus:outline-none"
                                    onClick={() => DeleteEmp(item._id)}
                                  >
                                    <DeleteForeverIcon />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
        <Pagination page={1} count={count} />
      </div>
    </>
  );
};

const th = [
  {
    name: "SN.",
  },
  {
    name: "Employee Name",
  },
  {
    name: "Employee Contact",
  },

  {
    name: "Employee Position",
  },
  {
    name: "Active",
  },
];
export default StaffList;

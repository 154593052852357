import React, { useEffect } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import Button from "../../Components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { getMaskebari } from "../../Redux/Feature/Maskebari/maskebariAction";
import { Autocomplete, TextField } from "@mui/material";
import { adToBs } from "@sbmdkl/nepali-date-converter";

const SubmitMaskebari = () => {
  const token = localStorage.getItem("NHHD:token");

  const { maskebari } = useSelector((state) => state.maskebari);
  const { data } = useSelector((state) => state.products);
  const { sales } = useSelector((state) => state.sale);

  console.log(maskebari, "sdfghjuyut");

  console.log(data);
  console.log(sales);

  // const [maskebariDetails, setMaskebariDetails] = useState([
  //   {
  //     purchase: {
  //       purchaseImportAmountWithImportVAT: 0,
  //       purchaseImportVATamount: 0,
  //       purchaseAmountWithVAT: 0,
  //       purchaseVATamount: 0,
  //       adjustmentMadeInReturnPurchase: 0,
  //     },
  //     sale: {
  //       saleNonTaxableAmount: 0,
  //       saleAmountWithVat: 0,
  //       saleVATamount: 0,
  //       adjustmentMadeInReturnSale: 0,
  //     },
  //     month: "",
  //   },
  // ]);

  const dispatch = useDispatch();

  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
  const day = String(today.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  const dateInBS = adToBs(formattedDate);

  // const monthInBS = parseInt(dateInBS.slice(5, 7)) - 1;
  const yearInBS = dateInBS.slice(0, 4);

  // const currentMonthInBS = monthOrder[monthInBS];

  // const currentDate = currentMonthInBS + "-" + yearInBS;

  // Helper function to calculate sums
  function calculateSums(data) {
    if (!data) {
      return {
        amountWithVAT: 0,
        VATamount: 0,
        VATamountImport: 0,
        costingIncludeVAT: 0,
      };
    }

    return data.reduce(
      (acc, item) => {
        acc.amountWithVAT += parseFloat(item.amountWithVAT || 0);
        acc.VATamount += parseFloat(item.VATamount || 0);
        acc.VATamountImport += parseFloat(item.VATamountImport || 0);
        acc.costingIncludeVAT += parseFloat(item.costingIncludeVAT || 0);
        return acc;
      },
      {
        amountWithVAT: 0,
        VATamount: 0,
        VATamountImport: 0,
        costingIncludeVAT: 0,
      }
    );
  }

  // Process purchase data
  const purchaseByMonth = {};
  data.forEach((item) => {
    const month = item.purchaseMonth || "Unknown";
    if (!purchaseByMonth[month]) {
      purchaseByMonth[month] = [];
    }
    purchaseByMonth[month].push(item);
  });

  // Process sale data
  const saleByMonth = {};
  sales.forEach((item) => {
    const month = item.saleMonth || "Unknown";
    if (!saleByMonth[month]) {
      saleByMonth[month] = [];
    }
    saleByMonth[month].push(item);
  });
  console.log(purchaseByMonth);
  console.log(saleByMonth);
  // Combine data for each month
  const combinedData = monthOrder.map((month, index) => {
    const purchaseSum = calculateSums(
      purchaseByMonth[`${month}-${yearInBS}`] || []
    );
    const saleSum = calculateSums(saleByMonth[`${month}-${yearInBS}`] || []);

    return {
      purchase: {
        purchaseImportAmountWithImportVAT: purchaseSum.costingIncludeVAT,
        purchaseImportVATamount: purchaseSum.VATamountImport,
        purchaseAmountWithVAT: purchaseSum.amountWithVAT,
        purchaseVATamount: purchaseSum.VATamount,
        adjustmentMadeInReturnPurchase: 0,
      },
      sale: {
        saleNonTaxableAmount: saleSum.nonTaxableAmount || 0,
        saleAmountWithVat: saleSum.amountWithVat || 0,
        saleVATamount: saleSum.vatAmount || 0,
        adjustmentMadeInReturnSale: 0,
      },
      month: `${month}-${yearInBS}`,
    };
  });

  console.log(combinedData);

  // const [selectedMonth, setSelectedMonth] = React.useState(currentDate);
  const [selectedData, setSelectedData] = React.useState([]);

  const handleOptionChange = (event, item) => {
    if (item) {
      console.log("Selected option object:", item.month);
      // setSelectedMonth(item.month);
      setSelectedData(item);
    } else {
      // console.log("Selected option cleared");
      // setSelectedMonth(null);
      setSelectedData(null);
    }
  };
  console.log(selectedData);
  useEffect(() => {
    async function fetch() {
      dispatch(getMaskebari({ token }));
    }
    fetch();
  }, [dispatch, token]);

  return (
    <>
      <Scrollbars style={{ height: 500 }}>
        {" "}
        <div class="container mx-auto">
          <div class="lg:flex md:flex justify-center px-6 ">
            <div class="w-full lg:w-9/12 px-4">
              <div>
                <div
                  className=" transition duration-150 ease-in-out z-10  "
                  id="modal"
                >
                  <div
                    role="alert"
                    className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
                  >
                    <div className=" ">
                      <div className=" lg:flex md:flex justify-center">
                        <div className="border border-gray-100 w-auto h-auto bg-gray-200 py-4 px-3 rounded-[6px] ">
                          <div>
                            <div className="flex items-center">
                              <p className="uppercase opacity-30 py-3">
                                Select Month
                              </p>
                              <div className="w-[85%] bg-green-600 h-[0.1px]  mx-2 "></div>
                            </div>
                            <div className="w-[300px] my-4 mx-4">
                              <Autocomplete
                                options={combinedData}
                                // defaultValue={currentDate}
                                // value={selectedMonth}
                                getOptionLabel={(item) => {
                                  //   console.log("getOptionLabel:", item);
                                  return item.month;
                                }}
                                renderInput={(params, i) => (
                                  <TextField
                                    {...params}
                                    key={i}
                                    label="Select an option"
                                    variant="filled"
                                  />
                                )}
                                onChange={handleOptionChange}
                                sx={{ mt: 1 }}
                              />
                            </div>
                          </div>
                          <div>
                            <div className="flex items-center">
                              <p className="uppercase opacity-30 py-3">
                                Purchase Data
                              </p>
                              <div className="w-[85%] bg-green-600 h-[0.1px]  mx-2 "></div>
                            </div>
                            <div className="flex gap-6">
                              <div>
                                <p className="uppercase text-green-800 py-1">
                                  Import Amount
                                </p>

                                <input
                                  readOnly
                                  value={
                                    selectedData?.purchase
                                      ?.purchaseImportAmountWithImportVAT
                                  }
                                  className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 "
                                />
                              </div>
                              <div>
                                <p className="uppercase text-green-800 py-1">
                                  Import Vat
                                </p>

                                <input
                                  readOnly
                                  value={
                                    selectedData?.purchase
                                      ?.purchaseImportVATamount
                                  }
                                  className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 "
                                />
                              </div>
                            </div>
                            <div className="flex gap-6">
                              <div>
                                <p className="uppercase text-green-800 py-1">
                                  Local Amount
                                </p>

                                <input
                                  readOnly
                                  value={
                                    selectedData?.purchase
                                      ?.purchaseAmountWithVAT
                                  }
                                  className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 "
                                />
                              </div>
                              <div>
                                <p className="uppercase text-green-800 py-1">
                                  Local Vat
                                </p>

                                <input
                                  readOnly
                                  value={
                                    selectedData?.purchase?.purchaseVATamount
                                  }
                                  className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 "
                                />
                              </div>
                            </div>
                            <div>
                              <p className="uppercase text-green-800 py-1">
                                Total Purchase Vat
                              </p>

                              <input
                                readOnly
                                value={
                                  selectedData?.purchase
                                    ?.purchaseImportVATamount +
                                  selectedData?.purchase?.purchaseVATamount
                                }
                                className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 "
                              />
                            </div>
                          </div>
                          <div>
                            <div className="flex items-center">
                              <p className="uppercase opacity-30 py-3">
                                Sales Data
                              </p>
                              <div className="w-[85%] bg-green-600 h-[0.1px]  mx-2 "></div>
                            </div>
                            <div className="flex gap-6">
                              <div>
                                <p className="uppercase text-green-800 py-1">
                                  Non Taxable Amount
                                </p>

                                <input
                                  readOnly
                                  value={
                                    selectedData?.sale?.saleNonTaxableAmount
                                  }
                                  className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 "
                                />
                              </div>
                            </div>
                            <div className="flex gap-6">
                              <div>
                                <p className="uppercase text-green-800 py-1">
                                  Local Amount
                                </p>

                                <input
                                  readOnly
                                  value={selectedData?.sale?.saleAmountWithVat}
                                  className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 "
                                />
                              </div>
                              <div>
                                <p className="uppercase text-green-800 py-1">
                                  Vat
                                </p>

                                <input
                                  readOnly
                                  value={selectedData?.sale?.saleVATamount}
                                  className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 "
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex justify-center my-3 gap-3 ">
                            <Button
                              btnName={"Submit"}
                              //   click={addToPurchase}
                              //   isLoading={isButtonDisabled}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Scrollbars>
    </>
  );
};

export default SubmitMaskebari;

const monthOrder = [
  "श्रावण",
  "भाद्र",
  "असोज",
  "कार्तिक",
  "मंसिर",
  "पुष",
  "माघ",
  "फागुन",
  "चैत्र",
  "बैशाख",
  "जेठ",
  "असार",
];

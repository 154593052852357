import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addEmployeeBonus } from "../../Redux/Feature/EmployeeSalary/EmpSalaryAction";
import { useParams } from "react-router-dom";

const AddBonus = () => {
  const [transctionType, setTransctionType] = useState("");
  const [bonusAmount, setBonusAmount] = useState("");
  const [reason, setReason] = useState("");
  const [dateOfPayment, setDateOfPayment] = useState("");
  const [partial_bank, setPartial_bank] = useState("");
  const [partial_cash, setPartial_cash] = useState("");
  const params = useParams();
  console.log(params.id);
  const dispatch = useDispatch();
  const submit = () => {
    dispatch(
      addEmployeeBonus({
        transctionType,
        employeeId: params.id,
        bonusAmount,
        reason,
        dateOfPayment,
        partial_bank,
        partial_cash,
      })
    );
  };

  return (
    <>
      <div class="container mx-auto">
        <div class="lg:flex md:flex justify-center px-6 ">
          <div class="w-full lg:w-9/12 px-4">
            <div>
              <div
                className=" transition duration-150 ease-in-out z-10  "
                id="modal"
              >
                <div
                  role="alert"
                  className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
                >
                  <div className=" ">
                    <div className=" lg:flex md:flex justify-center">
                      <div className="border border-gray-100 w-auto h-auto bg-gray-200 py-4 px-3 rounded-[6px] ">
                        <div className="flex gap-2">
                          <div className="w-full h-full">
                            <input
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              className={`  py-3 rounded-md w-full h-full placeholder:px-2 px-2`}
                              placeholder="Enter Amount"
                              value={bonusAmount}
                              onChange={(e) => setBonusAmount(e.target.value)}
                            />
                          </div>
                          <div className="w-full h-full">
                            <input
                              type="text"
                              className={`  py-3 rounded-md w-full h-full placeholder:px-2 px-2`}
                              placeholder="Enter Reason"
                              value={reason}
                              onChange={(e) => setReason(e.target.value)}
                            />
                          </div>
                        </div>

                        <>
                          <p className="uppercase opacity-30 pt-3">
                            Transiction
                          </p>
                          <div className="  gap-3  my-4">
                            <select
                              className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                              name="transictionType"
                              value={transctionType}
                              onChange={(e) =>
                                setTransctionType(e.target.value)
                              }
                            >
                              <option>Select Transiction Type</option>
                              {caseType.map((item, i) => {
                                return <option>{item.TypeName}</option>;
                              })}
                            </select>

                            <div className="my-2">
                              <p>
                                {transctionType === "Both" && (
                                  <div className="flex gap-2">
                                    <div className="w-full h-full">
                                      {" "}
                                      <input
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        value={partial_bank}
                                        onChange={(e) =>
                                          setPartial_bank(e.target.value)
                                        }
                                        placeholder="Enter  BANK Amount"
                                        className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 w-full "
                                      />
                                    </div>
                                    <div className="w-full h-full">
                                      {" "}
                                      <input
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        value={partial_cash}
                                        onChange={(e) =>
                                          setPartial_cash(e.target.value)
                                        }
                                        placeholder="Enter  CASH Amount"
                                        className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 w-full"
                                      />
                                    </div>
                                  </div>
                                )}
                              </p>
                            </div>
                          </div>
                          <input
                            type="Date"
                            className={`border py-3 rounded-md placeholder:px-2 px-2 w-full my-2 `}
                            value={dateOfPayment}
                            onChange={(e) => setDateOfPayment(e.target.value)}
                          />
                          <button
                            className="border bg-green-700 w-[200px] text-center text-white font-[500] text-xl py-2 px-3 rounded-[6px] my-4 uppercase"
                            onClick={submit}
                          >
                            Give Now
                          </button>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const caseType = [
  {
    TypeName: "Cash ",
  },
  {
    TypeName: "Bank ",
  },
  {
    TypeName: "Both ",
  },
];

export default AddBonus;

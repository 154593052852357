import React from "react";
import { useSelector } from "react-redux";

const TranscationDetails = () => {
  const { singleSale } = useSelector((state) => state.sale);
  console.log(singleSale);
  return (
    <div>
      <section class="container px-1 mx-auto">
        <div class="flex flex-col">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-2 lg:px-2">
              <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200  ">
                  <thead class="bg-gray-50  ">
                    <tr>
                      {th.map((item, i) => {
                        return (
                          <th
                            scope="col col-span-5"
                            key={i}
                            class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                          >
                            {item.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-gray-200    ">
                    {singleSale && singleSale.length === 0
                      ? "no data found"
                      : singleSale &&
                        singleSale.paymentDetails
                          .slice(0, 20)
                          .map((item, i) => {
                            return (
                              <tr key={i}>
                                <td className="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                  <div className="inline-flex items-center gap-x-3">
                                    <span>{item.date.slice(0, 10)}</span>
                                  </div>
                                </td>
                                <td className="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                  <div className="inline-flex items-center gap-x-3">
                                    <span>{item.amount}</span>
                                  </div>
                                </td>
                                <td className="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                  <div className="inline-flex items-center gap-x-3">
                                    <span>{item.transactionType}</span>
                                  </div>
                                </td>
                                <td className="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                  <div className="inline-flex items-center gap-x-3">
                                    <p
                                      className={`uppercase  text-white  font-bold  p-2 rounded-md ${
                                        item.status === "First payment"
                                          ? "bg-red-700"
                                          : item.status === "Payment Recived"
                                          ? "bg-green-700"
                                          : item.status === "sale return"
                                          ? "bg-blue-700"
                                          : null
                                      }`}
                                    >
                                      {item.status}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
// sale return
const th = [
  {
    name: "Date.",
  },
  {
    name: "Amount",
  },
  {
    name: "Transcation Type",
  },
  {
    name: "T.Type",
  },
];
export default TranscationDetails;

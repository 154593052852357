import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { saleReturn } from "../../../Redux/Feature/sale/saleAction";

import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
import Button from "../../../Components/Button/Button";

const ReturnSale = () => {
  // const { sales } = useSelector((state) => state.sale);
  const { data } = useSelector((state) => state.products);
  const { sales } = useSelector((state) => state.sale);
  console.log(sales);
  const [saleId, setSaleId] = useState("");
  console.log(sales, "sales");
  console.log(data, "data");

  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // New state for button disable

  const [returnSale, setReturnSale] = useState([
    {
      ret_productId: "",
      ret_productQuantity: "",
      ret_productPrice: "",
    },
  ]);

  // const [month, setMonth] = useState("");
  const [saleReturnDate, setSaleReturnDate] = useState("");
  // const [saleReturnBillNo, setSaleReturnBillNo] = useState("");
  const [customerName, setCustomerName] = useState("");

  const [transactionType, setTransactionType] = useState("");
  // const [bank, setBank] = useState("");
  // const [cash, setCash] = useState("");
  const [partialCash, setPartialCash] = useState("");
  const [partialBank, setPartialBank] = useState("");

  // const [registrationNumber, setRegistrationNumber] = useState("");
  // const [vatAmount, setVatAmount] = useState("");
  // const [nonTaxableAmount, setNonTaxableAmount] = useState("");

  const saleData = sales
    .filter((ele) => ele._id === saleId)
    .map((ele) => ele.soldProducts);
  console.log(saleData, "saleData");

  const x = sales.filter((ele) => ele._id === saleId).map((ele) => ele);

  console.log(x);

  const newSaleData = saleData.flat();
  console.log(newSaleData);

  const details = data.map((item) =>
    item.purchaseFrom.filter((ele) =>
      newSaleData.find(({ productId }) => productId === ele._id.toString())
    )
  );
  console.log(details);

  const newDetails = details.flat();
  console.log(newDetails);

  const productData = data.map((item) =>
    item.purchaseFrom.filter((ele) =>
      newSaleData.find(({ productId }) => productId === ele._id.toString())
    )
  );
  const newprodctName = productData.flat();

  const onChangeFeilds = (e, i) => {
    const { name, value } = e.target;
    const list = [...returnSale];
    list[i][name] = value;
    setReturnSale(list);
  };

  const removeClick = (i) => {
    const list = [...returnSale];
    list.splice(1, 1);
    setReturnSale(list);
  };

  const addClick = () => {
    setReturnSale([
      ...returnSale,
      {
        returnSale,
      },
    ]);
  };

  const monthInBS = parseInt(saleReturnDate.slice(5, 7)) - 1;
  const yearInBS = saleReturnDate.slice(0, 4);

  const currentMonthInBS = monthOrder[monthInBS];

  const saleReturnMonth = currentMonthInBS + "-" + yearInBS;

  const dispatch = useDispatch();
  const token = localStorage.getItem("NHHD:token");

  console.log(saleData, "fhajhfh");
  const registrationNumber = x[0]?.registrationNumber;
  const vatPercent = x[0]?.vatPercent;

  console.log(vatPercent);
  console.log(saleData, "datasale");
  console.log(x, "x");

  const nonTaxableAmount = x[0]?.nonTaxableAmount;
  console.log(customerName);

  console.log(returnSale);

  const vatAmount = returnSale.reduce((accumulator, product) => {
    console.log(product);
    console.log(vatPercent);
    const productResult =
      product.ret_productQuantity *
      product.ret_productPrice *
      vatPercent *
      0.01;
    return accumulator + productResult;
  }, 0);

  const totalAmountAfterVat = returnSale.reduce((accumulator, product) => {
    const productResult =
      product.ret_productQuantity *
      product.ret_productPrice *
      vatPercent *
      0.01;
    const result =
      product.ret_productPrice * product.ret_productQuantity + productResult;
    return accumulator + result;
  }, 0);

  console.log(vatAmount, totalAmountAfterVat);
  let bank = 0;
  let cash = 0;
  if (transactionType === "Cash") {
    cash = totalAmountAfterVat;
    // bank = 0;
  }
  if (transactionType === "Bank") {
    // cash = 0;
    bank = totalAmountAfterVat;
  }
  const submit = () => {
    if (
      saleId === "" ||
      returnSale === "" ||
      saleReturnDate === null ||
      saleReturnDate === ""
    ) {
      toast.error("Some Feilds are missing");
    } else {
      setIsButtonDisabled(true);
      dispatch(
        saleReturn({
          token,
          saleId,
          returnSale,
          saleReturnMonth,
          saleReturnDate,
          registrationNumber,
          nonTaxableAmount,
          vatAmount,
          customerName,
          transactionType,
          partialBank,
          partialCash,
          bank,
          cash,
        })
      ).then(() => {
        setIsButtonDisabled(false);
      });
    }
  };

  return (
    <>
      <Scrollbars style={{ height: 500 }}>
        <div class="container mx-auto">
          <div class="lg:flex md:flex justify-center px-6 ">
            <div class="w-full lg:w-9/12 px-4">
              <div>
                <div
                  className=" transition duration-150 ease-in-out z-10  "
                  id="modal"
                >
                  <div
                    role="alert"
                    className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
                  >
                    <div className=" ">
                      <div className=" lg:flex md:flex justify-center">
                        <div className="border border-gray-100 w-auto h-auto bg-gray-200 py-4 px-3 rounded-[6px] ">
                          <div className="flex">
                            <div className=" items-center">
                              <p className="text-red-500 px-3">
                                Please Select the Date
                              </p>
                              <NepaliDatePicker
                                className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0   "
                                value={saleReturnDate}
                                onChange={(value) => setSaleReturnDate(value)}
                                options={{
                                  calenderLocale: "ne",
                                  valueLocale: "en",
                                }}
                              />{" "}
                            </div>
                          </div>
                          <div>
                            <select
                              className="border py-3 rounded-md  w-full placeholder:px-2 px-2 lg:my-0 md:y-0 my-2"
                              name="ret_supplierId"
                              value={saleId}
                              onChange={(e) => {
                                setSaleId(e.target.value);
                                const selectedSale = sales.find(
                                  (item) => item._id === e.target.value
                                );
                                if (selectedSale) {
                                  setCustomerName(selectedSale.customerName);
                                } else {
                                  setCustomerName(""); // Reset customerName if no sale is selected
                                }
                              }}
                            >
                              <option>Select Customer Name</option>
                              {sales.map((item, i) => {
                                return (
                                  <option value={item._id}>
                                    {item.customerName}-{item.customerPhone}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          {saleId && (
                            <div>
                              <table class="min-w-full divide-y divide-gray-200  ">
                                <thead class="bg-gray-50  ">
                                  <tr>
                                    {th.map((item, i) => {
                                      return (
                                        <th
                                          scope="col col-span-5"
                                          key={i}
                                          class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                                        >
                                          {item.name}
                                        </th>
                                      );
                                    })}
                                  </tr>
                                </thead>

                                <tbody className="bg-white divide-y divide-gray-200    ">
                                  <tr>
                                    <td className="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                      <div className="items-center gap-x-3">
                                        {newDetails.map((ele) => {
                                          console.log(ele);
                                          return (
                                            <h2 className="text-sm capitalize font-medium text-gray-800  py-1 ">
                                              {ele.productName}
                                            </h2>
                                          );
                                        })}
                                      </div>
                                    </td>

                                    <td className="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                      <div className="flex items-center gap-x-2">
                                        <div>
                                          {newSaleData.map((ele) => {
                                            return (
                                              <h2 className="text-sm capitalize font-medium text-gray-800   py-1">
                                                {ele.soldQuantity}
                                              </h2>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                      <div className="flex items-center gap-x-2">
                                        <div>
                                          {newSaleData.map((ele) => {
                                            return (
                                              <h2 className="text-sm capitalize font-medium text-gray-800  py-1 ">
                                                {ele.soldPrice}
                                              </h2>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          )}

                          {returnSale.map((item, i) => {
                            return (
                              <>
                                <div className="lg:flex md:flex gap-3 my-2">
                                  <select
                                    className="border py-3 rounded-md w-full h-full placeholder:px-2 px-2 lg:my-0 md:y-0 my-2"
                                    name="ret_productId"
                                    onChange={(e) => onChangeFeilds(e, i)}
                                  >
                                    <option>Select Product</option>

                                    {newprodctName.map((ele) => {
                                      return (
                                        <option value={ele._id}>
                                          {ele.productName}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                                <div className="lg:flex md:flex gap-3 ">
                                  <div>
                                    <input
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      name="ret_productQuantity"
                                      onChange={(e) => onChangeFeilds(e, i)}
                                      className="border py-3 rounded-md w-full h-full placeholder:px-2 px-2 lg:my-0 md:y-0 my-2"
                                      placeholder="Enter Product Quantity"
                                    />
                                  </div>
                                  <div>
                                    {" "}
                                    <input
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      name="ret_productPrice"
                                      onChange={(e) => onChangeFeilds(e, i)}
                                      className="border py-3 rounded-md w-full h-full placeholder:px-2 px-2 lg:my-0 md:y-0 my-2"
                                      placeholder="Enter Product Price"
                                    />
                                  </div>
                                </div>

                                <div className="flex gap-3 my-2">
                                  {returnSale.length !== 1 && (
                                    <p
                                      onClick={removeClick}
                                      className="border border-black-600 bg-[white] p-2 text-red-800 hover:bg-red-800 hover:text-white text-bold rounded-[6px] "
                                    >
                                      <AiOutlineMinus />
                                    </p>
                                  )}
                                  {returnSale.length - 1 === i && (
                                    <p
                                      onClick={addClick}
                                      className="border border-black-600 bg-[white] p-2 text-green-800 hover:bg-green-800 hover:text-white text-bold rounded-[6px] "
                                    >
                                      <AiOutlinePlus />
                                    </p>
                                  )}
                                </div>
                              </>
                            );
                          })}

                          <div className="flex flex-col p-1 ">
                            <div>
                              <p>Customer Registration Number</p>
                              <input
                                className="w-full"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                readOnly
                                value={x[0]?.registrationNumber}
                              />
                            </div>
                            <div className="flex items-center  gap-3">
                              <div>
                                <p>VAT Percent</p>
                                <input
                                  className="w-full"
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  readOnly
                                  value={vatPercent}
                                />
                              </div>
                              <div>
                                <p>Total VAT Amount</p>
                                <input
                                  className="w-full"
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  readOnly
                                  value={vatAmount}
                                />
                              </div>
                              <div>
                                <p>Total Amount After VAT</p>
                                <input
                                  className="w-full"
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  readOnly
                                  value={totalAmountAfterVat}
                                />
                              </div>
                            </div>

                            <div>
                              {nonTaxableAmount !== undefined ? (
                                <div>
                                  <p>Total Non Taxable Amount</p>
                                  <input
                                    className="w-full"
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    readOnly
                                    value={nonTaxableAmount}
                                  />
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <>
                            <div className="lg:flex md:flex gap-3  my-4">
                              <select
                                className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                                name="transictionType"
                                value={transactionType}
                                onChange={(e) =>
                                  setTransactionType(e.target.value)
                                }
                              >
                                <option>Select Transiction Type</option>
                                {caseType.map((item, i) => {
                                  return <option>{item.TypeName}</option>;
                                })}
                              </select>

                              <div>
                                <p>
                                  {transactionType === "Bank" ? (
                                    <div>
                                      <input
                                        readOnly
                                        type="number"
                                        value={bank}
                                        className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                                      />
                                    </div>
                                  ) : transactionType === "Cash" ? (
                                    <div>
                                      <input
                                        readOnly
                                        type="number"
                                        value={cash}
                                        className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                                      />
                                    </div>
                                  ) : transactionType === "Both" ? (
                                    <>
                                      <div className="flex ">
                                        <input
                                          type="number"
                                          onWheel={(e) => e.target.blur()}
                                          value={partialBank}
                                          onChange={(e) => {
                                            setPartialBank(e.target.value);
                                            // console.log(
                                            //   forTranscation,
                                            //   "here"
                                            // );
                                            // const calculatedCash =
                                            //   forTransaction -
                                            //   e.target.value;
                                            // console.log(
                                            //   calculatedCash,
                                            //   "calculated cash"
                                            // );
                                            // setPartialCash(
                                            //   calculatedCash
                                            // );
                                          }}
                                          placeholder="Enter  BANK Amount"
                                          className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                                        />
                                      </div>
                                      <div className="my-2">
                                        <input
                                          type="number"
                                          onWheel={(e) => e.target.blur()}
                                          value={partialCash}
                                          onChange={(e) => {
                                            setPartialCash(e.target.value);
                                          }}
                                          placeholder="Enter  CASH Amount"
                                          className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                                        />
                                      </div>
                                    </>
                                  ) : null}
                                </p>
                              </div>
                            </div>
                          </>
                          <div className="flex justify-center my-3 gap-3 ">
                            <Button
                              btnName={"Return"}
                              click={submit}
                              isLoading={isButtonDisabled}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Scrollbars>
    </>
  );
};
const th = [
  {
    name: "Product Name.",
  },

  {
    name: "Quantity",
  },
  {
    name: "Price",
  },
];

const caseType = [
  {
    TypeName: "Cash ",
  },
  {
    TypeName: "Bank ",
  },
  {
    TypeName: "Both ",
  },
];

const monthOrder = [
  "बैशाख",
  "जेठ",
  "असार",
  "श्रावण",
  "भाद्र",
  "असोज",
  "कार्तिक",
  "मंसिर",
  "पुष",
  "माघ",
  "फागुन",
  "चैत्र",
];
export default ReturnSale;

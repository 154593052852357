import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../.././utils/API";
import { toast } from "react-toastify";
export const addAssest = createAsyncThunk("post/assests", async (payload) => {
  try {
    const response = await api.post("/assest/add-assests", payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
      },
    });
    if (response.data.success === true) {
      toast.success(response.data.message);
      window.location.reload();
    } else {
      toast.success(response.data.message);
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const fetchAssest = createAsyncThunk(
  `get/assest`,
  async ({ pageNo }) => {
    try {
      const response = await api.get(
        `/assest/all-assests?limit=${10}&page=${pageNo}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchAssestById = createAsyncThunk(
  "get/assest/id",
  async ({ id }) => {
    console.log(id);
    try {
      const response = await api.get(`/assest/find-assests-by/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
        },
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const deleteAssestById = createAsyncThunk(
  "delete/assest/id",
  async (payload) => {
    try {
      const response = await api.post(`/assest/delete-assests`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
        },
      });

      if (response.data.success === true) {
        toast.success(response.data.message);
        window.location.reload();
      } else {
        toast.success(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addAssestSale = createAsyncThunk(
  "post/assests-sale",
  async (payload) => {
    try {
      const response = await api.post("/assest/sold-assests", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
        },
      });
      if (response.data.success === true) {
        toast.success(response.data.message);
        window.location.reload();
      } else {
        toast.success(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchAssestSale = createAsyncThunk("get/assest-all", async () => {
  try {
    const response = await api.get(`/assest/all-sold-assests`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const assestPaymentRecived = createAsyncThunk(
  "post/assests-sale-payment",
  async (payload) => {
    try {
      const response = await api.post(
        "/assest/credit-assest-payment",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
          },
        }
      );
      if (response.data.success === true) {
        toast.success(response.data.message);
        window.location.reload();
      } else {
        toast.success(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const assetPayCredit = createAsyncThunk(
  "asset/pay-credit",
  async (payload) => {
    console.log(payload, "payload");
    try {
      const response = await api.post("assest/pay-credit-assests", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
        },
      });
      if (response.data.success === true) {
        toast.success(response.data.message);
        window.location.reload();
      } else {
        toast.info(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

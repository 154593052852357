import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addExpenses } from "../../Redux/Feature/Expenses/expensesAction";
import { toast } from "react-toastify";
import Button from "../../Components/Button/Button";

import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";

const AddExpense = () => {
  const [expenseName, setExpenseName] = useState("");
  const [expenseDescription, setExpenseDescription] = useState("");
  const [expenseAmount, setExpenseAmount] = useState("");
  const [expenseDate, setEpenseDate] = useState("");
  const [expensesCategorys, setExpensesCategorys] = useState("");
  const [transctionType, setTransctionType] = useState("");
  const [transctionCash, setTransctionCash] = useState("");
  const [transctionBank, setTransctionBank] = useState("");
  const [transction_B_Cash, setTransction_B_Cash] = useState("");
  const [transction_B_Bank, setTransction_B_Bank] = useState("");

  const [forTransaction, setForTransaction] = useState("");

  const [billNo, setBillNo] = useState("");

  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // New state for button disable

  const dispatch = useDispatch();
  const token = localStorage.getItem("NHHD:token");
  const { expensesCategory } = useSelector((state) => state.expense);

  const monthInBS = parseInt(expenseDate.slice(5, 7)) - 1;
  const yearInBS = expenseDate.slice(0, 4);

  const currentMonthInBS = monthOrder[monthInBS];

  const expenseMonth = currentMonthInBS + "-" + yearInBS;

  const submit = () => {
    if (
      expenseName === "" ||
      expenseAmount === "" ||
      billNo === "" ||
      expenseDate === "" ||
      expenseDescription === "" ||
      expensesCategorys === "" ||
      transctionType === ""
    ) {
      toast.error("Feild Cannot Be null");
    } else {
      setIsButtonDisabled(true);
      dispatch(
        addExpenses({
          token,
          expenseName,
          expenseAmount,
          expenseDate,
          expenseDescription,
          expensesCategorys,
          transctionType,
          transctionCash,
          transctionBank,
          transction_B_Cash,
          transction_B_Bank,
          billNo,
          expenseMonth,
        })
      ).then(() => {
        setIsButtonDisabled(false);
      });
    }
  };
  console.log(expenseMonth);

  return (
    <>
      <div class="container mx-auto">
        <div class="lg:flex md:flex justify-center px-6 ">
          <div class="w-full lg:w-9/12 px-4">
            <div>
              <div
                className=" transition duration-150 ease-in-out z-10  "
                id="modal"
              >
                <div
                  role="alert"
                  className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
                >
                  <div className=" ">
                    <div className=" lg:flex md:flex justify-center">
                      <div className="border border-gray-100 w-auto h-auto bg-gray-200 py-4 px-3 rounded-[6px] ">
                        <div className="flex">
                          <div className=" items-center">
                            <p className="text-red-500 px-3">
                              Please Select the Date
                            </p>

                            <NepaliDatePicker
                              className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0   "
                              value={expenseDate}
                              onChange={(value) => setEpenseDate(value)}
                              options={{
                                calenderLocale: "ne",
                                valueLocale: "en",
                              }}
                            />
                          </div>
                          <div>
                            <p className="uppercase text-green-800 py-1">
                              Bill No.
                            </p>
                            <input
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              value={billNo}
                              onChange={(e) => setBillNo(e.target.value)}
                              className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full"
                              placeholder="Enter Bill Number"
                            />
                          </div>
                        </div>
                        <div className="lg:flex md:flex gap-3 my-2">
                          <input
                            type="text"
                            className="border py-3 rounded-md w-full h-full placeholder:px-2 px-2 lg:my-0 md:y-0 my-2"
                            placeholder="Enter Expenses Name"
                            value={expenseName}
                            onChange={(e) => setExpenseName(e.target.value)}
                          />
                          <input
                            type="text"
                            className="border py-3 rounded-md w-full h-full placeholder:px-2 px-2"
                            placeholder="Enter Expenses Description"
                            value={expenseDescription}
                            onChange={(e) =>
                              setExpenseDescription(e.target.value)
                            }
                          />
                        </div>
                        <div className="lg:flex md:flex gap-3 ">
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            className="border py-3 rounded-md w-full h-full placeholder:px-2 px-2 lg:my-0 md:y-0 my-2"
                            placeholder="Enter Expenses Amount"
                            value={expenseAmount}
                            onChange={(e) => {
                              setExpenseAmount(e.target.value);
                              setForTransaction(e.target.value);
                              setTransctionCash(e.target.value);
                              setTransctionBank(e.target.value);
                            }}
                          />
                        </div>
                        <div className="my-3">
                          <select
                            className="border py-3 w-full rounded-md placeholder:px-2 px-2   my-2 md:my-0 lg:my-0"
                            value={expensesCategorys}
                            onChange={(e) =>
                              setExpensesCategorys(e.target.value)
                            }
                          >
                            <option>Select Category</option>
                            {expensesCategory.map((item, i) => {
                              return <option>{item.expenseCategory}</option>;
                            })}
                          </select>
                        </div>
                        <>
                          <p className="uppercase opacity-30 pt-3">
                            Transaction{" "}
                          </p>
                          <div className="flex flex-col gap-3  my-4 w-full">
                            <select
                              className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                              name="transictionType"
                              value={transctionType}
                              onChange={(e) =>
                                setTransctionType(e.target.value)
                              }
                            >
                              <option>Select Transiction Type</option>
                              {caseType.map((item, i) => {
                                return <option>{item.TypeName}</option>;
                              })}
                            </select>

                            <div>
                              <p>
                                {transctionType === "Bank" ? (
                                  <input
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    readOnly
                                    value={transctionBank}
                                    placeholder="Enter BANK Amount"
                                    className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 w-full"
                                  />
                                ) : transctionType === "Cash" ? (
                                  <input
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    readOnly
                                    value={transctionCash}
                                    placeholder="Enter  CASH Amount"
                                    className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 w-full"
                                  />
                                ) : transctionType === "Both" ? (
                                  <>
                                    <div className="flex gap-3">
                                      <input
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        value={transction_B_Bank}
                                        onChange={(e) => {
                                          setTransction_B_Bank(e.target.value);

                                          const calculatedCash =
                                            forTransaction - e.target.value;
                                          console.log(
                                            calculatedCash,
                                            "calculated cash"
                                          );
                                          setTransction_B_Cash(calculatedCash);
                                        }}
                                        placeholder="Enter  BANK Amount"
                                        className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0  w-full"
                                      />

                                      <input
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        value={transction_B_Cash}
                                        placeholder="Enter  CASH Amount"
                                        className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 w-full"
                                      />
                                    </div>
                                  </>
                                ) : null}
                              </p>
                            </div>
                          </div>
                        </>
                        <div className="flex justify-center my-3 gap-3 ">
                          <Button
                            btnName={"Add"}
                            click={submit}
                            isLoading={isButtonDisabled}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const caseType = [
  {
    TypeName: "Cash ",
  },
  {
    TypeName: "Bank ",
  },
  {
    TypeName: "Both ",
  },
];

const monthOrder = [
  "बैशाख",
  "जेठ",
  "असार",
  "श्रावण",
  "भाद्र",
  "असोज",
  "कार्तिक",
  "मंसिर",
  "पुष",
  "माघ",
  "फागुन",
  "चैत्र",
];

export default AddExpense;

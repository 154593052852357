import React from "react";
import { useSelector } from "react-redux";
import BasicModal from "../../Components/Modal/Modal";
import Button from "../../Components/Button/Button";
import Tittle from "../../Components/Tittle/Tittle";
import AddSalary from "./AddSalary";
import SalaryList from "./SalaryList";

const SingleEmployeePage = () => {
  const { singleEmployee } = useSelector((state) => state.employee);

  return (
    <div>
      <Tittle tittle={"Employee Salary"} />
      <BasicModal
        btn={<Button btnName={"Give Salary"} />}
        tittle={"Add Salary"}
        modalDetails={<AddSalary />}
      />
      <div className=" m-3">
        {" "}
        <p className="py-2">
          <span className="">Employee Name : </span>
          <span className="underline ">
            {singleEmployee && singleEmployee.employeeName}
          </span>
        </p>
        <p className="py-2">
          <span>Monthly Salary : </span>
          <span>{singleEmployee.employeeSalary}</span>
        </p>
        <p
          className={`text-white border font-semibold capitalize rounded-md px-3 py-2 w-[300px] text-center   ${
            singleEmployee.employeeSalary === 0
              ? "bg-yellow-600"
              : "bg-green-600"
          }`}
        >
          <span>
            {" "}
            {singleEmployee.employeeSalary === 0
              ? "Paid"
              : "Pending Salary"} :{" "}
          </span>
          <span>
            {singleEmployee.employeeSalary === 0
              ? null
              : singleEmployee.employeeSalary}
          </span>
        </p>
      </div>

      <Tittle tittle={"Salary History"} />
      <SalaryList />
    </div>
  );
};

export default SingleEmployeePage;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../utils/API";
import { toast } from "react-toastify";

export const addReturnProduct = createAsyncThunk(
  "post/return-product",
  async (payload) => {
    try {
      const response = await api.post("/return/return-product", payload, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      if (response.data.success === true) {
        toast.success(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const allReturnProduct = createAsyncThunk(
  "all/return-product",
  async (payload) => {
    try {
      const response = await api.post("/return/all-return-product", payload, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

import React from "react";

const CateTable = ({ data }) => {
  const cate_HeadingData = [
    {
      name: "SN.",
    },
    {
      name: "Category Name",
    },
  ];

  return (
    <>
      <section class="container px-4 mx-auto">
        <div class="flex flex-col">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div class="overflow-hidden border border-gray-200 md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      {cate_HeadingData.map((item, i) => {
                        return (
                          <th
                            scope="col col-span-5"
                            key={i}
                            class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                          >
                            {item.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  <tbody class="bg-white divide-y divide-gray-200    ">
                    {data &&
                      [data].map((item, i) => {
                        return (
                          <tr key={i}>
                            <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                              <div class="inline-flex items-center px-4 gap-x-3">
                                <span>{i + 1}</span>
                              </div>
                            </td>

                            <td class="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                              <div>
                                <h2 class="text-sm font-normal px-4">
                                  {item.category}
                                </h2>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CateTable;

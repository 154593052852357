import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../utils/API";
import { toast } from "react-toastify";

export const postCategorys = createAsyncThunk(
  "category/post",
  async (payload) => {
    try {
      const response = await api.post("/category/add-category", payload, {
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
      });

      if (response.data.success === true) {
        toast.success(response.data.message);
      }
      if (response.data.success === false) {
        toast.info(response.data.message);
      }
      setTimeout(3500);
      window.location.reload();

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

// export const fetchCategory = createAsyncThunk(
//   "get/category",

//   async (payload) => {
//     console.log(payload.pageNo);
//     try {
//       const response = await api.get(
//         `/category/categories?limit=${6}&page=${payload.pageNo}`,

//         {
//           headers: {
//             Authorization: `Bearer ${payload.token}`,
//           },
//         }
//       );
//       return response.data;
//     } catch (error) {
//       console.log(error);
//     }
//   }
// );

export const getAllCategory = createAsyncThunk(
  "get/all-category",
  async (payload) => {
    try {
      const response = await api.get(
        `/category/categories?limit=${20}&page=${payload.pageNo}`,
        {
          headers: {
            Authorization: `Bearer ${payload.token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const filterBycategoryName = createAsyncThunk(
  "get/category/name",

  async (payload) => {
    console.log(payload.category);
    try {
      const response = await api.get(
        `/category/search-categories?category=${payload.category}&limit=6`,

        {
          headers: {
            Authorization: `Bearer ${payload.token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "category/delete",
  async ({ id, token }) => {
    try {
      const response = await api.post(
        "/category/delete-category",
        { id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.success === true) {
        toast.success(response.data.message);
        window.location.reload();
      }
      if (!id) {
        toast.error(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

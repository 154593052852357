import React, { useEffect } from "react";
import BasicModal from "../../Components/Modal/Modal";
import GiveSalary from "./GiveSalary";
import Button from "../../Components/Button/Button";
import Tittle from "../../Components/Tittle/Tittle";
import PendingSalary from "./PendingSalary";
import CurrentEmployeeSalalry from "./CurrentEmployeeSalalry";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeSalaryById } from "../../Redux/Feature/EmployeeSalary/EmpSalaryAction";
import { Link, useParams } from "react-router-dom";
import AddBonus from "../Bonus/AddBonus";
import ListEmployeeBonus from "../Bonus/ListEmployeeBonus";
import GiveAdvance from "./AdvanceSalary/GiveAdvance";
import AdvanceSalaryList from "./AdvanceSalary/AdvanceSalaryList";
import PayPendingSalary from "./PayPendingSalary";

const SingleSalary = () => {
  const { singleBonus, singleEmp_salaries, pendingSalary, singleAdvance } =
    useSelector((state) => state.employeeSalary);
  const dispatch = useDispatch();
  const params = useParams();

  // Check if all remainingAmount in salaryDetails are zero
  const allRemainingAmountZero = singleEmp_salaries.every((salary) =>
    salary.salaryDetails.every((detail) => detail.remainingAmount === 0)
  );

  // Check if all months are included before the current month
  const currentMonth = new Date().toISOString().split("-")[1];
  const allMonthsIncluded = singleEmp_salaries.every((salary) =>
    salary.salaryDetails.some(
      (detail) => detail.month.split("-")[1] < currentMonth
    )
  );

  const isGiveSalaryButtonDisabled =
    allRemainingAmountZero && allMonthsIncluded;

  useEffect(() => {
    dispatch(getEmployeeSalaryById({ id: params.id }));
  }, [dispatch, params.id]);
  console.log(singleBonus, "dfghjkl;");

  return (
    <div>
      <div className="flex gap-3">
        <BasicModal
          tittle={`Employee Salary`}
          btn={
            <Button
              btnName={"Give Salary"}
              disabled={isGiveSalaryButtonDisabled}
            />
          } // Add disabled prop here
          modalDetails={<GiveSalary />}
        />
        <BasicModal
          tittle={`Give Bonus`}
          btn={<Button btnName={"Give Bonus"} />}
          modalDetails={<AddBonus />}
        />
        {pendingSalary.length === 0 && (
          <BasicModal
            tittle={`Give Advance`}
            btn={<Button btnName={"Give Advance"} />}
            modalDetails={<GiveAdvance />}
          />
        )}
        <BasicModal
          tittle={`Pay Pending Salary`}
          btn={<Button btnName={"Pay Pending Salary"} />}
          modalDetails={<PayPendingSalary />}
        />
      </div>

      <div className="flex p-3 justify-between">
        <Tittle
          tittle={`Recent Added Salary-(${singleEmp_salaries.length}) `}
        />
        <div class="flex items-center  m-3">
          <div className="flex items-center gap-3">
            <h2 class="text-sm capitalize font-medium text-blue-800   ">
              <Link to={`/salary-history/${params.id}`}>
                <p className="border border-[green]  px-3 py-2 rounded-[5px] text-[green]  hover:border-[white] hover:bg-[green] hover:text-[white] cursor-pointer ">
                  Salary History
                </p>
              </Link>
            </h2>
          </div>
        </div>
      </div>
      <CurrentEmployeeSalalry />

      <div className="grid lg:grid-cols-3 grid-flow-row">
        <div>
          <Tittle tittle={`Pending Salary List-(${pendingSalary.length})`} />
          <PendingSalary />
        </div>
        <div>
          <Tittle tittle={`Bonus List-(${singleBonus.length})`} />
          <ListEmployeeBonus />
        </div>
        <div>
          <Tittle tittle={`Advance List-(${singleAdvance.length})`} />
          <AdvanceSalaryList />
        </div>
      </div>
    </div>
  );
};

export default SingleSalary;

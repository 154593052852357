import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../utils/API";
import { toast } from "react-toastify";

export const addInventeryLossCategory = createAsyncThunk(
  "post/inventery-category",
  async (payload) => {
    try {
      const response = await api.post(
        "/inventery/add-inventery-category",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
          },
        }
      );
      if (response.data.success === true) {
        toast.success(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchInventeryLossCategory = createAsyncThunk(
  "get/inventery-category",
  async () => {
    try {
      const response = await api.get("/inventery/all-inventery-category", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
        },
      });
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addInventeryLossExpenses = createAsyncThunk(
  "post/inventery-expenses",
  async (payload) => {
    try {
      const response = await api.post(
        "/inventery/add-inventery-expenses",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
          },
        }
      );
      if (response.data.success === true) {
        toast.success(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchInventoryExpenses = createAsyncThunk(
  "get/inventery-expenses",
  async () => {
    try {
      const response = await api.get("/inventery/all-inventery-expenses", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
        },
      });
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

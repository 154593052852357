import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../utils/API";
import { toast } from "react-toastify";

export const purchaseProduct = createAsyncThunk(
  "product/purchase",

  async (payload) => {
    try {
      const response = await api.post(
        "/products/purchase-product",
        payload,

        {
          headers: {
            Authorization: `Bearer ${payload.token}`,
          },
        }
      );

      if (response.data.success === true) {
        toast.success(response.data.message);
      }
      if (response.data.success === false) {
        toast.error(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateExistingPoduct = createAsyncThunk(
  "update-existing-product/purchase",
  async (payload) => {
    console.log(payload);
    try {
      const response = await api.post(
        "/products/update-product",
        payload,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
          },
        }
      );

      if (response.data.success === true) {
        toast.success(response.data.message);
      }
      if (response.data.success === false) {
        toast.error(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchProduct = createAsyncThunk(
  "get/product",
  async ({ token, pageNo }) => {
    try {
      const response = await api.get(`/products/all-products`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const dltProduct = createAsyncThunk("dlt/product", async (payload) => {
  try {
    const response = await api.post("/products/delete-product", payload, {
      headers: {
        Authorization: `Bearer ${payload.token}`,
      },
    });
    console.log(response);
    if (response.data.success === true) {
      toast.success(response.data.message);
      // window.location.reload();
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const updateProductQuantity = createAsyncThunk(
  "product/update",
  async (payload) => {
    try {
      const response = await api.post(
        `/products/products-quantity`,

        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
          },
        }
      );

      if (response.data.success === true) {
        toast.success(response.data.message);
        window.location.reload();
      }
      if (response.data.success === false) {
        toast.error(response.data.message);
      }

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchProductById = createAsyncThunk(
  "get/id/product",
  async ({ id, token }) => {
    try {
      const response = await api.get(`/products/single-product/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const filterProductByProductName = createAsyncThunk(
  "filter/productName/name",
  async ({ productName, token }) => {
    try {
      const response = await api.get(
        `/products/search-products?productName=${productName}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const purchasePayCredit = createAsyncThunk(
  "post/pay-credit",
  async (payload) => {
    console.log(payload, "payload");
    try {
      const response = await api.post("products/pay-credit", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
        },
      });
      if (response.data.success === true) {
        toast.success(response.data.message);
        window.location.reload();
      } else {
        toast.info(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

import React, { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useDispatch, useSelector } from "react-redux";
import { getMaskebari } from "../../Redux/Feature/Maskebari/maskebariAction";
import Modal from "../../Components/Modal/Modal";
import Button from "../../Components/Button/Button";
import SubmitMaskebari from "./SubmitMaskebari";

// const monthOrder = [
//   "श्रावण",
//   "भाद्र",
//   "असोज",
//   "कार्तिक",
//   "मंसिर",
//   "पुष",
//   "माघ",
//   "फागुन",
//   "चैत्र",
//   "बैशाख",
//   "जेठ",
//   "असार",
// ];
const drawerWidth = 360;

const MaskebariTable = ({ TableData }) => {
  const [tableWidth, setTableWidth] = useState("100%");

  useEffect(() => {
    const screenWidth = window.innerWidth;
    const calculatedWidth = screenWidth - drawerWidth;
    setTableWidth(`${calculatedWidth}px`);
  }, []);

  const tableRef = useRef(null);

  const token = localStorage.getItem("NHHD:token");
  const dispatch = useDispatch();
  const { maskebari } = useSelector((state) => state.maskebari);

  useEffect(() => {
    async function fetch() {
      dispatch(getMaskebari({ token }));
    }
    fetch();
  }, [dispatch, token]);

  return (
    <div className="w-full">
      <section
        class="container p-4 mx-auto w-auto"
        style={{ width: tableWidth }}
      >
        <div className="flex justify-between">
          {/* <p>
            <button className=" text-[green] border  border-[gray]  rounded-[4px] p-2 hover:bg-[green] hover:text-white">
              Submit Maskebari
            </button>
          </p> */}
          <Modal
            btn={<Button btnName={"Submit Maskebari"} />}
            tittle={"Submit Maskebari"}
            modalDetails={<SubmitMaskebari />}
          />
          <p>
            <DownloadTableExcel
              filename="users table"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <button className=" text-[green] border  border-[gray]  rounded-[4px] p-2 hover:bg-[green] hover:text-white">
                Export to Excel
              </button>
            </DownloadTableExcel>
          </p>
        </div>

        <div ref={tableRef}>
          <div class="flex flex-col items-center justify-center my-2">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div class="overflow-hidden">
                  <table
                    class="min-w-auto border-spacing-0"
                    id="purchase-account-table-to-xls"
                  >
                    <tbody class="bg-white">
                      <tr className=" flex flex-col items-center">
                        <td
                          className="text-xl font-semibold flex flex-col items-center"
                          colSpan={18}
                        >
                          Ram Bharat Trade Link
                        </td>
                      </tr>
                      <tr className="text-md">
                        <td className="text-md" colSpan={18}>
                          Kalanki Chowk, Near Bus Park
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="font-medium flex flex-col items-center justify-center"
                          colSpan={18}
                        >
                          Maskebari (Statement of Materials and VAT)
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="font-medium flex flex-col items-center justify-center"
                          colSpan={18}
                        >
                          F.Y. 2080/2081
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col w-auto ">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div class="overflow-hidden border border-gray-200 md:rounded-lg">
                  <table
                    class="min-w-auto divide-y border-spacing-0 divide-gray-200 "
                    id="maskebari-table-to-xls"
                  >
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                          rowSpan={2}
                        >
                          Months
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-center rtl:text-right text-gray-500"
                          colSpan={6}
                        >
                          Purchase
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-center rtl:text-right text-gray-500"
                          colSpan={3}
                        >
                          Sale
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                          rowSpan={2}
                        >
                          Any adjustment made in return
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                          rowSpan={2}
                        >
                          VAT Credit/(Payable)
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-center rtl:text-right text-gray-500"
                          rowSpan={2}
                        >
                          VAT Payable
                        </th>
                      </tr>
                      <tr>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                        >
                          Non Taxable
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                        >
                          Import
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                        >
                          Import VAT
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                        >
                          Local
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                        >
                          Local VAT
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                        >
                          Total VAT
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                        >
                          Non Taxable
                        </th>

                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                        >
                          Local
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                        >
                          VAT
                        </th>
                      </tr>
                    </thead>

                    <tbody class="bg-white divide-y divide-gray-200    ">
                      {maskebari[0]?.maskebariDetails?.length === 0
                        ? "No product in the list"
                        : maskebari[0]?.maskebariDetails?.map((item, i) => {
                            const actualVat =
                              item?.purchase?.purchaseVATamount +
                              item?.purchase?.purchaseImportVATamount;

                            return (
                              <>
                                <tr key={i}>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>{item.month}</span>
                                    </div>
                                  </td>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>-</span>
                                    </div>
                                  </td>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>
                                        {
                                          item?.purchase
                                            ?.purchaseImportAmountWithImportVAT
                                        }
                                      </span>
                                    </div>
                                  </td>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>
                                        {
                                          item?.purchase
                                            ?.purchaseImportVATamount
                                        }
                                      </span>
                                    </div>
                                  </td>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>
                                        {item?.purchase?.purchaseAmountWithVAT}
                                      </span>
                                    </div>
                                  </td>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>
                                        {item?.purchase?.purchaseVATamount}
                                      </span>
                                    </div>
                                  </td>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>{actualVat}</span>
                                    </div>
                                  </td>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>
                                        {item?.sale?.saleNonTaxableAmount}
                                      </span>
                                    </div>
                                  </td>

                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>
                                        {item?.sale?.saleAmountWithVat}
                                      </span>
                                    </div>
                                  </td>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>{item?.sale?.saleVATamount}</span>
                                    </div>
                                  </td>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>{item?.adjustment}</span>
                                    </div>
                                  </td>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      {item?.vatCredit < 0 ? (
                                        <span>
                                          ({Math.abs(item?.vatCredit)})
                                        </span>
                                      ) : (
                                        <span>{item?.vatCredit}</span>
                                      )}
                                    </div>
                                  </td>

                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      {item?.vatPayable < 0 ? (
                                        <span>
                                          ({Math.abs(item?.vatPayable)})
                                        </span>
                                      ) : (
                                        <span>{item?.vatPayable} </span>
                                      )}
                                      {/* {item?.vatPayable} */}
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                      <tr>
                        <td class="px-4 py-4 text-lg font-semibold text-gray-700   whitespace-nowrap">
                          <div class="inline-flex items-center px-4 gap-x-3">
                            <span>Total:</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col w-auto mt-4 ">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                {/* <div class="overflow-hidden border border-gray-200 md:rounded-lg"> */}
                <table
                  class="min-w-auto divide-y border-spacing-0 divide-gray-200 "
                  id="reconciliation-table-to-xls"
                >
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col col-span-5"
                        class="px-[2rem] py-3.5  text-sm font-normal text-center rtl:text-right text-gray-500"
                        colSpan={2}
                      >
                        Purchase Reconciliation
                      </th>
                      <th
                        scope="col col-span-5"
                        class="px-[2rem] py-3.5  text-sm font-normal text-center rtl:text-right text-gray-500"
                        colSpan={2}
                      >
                        Sales Reconciliation
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200    ">
                    <tr>
                      <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                        <div class="inline-flex items-center px-4 gap-x-3">
                          <span>As per VAT Return</span>
                        </div>
                      </td>
                      <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                        <div class="inline-flex items-center px-4 gap-x-3">
                          <span>1234567.00</span>
                        </div>
                      </td>
                      <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                        <div class="inline-flex items-center px-4 gap-x-3">
                          <span>As per VAT Return</span>
                        </div>
                      </td>
                      <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                        <div class="inline-flex items-center px-4 gap-x-3">
                          <span>1234567.00</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                        <div class="inline-flex items-center px-4 gap-x-3">
                          <span>ADD:Purchase not shown in VAT Return</span>
                        </div>
                      </td>
                      <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                        <div class="inline-flex items-center px-4 gap-x-3">
                          <span>1234567.00</span>
                        </div>
                      </td>
                      <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                        <div class="inline-flex items-center px-4 gap-x-3">
                          <span>Sales not shown in Maskebari</span>
                        </div>
                      </td>
                      <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                        <div class="inline-flex items-center px-4 gap-x-3">
                          <span>1234567.00</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MaskebariTable;

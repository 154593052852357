import React, { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useDispatch, useSelector } from "react-redux";
import {
  getSalesAccount,
  getSalesAccountByMonth,
} from "../../Redux/Feature/SalesAccount/salesAccountAction";
import { Autocomplete, TextField } from "@mui/material";
import { adToBs } from "@sbmdkl/nepali-date-converter";

const drawerWidth = 360;

const SalesAccountTable = ({ TableData }) => {
  const [tableWidth, setTableWidth] = useState("100%");

  useEffect(() => {
    const screenWidth = window.innerWidth;
    const calculatedWidth = screenWidth - drawerWidth;
    setTableWidth(`${calculatedWidth}px`);
  }, []);

  const tableRef = useRef(null);

  const token = localStorage.getItem("NHHD:token");

  const { salesAccount, salesAccountByMonth } = useSelector(
    (state) => state.salesAccount
  );

  const dispatch = useDispatch();

  // const [selectedItem, setSelectedItem] = React.useState(null);
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
  const day = String(today.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  const dateInBS = adToBs(formattedDate);

  const monthInBS = parseInt(dateInBS.slice(5, 7)) - 1;
  const yearInBS = dateInBS.slice(0, 4);

  const currentMonthInBS = monthOrder[monthInBS];

  const currentDate = currentMonthInBS + "-" + yearInBS;

  const combinedMonth = salesAccount?.map((item) => {
    return item.saleMonth && item.saleMonth;
  });

  const uniqueSet = new Set(combinedMonth);
  // Convert the Set back to an array to maintain order
  const uniqueMonth = Array.from(uniqueSet);

  const defaultMonthIndex = uniqueMonth.indexOf(currentDate);

  const [selectedMonth, setSelectedMonth] = React.useState(currentDate);

  const handleOptionChange = (event, value) => {
    if (value) {
      console.log("Selected option object:", value);
      setSelectedMonth(value);
    } else {
      // console.log("Selected option cleared");
      setSelectedMonth(null);
    }
  };

  useEffect(() => {
    async function fetch() {
      dispatch(getSalesAccount({ token }));
      dispatch(
        getSalesAccountByMonth({
          token,
          selectedItem: selectedMonth,
        })
      );
    }
    fetch();
  }, [dispatch, token, selectedMonth]);

  return (
    <div className="w-full">
      <div className="w-[300px] my-4 mx-4">
        <Autocomplete
          options={uniqueMonth}
          defaultValue={defaultMonthIndex !== -1 ? currentDate : null}
          value={selectedMonth}
          getOptionLabel={(item) => {
            return item;
          }}
          renderInput={(params) => (
            <TextField {...params} label="Select an option" variant="filled" />
          )}
          onChange={handleOptionChange}
          sx={{ mt: 1 }}
        />
      </div>
      <section class="container p-4 mx-auto " style={{ width: tableWidth }}>
        <p className="text-end">
          {/* <ReactHTMLTableToExcel
            id="purchase-account-table-xls-button"
            className=" text-[green] border  border-[gray]  rounded-[4px] p-2 hover:bg-[green] hover:text-white"
            table="purchase-account-table-to-xls"
            filename="Kharid Khata"
            // sheet="tablexls"
            sheet="Sheet1"
            buttonText="Export to Excel"
          /> */}
          <DownloadTableExcel
            filename="users table"
            sheet="users"
            currentTableRef={tableRef.current}
          >
            <button className=" text-[green] border  border-[gray]  rounded-[4px] p-2 hover:bg-[green] hover:text-white">
              Export to Excel
            </button>
          </DownloadTableExcel>
        </p>

        <div ref={tableRef}>
          <div class="flex flex-col items-center justify-center my-2">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div class="overflow-hidden md:rounded-lg">
                  <table
                    class="min-w-auto border-collapse"
                    id="purchase-account-table-to-xls"
                  >
                    <tbody class="bg-white">
                      <tr className="flex flex-col items-center">
                        <td
                          className="text-xl font-semibold flex flex-col items-center"
                          colSpan={18}
                        >
                          बिक्री खाता
                        </td>
                      </tr>
                      <tr className="text-md">
                        <td className="text-md" colSpan={18}>
                          (नियम २३ को उपनियम (१) को खण्ड (ज) संग सम्बन्धित )
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="font-medium flex flex-col items-center justify-center"
                          colSpan={18}
                        >
                          <div>करदाता दर्ता नं : number</div>
                          <div>करदाताको नाम : Hulas Decor</div>
                          <div>
                            साल :
                            {selectedMonth !== null
                              ? selectedMonth?.slice(-4) +
                                "/" +
                                parseInt(parseInt(selectedMonth?.slice(-2)) + 1)
                              : null}
                          </div>
                          <div>
                            कर अवधि :
                            {selectedMonth !== null
                              ? selectedMonth?.replace(/-.*/, "")
                              : null}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col ">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div class="overflow-hidden border border-gray-200 md:rounded-lg">
                  <table class="min-w-auto divide-y border-spacing-0 divide-gray-200 ">
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-center rtl:text-right text-gray-500"
                          colSpan={7}
                        >
                          बीजक
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-center rtl:text-right text-gray-500"
                          rowSpan={2}
                        >
                          जम्मा बिक्री / निकासी (रु)
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-center rtl:text-right text-gray-500"
                          rowSpan={2}
                        >
                          स्थानीय कर छुटको बिक्री मूल्य (रु)
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-center rtl:text-right text-gray-500"
                          colSpan={2}
                        >
                          करयोग्य बिक्री
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-center rtl:text-right text-gray-500"
                          colSpan={4}
                        >
                          निकासी
                        </th>
                      </tr>
                      <tr>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                        >
                          मिति
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                        >
                          बीजक नं.
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                        >
                          खरिदकर्ताको नाम
                        </th>

                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                        >
                          खरिदकर्ताको स्थायी लेखा नम्बर
                        </th>

                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                        >
                          वस्तु वा सेवाको नाम
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                        >
                          वस्तु वा सेवाको परिमाण
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                        >
                          वस्तु वा सेवाको एकाई
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                        >
                          मूल्य (रु)
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                        >
                          कर (रु)
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                        >
                          निकासी गरेको वस्तु वा सेवाको मूल्य (रु)
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                        >
                          निकासी गरेको देश
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                        >
                          निकासी प्रज्ञापनपत्र नम्बर
                        </th>
                        <th
                          scope="col col-span-5"
                          class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                        >
                          निकासी प्रज्ञापनपत्र मिति
                        </th>
                      </tr>
                    </thead>

                    <tbody class="bg-white divide-y divide-gray-200    ">
                      {salesAccountByMonth === undefined
                        ? "No product in the list"
                        : salesAccountByMonth?.map((item, i) => {
                            const totalQuantity = item.particularName.map(
                              (item, i) => {
                                return item.quantity;
                              }
                            );
                            return (
                              <>
                                <tr key={i}>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>{item.billDate.slice(0, 10)}</span>
                                    </div>
                                  </td>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>{item.billNo}</span>
                                    </div>
                                  </td>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>{item.customerName}</span>
                                    </div>
                                  </td>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>
                                        {item.customerRegistrationNumber}
                                      </span>
                                    </div>
                                  </td>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>{item.particularName[0].name}</span>
                                    </div>
                                  </td>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>
                                        {item.particularName[0].quantity &&
                                        item.particularName[0].quantity
                                          ? totalQuantity
                                          : "1"}
                                      </span>
                                    </div>
                                  </td>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>{item.particularName[0].SKU}</span>
                                    </div>
                                  </td>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>{item.amountWithTax}</span>
                                    </div>
                                  </td>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>{item.nonTaxableAmount}</span>
                                    </div>
                                  </td>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>{item.totalAmountExcludeTax}</span>
                                    </div>
                                  </td>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>{item.vatAmount}</span>
                                    </div>
                                  </td>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>-</span>
                                    </div>
                                  </td>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>-</span>
                                    </div>
                                  </td>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>-</span>
                                    </div>
                                  </td>
                                  <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                    <div class="inline-flex items-center px-4 gap-x-3">
                                      <span>-</span>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                      <tr>
                        <td class="px-4 py-4 text-lg font-semibold text-gray-700   whitespace-nowrap">
                          <div class="inline-flex items-center px-4 gap-x-3">
                            <span>Total:</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SalesAccountTable;

const monthOrder = [
  "बैशाख",
  "जेठ",
  "असार",
  "श्रावण",
  "भाद्र",
  "असोज",
  "कार्तिक",
  "मंसिर",
  "पुष",
  "माघ",
  "फागुन",
  "चैत्र",
];

import { createSlice } from "@reduxjs/toolkit";
import {
  addSupplier,
  dltSupplier,
  filterSupplier,
  getSingleSupplier,
  getSupplier,
} from "./supplierAction";

const supplierSlice = createSlice({
  name: "supplier",
  initialState: {
    supplies: [],
    error: null,
    isLoading: true,
    count: 0,
    singleSupply: {},
  },
  reducers: {},
  extraReducers: {
    [addSupplier.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
      state.supplies = null;
    },
    [addSupplier.fulfilled]: (state, action) => {
      state.count = action.payload.count;
    },
    [addSupplier.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getSupplier.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getSupplier.fulfilled]: (state, action) => {
      // console.log(action.payload);
      state.isLoading = false;
      state.count = action.payload.count;
      state.supplies = action.payload.suppliers;
    },
    [getSupplier.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [dltSupplier.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [dltSupplier.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
    },
    [dltSupplier.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.error;
    },
    [filterSupplier.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [filterSupplier.fulfilled]: (state, action) => {
      // console.log(action.payload);
      state.isLoading = false;
      state.supplies = action.payload.suppliers;
    },
    [filterSupplier.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.error;
    },
    [getSingleSupplier.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getSingleSupplier.fulfilled]: (state, action) => {
      state.isLoading = false;
      console.log(action.payload);
      state.singleSupply = action.payload.supplier;
    },
    [getSingleSupplier.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.error;
    },
  },
});

export const supplierReducer = supplierSlice.reducer;

import React from "react";
import Modal from "../../Components/Modal/Modal";
import AddSuppliers from "./AddSuppliers";
import SuppliersList from "./SuppliersList";
import Button from "../../Components/Button/Button";
import Tittle from "../../Components/Tittle/Tittle";
import PayCredit from "../Product/PayCredit";

const index = () => {
  return (
    <>
      <div>
        <Tittle tittle={"supplier"} />
        <div className="flex">
          <Modal
            btn={<Button btnName={"Add a Supplier"} />}
            modalDetails={<AddSuppliers />}
            tittle={"add a Supplier"}
          />
          <Modal
            btn={<Button btnName={"Pay Credit "} />}
            tittle={" Pay Credit   "}
            modalDetails={<PayCredit />}
          />
        </div>
        <div className="mt-[2rem]">
          <SuppliersList />
        </div>
      </div>
    </>
  );
};

export default index;

import { createSlice } from "@reduxjs/toolkit";
import {
  addSalary,
  filtersalaryByEmployeeId,
  filtersalaryByPaidDate,
  filtersalaryByStatus,
  getSingleSalary,
  updateSalary,
} from "./salaryAction";

const salarySlice = createSlice({
  name: "salary",
  initialState: {
    count: 0,
    error: null,
    salarys: [],
    isLoading: true,
    success: false,
    singleSalarys: [],
    salaryById: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addSalary.pending, (state, action) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(addSalary.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(addSalary.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.error = action.payload.message;
    });
    builder.addCase(getSingleSalary.pending, (state, action) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(getSingleSalary.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
      state.singleSalarys = action.payload.salary;
    });
    builder.addCase(getSingleSalary.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.error = action.payload.message;
    });

    builder.addCase(filtersalaryByStatus.pending, (state, action) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(filtersalaryByStatus.fulfilled, (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.success = action.payload;
      state.salarys = action.payload.salaries;
      state.count = action.payload.total;
    });
    builder.addCase(filtersalaryByStatus.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.error = action.payload.message;
    });

    builder.addCase(filtersalaryByEmployeeId.pending, (state, action) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(filtersalaryByEmployeeId.fulfilled, (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.success = action.payload;
      state.salarys = action.payload.salaries;
      state.salaryById = action.payload.salaries;
      state.count = action.payload.count;
    });
    builder.addCase(filtersalaryByEmployeeId.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.error = action.payload.message;
    });

    builder.addCase(updateSalary.pending, (state, action) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(updateSalary.fulfilled, (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.success = action.payload;
    });
    builder.addCase(updateSalary.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.error = action.payload.message;
    });

    builder.addCase(filtersalaryByPaidDate.pending, (state, action) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(filtersalaryByPaidDate.fulfilled, (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.success = action.payload;
      state.salarys = action.payload.salaries;
      state.salaryById = action.payload.salaries;
      // state.singleSalarys = action.payload.salaries;
      state.count = action.payload.count;
    });
    builder.addCase(filtersalaryByPaidDate.rejected, (state, action) => {
      state.isLoading = true;
      state.success = false;
      state.error = action.payload.message;
    });
  },
});

export const salaryReducer = salarySlice.reducer;

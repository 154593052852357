import React, { useState } from "react";
import Button from "../../Components/Button/Button";
import { useDispatch } from "react-redux";

import { toast } from "react-toastify";
import {
  getAllCategory,
  postCategorys,
} from "../../Redux/Feature/category/cactegoryAction";

const AddCategory = () => {
  const token = localStorage.getItem("NHHD:token");
  const [category, setCotegory] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // New state for button disable

  const dispatch = useDispatch();

  const submit = () => {
    if (!category) {
      return toast.error("Please Enter Category Name");
    }

    setIsButtonDisabled(true); // Disable the button
    dispatch(postCategorys({ category, toast, token })).then(() => {
      setIsButtonDisabled(false); // Re-enable the button after API call
      dispatch(getAllCategory());
    });
  };

  return (
    <>
      <div className=" flex justify-center">
        <div className="border border-gray-100 w-auto h-auto bg-gray-200 py-4 rounded-[6px] ">
          {/* <p className="p-4 text-[1.5rem] text-[green]">Add Category</p> */}
          <div className="lg:flex md:flex gap-3 my-2 mx-2">
            {" "}
            <div className="flex  gap-3 my-2">
              <input
                type="text"
                name="category"
                value={category}
                onChange={(e) => setCotegory(e.target.value)}
                className="border py-3 rounded-md placeholder:px-2 px-2"
                placeholder="Category Name "
              />
            </div>
            <div className="flex justify-center my-3 gap-3 ">
              <Button
                btnName={"Add"}
                click={submit}
                isLoading={isButtonDisabled}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCategory;

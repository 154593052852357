import React from "react";
import "./Spinner.css";

export const Spinner = () => {
  return (
    <>
      {/* <div className="loadingSpinnerContainer">
        <div className="loadingSpinner"></div>
      </div> */}
      <div class="loader">
        <div class="square" id="sq1"></div>
        <div class="square" id="sq2"></div>
        <div class="square" id="sq3"></div>
        <div class="square" id="sq4"></div>
        <div class="square" id="sq5"></div>
        <div class="square" id="sq6"></div>
        <div class="square" id="sq7"></div>
        <div class="square" id="sq8"></div>
        <div class="square" id="sq9"></div>
      </div>
    </>
  );
};

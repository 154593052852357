import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../utils/API";
import { toast } from "react-toastify";

const token = localStorage.getItem("NHHD:token");
export const addSale = createAsyncThunk("post/sale", async (payload) => {
  console.log(payload);

  try {
    const response = await api.post("/sales/add-to-sell-product", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data.success === true) {
      toast.success(response.data.message);
    }

    if (response.data.success === false) {
      toast.success(response.data.message);
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const fetchSale = createAsyncThunk("get/sale", async (payload) => {
  try {
    const response = await api.get(
      `/sales/all-sales?limit=20&page=${payload.pageNo}`,
      {
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
      }
    );

    console.log(response.data.sales);
    payload.setSaleData(response.data.sales);
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const fetchSaleById = createAsyncThunk(
  "get/sale/id",
  async (payload) => {
    console.log(payload);
    try {
      const response = await api.post(`/sales/single-sale`, payload, {
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const filterByCusName = createAsyncThunk(
  "filter/sale/customerName",
  async ({ token, customerName, setSaleData }) => {
    try {
      const response = await api.get(
        `/sales/sales-by-customerName?customerName=${customerName}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSaleData(response.data.sales);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const filterByDate = createAsyncThunk(
  "filter/sale/date",
  async ({ token, startDate, endDate }) => {
    console.log(token);
    try {
      const response = await api.post(
        `/sales/sales-by-date`,
        { startDate, endDate },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data) {
        toast.info("No Data found");
      }

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "update/customer/id",
  async (payload) => {
    try {
      const response = await api.post(`/sales/update-customer-info`, payload, {
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
      });

      if (response.data.success === true) {
        toast.success(response.data.message);
        window.location.reload();
      }
      if (response.data.success === false) {
        toast.success(response.data.message);
      }

      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const generateBills = createAsyncThunk(
  "get/id/generate",
  async (payload) => {
    try {
      const response = await api.post(`/sales/generate-bill`, payload, {
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
      });

      if (response.data.success === true) {
        toast.success(response.data.message);
      }
      if (response.data.success === false) {
        toast.info(response.data.message);
        payload.navigate("/bil");
      }

      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const deleteSale = createAsyncThunk("delete/sale", async (id) => {
  console.log(id);
  try {
    const response = await api.post(
      "/sales/delete-sale",
      { id },
      {
        headers: {
          Authorization: `Bearer ${token} `,
        },
      }
    );
    if (response.data.success === true) {
      toast.success(response.data.message);
      window.location.reload();
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const saleReturn = createAsyncThunk(
  "post/return/sale",
  async (payload) => {
    try {
      const response = await api.post(`/sales/sale-return`, payload, {
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
      });

      if (response.data.success === true) {
        toast.success(response.data.message);
        window.location.reload();
      } else {
        toast.error(response.data.message);
      }

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const salePaymentRecived = createAsyncThunk(
  "post/payment-recived",
  async (payload) => {
    try {
      const response = await api.post("sales/sale-payement-recived", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
        },
      });
      if (response.data.success === true) {
        toast.success(response.data.message);
        window.location.reload();
      } else {
        toast.info(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

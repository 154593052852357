import React, { useRef } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";

const Test = () => {
  const tableRef = useRef(null);

  return (
    <div>
      <DownloadTableExcel
        filename="users table"
        sheet="users"
        currentTableRef={tableRef.current}
      >
        <button> Export excel </button>
      </DownloadTableExcel>
      <div ref={tableRef}>
        <p>jjjjjjjjjjjjjjjjjjjj</p>
        <table>
          <tbody>
            <tr>
              <th>Firstname</th>
              <th>Lastname</th>
              <th>Age</th>
            </tr>
            <tr>
              <td>Edison</td>
              <td>Padilla</td>
              <td>20</td>
            </tr>
            <tr>
              <td>Alberto</td>
              <td>Lopez</td>
              <td>94</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Test;

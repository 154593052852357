import React from "react";
import Modal from "../../Components/Modal/Modal";
import Button from "../../Components/Button/Button";
import StaffList from "./StaffList";
import AddStaff from "./AddStaff";
import Tittle from "../../Components/Tittle/Tittle";

const index = () => {
  return (
    <>
      <div>
        <Tittle tittle={"employee"} />
        <Modal
          btn={<Button btnName={"Add New Employee"} />}
          modalDetails={<AddStaff />}
          tittle={"Add new Employee"}
        />

        <>
          <StaffList />
        </>
      </div>
    </>
  );
};

export default index;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllExpenses } from "../../Redux/Feature/Expenses/expensesAction";

const Pagination = ({ page, count }) => {
  const token = localStorage.getItem("NHHD:token");
  const [pageNo, setPageNO] = useState(page);
  const dispatch = useDispatch();

  const pagePlus = () => {
    setPageNO(pageNo + 1);
  };
  const pageMins = () => {
    setPageNO(pageNo - 1);
  };
  useEffect(() => {
    async function fetch() {
      dispatch(getAllExpenses({ pageNo, token }));
    }
    fetch();
  }, [pageNo, dispatch, token]);

  return (
    <>
      <div class="flex items-center lg:justify-between mt-6">
        <button
          disabled={pageNo === 1}
          className="border-gray-300 shadow-md text-[500] rounded-[6px]  py-2 px-3 capitalize text-green-600 hover:bg-green-300 hover:text-white"
          onClick={pageMins}
        >
          previous
        </button>

        <button
          disabled={count < 20}
          className="border-gray-300 shadow-md text-[500] rounded-[6px]  py-2 px-3 capitalize text-green-600 hover:bg-green-300 hover:text-white"
          onClick={pagePlus}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default Pagination;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../utils/API";

export const fetchAllBill = createAsyncThunk("get/bill", async (payload) => {
  try {
    const response = await api.get("/bills/all-bills", {
      headers: {
        Authorization: `Bearer ${payload.token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const getSingleBill = createAsyncThunk(
  "get/bill/id",
  async (payload) => {
    console.log(payload);
    try {
      const response = await api.get(`/bills/single-bill/${payload.id}`, {
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
      });
      console.log(response);

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../utils/API";

export const getPurchaseReturn = createAsyncThunk(
  "get/purchase-account",
  async ({ token }) => {
    try {
      const response = await api.get(
        `/purchase-return-account/all-purchase-return-account`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const getPurchaseReturnByMonth = createAsyncThunk(
  "get/purchase-account/month",
  async ({ token, selectedItem }) => {
    console.log(selectedItem, "gello");
    try {
      const response = await api.get(
        `/purchase-return-account/purchase-return-account-month?month=${selectedItem}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

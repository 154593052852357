import React from "react";

const SupTable = ({ data }) => {
  const sup_HeadingData = [
    {
      name: "SN.",
    },
    {
      name: "Supplier Name.",
    },
    {
      name: "Supplier Contact.",
    },
    {
      name: "Supplier Address",
    },
  ];

  return (
    <>
      <section class="container px-4 mx-auto">
        <div class="flex flex-col">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div class="overflow-hidden border border-gray-200 md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      {sup_HeadingData.map((item, i) => {
                        return (
                          <th
                            scope="col col-span-5"
                            key={i}
                            class="px-[2rem] py-3.5  text-sm font-normal text-left rtl:text-right text-gray-500"
                          >
                            {item.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  <tbody class="bg-white divide-y divide-gray-200    ">
                    {data &&
                      [data].map((item, i) => {
                        return (
                          <tr key={i}>
                            <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                              <div class="inline-flex items-center px-4 gap-x-3">
                                <span>{i + 1}</span>
                              </div>
                            </td>
                            <td class="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">
                              <div class="flex items-center gap-x-2 px-2">
                                <div>
                                  <h2 class="text-sm font-medium text-gray-800 dark:text-white ">
                                    {item.supplierName}
                                  </h2>
                                </div>
                              </div>
                            </td>
                            <td class="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                              <div>
                                <h2 class="text-sm font-normal px-4">
                                  {item.supplierContact}
                                </h2>
                              </div>
                            </td>
                            <td class="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                              <div>
                                <h2 class="text-sm font-normal px-4">
                                  {item.supplierLocation}
                                </h2>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* <div class="flex items-center justify-between mt-6">
          <a
            href="#"
            class="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100      "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 rtl:-scale-x-100"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
              />
            </svg>

            <span>previous</span>
          </a>

          <a
            href="#"
            class="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100 dark:bg-gray-900   dark:border-gray-700  "
          >
            <span>Next</span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 rtl:-scale-x-100"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </a>
        </div> */}
      </section>
    </>
  );
};

export default SupTable;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import {
  deleteCategory,
  filterBycategoryName,
  getAllCategory,
} from "../../Redux/Feature/category/cactegoryAction";
import { Spinner } from "../../Components/Spinner/Spinner";
import Pagination from "./Pagenation";
// import Paginatin from "../../Components/Pagenation/Pagenation";

const CategoryList = () => {
  const { isLoading, categories, count } = useSelector(
    (state) => state.category
  );
  console.log(categories);
  const token = localStorage.getItem("NHHD:token");
  const dispatch = useDispatch();

  const dltClick = (itemId) => {
    console.log(itemId);
    dispatch(deleteCategory({ id: itemId, token }));
  };

  useEffect(() => {
    function fetch() {
      dispatch(getAllCategory({ token }));
    }
    fetch();
  }, [dispatch, token]);

  const [category, setCategory] = useState("");

  useEffect(() => {
    dispatch(filterBycategoryName({ token, category }));
  }, [token, category, dispatch]);

  return (
    <>
      {/* filter-start */}

      <div class="relative mx-3 my-5">
        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <svg
            class="w-5 h-5 text-gray-500  "
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
        </div>
        <input
          type="search"
          id="default-search"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          class="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-green-500 focus:border-green-500   "
          placeholder="Search Category..."
          required
        />
      </div>

      {/* filter-end */}

      <section class="container px-4 mx-auto">
        <div class="flex flex-col">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200  ">
                  <thead class="bg-gray-50  ">
                    <tr>
                      {th.map((item, i) => {
                        return (
                          <th
                            scope="col col-span-5"
                            key={i}
                            class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                          >
                            {item.name}
                          </th>
                        );
                      })}
                      <th
                        scope="col col-span-5"
                        class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  {isLoading && <Spinner />}

                  <tbody className="bg-white divide-y divide-gray-200    ">
                    {categories && categories.length === 0
                      ? "no data found"
                      : categories &&
                        categories.categories.slice(0, 20).map((item, i) => {
                          return (
                            <tr key={i}>
                              <td className="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                <div className="inline-flex items-center gap-x-3">
                                  <span>{i + 1}</span>
                                </div>
                              </td>

                              <td className="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                <div className="flex items-center gap-x-2">
                                  <div>
                                    <h2 className="text-sm capitalize font-medium text-gray-800   ">
                                      {item.category}
                                    </h2>
                                  </div>
                                </div>
                              </td>

                              <td className="px-4 py-4 text-sm whitespace-nowrap">
                                <div className="flex items-center gap-x-6">
                                  <button
                                    className="  transition-colors duration-200 text-red-500  focus:outline-none"
                                    onClick={() => dltClick(item._id)}
                                  >
                                    <DeleteForeverIcon />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Pagination page={1} count={count} />
      </section>
    </>
  );
};

const th = [
  {
    name: "SN.",
  },
  {
    name: "Category Name",
  },
];
export default CategoryList;

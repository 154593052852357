import { createSlice } from "@reduxjs/toolkit";
import {
  addInventeryLossCategory,
  addInventeryLossExpenses,
  fetchInventeryLossCategory,
  fetchInventoryExpenses,
} from "./InventeryExpensesAction";

const inventeryExpenses = createSlice({
  name: "inventery-expenses",
  initialState: {
    isLoading: true,
    success: false,
    inventeryLossCategories: [],
    inventoryExpenses: [],
  },
  extraReducers: (builder) => {
    builder.addCase(addInventeryLossCategory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addInventeryLossCategory.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(addInventeryLossCategory.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchInventeryLossCategory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchInventeryLossCategory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success;
      state.inventeryLossCategories = action.payload.inventeryLossCategory;
    });
    builder.addCase(fetchInventeryLossCategory.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(addInventeryLossExpenses.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addInventeryLossExpenses.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success;
    });
    builder.addCase(addInventeryLossExpenses.rejected, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchInventoryExpenses.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchInventoryExpenses.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success;
      state.inventoryExpenses = action.payload.response;
    });
    builder.addCase(fetchInventoryExpenses.rejected, (state, action) => {
      state.isLoading = true;
    });
  },
});

export const inventeryExpensesReducer = inventeryExpenses.reducer;

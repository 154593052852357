import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSalary } from "../../Redux/Feature/Salary/salaryAction";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddSalary = () => {
  // const [employeeId, setEmployeeId] = useState("");
  const [amount, setAmount] = useState("");
  const [paidDate, setPaidDate] = useState("");
  const [cashType, setCashType] = useState("");
  // const [salaryCreditType, setSalaryCreditType] = useState("");
  // const [cashCredit, setCashCredit] = useState("");
  // const [partial_cashCredit, setPartial_cashCredit] = useState("");
  // const [partial_Credit, setPartial_Credit] = useState("");
  const [transctionType, setTransctionType] = useState("");
  const [cashTransction, setCashTransction] = useState("");
  const [bankTransction, setBankTransction] = useState("");
  const [B_cashTransction, setB_cashTransction] = useState("");
  const [B_bankTransction, setB_bankTransction] = useState("");

  const { singleEmployee } = useSelector((state) => state.employee);

  const dispatch = useDispatch();
  const token = localStorage.getItem("NHHD:token");
  const navigate = useNavigate();

  const submit = () => {
    if (amount === 0 || paidDate === "") {
      toast.error("Some Feild are Missing");
    } else {
      dispatch(
        addSalary({
          token,
          amount,
          paidDate,
          navigate,
          employeeId: singleEmployee._id,
          cashType,
          // salaryCreditType,
          // cashCredit,
          // partial_cashCredit,
          // partial_Credit,
          transctionType,
          cashTransction,
          bankTransction,
          B_cashTransction,
          B_bankTransction,
        })
      );
    }
  };

  return (
    <>
      <div class="container mx-auto">
        <div class="lg:flex md:flex justify-center px-6 ">
          <div class="w-full lg:w-9/12 px-4">
            <div>
              <div
                className=" transition duration-150 ease-in-out z-10  "
                id="modal"
              >
                <div
                  role="alert"
                  className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
                >
                  <div className=" ">
                    <div className=" lg:flex md:flex justify-center">
                      <div className="border border-gray-100 w-auto h-auto bg-gray-200 py-4 px-3 rounded-[6px] ">
                        {/* <p className="p-4 text-[1.5rem] text-[green]">
                          Add Salary
                        </p> */}

                        <div>
                          {" "}
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            className={`  py-3 rounded-md w-full h-full placeholder:px-2 px-2 ${
                              singleEmployee.employeeSalary >= amount ||
                              singleEmployee.employeeSalary === amount
                                ? null
                                : "text-red-900"
                            }`}
                            placeholder="Enter Amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                          />{" "}
                          {singleEmployee.employeeSalary < amount && (
                            <p className="text-[13px] py-2 text-red-900">
                              Employee Salary must be less than or equals to
                              Monthy Salary: {singleEmployee.employeeSalary}{" "}
                            </p>
                          )}
                          {/* <div>
                            <select
                              className="py-3 rounded-md w-full h-full placeholder:px-2 px-2 my-3"
                              value={cashType}
                              onChange={(e) => setCashType(e.target.value)}
                            >
                              <option>Bank Transfer</option>
                              <option>Hand Cash</option>
                              <option>Mobile Wallet</option>
                            </select>
                          </div> */}
                          <div>
                            {/* <p className="uppercase opacity-30 pt-3">Credit</p>
                            <div className="mb-4">
                              <select
                                className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                                value={salaryCreditType}
                                onChange={(e) =>
                                  setSalaryCreditType(e.target.value)
                                }
                              >
                                <option>Select Credit Type</option>
                                <option>Cash Credit</option>
                                <option>Partial Credit</option>
                              </select>
                            </div> */}

                            {/* <input
                              type="number" onWheel={(e) => e.target.blur()}
                              value={cashCredit}
                              onChange={(e) => setCashCredit(e.target.value)}
                              placeholder="Enter Cash Amount"
                              className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                            /> */}

                            {/* <div className="gap-3 my-2">
                              <div className="my-2">
                                <input
                                  type="number" onWheel={(e) => e.target.blur()}
                                  value={partial_cashCredit}
                                  onChange={(e) =>
                                    setPartial_cashCredit(e.target.value)
                                  }
                                  placeholder="Enter Cash Amount"
                                  className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                                />
                              </div>
                              <div className="my-2">
                                <input
                                  type="number" onWheel={(e) => e.target.blur()}
                                  value={partial_Credit}
                                  onChange={(e) =>
                                    setPartial_Credit(e.target.value)
                                  }
                                  placeholder="Enter Credit Amount"
                                  className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                                />
                              </div>
                            </div> */}
                          </div>
                          <>
                            <p className="uppercase opacity-30 pt-3">
                              Transiction{" "}
                            </p>
                            <div className="  gap-3  my-4">
                              <select
                                className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                                name="transictionType"
                                value={transctionType}
                                onChange={(e) =>
                                  setTransctionType(e.target.value)
                                }
                              >
                                <option>Select Transiction Type</option>
                                {caseType.map((item, i) => {
                                  return <option>{item.TypeName}</option>;
                                })}
                              </select>

                              <div className="my-2">
                                <p>
                                  {transctionType === "Bank" ? (
                                    <input
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      value={bankTransction}
                                      onChange={(e) =>
                                        setBankTransction(e.target.value)
                                      }
                                      placeholder="Enter BANK Amount"
                                      className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                                    />
                                  ) : transctionType === "Cash" ? (
                                    <input
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      value={cashTransction}
                                      onChange={(e) =>
                                        setCashTransction(e.target.value)
                                      }
                                      placeholder="Enter CASH Amount"
                                      className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0"
                                    />
                                  ) : transctionType === "Both" ? (
                                    <>
                                      <div className="">
                                        {" "}
                                        <input
                                          type="number"
                                          onWheel={(e) => e.target.blur()}
                                          value={B_bankTransction}
                                          onChange={(e) =>
                                            setB_bankTransction(e.target.value)
                                          }
                                          placeholder="Enter  BANK Amount"
                                          className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                                        />
                                      </div>
                                      <div className="my-2">
                                        {" "}
                                        <input
                                          type="number"
                                          onWheel={(e) => e.target.blur()}
                                          value={B_cashTransction}
                                          onChange={(e) =>
                                            setB_cashTransction(e.target.value)
                                          }
                                          placeholder="Enter  CASH Amount"
                                          className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 "
                                        />
                                      </div>
                                    </>
                                  ) : null}
                                </p>
                              </div>
                            </div>
                          </>
                          <input
                            type="Date"
                            className={`border py-3 rounded-md placeholder:px-2 px-2 w-full my-2 `}
                            value={paidDate}
                            onChange={(e) => setPaidDate(e.target.value)}
                          />
                        </div>
                        <button
                          className="border bg-green-700 w-[200px] text-center text-white font-[500] text-xl py-2 px-3 rounded-[6px] my-4 uppercase"
                          onClick={submit}
                          disabled={
                            singleEmployee.employeeSalary < amount && true
                          }
                        >
                          Add Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const caseType = [
  {
    TypeName: "Cash ",
  },
  {
    TypeName: "Bank ",
  },
  {
    TypeName: "Both ",
  },
];

export default AddSalary;

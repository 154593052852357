import React, { useState } from "react";
import SupTable from "../../Screens/SingleProduct/SupTabel";
import CateTable from "../../Screens/SingleProduct/CateTabel";
import { useSelector } from "react-redux";
import { Spinner } from "../Spinner/Spinner";

const Tabs = ({ desc, supDetails, cateDetails }) => {
  const [isActive, setIsActive] = useState(1);

  const [tab, setTab] = useState(desc);
  const clickDesc = (id) => {
    setTab(desc);
    setIsActive(id);
  };
  const clickSupDetails = (id) => {
    setTab(<SupTable data={supDetails} />);
    setIsActive(id);
  };
  const clickCateDetails = (id) => {
    setTab(<CateTable data={cateDetails} />);
    setIsActive(id);
  };

  const { isLoading } = useSelector((state) => state.products);

  return (
    <>
      {isLoading === true ? (
        <Spinner />
      ) : (
        <div className="container grid grid-cols-3 grid-flow-row  cursor-pointer border bg-gray-200 rounded-[6px] p-2 my-3 ">
          <p
            className=" font-[500] border bg-[white] w-[200px]  text-center rounded-md py-3"
            style={{ color: 1 === isActive ? "green" : "" }}
            onClick={() => clickDesc(1)}
          >
            Description
          </p>
          <p
            className="font-[500] border bg-[white] w-[200px]  text-center rounded-md py-3"
            style={{ color: 2 === isActive ? "green" : "" }}
            onClick={() => clickSupDetails(2)}
          >
            Supplier Details
          </p>
          <p
            className=" font-[500] border bg-[white] w-[200px]  text-center rounded-md py-3"
            id="3"
            style={{ color: 3 === isActive ? "green" : "" }}
            onClick={() => clickCateDetails(3)}
          >
            Category Details
          </p>
        </div>
      )}

      <>
        <p>{tab}</p>
      </>
    </>
  );
};

export default Tabs;

import { MdOutlineCrisisAlert } from "react-icons/md";

import React from "react";
import {
  MdOutlineProductionQuantityLimits,
  MdOutlineCategory,
  MdLabelImportantOutline,
  MdOutlineAttachMoney,
  MdEqualizer,
  MdApproval,
} from "react-icons/md";

import { useSelector } from "react-redux";

const TotalCount = () => {
  const { dashboard } = useSelector((state) => state.dashboard);

  return (
    <div>
      {
        <div className="grid  lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4 my-5">
          <div className="border  transform  hover:scale-105 transition duration-300 shadow-xl rounded-lg col-span-1 bg-white">
            <div className="grid grid-cols-2 grid-flow-row px-4  pt-2">
              <p>
                <MdOutlineCategory className="text-[green] text-[2rem]" />
              </p>
            </div>
            <p className="px-4 text-[2rem] font-bold">
              {dashboard && dashboard.allCategories}
            </p>
            <p className="px-4 pb-3 ">All Categories</p>
          </div>
          <div className="border  transform  hover:scale-105 transition duration-300 shadow-xl rounded-lg col-span-1 bg-white">
            <div className="grid grid-cols-2 grid-flow-row px-4  pt-2">
              <p>
                <MdLabelImportantOutline className="text-[green] text-[2rem]" />
              </p>
            </div>
            <p className="px-4 text-[2rem] font-bold">
              {dashboard && dashboard.allSuppliers}
            </p>
            <p className="px-4 pb-3 ">All Suppliers</p>
          </div>
          <div className="border  transform  hover:scale-105 transition duration-300 shadow-xl rounded-lg col-span-1 bg-white">
            <div className="grid grid-cols-2 grid-flow-row px-4  pt-2">
              <p>
                <MdOutlineAttachMoney className="text-[green] text-[2rem]" />
              </p>
            </div>
            <p className="px-4 text-[2rem] font-bold">
              {dashboard && dashboard.totalProfit}
            </p>
            <p className="px-4 pb-3 ">Total Profit</p>
          </div>
          <div className="border  transform  hover:scale-105 transition duration-300 shadow-xl rounded-lg col-span-1 bg-white">
            <div className="grid grid-cols-2 grid-flow-row px-4  pt-2">
              <p>
                <MdEqualizer className="text-[green] text-[2rem]" />
              </p>
            </div>
            <p className="px-4 text-[2rem] font-bold">
              {/* {dashboard && dashboard.totalQuantity} */}00
            </p>
            <p className="px-4 pb-3 "> Total Quantity</p>
          </div>
          <div className="border  transform  hover:scale-105 transition duration-300 shadow-xl rounded-lg col-span-1 bg-white">
            <div className="grid grid-cols-2 grid-flow-row px-4  pt-2">
              <p>
                <MdOutlineCrisisAlert className="text-[green] text-[2rem]" />
              </p>
            </div>
            <p className="px-4 text-[2rem] font-bold">
              {dashboard && dashboard.totalSale}
            </p>
            <p className="px-4 pb-3 "> Total Sale</p>
          </div>
          <div className="border  transform  hover:scale-105 transition duration-300 shadow-xl rounded-lg col-span-1 bg-white">
            <div className="grid grid-cols-2 grid-flow-row px-4  pt-2">
              <p>
                <MdApproval className="text-[green] text-[2rem]" />
              </p>
            </div>
            <p className="px-4 text-[2rem] font-bold">
              {dashboard && dashboard.totalValue}
            </p>
            <p className="px-4 pb-3 "> Total Value</p>
          </div>
          <div className="border  transform  hover:scale-105 transition duration-300 shadow-xl rounded-lg col-span-1 bg-white">
            <div className="grid grid-cols-2 grid-flow-row px-4  pt-2">
              <p>
                <MdOutlineProductionQuantityLimits className="text-[green] text-[2rem]" />
              </p>
            </div>
            <p className="px-4 text-[2rem] font-bold">
              {/* {dashboard && dashboard.totatProducts} */}00
            </p>
            <p className="px-4 pb-3 "> Total Product</p>
          </div>
        </div>
      }
    </div>
  );
};

export default TotalCount;

import { useSelector } from "react-redux";

const PurchaseDetails = ({ single }) => {
  // const token = localStorage.getItem("NHHD:token");
  // const { id } = useParams();

  // const dispatch = useDispatch();

  const { data } = useSelector((state) => state.products);
  // const { singleSupply } = useSelector((state) => state.supplier);
  // Extract product details from inventoryDetails
  const inventoryDetails = single?.inventoryDetails || [];
  const productDetails = inventoryDetails.map((item) => item.productDetails);
  const flattenedProductDetails = productDetails
    .map((item) => item.details)
    .flat();

  // Calculate total purchase amount
  const totalPurchaseAmount = flattenedProductDetails.reduce(
    (total, item) => total + item.productSubtotal,
    0
  );

  // Calculate total credit amount
  const totalCreditAmount = single.creditDetails.reduce(
    (total, item) => total + item.creditAmount,
    0
  );

  // Calculate total credit transaction amount
  const totalCreditTransactionAmount = single?.transcationDetails.reduce(
    (total, item) => {
      const amount = item.amount;
      return total + isNaN(amount);
    },
    0
  );

  console.log(single?.transcationDetails);

  // const defactProductDetails = single?.inventoryDetails.map(
  //   (item) => item?.defactProductDetails?.details?.$each
  // );
  // const flattenedDefactDetails = [].concat(...defactProductDetails);

  const productMap = new Map();
  data?.forEach((product) => {
    product?.purchaseFrom?.forEach((purchaseInfo) => {
      productMap.set(
        purchaseInfo._id,
        purchaseInfo.productName,
        purchaseInfo.productPrice,
        purchaseInfo.productQuantity
      );
    });
  });

  const extractedProductNames = [];

  flattenedProductDetails?.forEach((item) => {
    const productName = productMap.get(item.productId);
    // const productPrice = productMap.get(item.productPrice);
    // const productQuantity = productMap.get(item.productQuantity);
    if (
      productName
      // || productPrice || productQuantity
    ) {
      extractedProductNames.push(
        productName
        // , productPrice, productQuantity
      );
    }
  });

  function addProductNameToDetails(productDetails, productMap) {
    for (const productDetail of productDetails) {
      const details = productDetail.details;
      for (const detail of details) {
        const productId = detail.productId;
        if (productMap.has(productId)) {
          detail.productName = productMap.get(productId);
        }
      }
    }
  }

  // Call the function to add productName to productDetails
  addProductNameToDetails(productDetails, productMap);

  // Now productDetails will contain productName for each detail

  return (
    <>
      <section class="container px-1 mx-auto">
        <div className="grid md:grid-cols-3 lg:grid-cols-3 grid-flow-row">
          <p>
            Total Credit Amount :
            <span className="text-xl text-green-800 font-semibold">
              Rs.{totalCreditAmount}
            </span>
          </p>
          <p>
            Total Credit Transaction :
            <span className="text-xl text-green-800 font-semibold">
              Rs.{totalCreditTransactionAmount}
            </span>
          </p>
          <p>
            Total Purchase Amount :
            <span className="text-xl text-green-800 font-semibold">
              Rs.{totalPurchaseAmount}
            </span>
          </p>
        </div>

        <div class="flex flex-col">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-2 lg:px-2">
              <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200  ">
                  <thead class="bg-gray-50  ">
                    <tr>
                      {th.map((item, i) => {
                        return (
                          <th
                            scope="col col-span-5"
                            key={i}
                            class="px-3 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                          >
                            {item.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    {productDetails.map((details, i) => {
                      // // console.log(
                      // //   details.productDetails.details.map(
                      // //     (ele) => ele.productId
                      // //   )
                      // // );

                      // // console.log(
                      // //   details.productDetails.details.map(
                      // //     (ele) => ele.productId
                      // //   )
                      // // );
                      // const fiterProduct = data.map((ele) => ele).flat();
                      // const newFilterProduct = fiterProduct.flat();
                      // // console.log(
                      // //   newFilterProduct
                      // //     .map((ele) => ele.purchaseFrom)
                      // //     .flat()
                      // //     .filter(
                      // //       (ele) => ele._id === "64f5be1adc6af191c3e812fe"
                      // //     )
                      // // );
                      // const filterProductName =
                      //   details.productDetails.details.map((product) =>
                      //     newFilterProduct
                      //       .map((ele) => ele.purchaseFrom)
                      //       .flat()
                      //       .filter(
                      //         (ele) => ele._id === "64f5be1adc6af191c3e812fe"
                      //       )
                      //   );
                      // const specificProductId = "64f5be1adc6af191c3e812fe";

                      // const filteredProduct = data
                      //   .map((item) => item.purchaseFrom)
                      //   .flat()
                      //   .filter(
                      //     (ele) => ele._id === specificProductId.toString()
                      //   );

                      // if (filteredProduct) {
                      //   // console.log("Found:", filteredProduct);
                      // } else {
                      //   // console.log("Product not found");
                      // }

                      // // console.log(filterProductName);
                      // const productdetails = details.productDetails.details.map(
                      //   (product) => product
                      // );
                      // const totalQuantity = productdetails.reduce(
                      //   (total, ele) => {
                      //     const qnty = ele.productQuantity;
                      //     return total + Number(qnty);
                      //   },
                      //   0
                      // );

                      // let totalAmount = 0;
                      // for (const obj of flattenedProductDetails) {
                      //   totalAmount += obj.productSubtotal;
                      // }

                      const amountWithoutVatAndDiscount =
                        details.details.reduce((total, ele) => {
                          const subTotal = ele.productSubtotal;
                          return total + Number(subTotal);
                        }, 0);

                      return (
                        <tr key={i}>
                          <td class="px-1 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                            <div class="inline-flex items-center">
                              <span>{details.purchaseDate}</span>
                            </div>
                          </td>
                          <td class="px-1 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                            <div class="inline-flex items-center">
                              <span>{details.purchaseBillNo}</span>
                            </div>
                          </td>
                          <td class="px-1 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                            <div class="flex flex-col">
                              {details.details.map((ele) => (
                                <span className="pt-3 px-2">
                                  {ele.length > 1
                                    ? `${ele.productName} ,`
                                    : ele.productName}
                                </span>
                              ))}
                            </div>
                          </td>
                          <td class="px-3 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                            <div class="flex flex-col">
                              {details.details.map((ele) => (
                                <span className="pt-3 px-2">
                                  {ele.length > 1
                                    ? `${ele.productUnitPrice} ,`
                                    : ele.productUnitPrice}
                                </span>
                              ))}
                            </div>
                          </td>
                          <td class="px-3 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                            <div class="flex flex-col">
                              {details.details.map((ele) => (
                                <span className="pt-3 px-2">
                                  {ele.length > 1
                                    ? `${ele.productQuantity} ,`
                                    : ele.productQuantity}
                                </span>
                              ))}
                            </div>
                          </td>
                          <td class="px-3 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                            <div class="flex flex-col">
                              {details.details.map((ele) => (
                                <span className="pt-3 px-2">
                                  {ele.length > 1
                                    ? `${ele.productSubtotal}  ,`
                                    : ele.productSubtotal}
                                </span>
                              ))}
                            </div>
                          </td>

                          <td class="px-1 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                            <div class="inline-flex items-center">
                              <span>{amountWithoutVatAndDiscount}</span>
                            </div>
                          </td>
                          <td class="px-1 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                            <div class="inline-flex items-center">
                              <span>{details.discountAmount}</span>
                            </div>
                          </td>
                          <td class="px-1 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                            <div class="inline-flex items-center">
                              <span>{details.vatAmount}</span>
                            </div>
                          </td>
                          <td class="px-1 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                            <div class="inline-flex items-center">
                              <span>{details.amountWithVAT}</span>
                            </div>
                          </td>
                          <td class="px-1 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                            <div class="inline-flex items-center">
                              <span>
                                {details.amountWithVAT - details.creditAmount}
                              </span>
                            </div>
                          </td>
                          <td class="px-1 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                            <div class="inline-flex items-center">
                              <span>{details.creditAmount}</span>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
// discount

const th = [
  {
    name: "Date",
  },
  {
    name: "Bill No.",
  },
  {
    name: "Product Name",
  },
  {
    name: "Unit Price",
  },
  {
    name: "Quantity",
  },
  {
    name: "Sub Total",
  },
  {
    name: "Amount without VAT & Discount",
  },
  {
    name: "Discount Amount",
  },

  // {
  //   name: "Amount Without VAT",
  // },
  {
    name: "VAT Amount ",
  },
  {
    name: "Total Amount ",
  },

  {
    name: "Paid Amount",
  },
  {
    name: "Credit Amount",
  },
];

// const defeactTabel = [
//   {
//     name: "Date",
//   },
//   {
//     name: "Bill No.",
//   },

//   {
//     name: "Product Name",
//   },
//   {
//     name: "Quanitity",
//   },
//   {
//     name: "Per Price",
//   },
//   {
//     name: "Total Amount",
//   },
//   {
//     name: "Reason",
//   },
// ];

export default PurchaseDetails;

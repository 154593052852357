import React from "react";
import Tittle from "../../../Components/Tittle/Tittle";
import BasicModal from "../../../Components/Modal/Modal";
import Button from "../../../Components/Button/Button";
import AssestToSale from "../Sold Assests/AssestToSale";
import SoldAssestList from "../Sold Assests/SoldAssestList";
import ReturnAssetPayment from "./ReturnAssetPayment";
import { useSelector } from "react-redux";

const Index = () => {
  const { assestSale } = useSelector((state) => state.assest);
  console.log(assestSale);
  const filteredData = assestSale.filter((item) => item.assessmentMethod);

  return (
    <>
      <div>
        <Tittle tittle={"Assest"} />
        <div className="flex gap-2">
          <BasicModal
            btn={<Button btnName={"Sell Asset"} />}
            tittle={"Sell Asset"}
            modalDetails={<AssestToSale />}
          />
          {filteredData === "Partial Credit" || filteredData === "Credit" ? (
            <BasicModal
              btn={<Button btnName={"Asset Credit Received"} />}
              tittle={"Asset Credit Received"}
              modalDetails={<ReturnAssetPayment />}
            />
          ) : null}
          <BasicModal
            btn={<Button btnName={"Asset Credit Received"} />}
            tittle={"Asset Credit Received"}
            modalDetails={<ReturnAssetPayment />}
          />
        </div>

        <div className="mt-[2rem]">
          <Tittle tittle={"Top  Sold Assets"} />
          <SoldAssestList />
        </div>
      </div>
    </>
  );
};

export default Index;

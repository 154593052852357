import React from "react";
import { useSelector } from "react-redux";
const SupplierCard = () => {
  const { topSupplier } = useSelector((state) => state.dashboard);
  console.log(topSupplier);
  return (
    <>
      <section class="container px-4 mx-auto">
        <div class="flex flex-col">
          <div class="-mx-4 my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div class="overflow-hidden border border-gray-200 md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      {th.map((item, i) => {
                        return (
                          <th
                            scope="col col-span-5"
                            key={i}
                            class="px-[1rem] py-3.5   text-left rtl:text-right text-xl font-bold  "
                          >
                            {item.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  <tbody class="bg-white divide-y divide-gray-200    ">
                    {topSupplier &&
                      topSupplier.map((item, i) => {
                        return (
                          <>
                            <tr
                              className=" grid grid-cols-2 grid-flow-row px-2"
                              key={i}
                            >
                              <tr key={i}>
                                <td class=" py-4 text-sm px-3 text-gray-500 capitalize   whitespace-nowrap">
                                  {item.supplierName}
                                </td>
                              </tr>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const th = [
  {
    name: "Top Suppliers",
  },
];

export default SupplierCard;

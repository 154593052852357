import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { purchasePayCredit } from "../../Redux/Feature/Product/productAction";

import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
import Button from "../../Components/Button/Button";

const PayCredit = () => {
  const { supplies } = useSelector((state) => state.supplier);
  const { data } = useSelector((state) => state.products);

  console.log(supplies, "supplies");
  console.log(data, "products");

  const purchaseData = data
    .map((e) => {
      const checkPartialCredit = e.creditType === "Partial Credit";
      console.log(checkPartialCredit);
      const chekCredit = e.creditType === "Credit";
      const checkAmount = e.credit > 0;
      const checkParitialAmount = e.partialCredit > 0;
      if (checkAmount || checkParitialAmount) {
        if (chekCredit || checkPartialCredit) return e;
        return null;
      } else {
        return null;
      }
    })
    .filter(Boolean); /// remove null value if there is

  console.log(purchaseData, "purchaseData");

  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // New state for button disable

  // const [paidMonth, setPaidMonth] = useState("");

  const [purchaseId, setPurchaseId] = useState("");
  const [amount, setAmount] = useState("");
  const [paidDate, setPaidDate] = useState("");
  const [transcationType, setTranscationType] = useState("");
  const [partialCash, setPartialCash] = useState("");
  const [partialBank, setPartialBank] = useState("");

  const [discountType, setDiscountType] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [forTransaction, setForTransaction] = useState("");

  const dispatch = useDispatch();

  const monthInBS = parseInt(paidDate.slice(5, 7)) - 1;
  const yearInBS = paidDate.slice(0, 4);

  const currentMonthInBS = monthOrder[monthInBS];

  const month = currentMonthInBS + "-" + yearInBS;

  const submit = () => {
    if (purchaseId === "" || amount === "" || transcationType === "") {
      toast.error("Some Feild are missing Once chek");
    } else {
      setIsButtonDisabled(true); // Disable the button

      return dispatch(
        purchasePayCredit({
          purchaseId,
          amount,
          paidDate,
          month,
          transcationType,
          partialBank,
          partialCash,
          discountAmount,
          discountType,
        })
      ).then(() => {
        setIsButtonDisabled(false); // Disable the button
      });
    }
  };

  console.log(
    purchaseId,
    amount,
    paidDate,
    transcationType,
    partialBank,
    partialCash
  );

  return (
    <>
      <Scrollbars style={{ height: 500 }}>
        <div class="container mx-auto">
          <div class="lg:flex md:flex justify-center px-6 ">
            <div class="w-full lg:w-9/12 px-4">
              <div>
                <div
                  className=" transition duration-150 ease-in-out z-10  "
                  id="modal"
                >
                  <div
                    role="alert"
                    className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
                  >
                    <div className=" ">
                      <div className=" lg:flex md:flex justify-center">
                        <div className="border border-gray-100 w-auto h-auto bg-gray-200 py-4 px-3 rounded-[6px] ">
                          <select
                            className="border py-3 rounded-md  w-full placeholder:px-2 px-2 lg:my-0 md:y-0 my-2"
                            name="purchaseId"
                            value={purchaseId}
                            onChange={(e) => setPurchaseId(e.target.value)}
                          >
                            <option>Select Supplier Name</option>
                            {purchaseData.map((item, i) => {
                              // console.log(item, "here");
                              return (
                                <option value={item._id}>
                                  {item.supplierDetails.supplierName} - Rs.
                                  {item.creditType === "Credit"
                                    ? item.credit
                                    : item.creditType === "Partial Credit"
                                    ? item.partialCredit
                                    : 0}
                                </option>
                              );
                            })}
                          </select>
                          <div className=" items-center">
                            <p className="text-red-500 px-3">
                              Please Select the Date
                            </p>
                            <NepaliDatePicker
                              className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0   "
                              value={paidDate}
                              onChange={(value) => setPaidDate(value)}
                              options={{
                                calenderLocale: "ne",
                                valueLocale: "en",
                              }}
                            />
                          </div>
                          <div>
                            <p className="text-[14px] text-gray-400 py-2">
                              Is the discount given?
                            </p>
                            <div className="flex gap-6 items-center ">
                              <input
                                type="checkbox"
                                className="border p-3 mb-4 rounded-md placeholder:p-2"
                                onChange={(e) => {
                                  setDiscountType(e.target.checked);
                                  if (!e.target.checked) {
                                    setDiscountAmount("");
                                  }
                                }}
                              />
                              {discountType === true ? (
                                <input
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  onChange={(e) =>
                                    setDiscountAmount(e.target.value)
                                  }
                                  className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 w-full"
                                  placeholder="Enter discount amount if any"
                                />
                              ) : null}
                            </div>
                          </div>

                          <div className="my-2 w-full">
                            <input
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              placeholder=" Enter Credit amount"
                              value={amount}
                              onChange={(e) => {
                                setAmount(e.target.value);
                                setForTransaction(e.target.value);
                              }}
                              className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 w-full"
                            />
                          </div>

                          <>
                            <p className="uppercase opacity-30 pt-1">
                              Transaction{" "}
                            </p>
                            <div className="  gap-3  ">
                              <select
                                className="border py-3 rounded-md placeholder:px-2 px-2 my-2 lg:my-0 md:my-0 w-full h-full"
                                name="transictionType"
                                value={transcationType}
                                onChange={(e) =>
                                  setTranscationType(e.target.value)
                                }
                              >
                                <option>Select Transaction Type</option>
                                {caseType.map((item, i) => {
                                  return <option>{item.TypeName}</option>;
                                })}
                              </select>

                              <div className="my-2">
                                <p>
                                  {transcationType === "Both" && (
                                    <div className="flex gap-2">
                                      <div className="">
                                        <input
                                          type="number"
                                          onWheel={(e) => e.target.blur()}
                                          value={partialBank}
                                          onChange={(e) => {
                                            setPartialBank(e.target.value);
                                            const calculatedValue =
                                              forTransaction - e.target.value;
                                            setPartialCash(calculatedValue);
                                          }}
                                          placeholder="Enter  BANK Amount"
                                          className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 w-full"
                                        />
                                      </div>
                                      <div className="">
                                        <input
                                          type="number"
                                          onWheel={(e) => e.target.blur()}
                                          value={partialCash}
                                          placeholder="Enter  CASH Amount"
                                          className="border  rounded-md placeholder:px-2 px-2 py-3  my-2 lg:my-0 md:my-0 w-full"
                                        />
                                      </div>
                                    </div>
                                  )}
                                </p>
                              </div>
                            </div>
                          </>

                          <div className="border  w-[200px] text-center font-[500] text-xl py-2 px-3 rounded-[6px] my-4 uppercase cursor-pointer">
                            <Button
                              btnName={"Pay"}
                              click={submit}
                              isLoading={isButtonDisabled}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Scrollbars>
    </>
  );
};

const caseType = [
  {
    TypeName: "Cash ",
  },
  {
    TypeName: "Bank ",
  },
  {
    TypeName: "Both ",
  },
];

const monthOrder = [
  "बैशाख",
  "जेठ",
  "असार",
  "श्रावण",
  "भाद्र",
  "असोज",
  "कार्तिक",
  "मंसिर",
  "पुष",
  "माघ",
  "फागुन",
  "चैत्र",
];

export default PayCredit;

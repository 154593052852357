import React from "react";
import ProductList from "./ProductList";
import Modal from "../../Components/Modal/Modal";
import Button from "../../Components/Button/Button";
import Tittle from "../../Components/Tittle/Tittle";
import ReturnProduct from "./ReturnProduct/ReturnProduct";
import { Link } from "react-router-dom";

const Index = () => {
  return (
    <>
      <div>
        <Tittle tittle={"product"} />
        <div className="flex items-center gap-3">
          <Link to={"/add-product"}>
            <Button btnName={"Add new Product"} />
          </Link>
          <Link to={"/add-existing-product"}>
            <Button btnName={"Add Existing Product"} />
          </Link>
          <Modal
            btn={<Button btnName={"Return Product"} />}
            tittle={"Return  Product"}
            modalDetails={<ReturnProduct />}
          />
          {/* <Modal
            btn={<Button btnName={"Pay Credit "} />}
            tittle={" Pay Credit   "}
            modalDetails={<PayCredit />}
          /> */}
        </div>

        <div className="mt-[2rem]">{<ProductList />}</div>
      </div>
    </>
  );
};

export default Index;

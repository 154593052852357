import React from "react";
import Modal from "../../Components/Modal/Modal";
import Button from "../../Components/Button/Button";
import AddCategory from "./AddCategory";
import CategoryList from "./CategoryList";
import Tittle from "../../Components/Tittle/Tittle";

const Index = () => {
  return (
    <>
      <div>
        <Tittle tittle={"category"} />
        <Modal
          btn={<Button btnName={"Add a Category"} />}
          tittle={"Fill Category"}
          modalDetails={<AddCategory />}
        />
        <div className="mt-[2rem]">
          <CategoryList />
        </div>
      </div>
    </>
  );
};

export default Index;

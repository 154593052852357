import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addInventeryLossExpenses } from "../../Redux/Feature/InventeryExpenses/InventeryExpensesAction";

const AddInventery = () => {
  const { data } = useSelector((state) => state.products);
  const [productId, setProductId] = useState(null);
  const [inventeryCategoryName, setInventeryCategoryName] = useState("");
  const [productQuantity, setProductQuantity] = useState("");
  const dispatch = useDispatch();
  const { inventeryLossCategories } = useSelector(
    (state) => state.inventeryExpenses
  );
  const submit = () => {
    dispatch(
      addInventeryLossExpenses({
        productId,
        inventeryCategoryName,
        productQuantity,
      })
    );
  };
  console.log(inventeryLossCategories);
  return (
    <>
      <div class="container mx-auto">
        <div class="lg:flex md:flex justify-center px-6 ">
          <div class="w-full lg:w-9/12 px-4">
            <div>
              <div
                className=" transition duration-150 ease-in-out z-10  "
                id="modal"
              >
                <div
                  role="alert"
                  className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
                >
                  <div className=" ">
                    <div className=" lg:flex md:flex justify-center">
                      <div className="border border-gray-100 w-auto h-auto bg-gray-200 py-4 px-3 rounded-[6px] ">
                        <div className="lg:flex md:flex gap-3 my-2">
                          <select
                            className="border py-3 h-full w-full rounded-md placeholder:px-2 px-2   my-2 md:my-0 lg:my-0"
                            value={productId}
                            onChange={(e) => setProductId(e.target.value)}
                          >
                            <option>Select Product</option>
                            {data?.map(
                              (item, i) =>
                                item &&
                                item.purchaseFrom.map((ele) => {
                                  return (
                                    <option value={ele._id}>
                                      {ele.productName}
                                    </option>
                                  );
                                })
                            )}
                          </select>
                        </div>
                        <div className="lg:flex md:flex gap-3 my-2">
                          <select
                            className="border py-3 h-full w-full rounded-md placeholder:px-2 px-2   my-2 md:my-0 lg:my-0"
                            value={inventeryCategoryName}
                            onChange={(e) =>
                              setInventeryCategoryName(e.target.value)
                            }
                          >
                            <option>Select Inventery Category</option>
                            {inventeryLossCategories.map((ele) => {
                              return <option>{ele.inventeryCategory}</option>;
                            })}
                          </select>
                        </div>
                        <div className="lg:flex md:flex gap-3 my-2">
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            className="border py-3 rounded-md w-full h-full placeholder:px-2 px-2 lg:my-0 md:y-0 my-2"
                            placeholder="Enter Quantity"
                            value={productQuantity}
                            onChange={(e) => setProductQuantity(e.target.value)}
                          />
                        </div>

                        <p
                          className="border bg-green-700 w-[200px] text-center text-white font-[500] text-xl py-2 px-3 rounded-[6px] my-4 uppercase "
                          onClick={submit}
                        >
                          Add Inventery
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddInventery;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  filtersalaryByPaidDate,
  getSingleSalary,
} from "../../Redux/Feature/Salary/salaryAction";
import BasicModal from "../../Components/Modal/Modal";
import UpdateSalary from "./UpdateSalary";
import { Link } from "react-router-dom";
import SalaryPagination from "./SalaryPagination";
const SalaryList = () => {
  const token = localStorage.getItem("NHHD:token");
  const dispatch = useDispatch();
  const { salaryById } = useSelector((state) => state.salary);
  console.log(salaryById);
  const updateSingleSalary = (id) => {
    dispatch(getSingleSalary({ id, token }));
  };

  const SingleHistory = (id) => {
    dispatch(getSingleSalary({ id, token }));
  };

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const findDate = () => {
    dispatch(filtersalaryByPaidDate({ token, startDate, endDate }));
  };

  return (
    <>
      <div className="flex gap-3 my-4">
        <p className="mt-7 opacity-60 text-md">Filter By Date : </p>
        <div className="  flex gap-2 ">
          <div>
            <p>Start Date</p>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>

          <div>
            <p>End Date</p>
            <div className="flex">
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
          <button
            className="text-md border-gray-400 bg-green-900  w-full  mt-6 cursor-pointer py-2 px-7 text-center uppercase rounded-md text-white font-medium"
            onClick={findDate}
            disabled={!startDate || !endDate ? true : false}
          >
            Find
          </button>
        </div>
      </div>

      <div class="flex flex-col">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div class="overflow-hidden border border-gray-200   md:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200  ">
                <thead class="bg-gray-50 ">
                  <tr>
                    {th.map((item, i) => {
                      return (
                        <th
                          scope="col col-span-5"
                          key={i}
                          class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                        >
                          {item.name}
                        </th>
                      );
                    })}
                    <th
                      scope="col col-span-5"
                      class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  {salaryById.slice(0, 6).map((item, i) => {
                    return (
                      <tr key={i}>
                        <td class="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                          <div class="inline-flex items-center gap-x-3">
                            <span>{i + 1}</span>
                          </div>
                        </td>
                        <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap ">
                          <div class="flex items-center gap-x-2">
                            <div>
                              <h2 class="text-sm capitalize font-medium text-gray-800   ">
                                {new Date(item.paidDate).toDateString()}
                              </h2>
                            </div>
                          </div>
                        </td>
                        <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap ">
                          <div class="flex items-center gap-x-2">
                            <div>
                              <h2 class="text-sm capitalize font-medium text-gray-800   ">
                                Rs.{item.amount}
                              </h2>
                            </div>
                          </div>
                        </td>
                        <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap ">
                          <div class="flex items-center gap-x-2">
                            <div>
                              <h2 class="text-sm capitalize font-medium text-gray-800   ">
                                Rs.
                                {item.currentPaidAmount.reduce(
                                  (total, item) => {
                                    const amount = parseInt(item.amount); // Convert amount string to number
                                    return total + amount;
                                  },
                                  0
                                )}
                              </h2>
                            </div>
                          </div>
                        </td>
                        <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap ">
                          <div class="flex items-center gap-x-2">
                            <div>
                              <h2 class="text-sm capitalize font-medium text-gray-800   ">
                                Rs.
                                {item.salaryToBePaid -
                                  item.currentPaidAmount.reduce(
                                    (total, item) => {
                                      const amount = parseInt(item.amount); // Convert amount string to number
                                      return total + amount;
                                    },
                                    0
                                  )}
                              </h2>
                            </div>
                          </div>
                        </td>

                        <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                          <div class="flex items-center gap-x-2">
                            <div>
                              <h2 class="text-sm capitalize font-medium text-gray-800   ">
                                {`${
                                  item.salaryToBePaid ===
                                  item.currentPaidAmount.reduce(
                                    (total, item) => {
                                      const amount = parseInt(item.amount); // Convert amount string to number
                                      return total + amount;
                                    },
                                    0
                                  )
                                    ? "paid"
                                    : item.salaryToBePaid / 2
                                    ? "partial paid"
                                    : item.salaryToBePaid / 2 <
                                      item.currentPaidAmount.reduce(
                                        (total, item) => {
                                          const amount = parseInt(item.amount); // Convert amount string to number
                                          return total + amount;
                                        },
                                        0
                                      )
                                    ? "pending"
                                    : null
                                }`}
                              </h2>
                            </div>
                          </div>
                        </td>

                        <td class="  py-4 text-sm text-gray-500   whitespace-nowrap">
                          <div class="flex items-center  m-3">
                            <div className="flex items-center gap-3">
                              <h2 class="text-sm capitalize font-medium text-blue-800   ">
                                <BasicModal
                                  tittle={"Cridiet Money"}
                                  modalDetails={<UpdateSalary />}
                                  btn={
                                    <button
                                      className={`border rounded-md shadow-md cursor-pointer py-2 px-3  captialize bg-blue-600 text-white font-[300] `}
                                      onClick={() =>
                                        updateSingleSalary(item._id)
                                      }
                                    >
                                      Update Salary
                                    </button>
                                  }
                                />
                              </h2>
                              <h2 class="text-sm capitalize font-medium text-blue-800   ">
                                <Link to={`salary-log/${item._id}`}>
                                  <button
                                    className={`border rounded-md shadow-md text-white py-2 px-3  captialize bg-green-600 font-[300] `}
                                    onClick={() => SingleHistory(item._id)}
                                  >
                                    History
                                  </button>
                                </Link>
                              </h2>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <SalaryPagination count={1} page={1} />
    </>
  );
};

const th = [
  {
    name: "SN.",
  },
  {
    name: "Date",
  },
  {
    name: "Given Salary",
  },
  {
    name: " Paid  Salary",
  },

  {
    name: " Remainig Salary",
  },

  {
    name: "Status",
  },
];

export default SalaryList;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCustomer } from "../../Redux/Feature/sale/saleAction";

const UpdateCustomer = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("NHHD:token");
  const { singleSale } = useSelector((state) => state.sale);
  console.log(singleSale.customerAddress);
  const [customerName, setCustomerName] = useState(singleSale.customerName);
  const [customerAddress, setCustomerAddress] = useState(
    singleSale.customerAddress
  );
  const [customerPhone, setcustomerPhone] = useState(singleSale.customerPhone);

  const updateCustomerdetails = () => {
    dispatch(
      updateCustomer({
        id: singleSale._id,
        customerAddress,
        customerName,
        customerPhone,
        token,
      })
    );
  };

  return (
    <>
      <>
        <div class="container mx-auto">
          <div class="flex justify-center px-6 ">
            <div class="w-full lg:w-9/12 px-4">
              <div>
                <div
                  className="py-4 transition duration-150 ease-in-out z-10  "
                  id="modal"
                >
                  <div
                    role="alert"
                    className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
                  >
                    <div className="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
                      <h1 className="text-gray-800 font-lg font-bold tracking-normal leading-tight mb- py-3">
                        Updatde Customer Details
                      </h1>

                      <input
                        id="name"
                        className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                        placeholder="Customer Name"
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                      />
                      <input
                        id="email"
                        className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                        placeholder="Customer Email"
                        value={customerAddress}
                        onChange={(e) => setCustomerAddress(e.target.value)}
                      />
                      <input
                        id="phone"
                        className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                        placeholder="Customer Phone"
                        value={customerPhone}
                        onChange={(e) => setcustomerPhone(e.target.value)}
                      />

                      <div className="flex items-center justify-start w-full mt-3">
                        <button
                          className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-700 transition duration-150 ease-in-out hover:bg-green-600 bg-green-700 rounded text-white px-8 py-2 text-md uppercase  font-[400]"
                          onClick={() =>
                            updateCustomerdetails(
                              singleSale.soldTo && singleSale.soldTo._id
                            )
                          }
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default UpdateCustomer;

import React, { useEffect } from "react";
import Tittle from "../../Components/Tittle/Tittle";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeSalaryById } from "../../Redux/Feature/EmployeeSalary/EmpSalaryAction";
import { useParams } from "react-router-dom";

const SalaryHistory = () => {
  const params = useParams();
  console.log(params.id);
  const { singleEmp_salaries } = useSelector((state) => state.employeeSalary);

  console.log(singleEmp_salaries);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEmployeeSalaryById(params.id));
  }, [dispatch, params.id]);

  return (
    <div className="w-full">
      <Tittle tittle={"Salary Statement"} />
      <div className="flex flex-col mx-3 w-auto ">
        {singleEmp_salaries.map((item, i) => (
          <div
            key={i}
            className="border-gray-100 bg-gray-200 rounded-[6px] py-2 px-3 my-2"
          >
            <p className="text-md italic">
              Employee ID :{" "}
              <span className="text-[blue]">{item.employeeId}</span>
            </p>
            <p className="text-md italic">
              Created At :{" "}
              <span className="text-[blue]">{item.createdAt.slice(0, 10)}</span>
            </p>
            {/* <p className="text-md italic">
              Time : <span className="text-[blue]">{item.timeZone}</span>
            </p> */}
            <p className="text-md italic">
              Updated At :{" "}
              <span className="text-[blue]">{item.updatedAt.slice(0, 10)}</span>
            </p>
            <p className="text-md italic">
              Transaction Type :{" "}
              <span className="text-[blue]">{item.transctionType}</span>
            </p>
            {/* ... (other properties) */}
            <div>
              {/* Here, you can use a nested map to iterate through salaryDetails */}
              {item.salaryDetails.map((salaryItem, j) => (
                <div
                  key={j}
                  className="border-gray-100 bg-gray-200 rounded-[6px] py-2 px-3 my-2"
                >
                  <p className="text-md italic">
                    Month :{" "}
                    <span className="text-[blue]">{salaryItem.month}</span>
                  </p>
                  <p className="text-md italic">
                    Amount : Rs. <span className="">{salaryItem.amount}</span>
                  </p>
                  <p className="text-md italic">
                    Paid Date :{" "}
                    <span className="text-[blue]">
                      {new Date(salaryItem.paidDate).toLocaleDateString()}
                    </span>
                  </p>
                  <p className="text-md italic">
                    Status :{" "}
                    <span
                      className={`text-lg ${
                        salaryItem.status.toLowerCase() === "paid"
                          ? "text-[green]"
                          : salaryItem.status.toLowerCase() === "pending"
                          ? "text-[red]"
                          : salaryItem.status.toLowerCase() === "partial-paid"
                          ? "text-[blue]"
                          : null
                      }`}
                    >
                      {salaryItem.status}
                    </span>
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SalaryHistory;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../utils/API";

export const getDashboard = createAsyncThunk(
  "get/dashboard",
  async ({ token }) => {
    console.log(token);
    try {
      const response = await api.get("/home/dashboard", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

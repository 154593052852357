import { CircularProgress } from "@mui/material";
import React from "react";

const Button = ({ btnName, click, isLoading }) => {
  return (
    <div
      className={`border border-[green] px-3 py-2 rounded-[5px] text-[green] hover:border-[white] hover:bg-[green] hover:text-[white] cursor-pointer ${
        isLoading ? "opacity-50 cursor-not-allowed" : ""
      }`}
      onClick={isLoading ? null : click}
    >
      {isLoading ? <CircularProgress color="secondary" size={24.5} /> : btnName}
    </div>
  );
};

export default Button;

// import React from "react";
// import { CircularProgress } from "@mui/material";
// import Button from "@mui/material/Button";

// const CustomButton = ({ loading, btnName, click }) => {
//   return (
//     <div>
//       <Button
//         type="submit"
//         onClick={click}
//         fullWidth
//         variant="contained"
//         sx={{
//           color: "white",
//           background: "#484848",
//           "&:hover": {
//             borderColor: "1px solid black",
//             background: "white",
//             color: "black",
//           },
//           borderRadius: 0,
//           paddingX: "30px",
//           mt: 3,
//           mb: 2,
//         }}
//       >
//         {loading ? (
//           <CircularProgress color="secondary" size={24.5} />
//         ) : (
//           `${btnName}`
//         )}
//       </Button>
//     </div>
//   );
// };

// export default CustomButton;

import React, { useEffect } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../../../Components/Spinner/Spinner";
import {
  dltExpensesCategory,
  getAllExpensesCategory,
} from "../../../Redux/Feature/Expenses/expensesAction";

const ExpensesCategoryList = () => {
  const { isLoading, expensesCategory } = useSelector((state) => state.expense);
  console.log(expensesCategory);
  const dispatch = useDispatch();
  const token = localStorage.getItem("NHHD:token");
  useEffect(() => {
    dispatch(getAllExpensesCategory({ token }));
  }, [dispatch, token]);

  const dltCategory = (id) => {
    dispatch(dltExpensesCategory({ id, token }));
  };
  return (
    <section class="container px-4 mx-auto">
      <div class="flex flex-col">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div class="overflow-hidden border border-gray-200  md:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200  ">
                <thead class="bg-gray-50  ">
                  <tr>
                    {th.map((item, i) => {
                      return (
                        <th
                          scope="col col-span-5"
                          key={i}
                          class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                        >
                          {item.name}
                        </th>
                      );
                    })}
                    <th
                      scope="col col-span-5"
                      class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                {isLoading === true ? (
                  <Spinner />
                ) : (
                  <tbody class="bg-white divide-y divide-gray-200  ">
                    {expensesCategory && expensesCategory.length === 0
                      ? "No data Plese Add Data"
                      : expensesCategory &&
                        expensesCategory.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td class="px-4 py-4 text-sm font-medium text-gray-700  whitespace-nowrap">
                                <div class="inline-flex items-center gap-x-3">
                                  <span>{i + 1}</span>
                                </div>
                              </td>

                              <td class="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                <div class="flex items-center gap-x-2">
                                  <div>
                                    <h2 class="text-sm capitalize font-medium text-gray-800   ">
                                      {item.expenseCategory}
                                    </h2>
                                  </div>
                                </div>
                              </td>

                              <td class="px-4 py-4 text-sm whitespace-nowrap">
                                <div class="flex items-center gap-x-6">
                                  <button class="  transition-colors duration-200 text-red-500  focus:outline-none">
                                    <DeleteForeverIcon
                                      onClick={() => dltCategory(item._id)}
                                    />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const th = [
  {
    name: "SN.",
  },
  {
    name: "Category Name",
  },
];

export default ExpensesCategoryList;

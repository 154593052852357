import { createSlice } from "@reduxjs/toolkit";
import {
  deleteCategory,
  filterBycategoryName,
  getAllCategory,
  postCategorys,
} from "./cactegoryAction";

const categorySlice = createSlice({
  name: "category",
  initialState: {
    categorys: [],
    error: null,
    success: false,
    isLoading: false,
    count: 0,
    dltCount: 0,
    items: [],
    categories: [],
  },
  reducers: {},
  extraReducers: {
    [postCategorys.pending]: (state) => {
      state.isLoading = true;

      state.error = null;
      state.success = false;
    },
    [postCategorys.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.categories.push(action.payload.newCategory);

      state.error = null;
      state.success = action.payload.success;
    },
    [postCategorys.rejected]: (state, action) => {
      state.isLoading = false;

      state.error = action.payload;
    },

    [filterBycategoryName.pending]: (sate) => {
      sate.isLoading = true;

      sate.error = null;
      sate.success = false;
    },

    [getAllCategory.pending]: (state, action) => {
      state.isLoading = true;
      state.success = false;
    },

    [getAllCategory.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success;
      state.categories = action.payload;
      state.count = action.payload.categoryCount;
    },
    [getAllCategory.rejected]: (state, action) => {
      state.isLoading = true;
      state.success = false;
    },
    [filterBycategoryName.fulfilled]: (sate, action) => {
      console.log(action.payload);
      sate.isLoading = false;
      sate.categories = action.payload;
      sate.success = action.payload;
      sate.count = action.payload;
    },
    [filterBycategoryName.rejected]: (sate, action) => {
      sate.isLoading = false;
      sate.categories = null;
      sate.error = action.payload;
    },
    [deleteCategory.pending]: (state) => {
      state.isLoading = true;

      state.error = null;
      state.success = false;
      state.dltCount = 0;
    },
    [deleteCategory.fulfilled]: (state, { payload }) => {
      console.log(payload);
      state.isLoading = false;

      state.error = null;
      state.success = payload;
      state.dltCount = payload;
    },
    [deleteCategory.rejected]: (state, action) => {
      state.isLoading = false;
      state.categories = null;
      state.error = action.payload;
    },
  },
});

export const { deleteItem } = categorySlice.actions;
export const categoryReducer = categorySlice.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../utils/API";

export const getPurchaseAccount = createAsyncThunk(
  "get/purchase-account",
  async ({ token }) => {
    try {
      const response = await api.get(`/purchase-account/all-purchase-account`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const getPurchaseAccountByMonth = createAsyncThunk(
  "get/purchase-account/month",
  async ({ token, selectedItem }) => {
    try {
      const response = await api.get(
        `/purchase-account/purchase-account-month?month=${selectedItem}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response, "response");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

import { createSlice } from "@reduxjs/toolkit";
import {
  addSale,
  deleteSale,
  fetchSale,
  fetchSaleById,
  filterByCusName,
  filterByDate,
  generateBills,
  salePaymentRecived,
  saleReturn,
  updateCustomer,
} from "./saleAction";

const saleSclice = createSlice({
  name: "sale",
  initialState: {
    isLoading: true,
    success: false,
    sales: [],
    singleSale: [],
    updateSale: [],
    count: 0,
    bils: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addSale.pending, (state) => {
      state.isLoading = true;
      state.success = false;
      state.sales = [];
    });
    builder.addCase(addSale.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(addSale.rejected, (state, payload) => {
      state.isLoading = false;
      state.success = false;
      state.sales = payload;
    });
    builder.addCase(fetchSale.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(fetchSale.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success;
      state.sales = action.payload.sales;
      state.count = action.payload.count;
    });
    builder.addCase(fetchSale.rejected, (state, payload) => {
      state.isLoading = false;
      state.success = false;
    });

    builder.addCase(deleteSale.pending, (state) => {
      state.isLoading = true;
      state.success = false;
      state.sales = null;
    });
    builder.addCase(deleteSale.fulfilled, (state, action) => {
      state.isLoading = true;
      state.success = action.payload.success;
      state.sales = action.payload.message;
    });
    builder.addCase(deleteSale.rejected, (state) => {
      state.isLoading = true;
      state.success = false;
      state.sales = null;
    });
    builder.addCase(fetchSaleById.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(fetchSaleById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
      state.singleSale = action.payload.sale;
    });
    builder.addCase(fetchSaleById.rejected, (state) => {
      state.isLoading = true;
      state.success = false;
      state.sales = null;
    });
    builder.addCase(updateCustomer.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(updateCustomer.fulfilled, (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      state.success = action.payload;
      state.singleSale = action.payload.sale;
    });
    builder.addCase(updateCustomer.rejected, (state) => {
      state.isLoading = true;
      state.success = false;
      state.sales = null;
    });
    builder.addCase(filterByCusName.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(filterByCusName.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
      state.sales = action.payload.sales;
    });
    builder.addCase(filterByCusName.rejected, (state) => {
      state.isLoading = true;
      state.success = false;
      state.sales = null;
    });
    builder.addCase(filterByDate.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(filterByDate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
      state.sales = action.payload.sales;
    });
    builder.addCase(filterByDate.rejected, (state) => {
      state.isLoading = true;
      state.success = false;
      state.sales = null;
    });
    builder.addCase(generateBills.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(generateBills.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success;
      state.bils = action.payload.success;
    });
    builder.addCase(generateBills.rejected, (state) => {
      state.isLoading = true;
      state.success = false;
      state.sales = null;
    });
    builder.addCase(saleReturn.pending, (state) => {
      state.isLoading = true;
      state.success = false;
      state.sales = [];
    });
    builder.addCase(saleReturn.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    });
    builder.addCase(saleReturn.rejected, (state, payload) => {
      state.isLoading = false;
      state.success = false;
      state.sales = payload;
    });
    builder.addCase(salePaymentRecived.pending, (state) => {
      state.isLoading = true;
      state.success = false;
      state.sales = [];
    });
    builder.addCase(salePaymentRecived.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success;
    });
    builder.addCase(salePaymentRecived.rejected, (state, payload) => {
      state.isLoading = false;
      state.success = false;
      state.sales = payload;
    });
  },
});

export const saleReducer = saleSclice.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../utils/API";
import { toast } from "react-toastify";

export const addtoBadDebt = createAsyncThunk(
  "post/bad debt",
  async (payload) => {
    console.log(payload.Amount);
    try {
      const response = await api.post("/baddebt/add-baddebt", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
        },
      });
      if (response.data.success === true) {
        toast.success(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addBadDebttoRecovery = createAsyncThunk(
  "post/bad debt/recovery",
  async (payload) => {
    try {
      const response = await api.post(
        "/baddebt/add-baddebt-recovery",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("NHHD:token")}`,
          },
        }
      );
      if (response.data.success === true) {
        toast.success(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

import React, { useEffect, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteSale,
  fetchSale,
  fetchSaleById,
  filterByCusName,
  filterByDate,
} from "../../Redux/Feature/sale/saleAction";
import { Spinner } from "../../Components/Spinner/Spinner";
import Pagination from "./Pagination";

const SaleList = () => {
  const { isLoading, sales, count } = useSelector((state) => state.sale);

  console.log(sales);

  const th = [
    {
      name: "SN.",
    },
    {
      name: "Customer Name",
    },
    {
      name: "Customer Address",
    },
    {
      name: "Customer Phone",
    },
    {
      name: "Sold Date",
    },
  ];
  const token = localStorage.getItem("NHHD:token");
  const dispatch = useDispatch();
  const [saleData, setSaleData] = useState([]);
  console.log(saleData);
  console.log(saleData, "searvh");
  useEffect(() => {
    dispatch(fetchSale({ token, setSaleData }));
  }, [dispatch, token]);

  const DeleteSale = (id) => {
    dispatch(deleteSale(id));
  };

  const [customerName, setCustomerName] = useState("");
  useEffect(() => {
    async function fetch() {
      dispatch(filterByCusName({ token, customerName, setSaleData }));
    }
    fetch();
  }, [customerName, token, dispatch]);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const submitDate = () => {
    if (startDate && endDate) {
      dispatch(filterByDate({ startDate, endDate, token }));
    }
  };

  const navigateToSingleSale = (id) => {
    console.log(id);
    dispatch(fetchSaleById({ token, id }));
  };

  return (
    <div>
      <div className="my-2 lg:grid md:grid lg:grid-cols-3 md:grid-cols-2 gap-4 grid-flow-row">
        <div className="flex gap-2 mt-4">
          {/* <span className=" text-2xl border bg-green-800 text-white rounded-[6px] p-2 h-[50px]">
              <AiOutlineSearch />
            </span> */}
          <input
            type="search"
            placeholder="Search by Customer Name"
            className="rounded-[6px] h-[50px] w-full"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
          />
        </div>
        <div className="  flex gap-2">
          <div>
            <p>Start Date</p>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>

          <div>
            <p>End Date</p>
            <div className="flex">
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="lg:flex lg:justify-center">
          <p
            className="uppercase border border-white mx-2  bg-green-800  text-white text-bold rounded-[6px]    w-[100px] text-center pt-2 h-[40px] lg:mt-[1.4rem] md:mt-0 mt-2"
            onClick={submitDate}
          >
            Find
          </p>
        </div>
      </div>

      <div className="flex flex-col">
        <div className=" -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden border border-gray-200   md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200  ">
                <thead className="bg-gray-50  ">
                  <tr>
                    {th.map((item, i) => {
                      return (
                        <th
                          scope="col col-span-5"
                          key={i}
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                        >
                          {item.name}
                        </th>
                      );
                    })}
                    <th
                      scope="col col-span-5"
                      className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                {isLoading === true ? (
                  <Spinner />
                ) : (
                  <tbody className="bg-white divide-y divide-gray-200    ">
                    {saleData && saleData.length === 0
                      ? "no data found"
                      : saleData &&
                        saleData.slice(0, 20).map((item, i) => {
                          return (
                            <tr key={i}>
                              <td className="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                <div className="inline-flex items-center gap-x-3">
                                  <span>{i + 1}</span>
                                </div>
                              </td>

                              <td className="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                <div className="flex items-center gap-x-2">
                                  <Link to={`single-sale`}>
                                    <div>
                                      <h2
                                        className="text-sm capitalize font-medium text-gray-800   "
                                        onClick={() =>
                                          navigateToSingleSale(item._id)
                                        }
                                      >
                                        {item.customerName}
                                      </h2>
                                      <p className="text-sm">
                                        Sale id: {item._id}
                                      </p>
                                    </div>
                                  </Link>
                                </div>
                              </td>
                              <td className="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                <div className="flex items-center gap-x-2">
                                  <div>
                                    <h2 className="text-sm capitalize font-medium text-gray-800   ">
                                      {item.customerAddress}
                                    </h2>
                                  </div>
                                </div>
                              </td>
                              <td className="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                <div className="flex items-center gap-x-2">
                                  <div>
                                    <h2 className="text-sm capitalize font-medium text-gray-800   ">
                                      {item.customerPhone}
                                    </h2>
                                  </div>
                                </div>
                              </td>
                              <td className="px-4 py-4 text-sm text-gray-500   whitespace-nowrap">
                                <div className="flex items-center gap-x-2">
                                  <div>
                                    <h2 className="text-sm capitalize font-medium text-gray-800   ">
                                      {item.saleDate}
                                    </h2>
                                  </div>
                                </div>
                              </td>

                              <td className="px-4 py-4 text-sm whitespace-nowrap">
                                <div className="flex items-center gap-x-6">
                                  <button
                                    className="  transition-colors duration-200 text-red-500  focus:outline-none"
                                    onClick={() => DeleteSale(item._id)}
                                  >
                                    <DeleteForeverIcon />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
        <Pagination page={1} count={count} />
      </div>
    </div>
  );
};

export default SaleList;

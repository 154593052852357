import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { fetchAssestById } from "../../../Redux/Feature/Assest/assestAction";
import { Container } from "@mui/material";

const SingleAssests = () => {
  // const { singleAssests, isLoading } = useSelector((state) => state.assest);
  // console.log(singleAssests);
  const location = useLocation();
  const item = location.state.item;
  console.log(item);
  const param = useParams();
  const id = param.id;
  console.log(id);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAssestById({ id }));
  }, [dispatch, id]);
  return (
    <Container>
      {/* {isLoading === true && <Spinner />}{" "} */}
      <div className="grid grid-flow-row grid-cols-2 gap-3 capitalize ">
        <div>
          <img
            src={item.image.url}
            alt={item.assestName}
            className="h-[350px "
          />
          <p>
            <span>Assest Name :-</span>
            <span>{item.assestName}</span>
          </p>
          <p>
            <span>Assest Price :-</span>
            <span>Rs.{item.assestPrice}</span>
          </p>
          <p>
            <span>Assest Buying Date :-</span>
            <span> {new Date(item.assestDate).toDateString()}</span>
          </p>
        </div>
        <div className="my-3">
          <p>
            <span> Assessment Method :-</span>
            <span>{item.assessmentMethod}</span>
          </p>
          <p>
            <span>
              {item.assessmentMethod === "Partial Credit" && (
                <>
                  <p>
                    <span>Partial Credit :-</span>
                    <span>{item.partial_Credit}</span>
                  </p>
                  <p>
                    <span>Partial Amount :-</span>
                    <span>{item.partial_Amount}</span>
                  </p>
                </>
              )}
            </span>
          </p>
          <p>
            <span> Transiction Method :-</span>
            <span>{item.transctionType}</span>
          </p>
          <p>
            <span>
              {item.assessmentMethod === "Both" && (
                <>
                  <p>
                    <span>Partial Bank :-</span>
                    <span>{item.partial_bank}</span>
                  </p>
                  <p>
                    <span>Partial Cash :-</span>
                    <span>{item.partial_cash}</span>
                  </p>
                </>
              )}
            </span>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default SingleAssests;

import { createSlice } from "@reduxjs/toolkit";
import { getSalesReturn, getSalesReturnByMonth } from "./salesReturnAction";

const salesReturnSlice = createSlice({
  name: "salesReturn",
  initialState: {
    salesReturn: [],
    error: null,
    isLoading: true,
    count: 0,
    salesReturnByMonth: [],
  },
  reducers: {},
  extraReducers: {
    [getSalesReturn.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getSalesReturn.fulfilled]: (state, action) => {
      // console.log(action.payload);
      state.isLoading = false;
      state.count = action.payload.count;
      console.log(action.payload);
      state.salesReturn = action.payload.saleReturnAccount;
    },
    [getSalesReturn.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getSalesReturnByMonth.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getSalesReturnByMonth.fulfilled]: (state, action) => {
      // console.log(action.payload);
      state.isLoading = false;
      console.log(action.payload);
      state.salesReturnByMonth = action.payload.saleReturn;
    },
    [getSalesReturnByMonth.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export const salesReturnReducer = salesReturnSlice.reducer;

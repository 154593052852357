import React from "react";

const CreditTranscation = ({ single }) => {
  console.log(single);
  return (
    <>
      {/* <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-flow-row gap-2">
        {single.purchaseCreditReturn.slice(0, 6).map((ele, i) => (
          <p
            className="border bg-yellow-400 text-start px-2  shadow-md rounded-md py-3 "
            key={i}
          >
            {single.supplierName} Recived{" "}
            <span className=" text-red-900 font-bold px-2 ">
              Rs.{ele.amount}
            </span>
            through{" "}
            {ele.transactionType === "Both"
              ? "Bank & Cash"
              : ele.transactionType}
            &nbsp; at &nbsp;
            <span className="underline text-[green] font-medium">
              {ele.date.replace("T00:00:00.000Z", "")}
            </span>
          </p>
        ))}
      </div> */}
      <section class="container px-1 mx-auto">
        <div class="flex flex-col">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-2 lg:px-2">
              <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200  ">
                  <thead class="bg-gray-50  ">
                    <tr>
                      {th.map((item, i) => {
                        return (
                          <th
                            scope="col col-span-5"
                            key={i}
                            class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500  "
                          >
                            {item.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-gray-200    ">
                    {single && single.length === 0
                      ? "no data found"
                      : single &&
                        single.transcationDetails
                          .slice(0, 20)
                          .map((item, i) => {
                            return (
                              <tr key={i}>
                                <td className="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                  <div className="inline-flex items-center gap-x-3">
                                    <span>{item.date.slice(0, 10)}</span>
                                  </div>
                                </td>
                                <td className="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                  <div className="inline-flex items-center gap-x-3">
                                    <span>{item.amount}</span>
                                  </div>
                                </td>
                                <td className="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                  <div className="inline-flex items-center gap-x-3">
                                    <span>{item.transactionType}</span>
                                  </div>
                                </td>
                                <td className="px-4 py-4 text-sm font-medium text-gray-700   whitespace-nowrap">
                                  <div className="inline-flex items-center gap-x-3">
                                    <span>{item.status}</span>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreditTranscation;

const th = [
  {
    name: "Date",
  },
  {
    name: "Amount",
  },

  {
    name: "Transaction Means",
  },
  {
    name: "Transaction Status",
  },
];

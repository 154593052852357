const { createSlice } = require("@reduxjs/toolkit");
const { addReturnProduct, allReturnProduct } = require("./returnProductAction");

const returnSlice = createSlice({
  name: "return",
  initialState: {
    isLoading: true,
    productReturn: [],
    success: false,
  },

  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addReturnProduct.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(addReturnProduct.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(addReturnProduct.rejected, (state, payload) => {
      state.isLoading = true;
      state.success = false;
    });

    builder.addCase(allReturnProduct.pending, (state) => {
      state.isLoading = true;
      state.success = false;
    });
    builder.addCase(allReturnProduct.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success;
      state.productReturn = action.payload.returnProduct;
    });
    builder.addCase(allReturnProduct.rejected, (state, payload) => {
      state.isLoading = true;
      state.success = false;
      state.productReturn = [];
    });
  },
});

export const returnReducer = returnSlice.reducer;
